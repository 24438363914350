export const MetaDataInfo = {
    home: {
        Title: "Best artificial intelligence | AI apps |top artificial intelligence company",
        Description: "Blinx AI provides apps to tackle the latest complex problems with simple analysis and predictions that leverage artificial intelligence and Machine learning. ",
        Keywords: "Ai apps,artificial intelligence applications,key to data modernization,ai model,analyst,data analyst,ai data analyst",
        URL: "https://www.blinx.ai/",
        URLKeys: "ai capabilities,case study on artificial intelligence,ai implementation,data management,data protection,copy data management,develop data strategy",
        OGKeyword: "artificial intelligence,Ai apps",
        OGDescription: "Blinx AI provides apps to tackle the latest complex problems with simple analysis and predictions that leverage artificial intelligence and Machine learning."
    },
    platform: {
        Title: "AI Platform | Top artificial intelligence apps company | AI applications| BlinxAI",
        Description: "AI App Platform allows for the creation of applications that run on both the Google Cloud Platform and on-premises. It targets data scientists and data engineers ",
        Keywords: "artificial intelligence applications,Blinx Platform Technology,AI App Platform,AI lifecycle automation,AI frameworks,cloud application management",
        URL: "https://www.blinx.ai/platform",
        URLKeys: "data science,Business and IT Collaboration,AI apps",
        OGKeyword: "AI App Platform ,AI lifecycle automation",
        OGDescription: "AI App Platform allows for the creation of applications that run on both the Google Cloud Platform and on-premises. It targets data scientists and data engineers.",
    },
    it: {
        Title: "AI in IT | AI technologies for IT | Blinx AI",
        Description: "Ai in IT To manage information technology based on a multi based platform. Blinx is best place for all IT related industries apps with artificial intelligence",
        Keywords: "AI in IT,AI technologies for IT ,AI Technologies,AI apps,public app store",
        URL: "https://www.blinx.ai/solutions/it",
        URLKeys: "AI in IT,AI technologies for IT ,AI Technologies,AI apps,public app store",
        OGKeyword: "AI in IT,AI technologies for IT",
        OGDescription: "Ai in IT To manage information technology based on a multi based platform. Blinx is best place for all IT related industries apps with artificial intelligence",
    },
    "business-function": {
        Title: "AI customer segmentation| AI solution| Blinx AI platform",
        Description: "AI has a wide range of uses in business  like sales demand forecasting, targeted marketing, ad campaign in It department, hr department, customer service",
        Keywords: "sales,demand forecasting,targeted marketing,ad campaign,It department,Hr department,customer service,sales team",
        URL: "https://www.blinx.ai/solutions/business-function",
        URLKeys: "ad campaign,It department,Hr department,customer service,sales team",
        OGKeyword: "customer segmentation,predictive lead scoring",
        OGDescription: "AI has a wide range of uses in business  like sales demand forecasting, targeted marketing, ad campaign in It department, hr department, customer service",
    },
    industries: {
        Title: "AI industries| Artificial Intelligence applications | AI apps",
        Description: "",
        Keywords: "Aerospace company,agriculture artificial intelligence,ai in healthcare,healthcare ai use cases,ai in entertainment,arificial intelligence services company",
        URL: "https://www.blinx.ai/solutions/industries",
        URLKeys: "Aerospace company,agriculture artificial intelligence,ai in healthcare,healthcare ai use cases,ai in entertainment,arificial intelligence services company",
        OGKeyword: "AI industries,Artificial Intelligence in Healthcare",
        OGDescription: " ",
    },
    "product-videos": {
        Title: "AI app lifecycle|app videos | short videos",
        Description: "Watch short videos from our knowledge library to learn about every aspect of AI app lifecycle – from data to models to apps",
        Keywords: "AI app lifecycle,app videos,short videos,videos for app,installation of apps,blinx apps",
        URL: " ",
        URLKeys: "product videos,app videos,shorts app videos,blinx videos",
        OGKeyword: "AI app lifecycle,app videos,short videos",
        OGDescription: "Watch short videos from our knowledge library to learn about every aspect of AI app lifecycle – from data to models to apps",
    },
    "use-cases": {
        Title: "use cases for ai apps|AI apps | application videos",
        Description: "Blinx AI use cases describe what the platform does with the apps for the users so that the user experiences the best practical application of apps.",
        Keywords: "use cases,apps in AI,artificial apps",
        URL: "https://www.blinx.ai/resources/use-cases",
        URLKeys: "apps videos,artificial intelligence apps,AI app videos,AI app use cases",
        OGKeyword: "use cases for ai apps,app videos,application videos",
        OGDescription: "Blinx AI use cases describe what the platform does with the apps for the users so that the user experiences the best practical application of apps.",
    },
    shorts: {
        Title: "AI apps|short videos | application videos",
        Description: "get a brief detail of how our Blinx AI's various apps perform with their use case, in a short time through our Short videos",
        Keywords: "short videos,AI apps,videos AI",
        URL: "https://www.blinx.ai/resources/shorts",
        URLKeys: "short videos,app videos",
        OGKeyword: "AI apps,short videos",
        OGDescription: "get a brief detail of how our Blinx AI's various apps perform with their use case, in a short time through our Short videos",
    },
    blog: {
        Title: "Blog-Blinx AI",
        Description: "drown deep into the world of Blinx AI platform for easy understanding of the app and make your work easy",
        Keywords: "short videos,AI apps,AI applications",
        URL: "https://blogs.blinx.ai/",
        URLKeys: "Blog,Blinx AI",
        OGKeyword: "Blog,Blinx AI",
        OGDescription: "drown deep into the world of Blinx AI platform for easy understanding of the app and make your work easy",
    },
    "about-us": {
        Title: "Ai app store|artificial intelligence|AI Application|AI Apps",
        Description: "we the Blinx AI platform turn your data into AI apps today and allow users to experience Artificial Intelligence in a easy way",
        Keywords: "ai app development company in usa,ai app store,Artificial intelligence apps,product videos,best ai application",
        URL: "https://www.blinx.ai/about-us",
        URLKeys: "Ai app store,artificial intelligence,AI apps,ai application,ai app videos,ai app use cases",
        OGKeyword: "Ai app store,artificial intelligence,AI in aerospace",
        OGDescription: "we the Blinx AI platform turn your data into AI apps today and allow users to experience Artificial Intelligence in a easy way",
    },
    aerospace: {
        Title: "Aerospace company | artificial intelligence in aerospace | BlinxAI",
        Description: "Artificial intelligence in the aerospace industry helps businesses maintain aircraft and streamline their takeoff and landing processes.",
        Keywords: "Aerospace company,artificial intelligence in aerospace,artificial intelligence technology, landing crashes,Aircraft,aircraft maintenance,rocket",
        URL: "https://www.blinx.ai/ai-appstore/artificial-intelligence-in-aerospace",
        URLKeys: "Aerospace company,artificial intelligence in aerospace,artificial intelligence technology, landing crashes,Aircraft,aircraft maintenance,rocket",
        OGKeyword: "Aerospace company,artificial intelligence in aerospace",
        OGDescription: "Artificial intelligence in the aerospace industry helps businesses maintain aircraft and streamline their takeoff and landing processes.",
    },
    agriculture: {
        Title: "Best agriculture apps| artificial intelligence in agriculture |crop yield | BlinxAI",
        Description: "Agriculture in artificial intelligence helps promote sustainable agriculture by addressing agricultural problems and protecting crop yields.",
        Keywords: "traditional agricultural,benefits of AI,AI benefits,use of AI",
        URL: "https://www.blinx.ai/ai-appstore/artificial-intelligence-in-agriculture",
        URLKeys: "artificial intelligence robotics,agricultural problems,AI in agricultural,AI and agricultural",
        OGKeyword: "sustainable agricultural,artificial intelligence in agriculture,crop yield",
        OGDescription: "Agriculture in artificial intelligence helps promote sustainable agriculture by addressing agricultural problems and protecting crop yields.",
    },
    "automobiles-and-vehicles": {
        Title: "Artificial intelligence in the automobiles and vehicles | automobile applications| BlinxAI",
        Description: "Artificial intelligence in the automobile and vehicle industry predicts the value of a vehicle, and self driving cars are helping the automobile industry boom.",
        Keywords: "AI in automotive, AI car, AI and self driving cars, AI in self driving cars",
        URL: "https://www.blinx.ai/ai-appstore/artificial-intelligence-in-automobiles-and-vehicles",
        URLKeys: "advance auto parts, auto parts, self driving cars, self driving vehicles",
        OGKeyword: "Artificial intelligence in the automobiles and vehicles, automobile industry",
        OGDescription: "Artificial intelligence in the automobile and vehicle industry predicts the value of a vehicle, and self driving cars are helping the automobile industry boom.",
    },
    chemical: {
        Title: "artificial intelligence in the chemical industry | Chemical applications| BlinxAI",
        Description: "artificial intelligence in the chemical industry helps predict wine intensity, quality of coal, CO2 emissions, and nuclear radiation to give the best suggestions.",
        Keywords: "nuclear power plant,quality,coal,types of wine",
        URL: "https://www.blinx.ai/ai-appstore/artificial-intelligence-in-chemical",
        URLKeys: "AI chemical structure,AI chemicals, AI in chemical industry,nuclear radiation",
        OGKeyword: "artificial intelligence in the chemical industry, Chemical applications",
        OGDescription: "artificial intelligence in the chemical industry helps predict wine intensity, quality of coal, CO2 emissions, and nuclear radiation to give the best suggestions.",
    },
    "cities-and-urban-areas": {
        Title: "artificial intelligence in cities and urban areas |cities and urban areas applications| AI city",
        Description: "artificial intelligence for cities and urban areas is an app that studies the traffic, demographics, and population to suggest you the best suitable location.",
        Keywords: "AI city,smart cities AI, city AI security system,city AI,cities skylines traffic AI,Smart City,living spaces,location",
        URL: "https://www.blinx.ai/ai-appstore/artificial-intelligence-in-cities-and-urban-areas",
        URLKeys: "AI city,smart cities AI,location, city AI security system,city AI,cities skylines traffic AI,Smart City,living spaces",
        OGKeyword: "artificial intelligence in cities and urban areas, cities and urban areas applications",
        OGDescription: "artificial intelligence for cities and urban areas is an app that studies the traffic, demographics, and population to suggest you the best suitable location.",
    },
    "computer-and-software": {
        Title: "artificial intelligence in computer and software |Best AI software",
        Description: "Protect your business against disruption and changing software demand with our Blinx AI's computer and software apps",
        Keywords: "current AI technology,artificial intelligence latest developments,artificial intelligence impact,quantum computing,current AI technology,how is AI used",
        URL: "https://www.blinx.ai/ai-appstore/artificial-intelligence-in-computer-and-software",
        URLKeys: "current AI technology,artificial intelligence latest developments,artificial intelligence impact,quantum computing,current AI technology,how is AI used",
        OGKeyword: "artificial intelligence in cities computer and software,Best AI software",
        OGDescription: "Protect your business against disruption and changing software demand with our Blinx AI's computer and software apps",
    },
    crime: {
        Title: "crime in AI |AI crime defense |AI crime Applications |Blinx AI",
        Description: "Artificial intelligence crime app helps in crime prevention by identifying the various types of fraud and alert you before time. Blinx Ai provides best Ai apps",
        Keywords: "AI and financial crimes,AI predict next crime",
        URL: "https://www.blinx.ai/ai-appstore/artificial-intelligence-in-crime",
        URLKeys: "AI artificial intelligence patterns data model,prevent terrorist attacks crime",
        OGKeyword: "crime in AI,AI crime defense,AI crime applications",
        OGDescription: "Artificial intelligence crime app helps in crime prevention by identifying the various types of fraud and alert you before time. Blinx Ai provides best Ai apps",
    },
    "earth-and-nature": {
        Title: "artificial intelligence for earth and nature|earth and nature applications",
        Description: "Earth and Nature app with Artificial Intelligence, best used for disaster prediction like natural calamities, sea ice prediction, and others to prevent life.",
        Keywords: "AI and financial crimes,AI predict next crime",
        URL: "https://www.blinx.ai/ai-appstore/artificial-intelligence-in-earth-and-nature",
        URLKeys: "AI artificial intelligence patterns data model,prevent terrorist attacks crime",
        OGKeyword: "artificial intelligence for earth and nature,earth and nature applications",
        OGDescription: "Earth and Nature app with Artificial Intelligence, best used for disaster prediction like natural calamities, sea ice prediction, and others to prevent life.",
    },
    education: {
        Title: "artificial intelligence in education |Education applications|Blinx AI",
        Description: "Blinx AI provides a best AI apps for education industryartificial intelligence helps in education like grading in college, school online classess",
        Keywords: "school of AI,artificial intelligence in education,learning in AI,artificial intelligence education,machine learning education,AI intelligence in education",
        URL: "https://www.blinx.ai/ai-appstore/artificial-intelligence-in-education",
        URLKeys: "school of AI,artificial intelligence in education,learning in AI,artificial intelligence education,machine learning educationAI intelligence in education",
        OGKeyword: "artificial intelligence education,education applications",
        OGDescription: "Blinx AI provides a best AI apps for education industry artificial intelligence helps in educations like grading in college, school online class.",
    },
    energy: {
        Title: "AI applications| AI for energy | future of energy industry|Blinx AI",
        Description: "Blinx AI is one of the leading Energy and power traders in USA. We support you with the Market Access & other Trading Services with Artificial intelligence apps",
        Keywords: "artificial intelligence in energy,AI in energy industry,AI power,machine learning renewable energy,future of energy industry",
        URL: "https://www.blinx.ai/ai-appstore/artificial-intelligence-in-energy",
        URLKeys: "renewable energy smart grid,applications of AI,energy problems,us energy consumption,AI for energy",
        OGKeyword: "AI applications,AI for energy,future of energy industry",
        OGDescription: "Blinx AI is one of the leading Energy and power traders in USA. We support you with the Market Access & other Trading Services with Artificial intelligence apps",
    },
    "human-resources": {
        Title: "AI in human resources| AI for recruitment |human resources applications|Blinx AI",
        Description: "Artificial Intelligence in human resources fits best for predicting hiring, motivation, and employee attrition rate resulting in forming the best workforce.",
        Keywords: "AI for hr,machine learning and human resources,AI in human resources,human resource topics,AI resume screening,machine learning resume",
        URL: "https://www.blinx.ai/ai-appstore/artificial-intelligence-in-human-resources",
        URLKeys: "virtual human resource management images,AI resume template,resume screening template,AI for recruitment,AI recruitment tools,resume processing AI app",
        OGKeyword: "AI in human resources,AI for recruitment,human resources applications",
        OGDescription: "Artificial Intelligence in human resources fits best for predicting hiring, motivation, and employee attrition rate resulting in forming the best workforce.",
    },
    infrastructure: {
        Title: "AI in infrastructure|infrastructure applications|Blinx AI",
        Description: "Artificial intelligence with Infrastructure helps in predicting concrete strength and analyzing bridge design, energy efficiency for making cities innovative.",
        Keywords: "AI ready infrastructure,hoi4 AI and infrastructure,leveraging AI for network infrastructure improve",
        URL: "https://www.blinx.ai/ai-appstore/artificial-intelligence-in-infrastructure",
        URLKeys: "AI platform for network infrastructure,miscellaneous,machine learning infrastructure",
        OGKeyword: "AI ready infrastructure,machine learning infrastructure",
        OGDescription: "Artificial intelligence with Infrastructure helps in predicting concrete strength and analyzing bridge design, energy efficiency for making cities innovative.",
    },
    insurance: {
        Title: "AI in insurance|insurance applications|Blinx AI",
        Description: "Artificial intelligence in insurance is best used for fraud detection, predicting claims, damages related queries for a better understanding of the insurance world.",
        Keywords: "AI in insurance,AI insurance,AI essentials insurance",
        URL: "https://www.blinx.ai/ai-appstore/artificial-intelligence-in-insurance",
        URLKeys: "AI adoption in insurance industry,AI for insurance,AI insurance trust,health insurance AI,machine learning in insurance",
        OGKeyword: "AI insurance,insurance applications",
        OGDescription: "Artificial intelligence in insurance is best used for fraud detection, predicting claims, damages related queries for a better understanding of the insurance world.",
    },
    management: {
        Title: "AI in management| management applications|Blinx AI",
        Description: "Artificial Intelligence in Management helps in generating information related to energy from waste, mainly focusing on the proper utilization of resources.",
        Keywords: "AI product manager,AI and machine learning manager,office management budget AI",
        URL: "https://www.blinx.ai/ai-appstore/artificial-intelligence-in-management",
        URLKeys: "AI manager rimworld,AI energy management systems,AI investment management,AI management",
        OGKeyword: "AI in management,management applications",
        OGDescription: "Artificial Intelligence in Management helps in generating information related to energy from waste, mainly focusing on the proper utilization of resources.",
    },
    manufacturing: {
        Title: "AI in manufacturing|manufacturing applications|Blinx AI",
        Description: "Artificial Intelligence in Manufacturing predicts the best ways to ensure precision in your manufacturing processes",
        Keywords: "AI use cases in manufacturing,manufacturing AI,AI in manufacturing",
        URL: "https://www.blinx.ai/ai-appstore/artificial-intelligence-in-manufacturing",
        URLKeys: "AI use cases in manufacturing,manufacturing AI,AI in manufacturing",
        OGKeyword: "AI in manufacturing,manufacturing manufacturing applications",
        OGDescription: "Artificial Intelligence in Manufacturing predicts the best ways to ensure precision in your manufacturing processes",
    },
    "oil-and-gas": {
        Title: "AI in oil and gas|Best oil and gas applications|Blinx AI",
        Description: "Machine Learning and AI in oil and gas predict and monitor the fuel price, oil price, moreover pipeline accidents to ensure the right decision can be taken before the cause.",
        Keywords: "AI in oil and gas,AI and data analytics in drilling oil and gas,AI machine learning data analytics drilling oil gas",
        URL: "https://www.blinx.ai/ai-appstore/artificial-intelligence-in-oil-and-gas",
        URLKeys: "AI in oil and gas market,oil and gas AI companies,AI in oil and gas market,Machine learning in oil and gas",
        OGKeyword: "AI in oil and gas,best oil and gas applications",
        OGDescription: "Machine Learning and AI in oil and gas predict and monitor the fuel price, oil price, moreover pipeline accidents to ensure the right decision can be taken before the cause.",
    },
    pharma: {
        Title: "AI pharma industry|pharma applications|Blinx AI",
        Description: "Artificial Intelligence in pharma embraces the healthcare sector by analyzing drug classification, side effects, and tumor detectors to allow early preventive decisions.",
        Keywords: "AI in pharmaceutical industry,AI in pharmaceuticals,artificial intelligence in pharmaceuticals,AI and pharma",
        URL: "https://www.blinx.ai/ai-appstore/artificial-intelligence-in-pharma",
        URLKeys: "mind maps AI in pharma,AI in pharma challenges,pharma industry,predictive medicine companies,pharma intelligence,new technology in pharmaceutical industry,AI drug discovery,drug development process,drug development analytics",
        OGKeyword: "AI pharma industry,pharma applications",
        OGDescription: "Artificial Intelligence in pharma embraces the healthcare sector by analyzing drug classification, side effects, and tumor detectors to allow early preventive decisions.",
    },
    pollution: {
        Title: "Artificial intelligence in pollution|pollutions applications|Blinx AI",
        Description: "Blinx AI provides a best pollutions control apps in artificial intelligence. such as air quality monitoring app, air pollution tracking apps.",
        Keywords: "AI in pharmaceutical industry,AI in pharmaceuticals,artificial intelligence in pharmaceuticals,AI and pharma",
        URL: "https://www.blinx.ai/ai-appstore/artificial-intelligence-in-pollution",
        URLKeys: "mind maps AI in pharma,AI in pharma challenges,pharma industry,predictive medicine companies,pharma intelligence,new technology in pharmaceutical industry,AI drug discovery,drug development process,drug development analytics",
        OGKeyword: "Artificial intellligence in pollution,pollution applications",
        OGDescription: "Blinx AI provides a best pollutions control apps in artificial intelligence. such as air quality monitoring app, air pollution tracking apps.",
    },
    "public-services": {
        Title: "AI in public sector|public sector applications|Blinx AI",
        Description: "Artificial intelligence in the public services industry harnesses the power to solve government rugged tasks through its apps like  Aquifers, waste trade, Uber pickups",
        Keywords: "AI services public companies,AI in public sector,public sector AI,AI in public sector uk",
        URL: "https://www.blinx.ai/ai-appstore/artificial-intelligence-in-public-services",
        URLKeys: "AI services public companies,AI in public sector,public sector AI,AI in public sector uk",
        OGKeyword: "AI in public sector,public sector applications",
        OGDescription: "Artificial intelligence in the public services industry harnesses the power to solve government rugged tasks through its apps like  Aquifers, waste trade, Uber pickups",
    },
    "real-estate": {
        Title: "Artificial intelloigence in real estate|real estate applications|Blinx AI",
        Description: "Blinx AI offers the best artificial intelligence real estate apps such as AI realtor, Rent Metrics, and Construction Cost Predictor",
        Keywords: "AI in real estate,AI and real estate,real estate AI",
        URL: "https://www.blinx.ai/ai-appstore/artificial-intelligence-in-real-estate",
        URLKeys: "AI for commercial real estate,AI and ml in real estate,AI for real estate leads",
        OGKeyword: "AI in real estate,real estate Applications",
        OGDescription: "Blinx AI offers the best artificial intelligence real estate apps such as AI realtor, Rent Metrics, and Construction Cost Predictor",
    },
    retail: {
        Title: "AI in retail|retail apps|retail apps",
        Description: "from a prediction of fashion trends, beauty skin care routines to analyzing grocery stores all can be done through Artificial Intelligence in the Retail sector",
        Keywords: "Alco tracker app,cosmoalerts app,elite agro,fuel spy app",
        URL: "https://www.blinx.ai/ai-appstore/artificial-intelligence-in-retail",
        URLKeys: "mercado app,salesmax app,DPZ cap prediction app,fashion trends apps,easy sales apps,fiat re auto app",
        OGKeyword: "AI in retail,retial apps",
        OGDescription: "from a prediction of fashion trends, beauty skin care routines to analyzing grocery stores all can be done through Artificial Intelligence in the Retail sector",
    },
    satellite: {
        Title: " AI in satellite |free AI satellite tracking apps|Blinx AI",
        Description: "Blinx AI offers you a free AI satellite tracking app to analyze and predict climate change impacted by the Australian wildfire to save further life.",
        Keywords: "satellite AI,free AI satellite tracking",
        URL: "https://www.blinx.ai/ai-appstore/artificial-intelligence-in-satellite",
        URLKeys: "AI based satellite image analysis,AI in satellite analysis",
        OGKeyword: "AI in satellite,free AI satellite tracking app",
        OGDescription: "Blinx AI offers you a free AI satellite tracking app to analyze and predict climate change impacted by the Australian wildfire to save further life.",
    },
    science: {
        Title: "artificial intelligence in science|science applications|Blinx AI",
        Description: "With Blinx AI, you can use artificial intelligence to explore the world of science, predict meteoroids in space, analyze natural disasters, and make effective decisions.",
        Keywords: "artificial intelligence science, AI revolution in science,neural networks and deep learning",
        URL: "https://www.blinx.ai/ai-appstore/artificial-intelligence-in-science",
        URLKeys: "neural network,revolution in science",
        OGKeyword: "artificial intelligence in science,science applications",
        OGDescription: "With Blinx AI, you can use artificial intelligence to explore the world of science, predict meteoroids in space, analyze natural disasters, and make effective decisions",
    },
    "social-media": {
        Title: "AI in social media|social media applications|Blinx AI",
        Description: "Blinx AI's Insta filter app keeps the user away from the Instagram fake spammer with its Artificial Intelligence technology and lets you enjoy your social media.",
        Keywords: "AI in social media,social media icons AI,artificial intelligence and social media,AI in social media market",
        URL: "https://www.blinx.ai/ai-appstore/artificial-intelligence-in-social-media",
        URLKeys: "social media calendar template,content calendar template,social media AI,AI in social media market,AI social media content creation",
        OGKeyword: "AI in social media,social media applications",
        OGDescription: "Blinx AI's Insta filter app keeps the user away from the Instagram fake spammer with its Artificial Intelligence technology and lets you enjoy your social media.",
    },
    sport: {
        Title: "AI in sports|sports applications|Blinx AI",
        Description: "Blinx AI best place to provide artificial intelligence apps for the sports industry like IPL and rugby games. and also Sporting License Fraud Predicting app",
        Keywords: "AI in sports,video game AI sportsbook,sport AI,AI sports cycle support",
        URL: "https://www.blinx.ai/ai-appstore/artificial-intelligence-in-sport",
        URLKeys: "AI sport referee,al sporting goods,AI sports tv,AI and sports",
        OGKeyword: "AI in sports,sports applications",
        OGDescription: "Blinx AI best place to provide artificial intelligence apps for the sports industry like IPL and rugby games. and also Sporting License Fraud Predicting app",
    },
    wildlife: {
        Title: "AI to protect wildlife|AI for wildlife|Blinx AI",
        Description: "Blinx AI offers wildlife market apps with artificial intelligence, through which a user can analyze any agitation in the market related to wildlife.",
        Keywords: "wildlife market app,AI for wildlife conservation,AI to protect wildlife,AI for wildlife conservation,AI apps in wildlife",
        URL: "https://www.blinx.ai/ai-appstore/artificial-intelligence-in-wildlife",
        URLKeys: "AI for wildlife conservation,AI to protect wildlife,AI for wildlife conservation",
        OGKeyword: "AI to protect wildlife,AI for wildlife conservation",
        OGDescription: "Blinx AI offers wildlife market apps with artificial intelligence, through which a user can analyze any agitation in the market related to wildlife",
    },
    "weather-and-climate": {
        Title: "AI weather forecasting|weather and climate applications|Blinx AI",
        Description: "Artificial intelligence in Weather and Climate industry predicts and analyze various aspect with Blinx AI apps like Floods Predictive System, Air Mask, etc.",
        Keywords: "global weather,global weather radar,weather predictor globe,predicting weather",
        URL: "https://www.blinx.ai/ai-appstore/artificial-intelligence-in-weather-and-climate",
        URLKeys: "weather prediction,AI forecast,AI weather forecasting,periodic table,accurate weather,most accurate weather",
        OGKeyword: "AI weather forecasting,weather and climate applications",
        OGDescription: "Artificial intelligence in Weather and Climate industry predicts and analyze various aspect with Blinx AI apps like Floods Predictive System, Air Mask, etc.",
    },
    entertainment: {
        Title: "Artificial Intelligence in enterainment | Entertainment applications | AI-generated art",
        Description: "Artificial intelligence is taking a twist on the entertainment industry by developing applications for playing and designing games, AI-generated art, and custom gifts.",
        Keywords: "games,prediction,lotto numbers,custom gifts,Gender Recognition by Voice",
        URL: "https://www.blinx.ai/ai-appstore/artificial-intelligence-in-entertainment",
        URLKeys: "voice analysis,christmas gift ideas,promotions,christmas gifts,lotto,video games,AI",
        OGKeyword: "Artificial Intelligence in enterainment, Entertainment applications",
        OGDescription: "Artificial intelligence is taking a twist on the entertainment industry by developing applications for playing and designing games, AI-generated art, and custom gifts.",
    },
    financial: {
        Title: "Artificial Intelligence in Financial Services |financial applications| BlinxAI",
        Description: "Artificial intelligence in financial services makes it easier for people to effectively manage their finances. Helps to predict house prices and stock prices.",
        Keywords: "Crypto Trading,Predicting House Sale Prices using ML ,Black Swan Event ,customer segmentation ,stock markets,AI",
        URL: "https://www.blinx.ai/ai-appstore/artificial-intelligence-in-financial",
        URLKeys: "budget,Financial expenditure, stock prices,insurance,Bank Accounts, marketing campaigns ",
        OGKeyword: "Artificial Intelligence in Financial Services,financial applications",
        OGDescription: "Artificial intelligence in financial services makes it easier for people to effectively manage their finances. Helps to predict house prices and stock prices.",
    },
    fmcg: {
        Title: "Artificial intelligence in FMCG | fmcg applications| BlinxAI",
        Description: "FMCG with Artificial intelligence gives accurate predictions for healthy skin, health and beauty tips,and the overall cosmetic industry.",
        Keywords: "cosmetics industry,Beauty and Cosmetics,facial beauty prediction,cosmetics products,health and beauty,AI",
        URL: "https://www.blinx.ai/ai-appstore/artificial-intelligence-in-fmcg",
        URLKeys: "cosmetics industry,Beauty and Cosmetics,facial beauty prediction,cosmetics products,health and beauty",
        OGKeyword: "Artificial intelligence in FMCG,fmcg applications",
        OGDescription: "FMCG with Artificial intelligence gives accurate predictions for healthy skin, health and beauty tips,and the overall cosmetic industry.",
    },
    "food-and-drink": {
        Title: "Artificial intelligence in food and drinks |food and drinks applications| BlinxAI",
        Description: "Artificial intelligence in Food & Drinks focuses on ecological assets, analyzes the natural resource, and reflects the issues needed to be considered.",
        Keywords: "AI in food industry,natural resources,food and beverage,AI",
        URL: "https://www.blinx.ai/ai-appstore/artificial-intelligence-in-food-and-drink",
        URLKeys: "AI in food industry,natural resources,food and beverage,AI",
        OGKeyword: "Artificial intelligence in food and drinks,food and drinks applications, BlinxAI",
        OGDescription: "Artificial intelligence in Food & Drinks focuses on ecological assets, analyzes the natural resource, and reflects the issues needed to be considered.",
    },
    healthcare: {
        Title: "Artificial Intelligence in Healthcare | healthcare applications | BlinxAI",
        Description: "Artificial Intelligence in Healthcare covers the prediction of heart diseases, and cancer prognosis to analyzing COVID-19 cases to make the world a healthy place to live.",
        Keywords: "healthcare AI,AI in healthcare,economic impact of covid ,medical insurance,covid 19 tracker,health insurance,Predicting heart disease",
        URL: "https://www.blinx.ai/ai-appstore/artificial-intelligence-in-healthcare",
        URLKeys: "healthcare AI,AI in healthcare,economic impact of covid ,medical insurance,covid 19 tracker,health insurance,Predicting heart disease,AI",
        OGKeyword: "Artificial Intelligence in Healthcare,healthcare applications",
        OGDescription: "Artificial Intelligence in Healthcare covers the prediction of heart diseases, and cancer prognosis to analyzing COVID-19 cases to make the world a healthy place to live.",
    },
    marine: {
        Title: "Artificial intelligence in marine | ocean pollution | marine applications| BlinxAI",
        Description: "Artificial Intelligence in Marine analyzes ocean pollution, and coastal litter to forecast the plastic debris and choose the best preventive steps to save the marine ecosystem.",
        Keywords: "plastic pollution in the ocean, pollution in the ocean,coastal,plastic debris in the ocean,marine ais,Artificial Intelligence in the Ocean",
        URL: "https://www.blinx.ai/ai-appstore/artificial-intelligence-in-marine",
        URLKeys: "plastic pollution in the ocean, pollution in the ocean,coastal,plastic debris in the ocean,marine ais,Artificial Intelligence in the Ocean,AI",
        OGKeyword: "Artificial intelligence in marine,ocean pollution,marine applications",
        OGDescription: "Artificial Intelligence in Marine analyzes ocean pollution, and coastal litter to forecast the plastic debris and choose the best preventive steps to save the marine ecosystem.",
    },
    marketing: {
        Title: "Artificial intelligence in Marketing |AI marketing apps | marketing applications",
        Description: "Artificial intelligence in Marketing is leveraging an environmentally friendly method for fuel consumption with Blinx's bike sharing App.",
        Keywords: "AI marketing,environmentally friendly,fuel consumption,AI in digital marketing",
        URL: "https://www.blinx.ai/ai-appstore/artificial-intelligence-in-marketing",
        URLKeys: "AI and ml in marketing,traffic management,bike sharing,AI",
        OGKeyword: "Artificial intelligence in Marketing, AI marketing app,marketing applications",
        OGDescription: "Artificial intelligence in Marketing is leveraging an environmentally friendly method for fuel consumption with Blinx's bike sharing App.",
    },
    "media-and-advertisement": {
        Title: "Artificial intelligence for Media and Advertising |media and advertising applications",
        Description: "Artificial Intelligence drives the Media and advertising industry to experience technology upgrades with Apps recommending the Best Bollywood movies, Marketing Strategies, etc.",
        Keywords: "AI advertising,Top 50 songs,Movie Reviews,traditional persian music",
        URL: "https://www.blinx.ai/ai-appstore/artificial-intelligence-in-media-and-advertisement",
        URLKeys: "Music apps,marketing campaigns,Measuring health lifestyles,digital advertising",
        OGKeyword: "Artificial intelligence for Media and Advertising, best bollywood movies ",
        OGDescription: "Artificial Intelligence drives the Media and advertising industry to experience technology upgrades with Apps recommending the Best Bollywood movies, Marketing Strategies, etc.",
    },
    military: {
        Title: "Artificial intelligence in Military |military applications",
        Description: "Artificial Intelligence with Military power app, contribute to the most powerful military forces with its analyzing and predicting feature making manpower more powerful.",
        Keywords: "AI in the military,military power,Military capability,fsx military AI",
        URL: "https://www.blinx.ai/ai-appstore/artificial-intelligence-in-military",
        URLKeys: "AI in the military,military power,Military capability,fsx military AI,AI",
        OGKeyword: "Artificial intelligence in Military,military applications",
        OGDescription: "Artificial Intelligence with Military power app, contribute to the most powerful military forces with its analyzing and predicting feature making manpower more powerful.",
    },
    misc: {
        Title: "Artificial intelligence for tobacco risk prediction|Assessing the Health and Well-being of Horses",
        Description: "Emerging apps using Artificial Intelligence covers multiple functionality apps from tobacco risk prediction to Assessing the Health and Well-being of Horses fulfilling all needs of the user",
        Keywords: "find color from image,Tobacco Risk Predictor,horse eye,next chess move",
        URL: "https://www.blinx.ai/ai-appstore/artificial-intelligence-in-misc",
        URLKeys: "AI voice recognition,best places to raise a family,classification of waves,AI",
        OGKeyword: "Artificial intelligence for tobacco risk prediction,Assessing the Health and Well-being of Horses",
        OGDescription: "Emerging apps using Artificial Intelligence covers multiple functionality apps from tobacco risk prediction to Assessing the Health and Well-being of Horses fulfilling all needs of the user",
    },
    telecom: {
        Title: "Artificial intelligence in Telecommunications | telecom applications| BlinxAI",
        Description: "Blinx is taking a leap forward to transform the future of AI in telecom industry by providing various applications for its growth and improvement.",
        Keywords: "artificial intelligence in telecommunication,Customer Retention in the Telecom Industry,fake review finder,Coverage prediction for mobile radio systems,Global Video Streaming Market Share Report",
        URL: "https://www.blinx.ai/ai-appstore/artificial-intelligence-in-telecom",
        URLKeys: "artificial intelligence in telecommunication,Customer Retention in the Telecom Industry,fake review finder,Coverage prediction for mobile radio systems,Global Video Streaming Market Share Report",
        OGKeyword: "Artificial intelligence in Telecommunications ,telecom applications",
        OGDescription: "Blinx is taking a leap forward to transform the future of AI in telecom industry by providing various applications for its growth and improvement.",
    },
    transportation: {
        Title: "Artificial intelligence in Transportation |Transportation applications",
        Description: "Artificial intelligence in transportation will let you manage Effective Logistics, Air Transport Management with the help of its apps such as traffic tracker.",
        Keywords: "public transportation,Air Traffic Analyzer,website traffic tracker,Reaching for the Skies",
        URL: "https://www.blinx.ai/ai-appstore/artificial-intelligence-in-transportation",
        URLKeys: "Air Transport Management,AI in transportation,Effective Logistics,AI in the transportation & logistics sector",
        OGKeyword: "Artificial intelligence in Transportation,transporations applications",
        OGDescription: "Artificial intelligence in transportation will let you manage Effective Logistics, Air Transport Management with the help of its apps such as traffic tracker.",
    },
    "travel-and-tourism": {
        Title: "Artificial intelligence in Travel and Tourism |travel and tourism applications",
        Description: "AI in travel and tourism app takes you on the tour of predicting hotel booking and analyzing recreational activities for your hassle-free vacation.",
        Keywords: "amusement parks,AI travel assistant,AI in travel & transport,Travel and Tourism,Hotel Booking Prediction,Hotel Booking,recreational activities",
        URL: "https://www.blinx.ai/ai-appstore/artificial-intelligence-in-travel-and-tourism",
        URLKeys: "amusement parks,AI travel assistant,AI in travel & transport,Travel and Tourism,Hotel Booking Prediction,Hotel Booking,recreational activities",
        OGKeyword: "Artificial intelligence in Travel and Tourism,travel and tourism applications",
        OGDescription: "AI in travel and tourism app takes you on the tour of predicting hotel booking and analyzing recreational activities for your hassle-free vacation.",
    },
    warehouse: {
        Title: "Artificial intelligence in Warehouse | warehouse applications |Global Trade Tracker",
        Description: "Blink's warehouse app using Artificial intelligence contributes a large value towards inventory management and Global Trade Tracker for smoother warehouse operations.",
        Keywords: "Truck Arrival and Departure Tracking System,Export Tracker,Export tracker artifacts,coast guard,Port of entry,ways to improve inventory management,AI in operations management,Container Statistics",
        URL: "https://www.blinx.ai/ai-appstore/artificial-intelligence-in-warehouse",
        URLKeys: "Truck Arrival and Departure Tracking System,Export Tracker,Export tracker artifacts,coast guard,Port of entry,ways to improve inventory management,AI in operations management,Container Statistics",
        OGKeyword: "Artificial intelligence in Warehouse,warehouse applications,Global Trade Tracker",
        OGDescription: "Blink's warehouse app using Artificial intelligence contributes a large value towards inventory management and Global Trade Tracker for smoother warehouse operations.",
    },
    "privacy-policy": {
        Title: "Privacy Policy - Blinx AI",
        Description: "Blinx is committed to securing your privacy. This privacy statement describes how Blinx collects, uses, and discloses your personal information.",
        Keywords: "Blinx ai,Blinx privacy-policy",
        URL: "https://www.blinx.ai/privacy-policy",
        URLKeys: "Blinx ai,Blinx policy",
        OGKeyword: "Privacy Policy - Blinx AI",
        OGDescription: "Blinx is committed to securing your privacy. This privacy statement describes how Blinx collects, uses, and discloses your personal information.",
    },
    "instant-model": {
        Title: "Instant model |instant modeler|Blinx AI",
        Description: "Blinx AI provides an instant model tool for datasets to get quick a result.",
        Keywords: "advanced model building,Instant Model tool",
        URL: "https://www.blinx.ai/instant-model",
        URLKeys: "Blinx AI,instant model tool,ai app tool",
        OGKeyword: "Instant model,create instant model",
        OGDescription: "Blinx AI provides an instant model tool for datasets to get quick a result.",
    },
    "terms-and-conditions": {
        Title: "Terms and conditions - Blinx AI",
        Description: "Terms and conditions details to make sure you are protected in Ai applications.",
        Keywords: "Blinx AI, terms and conditions",
        URL: "https://www.blinx.ai/terms-and-conditions",
        URLKeys: "Blinx AI, terms and conditions",
        OGKeyword: "terms and conditions - Blinx AI",
        OGDescription: "Terms and conditions details to make sure you are protected in Ai applications.",
    },
    "ai-app-store": {
        Title: "ai app store|ai applications |artificial intelligence apps |Blinx AI",
        Description: "AI AppStore platform offers AI and ML models to predict, analyse, and forecast different industries like agriculture, manufacturing, insurance, finance, real estate, crime, health care,  and chemicals.",
        Keywords: "advanced model building,Instant Model tool",
        URL: "https://www.blinx.ai/ai-app-store",
        URLKeys: "Artificial intelligence apps,ai apps store,ai app store,blinx, ai in aerospace apps,pollution apps,climate prediction apps",
        OGKeyword: "ai app store,ai applications",
        OGDescription: "AI AppStore platform offers AI and ML models to predict, analyse, and forecast different industries like agriculture, manufacturing, insurance, finance, real estate, crime, health care,  and chemicals.",
    },
    "pricing-plan": {
        Title: "ai app store|ai applications |artificial intelligence apps |Blinx AI",
        Description: "AI AppStore platform offers AI and ML models to predict, analyse, and forecast different industries like agriculture, manufacturing, insurance, finance, real estate, crime, health care,  and chemicals.",
        Keywords: "advanced model building,Instant Model tool",
        URL: "https://www.blinx.ai/ai-app-store",
        URLKeys: "Artificial intelligence apps,ai apps store,ai app store,blinx, ai in aerospace apps,pollution apps,climate prediction apps",
        OGKeyword: "ai app store,ai applications",
        OGDescription: "AI AppStore platform offers AI and ML models to predict, analyse, and forecast different industries like agriculture, manufacturing, insurance, finance, real estate, crime, health care,  and chemicals.",
    },

};