import React, {createRef} from "react";
import Dropzone from "react-dropzone";
import Icon from "../../Assets/IconLibrary/Icon";
import Button from "../../Assets/Buttons/Button";
import "./instantModel.css";
import "./sources.css"

const dropzoneRef = createRef()
const browseRef = createRef()

export default class FileUpload extends React.Component {
    zipFileUploadOnDrop() {
        console.log("zipFileUploadOnDrop")
        let dragArea = document.getElementById("drag-drop-zip-file-screen");
        let dragFrame = document.getElementById("drop-zip-file-frame");
        dragArea.style.display = "none";
        dragFrame.style.border = "2px dashed #cdcdcd"
    }

    zipFileUploadDragOver() {
        let dragArea = document.getElementById("drag-drop-zip-file-screen");
        let dragFrame = document.getElementById("drop-zip-file-frame");
        dragArea.style.display = "block";
        dragFrame.style.border = "2px dashed #cdcdcd"
    }

    zipFileUploadDragLeave() {
        let dragArea = document.getElementById("drag-drop-zip-file-screen");
        let dragFrame = document.getElementById("drop-zip-file-frame");
        dragArea.style.display = "none";
        dragFrame.style.border = "2px dashed #cdcdcd"
    }

    handleBrowseOpenFiles() {
        console.log("reached to open files")
        if (browseRef.current) {
            browseRef.current.open()
        }
    }

    handleCancelBrowseFile() {
        console.log("entered to handleCancelBrowseFile")
    }

    handleSaveFileInfo(event) {
        console.log("handleSaveFileInfo :: ", event.target)
        let browsedFiles = event.target.files;
        console.log("browsedFiles >> ", browsedFiles)
        let acceptedFiles = []
        for (let i = 0; i < browsedFiles.length; i++) {
            acceptedFiles = acceptedFiles.concat(browsedFiles[i])
        }
        this.fileUploadOnDrop("browse", acceptedFiles)
    }

    onFileInputChange(event) {
        console.log("entered to onFileInputChange", event.target.files)
    }

    fileUploadOnDrop(screen, acceptedFiles) {
        if (screen === "screen") {
        } else if (screen === "browse") {
            console.log("checking accepted files key :: ", acceptedFiles)
        } else {
            let draggingScreen = document.getElementById("file-drag-popup");
            if (draggingScreen !== null) {
                draggingScreen.style.display = "none";
            }
        }
    }

    render() {
        return (
            <div className="instant-model-file-drop-zone" style={{background: "blue"}}>
                <Dropzone
                    onDrop={this.zipFileUploadOnDrop.bind(this)}
                    onDragEnter={this.zipFileUploadDragOver.bind(this)}
                    onDragLeave={this.zipFileUploadDragLeave.bind(this)}
                    noClick={true}
                    disabled={false}
                    ref={dropzoneRef}
                >
                    {({getRootProps, getInputProps}) => (
                        <>
                            <div {...getRootProps()}
                                 className="instant-model-file-drop-dotted-line"
                                 id='drop-zip-file-frame'>
                                <Icon
                                    icon="file-upload"
                                    size={30}/>
                                <p>{"Drag and Drop your files"}</p>


                                <div className="instant-model-file-drop-middle-label">
                                    <div
                                        className="instant-model-file-drop-or-line-decoration"/>
                                    <div className="instant-model-label--or">OR</div>
                                </div>
                                <Button onClick={() => this.handleBrowseOpenFiles()}
                                        labelText="Upload your file"
                                        backgroundColor={"var(--primary-color)"}/>


                                <Dropzone
                                    noClick={true}
                                    ref={browseRef}
                                    onFileDialogCancel={() => this.handleCancelBrowseFile()}
                                >
                                    {({getRootProps, getInputProps}) => (
                                        <section>
                                            <div {...getRootProps()}
                                                 className="source-container-frame">
                                                <input {...getInputProps()}
                                                       type="file"
                                                       name="browseFileInformation"
                                                       onChange={this.handleSaveFileInfo.bind(this)}
                                                />
                                            </div>
                                        </section>
                                    )}
                                </Dropzone>
                                <div
                                    id="drag-drop-zip-file-screen">
                                    <div
                                        className='zip-file-drop-screen'>
                                        <Icon
                                            icon='dragAndDrop'
                                            size={35}
                                            className='upload-image-icon'/>
                                        <div
                                            className='zip-file-drop-title'>Drop
                                            to
                                            upload
                                            the
                                            file
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <input {...getInputProps()}
                                   className="upload-zip-file-input-box"
                            />
                        </>
                    )}
                </Dropzone>
            </div>
        )
    }
}