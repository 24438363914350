import React from "react";
import "./instantModel.css";
import Popup from "reactjs-popup";
import {Scrollbars} from "react-custom-scrollbars";
import PropTypes from 'prop-types';
import Radio from "@mui/material/Radio";
import GhostButton from "../../Assets/Buttons/GhostButton";
import Button from "../../Assets/Buttons/Button";

class ChooseCategoricalValue extends React.Component{
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        let {valueList} = this.props;
        let width = 600;
        let itemWidth = "50%";
        if(valueList.length < 10){
            width = 400;
        }
        return (
            <Popup overlayStyle={{backgroundColor: 'rgba(0,0,0,70%)'}}
                   contentStyle={{
                       width: "auto",
                       height: "auto",
                       backgroundColor: 'transparent',
                       padding: 0,
                       borderRadius: 0,
                   }}
                   lockScroll={true}
                   closeOnDocumentClick={false}
                   open={true}

            >

                <div className="choose-categorical-value-layout" style={{width: width}}>
                    <div className="choose-categorical-value--header">
                        <div className="choose-categorical-value--title">Categorical Values</div>
                        <button className="choose-categorical-value--exit">
                            X
                        </button>
                    </div>
                    <div className="choose-categorical-value--body">
                        <div className="choose-categorical-value--description">
                            Choose Categorical Value
                        </div>
                        <div className="choose-categorical-value-list-container">
                            < Scrollbars
 renderThumbVertical={({style, ...props}) =>
                                <div {...props} style={{...style, width: '5px', borderRadius: '4px',backgroundColor: '#76a1d5'}}/>
                            } style={{height: '100%', width: '100%'}}>
                                <div className="choose-categorical-value-list">
                                    {
                                        valueList.map((value, vid)=>
                                        <div className="categorical-value-item" key={vid}>
                                            <Radio size="small" />
                                            <div className="categorical-value">Value {vid}</div>
                                        </div>
                                        )
                                    }
                                </div>
                            </Scrollbars>
                        </div>

                    </div>
                    <div className="choose-categorical-value-pagination-row">

                    </div>
                    <div className="choose-categorical-value-bottom-row">
                        <GhostButton onClick={()=>{}} label={"Cancel"} color={"var(--body-color)"}/>
                        <Button onClick={()=>{}} labelText={"Choose"} backgroundColor={"var(--primary-color)"}/>
                    </div>
                </div>
            </Popup>
        );
    }
}


ChooseCategoricalValue.defaultProps={
    valueList :[
        1,2,3,4,5,6,,7,8,9,10
    ]
}

ChooseCategoricalValue.propTypes={
    valueList : PropTypes.array.isRequired
}
export default ChooseCategoricalValue