import React from "react";
import "./pricingPlan.css";
import MetaData from "../MetaData/MetaData";
import Icon from "../../Assets/IconLibrary/Icon";
import {DefaultParameters} from "../../api/ApiCallMethod";

class PricingPlan extends React.Component{
    constructor(props) {
        super(props);
        this.state ={
            pricingPlanList:[
                {
                    Name:   "Free",
                    Price:  "$0",
                    Unit:   "Per User Per Month",
                    Data:   "1GB",
                    Models: "25",
                    Apps:   "5",
                },
                {
                    Name:   "Premium",
                    Price:  "$1000",
                    Unit:   "Per User Per Month",
                    Data:   "100GB",
                    Models: "Unlimited",
                    Apps:   "Unlimited",
                },
                {
                    Name:   "Enterprise",
                    Price:  "Custom",
                    Unit:   "",
                    Data:   "Unlimited",
                    Models: "Unlimited",
                    Apps:   "Unlimited",
                },
            ]
        }
    }

    contactForPrice = () =>{
        window.open("https://calendly.com/blinx_sales", "_blank")
    }

    getStartedWithPrice = () =>{
        // let productUrl = DefaultParameters().ProductURL;
        // productUrl += DefaultParameters().SignupRoute;
        // window.open(productUrl, '_blank');
        window.open("https://calendly.com/blinx_sales", "_blank");

    }

    render() {
        return (
            <div className='pricing-plan-layout'>
                <MetaData keyword={"pricing-plan"}/>
                <div className="pricing-plan-body bg-dark">
                    <div className="pricing-plan-header">
                        Flexible Plans and Pricing
                    </div>
                    <div className="pricing-plan-description">
                        We work with you to understand your unique needs and provide a tailored plan that fits your budget.
                    </div>
                </div>
                <div className="pricing-plan-body bg-light">
                    {/*<div className="pricing-plan-body--header">Plans and Pricing</div>*/}
                    {/*<div className="pricing-plan-body--tag-line">*/}
                    {/*    We work with you to understand your unique needs and provide a tailored solution that fits your budget.*/}
                    {/*</div>*/}
                    <div className="pricing-plan-card-list">
                        {
                            this.state.pricingPlanList.map((plan,pid)=>
                                <div className="pricing-plan-card">
                                    <div className="pricing-plan-card--header">
                                        <div className="pricing-plan-card-tag-line">
                                            {plan.Name}
                                        </div>
                                        <div className="pricing-plan-card-pricing-info">
                                            <div className="pricing-plan-card-price">
                                                {plan.Price}
                                            </div>
                                            <div className="pricing-plan-card-price-unit">
                                                {plan.Unit}
                                            </div>
                                        </div>

                                        <button className="pricing-plan-card-cta-btn"
                                                onClick={pid === 2? this.contactForPrice.bind(this):this.getStartedWithPrice.bind(this)}>
                                            {pid === 2? "Contact Us": "Get Started"}
                                        </button>
                                    </div>
                                    <div className="pricing-plan-card-body">
                                        <div className="pricing-plan-card-body--title">Includes</div>
                                        <div className="pricing-plan-card-info-row">
                                            <div className="pricing-plan-card-info-row--label">
                                                Data
                                            </div>
                                            <div className="pricing-plan-card-info-row--value">
                                                {plan.Data}
                                            </div>
                                        </div>
                                        <div className="pricing-plan-card-info-row">
                                            <div className="pricing-plan-card-info-row--label">
                                                Models
                                            </div>
                                            <div className="pricing-plan-card-info-row--value">
                                                {plan.Models}
                                            </div>
                                        </div>
                                        <div className="pricing-plan-card-info-row">
                                            <div className="pricing-plan-card-info-row--label">
                                                Apps
                                            </div>
                                            <div className="pricing-plan-card-info-row--value">
                                                {plan.Apps}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default PricingPlan;