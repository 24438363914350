import React from "react";
import Lottie from "react-lottie";
import {AnimationList} from "./AnimationList";

export default function LottieAnimation({ lotti, width, height }) {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: AnimationList[lotti],
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },

    };

    return (
        <div>
            <Lottie options={defaultOptions} height={height} width={width} />
        </div>
    );
};

LottieAnimation.defaultProps={
    height: "150px",
    width: "100%"
}

LottieAnimation.propsType = {

}