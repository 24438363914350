import React from "react";
import "./errorPages.css";

class ErrorPages extends React.Component{
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    goToHome = () =>{
        // this.props.history.push(`/`)
        // this.props.history.go(this.props.history);
        this.props.history.goBack();
    }
    render() {
        return (
            <div className="error-page-layout">
                <div className="error-page-container">
                    <div className="error-page-text_container">
                        <div className="error-page-header-text">
                           Sorry, We couldn't find that page
                        </div>
                        <div className="error-page-content">
                            The page you are looking for might have been removed, had its name changed or is temporarily unavailable.
                        </div>
                        <div className="error-page-button-container">
                            <button className="error-page-button" onClick={this.goToHome.bind(this)}>Go Back</button>
                        </div>
                    </div>
                    <img src={'/static/media/404PageNotFound.svg'} alt={"404 Page Not Found"} className="error-page-logo"/>
                </div>

            </div>
        );
    }

}

export default ErrorPages;