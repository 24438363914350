import React,{lazy,Suspense } from "react";
import "./platform.css";
import {Carousel} from "react-responsive-carousel";
import {PlatformConcept} from "../../Assets/Contents/AIPlatformConcept";
import {CheckEmpty, CheckValidity} from "../../Assets/CommonMethods";
import Icon from "../../Assets/IconLibrary/Icon";
import Button from "../../Assets/Buttons/Button";
// import SummaryText from "../Commoncomponents/SummaryText/SummaryText";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import MetaData from "../MetaData/MetaData";
import {ReactComponent as Carousel1} from "../../Assets/Images/Carousel_1.svg";
import Carousel2 from "../../Assets/Images/Carousel_2.svg";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay} from "swiper";

const SummaryText =lazy(() => import('../Commoncomponents/SummaryText/SummaryText'));

class DetailedAIPlatform extends React.Component{
    constructor(props) {
        super(props);
        this.state = {}
    }

        componentDidMount() {
                window.scrollTo(0,0)
        }
    gotoSolutionPage = () =>{
        this.props.history.push("/solutions/industries");
        this.props.history.go(this.props.history);
    }
    render() {
        return (
            <div className="platform-layout">
                <MetaData keyword="platform"/>
                <Suspense fallback={''}>
                <SummaryText topic="fullLifeCycle"/>
                </Suspense>
                <div className="platform-carousel-division">
                    {/*<Swiper*/}
                    {/*    spaceBetween= {0}*/}
                    {/*    centeredSlides= {true}*/}
                    {/*    speed= {5000}*/}
                    {/*    modules={[Autoplay]}*/}
                    {/*    autoplay= {{*/}
                    {/*        delay: 10,*/}
                    {/*    }}*/}
                    {/*    loop= {true}*/}
                    {/*    slidesPerView={1}*/}
                    {/*    allowTouchMove= {false}*/}
                    {/*    disableOnInteraction= {true}*/}
                    {/*    preventClicks={true}*/}
                    {/*    preventClicksPropagation={true}*/}
                    {/*    preventInteractionOnTransition={true}*/}

                    {/*>*/}
                    {/*    <SwiperSlide>*/}
                    {/*    <Carousel1 className="carousel-image" alt='Rapid Turn Data Into AI apps'/>*/}
                    {/*    </SwiperSlide>*/}
                    {/*    <SwiperSlide>*/}
                    {/*    <img src={Carousel2} className="carousel-image" alt='blinx Platform Technology'/>*/}
                    {/*    </SwiperSlide>*/}
                    {/*</Swiper>*/}
                    <Carousel showThumbs={false}
                              interval={5000}
                              infiniteLoop={true} autoPlay={true} className="platform-carousel">
                        <Carousel1 className="carousel-image" alt='Rapid Turn Data Into AI apps'/>
                        <img src={Carousel2} className="carousel-image" alt='blinx Platform Technology'/>
                    </Carousel>
                </div>
                <div className="platform-main-info">
                    <div className="platform-main-info--block">
                        <div className="platform-main-info--title">
                            Blinx AI App Platform
                        </div>
                        <div className="platform-main-info--description">
                            Blinx enables full AI lifecycle automation from data to apps – all accessible through a design studio. Blinx platform integrates a feature lake, app factory, AI app store, and model server.
                        </div>
                    </div>
                    <div className="platform-main-info--block">
                        <div className="platform-main-info--title">
                            Blinx Platform Technology
                        </div>
                        <div className="platform-main-info--description">
                            Blinx integrates technologies ranging from popular data connectors, data integration software, AI frameworks, containers, and integrations for cloud application management.                        </div>
                    </div>
                </div>

                {
                    PlatformConcept.map((concept, cid)=>
                        <>
                            <div className="platform-concept-block" key={concept.Title} >
                                <div className={cid % 2 === 0? "platform-concept-header flex-start":"platform-concept-header"}>
                                    <div className="platform-concept-semi-tag">{concept.Tag}</div>
                                    <div className="platform-concept--header">{concept.Title}</div>
                                </div>
                                <div className={cid % 2 === 0? "platform-concept-body":"platform-concept-body row-reverse"}>
                                    <img src={concept.Image} className="platform-concept-image" alt={concept.ImageAlt}/>
                                    <div className="platform-concept-description">
                                        <p>{concept.Description}</p>
                                        {
                                            concept.DetailedInfo?
                                                <Button labelText={'Learn More'} onClick={this.gotoSolutionPage.bind(this)}/>
                                                :
                                                null
                                        }
                                    </div>
                                </div>
                            </div>
                            {
                                CheckValidity(concept.DetailedListInfo) && CheckEmpty(concept.DetailedListInfo)?
                                    <div className="platform-concept-block--list">
                                        <div className="platform-concept-list--title">
                                            {concept.DetailedListInfo.Title}
                                        </div>
                                        <div className="platform-concept-list--body">
                                            {
                                                concept.DetailedListInfo.List.map(item =>
                                                    <div className="platform-concept-list--item">
                                                        <Icon icon="bullet" className="platform-concept-list--bullet"/>
                                                        <div className="platform-concept-list--item-name">{item}</div>

                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                    :
                                    null
                            }
                        </>

                    )
                }
            </div>
        );
    }

}

export default DetailedAIPlatform;