export const KeyITSolutionList = [
    {
        Title: "Automatic AI App Generation",
        Icon: "blinx_website",
        Description: "Using Blinx’s app factory, you can auto-generate fully-functional apps from models. Pick your language of choice, container, and target cloud environment. Blinx will generate the app, check-in code, containerize the app and push it to production or your Kubernetes environment.",
        Color: "var(--light-sky)"
    },
    {
        Title: "Publish Models As Services",
        Icon: "blinx_website",
        Description: "With Blinx enterprise AI app store, you can publish your generated apps for consumers in your own private app store. Our public app store contains 100s of functional apps to help accelerate your business and industry solutions.",
        Color: "var(--orange)"
    },
    {
        Title: "Lower Operational Cost of AI Apps (MLOps)",
        Icon: "blinx_website",
        Description: "Managing models in production is labor-intensive. Leave your model monitoring to Blinx. As the data and model drift in production, Blinx tracks the accuracy changes and alerts your or automatically updates the model based on your model governance policies.",

        Color: "#1F274F"
    },
    {
        Title: "Lower Run-time Costs & Federated Learning",
        Icon: "blinx_website",
        Description: "Runtime costs of inferencing is exorbitant, let alone that of concurrent inferencing and learning. Blinx model server is highly optimized to keep model overhead low so that you are not paying extra for unwieldy middleware.",
        Color: "var(--light-bg)"
    },
]