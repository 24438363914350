import React from "react";
import "../TermsAndConditions/termsAndCondition.css"
import MetaData from "../MetaData/MetaData";

class PrivacyPolicy extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <div className="terms-condition-layout">
                <MetaData keyword={'privacy-policy'}/>
                <div className="terms-condition-body">
                    <h1 className="terms-condition-context-header">
                        Privacy Policy
                    </h1>
                    <div className="terms-and-conditions-sub-section">
                        <div className='align-terms-paragraph' style={{paddingLeft: "0px"}}>
                            Sys Inc. d/b/a BlinX (“BlinX,” “Company,” “We,” “Our”) respects your privacy and is
                            committed to protecting it through our compliance with this policy. This document
                            details the types of information we may collect or that you may provide when you
                            access, use or register with BlinX’s website, online platform and software
                            (“Services”) and our practices for using, maintaining, protecting and disclosing that
                            information.
                        </div>
                        <div className='align-terms-paragraph' style={{paddingLeft: "0px"}}>
                            Please read this carefully to understand our policies and practices regarding your
                            information and how we will treat it. By using or registering with our Services, you
                            agree to this Privacy Policy. Your continued use of our Services after we make
                            changes is deemed to be acceptance of those changes, so please check the policy
                            periodically for updates as it may change from time to time.
                        </div>

                    </div>

                    <div className="terms-and-conditions-sub-section">
                        <div className="sub-section-title">1.&nbsp;Information We Collect and How We Collect It</div>
                        <div className="align-terms-paragraph">
                            We collect information from and about users of our Services:
                        </div>
                        <ol type={"i"} className="align-terms-paragraph" style={{gap: 15}}>
                            <li>
                                Directly from you when you provide it to us.
                            </li>
                            <br/>
                            <li>
                                Automatically when you use the Services.
                            </li>
                        </ol>
                        <br/>
                        <u>
                            Information You Provide to Us
                        </u>
                        <div className="align-terms-paragraph">
                            When you use or register with the Services, we may ask you to provide information
                            by which you may be personally identified, such as your name, your company’s
                            name, email address, phone number, location, and any other identifier by which you
                            may be contacted or identified online or offline ("Personal Information").
                            <br/>
                            This information includes:

                        </div>
                        <ol type={"i"} className="align-terms-paragraph" style={{gap: 15}}>
                            <li>
                                Information that you provide by filling in forms in the Services. This includes
                                information provided at the time of registering to use the Services.
                            </li>
                            <br/>
                            <li>
                                Details of transactions you carry out through the Services and of the
                                fulfillment of your orders and requests.
                            </li>
                            <br/>
                            <li>
                                Records and copies of your correspondence (including e-mail addresses and
                                phone numbers), if you contact us. We may also ask you for information
                                when you report a problem with the Services.
                            </li>
                            <br/>
                            <li>
                                Your responses to surveys that we might ask you to complete for research
                                and development purposes.
                            </li>
                            <br/>
                        </ol>
                        <u>Automatic Information Collection</u>
                        <div className="align-terms-paragraph">
                            When you download, access or use the Services, it may use technology to
                            automatically collect:
                        </div>
                        <ol type={"i"} className="align-terms-paragraph" style={{gap: 15}}>
                            <li>
                                Usage Details. When you access and use the Services, we may
                                automatically collect certain details of your access to and use of the
                                Services, including traffic data, location data, logs and other
                                communication data and the resources that you access and use on or
                                through the Services.
                            </li>
                            <br/>
                            <li>
                                Device Information. We may collect information about your computer,
                                mobile device and internet connection, including the computer or mobile
                                device's unique device identifier, IP address, operating system, browser
                                type, mobile network information and the device's telephone number.
                            </li>
                            <br/>
                        </ol>
                        <u>Local Storage, Cookies, Web Beacons, and Tracking</u>
                        <div className="align-terms-paragraph">
                            Our Services use local storage and cookies, which are small text files that are
                            intended to make the Services better for you to use. In general, cookies are used to
                            retain preferences, store information for things like shopping carts, and provide
                            tracking data to third-party applications like Google Analytics.
                        </div>
                        <div className="align-terms-paragraph">
                            We may also employ software technology known as “web beacons” or “clear GIFs,”
                            which helps us keep track of what content on our service is effective. Web beacons
                            are small graphics with a unique identifier that are used to track the online
                            movements of Internet users. Web beacons are embedded in the web pages you
                            review, so they are not stored on your hard drive. The web beacons we may use will
                            not track or collect any personally identifiable information about you and they are in
                            no way linked to your personally identifiable information.
                        </div>
                        <div className="align-terms-paragraph">
                            The Services also use third-party analysis and tracking services to track the
                            performance of our services, understand how you use our services, and offer you an
                            improved and safer experience. Such usage information will be deleted as soon as
                            this information is no longer required for the purpose collected, and will not be
                            shared with third parties until after being anonymized.
                        </div>
                        <div className="sub-section-title">2.&nbsp;Use of Personal Information We Collect
                        </div>
                        <div className="align-terms-paragraph">
                            We may use the information we collect from You in the following ways:
                        </div>
                        <ol type={"i"} className="align-terms-paragraph" style={{gap: 15}}>
                            <li>
                                To set up your account with us.
                            </li>
                            <br/>
                            <li>
                                To perform transactions on your behalf through the Services and third-
                                party service providers.
                            </li>
                            <br/>
                            <li>
                                To personalize your user experience and to allow us to deliver the type of
                                content and offerings in which you are most interested, and to keep
                                records of your learning process.
                            </li>
                            <br/>
                            <li>
                                To deliver service messages and other services and content you request
                                and send information related to accounts and services, including
                                confirmations, invoices, technical notices, updates, security alerts, and
                                support and administrative messages, and to enable user-to user
                                communication.
                            </li>
                            <br/>
                            <li>
                                To conduct an aggregated analysis of the performance of our Services,
                                including referral data if you arrive at our website from an external
                                source.
                            </li>
                        </ol>
                        <div className="align-terms-paragraph">
                            All the data we collect at BlinX is necessary for us to deliver the services you use.
                            The amount we collect has been minimized wherever possible to respect your
                            privacy.
                        </div>
                        <div className="sub-section-title">3.&nbsp;How We May Share Information We Collect
                        </div>
                        <div className="align-terms-paragraph">
                            We may share personal information as follows:
                        </div>
                        <ol type={"i"} className="align-terms-paragraph" style={{gap: 15}}>
                            <li>
                                To provide to third party service providers that perform services on our
                                behalf, such as third-party accounting or payment platforms.
                            </li>
                            <br/>
                            <li>
                                We may share personal information when we do a business deal, or
                                negotiate a business deal, involving the sale or transfer of all or a part of
                                our business or assets. These deals can include any merger, financing,
                                acquisition, or bankruptcy transaction or proceeding. However, this
                                information will be in the aggregate and anonymized to protect your
                                personal information.
                            </li>
                            <br/>
                            <li>
                                We may share information to respond to lawful requests and legal
                                processes, and with banking partners as required by credit card
                                association rules.
                            </li>
                            <br/>
                            <li>
                                We may share information to protect the rights and property of BlinX, our
                                agents, customers, and others. This includes enforcing our agreements,
                                policies, and terms of use.
                            </li>
                            <br/>
                            <li>
                                We may share your information in marketing materials or with investors or
                                potential investors.
                            </li>
                            <br/>
                            <li>
                                We may share information in an emergency. This includes protecting the
                                safety of our employees and agents, our customers, or any person.

                            </li>
                            <br/>
                            <li>
                                We may share information with those who need it to do work for us.
                            </li>
                        </ol>
                        <div className="align-terms-paragraph">
                            We do not sell, trade, or otherwise transfer to outside parties your personally
                            identifiable information except as outlined by this privacy policy.
                        </div>
                        <div className="sub-section-title">4.&nbsp;Data Retention
                        </div>
                        <div className="align-terms-paragraph">
                            We retain personal information we collect from you where we have an ongoing
                            legitimate business need to do so (for example, to provide you with a service you
                            have requested or to comply with applicable legal, tax, or accounting
                            requirements).
                        </div>
                        <div className="align-terms-paragraph">
                            When we have no ongoing legitimate business need to process personal
                            information, we will either delete or anonymize it or, if this is not possible (for
                            example, because personal information has been stored in backup archives), then
                            we will securely store personal information and isolate it from any further
                            processing until deletion is possible.
                        </div>
                        <div className="sub-section-title">5.&nbsp;Security of Your Personal Information
                        </div>
                        <div className="align-terms-paragraph">
                            The security of your Personal Information is important to us. When you enter
                            sensitive information (such as credit card number) on our Services, we encrypt that
                            information using secure socket layer technology (SSL).
                        </div>
                        <div className="align-terms-paragraph">
                            BlinX takes reasonable security measures to protect your Personal Information to
                            prevent loss, misuse, unauthorized access, disclosure, alteration, and destruction.
                            Please be aware, however, that despite our efforts, no security measures are
                            impenetrable.
                        </div>
                        <div className="align-terms-paragraph">
                            If you use a password on the Services, you are responsible for keeping it
                            confidential. Do not share it with any other person. If you believe your password has
                            been misused, please notify us immediately.
                        </div>
                        <div className="sub-section-title">6.&nbsp;Legal Basis for Processing Personal Information under the General Data
                            Protection Regulation (GDPR)
                        </div>
                        <div className="align-terms-paragraph">
                            Our legal basis for collecting and using the personal information described above
                            will depend on the personal information concerned and the specific context in which
                            we collect it.
                        </div>
                        <div className="align-terms-paragraph">
                            However, we will normally collect personal information from you only (i) where we
                            need the personal information to perform a contract with you; (ii) where the
                            processing is in our legitimate interests and not overridden by your rights; or (iii)
                            where we have your consent to do so.  We have a legitimate interest in operating
                            our Services and communicating with you as necessary to provide these Services,
                            for example when responding to your queries, improving our platform, undertaking
                            marketing, or for the purposes of detecting or preventing illegal activities.
                        </div>
                        <div className="align-terms-paragraph">
                            In some cases, we may also have a legal obligation to collect personal information
                            from you or may otherwise need the personal information to protect your vital
                            interests or those of another person.
                        </div>
                        <div className="align-terms-paragraph">
                            If we ask you to provide personal information to comply with a legal requirement or
                            to perform a contract with you, we will make this clear at the relevant time and
                            advise you whether the provision of your personal information is mandatory or not
                            (as well as of the possible consequences if you do not provide your personal
                            information).
                        </div>
                        <div className="sub-section-title">7.&nbsp;Your Data Protection Rights Under the General Data
                            Protection Regulation (GDPR)
                        </div>
                        <div className="align-terms-paragraph">
                            If you are a resident of the European Union, you have the following data protection
                            rights:
                        </div>
                        <ol type={"i"} className="align-terms-paragraph" style={{gap: 15}}>
                            <li>
                                If you wish to access, correct, update, or request deletion of your personal
                                information, you can do so at any time.
                            </li>
                            <br/>
                            <li>
                                In addition, you can object to the processing of your personal information,
                                ask us to restrict the processing of your personal information, or request
                                portability of your personal information.
                            </li>
                            <br/>
                            <li>
                                You have the right to opt-out of marketing communications we send you at
                                any time. You can exercise this right by clicking on the "unsubscribe" or "opt-
                                out" link in the marketing emails we send you.
                            </li>
                            <br/>
                            <li>
                                Similarly, if we have collected and process your personal information with
                                your consent, then you can withdraw your consent at any time.  Withdrawing
                                your consent will not affect the lawfulness of any processing we conducted
                                prior to your withdrawal, nor will it affect the processing of your personal
                                information conducted in reliance on lawful processing grounds other than
                                consent.
                            </li>
                            <br/>
                            <li>
                                You have the right to complain to a data protection authority about our
                                collection and use of your personal information. For more information, please
                                contact your local data protection authority.
                            </li>
                        </ol>
                        <div className="align-terms-paragraph">
                            We respond to all requests we receive from individuals wishing to exercise their
                            data protection rights in accordance with applicable data protection laws.
                        </div>
                        <div className="align-terms-paragraph">
                            If we are relying on your consent to process your personal information, you have
                            the right to withdraw your consent at any time. Please note however that this will
                            not affect the lawfulness of the processing before its withdrawal, nor will it affect
                            the processing of your personal information conducted in reliance on lawful
                            processing grounds other than consent.
                        </div>
                        <div className="align-terms-paragraph">
                            If you are a resident in the European Economic Area and you believe we are unlawfully
                            processing your personal information, you also have the right to complain to your local data
                            protection supervisory authority. You can find their contact details here:
                            <label className='legal-email-link'>http://ec.europa.eu/justice/data-protection/bodies/authorities/
                                index_en.html.</label>
                        </div>
                        <div className="align-terms-paragraph">
                            If you are a resident in the European Economic Area and you believe we are
                            unlawfully processing your personal information, you also have the right to
                            complain to your local data protection supervisory authority. You can find their
                            contact details here:
                            <label className='legal-email-link'>http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.</label>
                        </div>
                        <div className="align-terms-paragraph">
                            If you are a resident in Switzerland, the contact details for the data protection
                            authorities are available here:
                            <label className='legal-email-link'>&nbsp;https://www.edoeb.admin.ch/edoeb/en/home.html.</label>
                        </div>
                        <div className="align-terms-paragraph">
                            If you are an EU resident and wish to exercise your rights under the GDPR, please
                            contact us a
                            <label className='legal-email-link'>&nbsp;legal@blinx.ai.</label>
                        </div>

                        <div className="sub-section-title">8.&nbsp;Your Data Protection Rights under the California Consumer Privacy Act
                            (CCPA)
                        </div>
                        <div className="align-terms-paragraph">
                            The California Consumer Privacy Act (“CCPA”) provides consumers with specific
                            rights regarding their Personal Information. If you are a California resident, you have
                            the right to:
                        </div>
                        <ol type={"i"} className="align-terms-paragraph" style={{gap: 15}}>
                            <li>
                                Request we disclose to you free of charge the following information covering
                                the 12 months preceding your request: (a) the categories of Personal
                                Information about you that we collected; (b) the categories of sources from
                                which the Personal Information was collected; (c) the purpose for collecting
                                Personal Information about you; (d) the categories of third parties to whom
                                we disclosed Personal Information about you and the categories of Personal
                                Information that was disclosed (if applicable) and the purpose for disclosing
                                the Personal Information about you; and (e) the specific pieces of Personal
                                Information we collected about you;
                            </li>
                            <br/>
                            <li>
                                Request we delete Personal Information we collected from you, unless the
                                CCPA recognizes an exception;
                            </li>
                            <br/>
                            <li>
                                If the business sells Personal Information, you have a right to opt-out of that
                                sale.
                            </li>
                        </ol>
                        <div className="align-terms-paragraph">
                            Consistent with California law, if you choose to exercise your applicable CCPA rights,
                            we will not charge you different prices or provide you a different quality of services.
                            If we ever offer a financial incentive or product enhancement that is contingent
                            upon you providing your Personal Information, we will not do so unless the benefits
                            to you are reasonably related to the value of the Personal Information that you
                            provide to us. If you are a California resident and wish to exercise your rights under
                            the CCPA, please contact us a
                            <label className='legal-email-link'>&nbsp;legal@blinx.ai.</label>
                        </div>
                        <div className="sub-section-title">9.&nbsp;Updating and Accessing Your Personal Information
                        </div>
                        <div className="align-terms-paragraph">
                            If your Personal Information changes, we invite you to correct or update your
                            information. We will retain your information for as long as your account is active or
                            as needed to provide you services. If you wish to cancel your account, request that
                            we no longer use your information to provide you services, or delete your Personal
                            Information, you may do so through your account settings, or contact us. We will
                            respond to your request to have your Personal Information updated as soon as
                            possible. We will retain and use your information as necessary to comply with our
                            legal obligations, resolve disputes, and enforce our agreements.
                        </div>
                        <div className="sub-section-title">10.&nbsp;Choices About Use of Your Information
                        </div>
                        <div className="align-terms-paragraph">
                            You can request that your account be deleted, however, certain financial record keeping
                            information may be maintained in our systems because we have a legitimate legal and
                            financial interest in maintaining such records.
                        </div>
                        <div className="align-terms-paragraph">
                            You may unsubscribe from receiving certain promotional emails from us. If you wish
                            to do so, simply follow the instructions found at the end of the email. Even if you
                            unsubscribe, we may still contact you for informational, transactional, account-
                            related, or similar purposes, including messages about your current BlinX account
                            and services.
                        </div>
                        <div className="align-terms-paragraph">
                            Many browsers have an option for disabling cookies, which may prevent your
                            browser from accepting new cookies or enable selective use of cookies. Please note
                            that, if you choose not to accept cookies, some features and the personalization of
                            our Services may no longer work for you. You will continue to receive advertising
                            material but it will not be tailored to your interests.
                        </div>
                        <div className="sub-section-title">11.&nbsp;Third-Party Disclosure and Links
                        </div>
                        <div className="align-terms-paragraph">
                            The Services may include links to other websites whose privacy practices may differ
                            from those of BlinX. If you submit Personal Information to any of those sites, your
                            information is governed by their privacy statements. We encourage you to carefully
                            read the privacy statement of any third-party websites you visit.
                        </div>
                        <div className="sub-section-title">12.&nbsp;Notice Concerning The Information Of Children
                        </div>
                        <div className="align-terms-paragraph">
                            BlinX is not directed to children under the age of 13 and does not intentionally
                            collect any information from children under the age of 13. Please contact us if your
                            child has provided Personal Information to us and we will delete the information.
                        </div>
                        <div className="sub-section-title">13.&nbsp;Changes to This Policy
                        </div>
                        <div className="align-terms-paragraph">
                            BlinX may change this Policy from time to time. If we make any changes to this
                            Policy, we will change the "Last Updated" date below and may notify you by email
                            (sent to the e-mail address specified in your account). We encourage you to review
                            this Policy whenever you visit the Services to understand how your Personal
                            Information is used.
                        </div>
                        <div className="sub-section-title">14.&nbsp;Questions About This Policy
                        </div>
                        <span className="align-terms-paragraph">
                            If you have any questions about this Policy, our practices related to the Services, or
                            if you would like to have us remove your information from our database, please feel
                            free to contact us at&nbsp;<u style={{color: "var(--light-sky)"}}>legal@blinx.ai</u>.
                        </span>

                    </div>
                    <p className="terms-and-conditions-update-date">Last Updated: July 31, 2022</p>
                </div>
            </div>
        );
    }
}

export default PrivacyPolicy;