import React from "react";
import "./searchbox.css";
import PropTypes from "prop-types";
import SummaryText from "../../Components/Commoncomponents/SummaryText/SummaryText";
import {CheckEmpty, CheckValidity} from "../CommonMethods";

class Searchbox extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            searchKeyword: ""
        }
    }

    changeSearchText = (event) =>{
        if(event.target.value.length === 0){

            this.setState({
                searchKeyword: event.target.value
            })
            let {onSearch} = this.props;
            onSearch(event.target.value)
        }
       else{
            this.setState({
                searchKeyword: event.target.value
            })
        }
    }

    onSearchHandler = () =>{
        let {onSearch} = this.props;
        onSearch(this.state.searchKeyword)
    }

    checkKeyPress = (event) =>{
        if(event.which === 13){
            this.onSearchHandler()
        }

    }

    render() {
        let {searchKeyword} = this.state;

        let {placeholder} = this.props;
        return (
            <div className="default-search-box">
              <input type="text" placeholder={placeholder} onChange={this.changeSearchText.bind(this)} onKeyPress={this.checkKeyPress.bind(this)}/>
                {/*<Icon icon="search"/>*/}
                <button className="default-search-box-btn" disabled={!(CheckValidity(searchKeyword) && CheckEmpty(searchKeyword))} onClick={this.onSearchHandler.bind(this)}>Search</button>

            </div>
        );
    }

}


SummaryText.defaultProps={
    placeholder: "Search Keyword",
}
SummaryText.propTypes={
    placeholder: PropTypes.string,
    onSearch: PropTypes.func
}
export default Searchbox;