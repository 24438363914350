import React from 'react'
import './Footer.css'
import Button from "../../Assets/Buttons/Button";
import Icon from "../../Assets/IconLibrary/Icon";
import { withRouter } from "react-router";
import HubspotForm from "react-hubspot-form";
import {DefaultParameters} from "../../api/ApiCallMethod";
import DuoIcon from "../../Assets/IconLibrary/DuoIcon";
import {ReactComponent as Instagram} from "../../Assets/Images/instagram.svg";

class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            footerProductList:[
                {
                    Name: "AI App Platform",
                    Route: "platform",
                    Icon: "",
                    Disabled: false,
                    isActive: false,
                    Action: this.changeRouteHandler.bind(this, "platform")
                },
                {
                    Name: "Solutions",
                    Route: "solutions/it",
                    Icon: "",
                    Disabled: false,
                    isActive: false,
                    Action: this.changeRouteHandler.bind(this, "solutions/it")
                },
                {
                    Name: "AI App Store",
                    Route: "ai-app-store",
                    Icon: "",
                    Disabled: false,
                    isActive: false,
                    Action: this.changeRouteHandler.bind(this, "ai-app-store")
                },
                {
                    Name: "Resources",
                    Route: "resources/product-videos",
                    Icon: "",
                    Disabled: false,
                    isActive: false,
                    Action: this.changeRouteHandler.bind(this, "resources/product-videos"),
                },
            ],
            footerCompanyList:[
                {
                    Name: "About Us",
                    Route: "about-us",
                    Icon: "",
                    Disabled: false,
                    isActive: false,
                    Action: this.changeRouteHandler.bind(this, "about-us")
                },
                {
                    Name: "Contact",
                    Route: "contact",
                    Icon: "",
                    Disabled: false,
                    isActive: false,
                    Action: this.changeRouteHandler.bind(this, "about-us")
                },
                {
                    Name: "Terms and Conditions",
                    Route: "terms-and-conditions",
                    Icon: "",
                    Disabled: false,
                    isActive: false,
                    Action: this.changeRouteHandler.bind(this, "terms-and-conditions")
                },
                {
                    Name: "Privacy Policy",
                    Route: "privacy-policy",
                    Icon: "",
                    Disabled: true,
                    isActive: false,
                    Action: this.changeRouteHandler.bind(this, "privacy-policy")
                },
            ],
            newsLetterOnLoad: false,
            socialMediaList:[
                {
                    Name:'LinkedIn',
                    Link:'https://www.linkedin.com/company/blinxai/',
                    Icon:'linked_in'
                },
                {
                    Name:'Twitter',
                    Link:'https://twitter.com/ai_blinx',
                    Icon:'twitter'
                },
                {
                    Name:'Youtube',
                    Link:'https://www.youtube.com/c/BlinxUniversity',
                    Icon:'youtube'
                },
                {
                    Name:'Pinterest',
                    Link:'https://www.pinterest.com/blinxintelligence/',
                    Icon:'pinterest'
                },
                {
                    Name:'Facebook',
                    Link:'https://www.facebook.com/Blinx-AI-108286298638885',
                    Icon:'facebook'
                },
                {
                    Name:'Instagram',
                    Link:'https://www.instagram.com/blinx.ai/',
                    Icon:'instagram'
                },

                {
                    Name:'Reddit',
                    Link:'https://www.reddit.com/user/Blinx-AI/',
                    Icon:'reddit'
                },
                {
                    Name:'Medium',
                    Link:'https://medium.com/@blinx',
                    Icon:'medium'
                },
                {
                    Name:'Quora',
                    Link:'https://www.quora.com/profile/Blinx-AI',
                    Icon:'quora'
                },



            ]
        }
    }
    changeRouteHandler = (route) =>{

        this.props.history.push(`/${route}`);
        this.props.history.go(this.props.history);
        this.manualScroll11(0)
    }

    manualScroll11 = (location) =>{
        let {manualScroll} = this.props;
        manualScroll(location)
    }
    tryPlatform =()=>{
        // let productUrl = DefaultParameters().ProductURL
        // productUrl += DefaultParameters().LoginRoute
        // window.open(productUrl, '_blank');
        window.open("https://calendly.com/blinx_sales", "_blank");
    }

    requestDemo = () =>{
        window.open("https://calendly.com/blinx_sales", "_blank")
    }

    render() {
        let {footerProductList,footerCompanyList}=this.state
        return (
            <div className='footer-main-layout'>
                <div className='footer-request-demo-layout'>
                    <div className='blinx-footer-text tag-line'>
                        BLINX AI PLATFORM
                    </div>
                    <div className='footer-platform'>

                        <div className='blinx-footer-main-title'>
                            Leverage AI Outcomes Today
                        </div>

                        <Button labelText={'Try Our Platform'} backgroundColor={"var(--primary-color)"}
                                onClick={this.tryPlatform.bind(this)}
                                width={'fit-content'}/>
                    </div>
                    <div className='footer-platform'>
                        <div className='blinx-footer-main-title'>
                            Request Demo
                            <div className='blinx-footer-text'>
                                Contact us for your own app-generation event
                            </div>
                        </div>

                        <Button labelText={'Schedule a Demo'} backgroundColor={"var(--primary-color)"}
                                onClick={this.requestDemo.bind(this)}
                                width={'fit-content'}/>

                    </div>
                </div>
                <div className='footer-contact-layout'>
                    <div className='footer-contact-address'>
                        <div className='blinx-footer-icon'>
                            <Icon icon={'logo'} size={120}/>
                            {/*<div className='blinx-footer-main-title' style={{fontSize: 30}}>Blinx AI</div>*/}
                        </div>
                        <div className='blinx-footer-text address'>
                            228 Hamilton Ave, 3rd Fl
                        </div>
                        <div className='blinx-footer-text address'>
                            Palo Alto, CA 94301, US
                        </div>
                        <div className='blinx-footer-text active address'>
                            +1 650-750-1000

                        </div>
                        <div className='blinx-footer-text active address'>
                            try@blinx.ai
                        </div>
                        <div className="blinx-newsletter-hubspot-form">
                            <div className="blinx-newsletter-title">Join Our NewsLetter</div>
                            <HubspotForm
                                portalId="21105117"
                                formId="cc1332cf-f099-401f-899b-c343266ea50a"
                                onSubmit={() => console.log("Submit Newsletter Form!")}
                                onReady={(form) => console.log("Newsletter Form Ready!")}
                                loading={<div/>}
                                cssRequired={""}
                                cssClass={"newsletter-input-container"}
                                submitButtonClass={"newsLetterSubmitButton"}
                            />
                        </div>

                    </div>
                    <div className='footer-contact-right-layout'>
                    <div className='footer-contact-product-and-company-layout'>
                        <div className='footer-contact-product-and-company'>
                            <div className='blinx-footer-main-title white-font' style={{fontSize: 24}}>Product</div>
                        {
                            footerProductList.map(listName=>
                                    <div className={'blinx-footer-text-route'} onClick={()=>this.changeRouteHandler(listName.Route)}>
                                        {listName.Name}
                                    </div>

                            )
                        }
                        </div>
                        <div className='footer-contact-product-and-company'>
                            <div className='blinx-footer-main-title white-font' style={{fontSize: 24}}>Company</div>
                            {
                                footerCompanyList.map(listName=>
                                    <div className={'blinx-footer-text-route'} onClick={()=>this.changeRouteHandler(listName.Route)}>
                                        {listName.Name}
                                    </div>

                                )
                            }
                        </div>
                    </div>
                        <div className='footer-contact-social-layout'>
                            {
                                this.state.socialMediaList.map(mediaList=>
                                    <button className='footer-contact-social-icon' title={mediaList.Name}>
                                        <a href={mediaList.Link}>
                                            {
                                                mediaList.Name === 'Instagram' ?
                                                    <Instagram width={24}/>
                                                    :
                                                    <DuoIcon name={mediaList.Icon} size={24}/>
                                            }

                                        </a>
                                    </button>
                                )
                            }

                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default withRouter(Footer);