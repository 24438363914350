import React from "react";
import './termsAndCondition.css';


class CopyrightInfringementAndDigitalMillennium extends React.Component{
    render() {
        return (
            <div className="terms-and-conditions-sub-section">
                <div className="sub-section-title">5.&nbsp;Copyright Infringement and the Digital Millennium Copyright Act (DMCA) Safe Harbor</div>
                <div className="align-terms-paragraph">
                    We take the intellectual property rights of others seriously and require that users of Breadwinner do the same. The Digital Millennium Copyright Act (DMCA) established a process for addressing claims of copyright infringement that we have implemented for our services. If you own a copyright or have authority to act on behalf of a copyright owner and want to report a claim that a third party is infringing that material on or through Breadwinner, please send a notice to our copyright agent that includes all of the items below and we will expeditiously take appropriate action:
                </div><br/>
                <ol type="1" className="align-terms-paragraph">
                    <li>
                        A description of the copyrighted work that you claim is being infringed;
                    </li><br/>
                    <li>
                        A description of the material you claim is infringing and that you want removed or access to which you want disabled and the URL or other location of that material
                    </li><br/>
                    <li>
                        Your address, telephone number, and email address;
                    </li><br/>
                    <li>
                        The following statement: “I have a good faith belief that the use of the copyrighted material I am complaining of is not authorized by the copyright owner, its agent, or the law (e.g., as a fair use)”;
                    </li><br/>
                    <li>
                        The following statement: “The information in this notice is accurate and, under penalty of perjury, I am the owner, or authorized to act on behalf of the owner, of the copyright or of an exclusive right that is allegedly infringed”; and
                    </li><br/>
                    <li>
                        An electronic or physical signature of the owner of the copyright or a person authorized to act on the owner's behalf.
                    </li><br/>
                </ol>
                <div className="align-terms-paragraph">
                    We may, in appropriate circumstances, disable or terminate the accounts of users who may be repeat infringers. This process does not limit our ability to pursue any other remedies we may have to address suspected infringement.
                </div>
            </div>
        );
    }
}
export default CopyrightInfringementAndDigitalMillennium;