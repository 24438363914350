import React from "react";
import "./summaryText.css";
import PropTypes from "prop-types";
import {SummaryTextList} from "../../../Assets/Contents/SummaryText";
import {CheckEmpty, CheckValidity} from "../../../Assets/CommonMethods";

class SummaryText extends React.Component{
    constructor() {
        super();
        this.state ={
        }
    }

    render() {
        let {topic, theme} = this.props;
        return (
            <div className={`summary-text-layout ${theme}`}>
                <div className="summary-text-division">
                    {
                        (SummaryTextList[topic].Tag === null || SummaryTextList[topic].Tag === undefined || SummaryTextList[topic].Tag === '')?
                            null
                            :
                            <div className="summary-small-tag">{SummaryTextList[topic].Tag}</div>
                    }
                    {
                        (SummaryTextList[topic].Title === null || SummaryTextList[topic].Title === undefined || SummaryTextList[topic].Title === '')?
                            null
                            :
                            <div className="summary-title">{SummaryTextList[topic].Title}</div>
                    }
                    {
                        CheckValidity(SummaryTextList[topic].Description) && CheckEmpty(SummaryTextList[topic].Description)?
                            <div className="summary-description">
                                {
                                    SummaryTextList[topic].Description.map((description)=>
                                        <p>{description}</p>
                                    )

                                }
                            </div>
                            :
                            null
                    }

                </div>
                {
                    CheckValidity(SummaryTextList[topic].Illustration) && CheckEmpty(SummaryTextList[topic].Illustration)?
                        <div className="summary-image-division">
                            <img src={SummaryTextList[topic].Illustration} alt={SummaryTextList[topic].IllustrationAlt}/>
                        </div>
                        :
                        null
                }


            </div>
        );
    }
}


SummaryText.defaultProps={
    topic: "application",
    theme: "dark",
}
SummaryText.propTypes={
    topic: PropTypes.string.isRequired,
    theme: PropTypes.oneOf(['dark','light', 'white']),
}

export default SummaryText;