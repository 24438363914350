export const CheckValidity = (checkItem) =>{
    return(checkItem !== undefined && checkItem !== null)
}

export const CheckEmpty = (checkItem) =>{
    return(checkItem.length !== 0)
}

export const getMlDataTypeSafety = (dataType) => {
    //check if it is scaled or not
    dataType = dataType.toLowerCase()
    let mlDataType = dataType
    switch (dataType) {
        case "nlp":
            mlDataType = "int"
            break
        case "image":
            mlDataType = "int"
            break
        case "audio":
            mlDataType = "int"
            break
        case "video":
            mlDataType = "int"
            break
        case "enum":
            mlDataType = "int"
            break
        case "integer":
            mlDataType = "int"
            break
        case "number":
            mlDataType = "int"
            break
        case "int32":
            mlDataType = "int"
            break
        case "int64":
            mlDataType = "int"
            break
        case "float32":
            mlDataType = "float"
            break
        case "float64":
            mlDataType = "float"
            break
        case "double":
            mlDataType = "float"
            break
        case "varchar":
            mlDataType = "string"
            break
        case "complex":
            mlDataType = "float"
            break
        case "varchar2":
            mlDataType = "string"
            break
        case "text":
            mlDataType = "string"
            break
        case "char varying":
            mlDataType = "string"
            break
    }

    return mlDataType
}

export const getSortingDataByAlphabetically = (currentData, name) => {
    currentData.sort(function (a, b) {
        let nameA = a[name].toLowerCase(); // ignore upper and lowercase
        let nameB = b[name].toLowerCase(); // ignore upper and lowercase
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    });
    return currentData
}
