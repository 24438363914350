import React from "react";
import Helmet from "react-helmet";
import {MetaDataInfo} from "./MetaDataInfo"
import PropTypes from "prop-types";

class MetaData extends React.Component{
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        let {keyword} = this.props;
        let MetaData = MetaDataInfo[keyword];
        return (
            <Helmet>
                <meta charSet="utf-8" />
                <title>{MetaData.Title}</title>
                <meta name="description" content={MetaData.Description}/>
                <meta name="keywords" content={MetaData.Keywords}/>
                <meta httpEquiv="cleartype" content="on"/>
                <meta name="author" content="Blinx AI"/>
                <meta name={MetaData.URL}
                      content={MetaData.URLKeys}/>
                <meta name="rating" content="General"/>
                <meta name="robots" content="index,follow,noarchive"/>
                <meta name="revisit-after" content="daily"/>
                <meta content="English" name="language"/>
                <link href="https://www.blinx.ai/home/opensearch.xml"
                      rel="search"
                      title={MetaData.OGKeyword}
                      type="application/opensearchdescription+xml"/>
                <link rel="canonical" href={MetaData.URL}/>
                <meta property="og: " content="website" />
                <meta property="og:title locale" content="en_US" />
                <meta property="og:type " content={" "+MetaData.URLKeys+" "} />
                <meta property="og:description" content={MetaData.OGDescription}/>
                <meta property="og:url" content={MetaData.URL} />
                <meta property="og:site_name" content={MetaData.OGKeyword} />
            </Helmet>
        );
    }
}

MetaData.defaultProps = {
    keyword: "home"
}

MetaDataInfo.propTypes = {
    keyword: PropTypes.string.isRequired
}

export default MetaData;