import HomeImage from '../../Assets/Images/home.svg'
import LifeCycle from '../../Assets/Images/lifecycle.svg'
import AiPlatform from '../../Assets/Images/aiAppPlatform.svg'
import Collaboration from '../../Assets/Images/collaboration.png'

export const SummaryTextList = {
    lifeCycle:{
        Tag: "",
        Title: "Accelerate Your AI Lifecycle",
        Description:[
            "Next-generation AI requires full AI lifecycle automation – from building adaptive apps to validating apps to managing them."
        ],
        Illustration: LifeCycle,
        IllustrationAlt:'blinx ai build app'
    },
    aiAppPlatform:{
        Tag: "Design To Run",
        Title: "Blinx AI App Platform",
        Description:[
            "Blinx AI platform equips businesses with a full AI lifecycle automation platform to effortlessly build, validate, and manage AI applications. With Blinx, turning data and models to full-fledged apps is just a few clicks away."
        ],
        Illustration: AiPlatform,
        IllustrationAlt: 'blinx ai app platform'
    },
    Solution:{
        Tag: "BLINX AI PLATFORM",
        Title: "Solution for Business and IT",
        Description:[
            "With Blinx, solve your toughest AI challenges –talent or technology. Focus on your data and generate ready-to-deploy AI apps."
        ],
        Illustration:HomeImage,
        IllustrationAlt:'Solution for Business and IT'
    },
    solution:{
        Tag: "Blinx AI Platform",
        Title: "Solution for Business and IT",
        Description:[
            "With Blinx, solve your toughest AI challenges –talent or technology. Focus on your data and generate ready-to-deploy AI apps."
        ],
        // Illustration: Collaboration
    },
    application:{
        Tag: "PUBLISH YOUR APPS",
        Title: "AI App Store",
        Description:[
            "Blinx offers a unique AI App Store for enterprises to publish their AI apps for their internal AI ecosystem. Additionally, our public App Store hosts 1000s of AI apps built from reliable datasets to help accelerate your solutions."
        ],
        // Illustration: ApplicationIllustration
    },
    platform:{
        Tag: "BLINX AI PLATFORM",
        Title: "",
        Description:[
            "Blinx offers a unique AI App Store for enterprises to publish their AI apps for their internal AI ecosystem. Additionally, our public App Store hosts 1000s of AI apps built from reliable datasets to help accelerate your solutions."
        ],
        Illustration: HomeImage,
        IllustrationAlt:'blinx application'
    },

    fullLifeCycle:{
        Tag: "Automated AI Full LifeCycle",
        Title: "Accelerate Your AI Lifecycle",
    },

    collaboration:{
        Tag: "Blinx AI Platform",
        Title: "Business and IT Collaboration in AI",
        Description:[
            "With Blinx, the entire ecosystem of AI from business owners to analysts to data scientists to \n" +
            "application developers can collaborate to ensure that not just AI models are created, but AI \n" +
            "applications are created, validated, and deployed."
        ],
        Illustration: Collaboration,
        IllustrationAlt:'Business and IT Collaboration in AI'
    },
    resources:{
        Tag: "Blinx University",
        Title: "Resources",
        Description:[
            "Watch short videos from our knowledge library to learn about every aspect of AI app lifecycle – from data to models to apps."
        ],
        // Illustration: Collaboration
    },
    instant_model:{
        // Tag: "Blinx University",
        Title: "Instant Model",
        Description:[
            "Instant Model is an advanced model building tool in Blinx that provides a simple, modeling environment to let you rapidly create and execute instant model tests that save time and effort. This tool lets you use the drag and drop UI to create and test your model as simple as 1-2-3." ,
            "With Instant Model tool, you can create your own model with only a single CSV file. Sign up for a trial today! to explore other sources for model generation"
        ],
        // Illustration: Collaboration
    },
}