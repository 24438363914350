import React from "react";
import "./header.css";
import Icon from "../../Assets/IconLibrary/Icon";
import Button from "../../Assets/Buttons/Button";
import GhostButton from "../../Assets/Buttons/GhostButton";
import { withRouter } from "react-router";
import {DefaultParameters} from "../../api/ApiCallMethod";
import Popup from "reactjs-popup";
import LottieAnimation from "../../Assets/Lotties/LottieAnimation";

class Header extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            blogNotExistPopup: false,
            menuList:[
                {
                    Name: "AI App Platform",
                    Route: "platform",
                    Icon: "",
                    Disabled: false,
                    isActive: false,
                    Action: this.changeRouteHandler.bind(this, "platform")
                },
                {
                    Name: "Solutions",
                    Route: "solutions/it",
                    Icon: "",
                    Disabled: false,
                    isActive: false,
                    Action: this.changeRouteHandler.bind(this, "solutions/it")
                },
                {
                    Name: "AI App Store",
                    Route: "ai-app-store",
                    Icon: "",
                    Disabled: false,
                    isActive: false,
                    Action: this.changeRouteHandler.bind(this, "ai-app-store")
                },
                {
                    Name: "Resources",
                    Route: "resources",
                    Icon: "",
                    Disabled: false,
                    isActive: false,
                    Action: this.changeRouteHandler.bind(this, "resources/product-videos"),

                },
                {
                    Name: "Pricing",
                    Route: "pricing",
                    Icon: "",
                    Disabled: false,
                    isActive: false,
                    Action: this.changeRouteHandler.bind(this, "pricing-plan"),

                },
                {
                    Name: "About Us",
                    Route: "about_us",
                    Icon: "",
                    Disabled: false,
                    isActive: false,
                    Action: this.changeRouteHandler.bind(this,"about-us")
                },
            ],
            resourceList:[
                {
                    Name: "Blinx University",
                    Route: "resources/videos",
                    Icon: "",
                    Disabled: false,
                    isActive: false,
                    Action: this.changeRouteHandler.bind(this, "resources/product-videos"),
                },
                {
                    Name: "blog",
                    Route: "resources/blog",
                    Icon: "",
                    Disabled: false,
                    isActive: false,
                    Action: this.changeRouteHandler.bind(this, "resources/blog"),
                },
            ]
        }
    }

    login =()=>{
        // let productUrl = DefaultParameters().ProductURL
        // productUrl += DefaultParameters().LoginRoute
        // window.open(productUrl, '_blank');
        window.open("https://calendly.com/blinx_sales", "_blank")

    }


    changeRouteHandler = (route) =>{

        let {menuList}  =this.state;
        for(let i =0;i<menuList.length;i++){
            menuList[i].isActive = menuList[i].Route === route
        }
        let {routingPage} = this.props;
        routingPage(route);
        this.manualScroll11(0)


    }

    manualScroll11 = (location) =>{
        let {manualScroll} = this.props;
        manualScroll(location)
    }

    openRequestDemo = () =>{
        window.open("https://calendly.com/blinx_sales", "_blank")
    }

    openBlinxBlog = () =>{

        let blogURL = "https://blogs.blinx.ai"
        window.open(blogURL, "_self")
    }

    render() {
        let {menuList} = this.state;
        let {backgroundColor} = this.props;

        return (
            <div className="blinx-header" style={{backgroundColor: backgroundColor}}>
                <div className="blinx-inner-header">
                    <div className="blinx-header--left">
                        <div className="blinx-logo-division" onClick={this.changeRouteHandler.bind(this,"home")}>
                            <Icon icon="logo" size={150} className="blinx-logo"/>
                        </div>
                        <div className="blinx-main-menu-list">
                            {
                                menuList.map((menu)=>
                                    menu.Name !== "Resources"?
                                        <button key={menu.Name}
                                                className={menu.isActive?"blinx-main-menu active":"blinx-main-menu" }
                                                disabled={menu.Disabled}
                                                value={menu.Route}
                                                onClick={menu.Action}>
                                            {menu.Name}
                                        </button>
                                        :
                                        <button key={menu.Name}
                                                className={menu.isActive?"blinx-main-menu active":"blinx-main-menu" }
                                                value={menu.Route}
                                        >
                                            {menu.Name}
                                            <div className="blinx-main-menu-child-list">
                                                <button className="blinx-main-menu-child border-line" onClick={()=>{
                                                    let {menuList}  =this.state;
                                                    for(let i =0;i<menuList.length;i++){
                                                        menuList[i].isActive = menuList[i].Route === "resources"
                                                    }
                                                    this.setState({
                                                        menuList
                                                    })

                                                    this.props.history.push(`/resources/product-videos`)
                                                    this.props.history.go(this.props.history);

                                                }}>
                                                    Blinx University
                                                </button>
                                                <button className="blinx-main-menu-child"
                                                        onClick={this.openBlinxBlog.bind(this)}>
                                                    Blogs
                                                </button>
                                            </div>
                                        </button>
                                )
                            }
                        </div>
                    </div>
                    <div className="blinx-header--right">
                        <GhostButton onClick={this.login.bind(this)} label={"Login"}/>
                        <Button onClick={this.openRequestDemo.bind(this)} backgroundColor={"var(--primary-color)"} labelText="Request Demo"/>
                    </div>
                </div>

                <Popup contentStyle={{
                    width: "auto",
                    background: "transparent",
                    border: "none",
                    padding: 0

                }}
                       open={this.state.blogNotExistPopup}
                       closeOnDocumentClick={true}
                       onClose={()=>this.setState({blogNotExistPopup: false})}
                       lockScroll={true}>
                    <div className="blog-error-popup">
                        <button onClick={()=>this.setState({blogNotExistPopup: false})}
                                className="email-send-close-btn">
                            X
                        </button>
                        <div className="email-send-image success">
                            <LottieAnimation lotti={"EmptyBlog"}/>
                        </div>
                        <div className={"email-send-text-title fail"}>
                            Sorry
                        </div>
                        <div className="email-send-text-body">
                            Currently there are no blogs!
                        </div>
                    </div>

                </Popup>


            </div>
        );
    }

}

export default withRouter(Header)