import React from 'react'
import './WebsiteButtons.css'
import PropTypes from "prop-types";
import Icon from "../IconLibrary/Icon";
import {CheckEmpty, CheckValidity} from "../CommonMethods";

class Button extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        let{
            keyvalue,
            icon,
            iconSize,
            disabled,
            borderColor,
            fill
        } = this.props
        return (
            <button className='default-button' style={{
                color: this.props.color,
                height: `${this.props.height}`,
                width: `${this.props.width}`,
                borderRadius:`${this.props.borderRadius}px`,
                backgroundColor:this.props.backgroundColor,
                borderColor: borderColor,
                fill: fill
            }}
                    disabled={disabled}
                    key={keyvalue}
                    onClick={this.props.onClick}>
                {
                    CheckValidity(icon) && CheckEmpty(icon)?
                        <Icon icon={icon} size={iconSize}/>
                        :
                        null
                }

                {this.props.labelText}
            </button>
        )
    }
}

Button.propTypes = {
    width:          PropTypes.string,
    height:         PropTypes.string,
    border:         PropTypes.number,
    borderColor:         PropTypes.string,
    borderRadius:   PropTypes.number,
    color:          PropTypes.string,
    labelText:      PropTypes.string.isRequired,
    onClick:        PropTypes.func.isRequired,
    backgroundColor:PropTypes.string,
    keyvalue:       PropTypes.string,
    icon:       PropTypes.string,
    fill:       PropTypes.string,
};

Button.defaultProps = {
    label: 'Submit',
    background: "var(--primary-color)",
    keyvalue: "button",
    borderColor: "transparent",
    iconSize: 16,
    fill: "#000000"
};

export default Button;
