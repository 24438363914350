export const BusinessFunctionList = [
    {
        Icon: "marketing",
        Title: "Marketing",
        FunctionList:[
            {
                FunctionName: "Customer Churn Prediction",
                isExpand: false,
                Challenges:"Customer churn or turnover is a critical metric for companies, especially in B2C industry segments. As the Customer Acquisition Cost is typically higher than Retention Cost, they look to learn which of the customers are likely to stop using the company’s products or services. So, advance knowledge of a customer’s intention to drop the company’s service would be helpful in appropriate planning, especially if the customer has a high lifetime value to the company.",
                Solutions: "The rules-based approaches that companies often use to predict the churn may trigger high false positives or false negatives costing the company’s bottom line. AI offers a much more accurate prediction that is also flexible and adaptable to changing market conditions. A well-implemented AI solution can analyze large sets of data and identify patterns that are missed by humans, thereby identifying the precursors to customer churn. Once a model is created and deployed across the enterprise, anyone with access to the model within the organization can continuously get updates on at-risk customers and take appropriate actions.",
            },
            {
                FunctionName: "Customer Segmentation",
                isExpand: false,
                Challenges:"Customer segmentation helps companies match their products with distinct groups based on their behavior, interests, and other characteristics. This would improve the efficiency of their marketing programs and guide the product development by aligning with the target prospective customers. Traditional approaches of segmentation are time consuming, requiring enormous manual hours to sift through data, especially when the market size is large and numerous factors that could affect purchase decisions.",
                Solutions: "AI models make the segmentation process faster as they can study large amounts of data and discover patterns more efficiently. More importantly, they can create the segments in ways that may not be initially obvious to humans, but more effective as they can account for more dimensions in the analysis. This results in increased granularity enabling organizations to create customized marketing programs.",
            },
            {
                FunctionName: "Predictive Lead Scoring",
                isExpand: false,
                Challenges:"Customer churn or turnover is a critical metric for companies, especially in B2C industry segments. As the Customer Acquisition Cost is typically higher than Retention Cost, they look to learn which of the customers are likely to stop using the company’s products or services. So, advance knowledge of a customer’s intention to drop the company’s service would be helpful in appropriate planning, especially if the customer has a high lifetime value to the company.",
                Solutions: "The rules-based approaches that companies often use to predict the churn may trigger high false positives or false negatives costing the company’s bottom line. AI offers a much more accurate prediction that is also flexible and adaptable to changing market conditions. A well-implemented AI solution can analyze large sets of data and identify patterns that are missed by humans, thereby identifying the precursors to customer churn. Once a model is created and deployed across the enterprise, anyone with access to the model within the organization can continuously get updates on at-risk customers and take appropriate actions.",
            },
            {
                FunctionName: "Sales/Demand Forecasting",
                isExpand: false,
                Challenges:"Sales forecasts help companies plan operations, sales, and marketing budgets. Multiple factors such as seasonality, macroeconomic factors, local demographics, local economy, competition, pricing, and promotion need to be considered for good estimates. However, the interaction between these multiple factors is hard to quantify as the relationships can be complex and nonlinear. So, often companies use simplified approaches which turn out to be mostly inaccurate.",
                Solutions: "AI models can be used to analyze past data and gain deep insights into the driving factors and the level of influence of each of the factors on sales. Unlike the static rules traditionally used to derive the estimates, AI models learn from the errors and improve future predictions. This results in continuously better estimates so that companies can prepare better budgets and resource plans.",
            },
            {
                FunctionName: "Targeted Marketing",
                isExpand: false,
                Challenges:"The response rate for many marketing programs is very low as they are often designed to reach a very broad audience, most of whom are not part of the target audience. While companies recognize the need for targeted approaches, creating effective segments is time consuming. So, easily identifiable demographic datasets are often used resulting in low response rates.",
                Solutions: "Using data on customer demographics and past behaviors, AI can identify the customers that are more likely to respond to the current campaign. AI models can study a broader set of influencing traits and habits, saving both time and effort, while improving the relevance of the market campaign. This approach cuts the needed additional investments and increases the ROI.",
            },

            {
                FunctionName: "Ad/Campaign Effectiveness",
                isExpand: false,
                Challenges: "Marketing campaigns often involve several elements spanning multiple media and may extend for weeks or months. Tracing a sale back to the appropriate marketing tactic or media would help companies optimize their marketing expenditure. However, the marketing mix study is time-intensive and is generally done as an after-the-fact exercise.",
                Solutions: "AI models help optimize the marketing mix modeling by ingesting and analyzing the steady stream of data. With the rise in share of the digital marketing and advertising, companies can get deeper insights into the links between each tactic and closing of a sale at user level. The model can also be used to estimate the effectiveness of a specific marketing tactic and messaging. "
            },
        ],
        isExpand: true,
    },

    {
        Icon: "it_department",
        Title: "IT Department",
        FunctionList:[
            {
                FunctionName: "Account Takeover and Defense",
                isExpand: false,
                Challenges:"Account Takeover attacks have been steadily rising and the cost to users and companies has also been increasing rapidly. Hackers are using increasingly sophisticated methods and the current solutions are not able to keep up. Traditional, analyst-driven solutions such as firewalls and intrusion detection and presentation systems which rely on rules-based approaches. If a new type of attack is detected, the rules must be updated and tested before deploying to prevent future attacks, leaving the companies exposed until the fix is in place.",
                Solutions: "AI methods continuously sift the network traffic looking for anomalies. The AI model would first get trained on the past network data, so that it can distinguish anomalies that are then reported for human investigation. The model would improve with time as it is retrained based on analyst’s input on these outliers. AI models can also learn legitimate user behavior patterns and use this knowledge to detect bad actors. products.",
            }
        ],
        isExpand: false,
    },

    {
        Icon: "hr_department",
        Title: "HR Department",
        FunctionList:[
            {
                FunctionName: "Employee Turnover",
                isExpand: false,
                Challenges:"Unplanned employee turnover can lead to missed deadlines, lower productivity, and higher expenses for organizations. The effect on companies with low profit margins is starker as it can take new employees several months to be fully productive. Employees may be motivated to make a change for any number of reasons, including career growth opportunities, family situations, lack of training, team dynamics and compensation. One size fits all programs do not help keeping the turnover low.",
                Solutions: "AI can help identify the underlying root causes of turnover based on past data and helps companies devise programs to reduce the employee turnover. By analyzing historical data on factors such as absenteeism, employee behavior, job satisfaction, work performance, compensation competitiveness in addition to employee demographics and past job mobility, AI models can identify the factor that could encourage employee ‘stickiness’. The insights provided by AI can help businesses arrive at potential solutions to reduce the turnover and get maximum return on their investments.",
            }
        ],
        isExpand: false,
    },
    {
        Icon: "customer_service",
        Title: "Customer Service",
        FunctionList:[
            {
                FunctionName: "Employee Efficiency",
                isExpand: false,
                Challenges:"Repeat or continued revenue stream from customers depends strongly on how the organization handles incident reports. Analysis of severity of customer complaints and requests for support, and the speed at which they are addressed are some of the key indicators of customer satisfaction. Current approach of surveys alone does not provide a clear picture of customer satisfaction.",
                Solutions: "AI models analyze multiple factors to learn the patterns of call volume, customer support team engagement, and ticket resolution metrics. If deviations and anomalies are noticed in the call patterns, alerts are issued so that humans can follow up to ensure satisfactory resolution of the problem. This can help improve customer satisfaction and reduce long term costs.",
            },
            {
                FunctionName: "Ticket Resolution",
                isExpand: false,
                Challenges:"Repeat or continued revenue stream from customers depends strongly on how the organization handles incident reports. Analysis of severity of customer complaints and requests for support, and the speed at which they are addressed are some of the key indicators of customer satisfaction. Current approach of surveys alone does not provide a clear picture of customer satisfaction.",
                Solutions: "AI models analyze multiple factors to learn the patterns of call volume, customer support team engagement, and ticket resolution metrics. If deviations and anomalies are noticed in the call patterns, alerts are issued so that humans can follow up to ensure satisfactory resolution of the problem. This can help improve customer satisfaction and reduce long term costs.",
            },
        ],
        isExpand: false,
    },
    {
        Icon: "sale_team",
        Title: "Sales Team",
        FunctionList:[
            {
                FunctionName: "Employee Efficiency",
                isExpand: false,
                Challenges:
                    "Repeat or continued revenue stream from customers depends strongly on how the organization handles incident reports. Analysis of severity of customer complaints and requests for support, and the speed at which they are addressed are some of the key indicators of customer satisfaction. Current approach of surveys alone does not provide a clear picture of customer satisfaction.",
                Solutions: "AI models analyze multiple factors to learn the patterns of call volume, customer support team engagement, and ticket resolution metrics. If deviations and anomalies are noticed in the call patterns, alerts are issued so that humans can follow up to ensure satisfactory resolution of the problem. This can help improve customer satisfaction and reduce long term costs.",
            }
        ],
        isExpand: false,
    },
]