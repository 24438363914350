import React from "react";
import "./modelTester.css";
import Icon from "../../../Assets/IconLibrary/Icon"
import {ModelTesterColumnList} from "./ModelTesterColumnList";
import {Scrollbars} from "react-custom-scrollbars";
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import ModelTesterResult from "./ModelTesterResult";
import ToggleButton from "../../../Assets/ToggleButton/ToggleButton";
import {
    renderSwitchDipService,
    renderSwitchDOceanService,
    renderSwitchMLPService,
    renderSwitchOrgService,
    renderSwitchScaledService
} from "../../../api/ResourcesApiLayer";
import ResponsePopup from "../../Commoncomponents/ResponsePopups/ResponsePopup";
import Loading from "../../../Assets/Loading/Loading";
import VerticalEmptyScreen from "../../../Assets/EmptyScreen/VerticalEmptyScreen";
import WaitingResultImage from "../../../Assets/Images/pictures/empty_model_tester.svg";
import Pagination from "../../../Assets/Pagination/Pagination";
import SysPopup from "../../../Assets/SysPopup/SysPopup";
import Radio from "@material-ui/core/Radio/Radio";
import RotatingCircle from "../../../Assets/Loading/RotatingCircle";
import PianoLoading from "../../../Assets/Loading/PianoLoading";
import {checkValidity} from "../../../api/ApiCallMethod";

const uuidv4 = require('uuid').v4;
let HashMap = require('hashmap');

class ModelTester extends React.Component {
    constructor(props) {
        super(props);
        let {
            currentModelInfo,
            userInfo,
            algorithmType,
            TenantID,
            ApplicationID,
            ModelID,
            AlgorithmID,
            RunID,
            CkptFile
        } = this.props
        this.state = {
            dataReady: false,
            textInputDataHashMap: {}, //key : traceID, value: text input data
            userInfo,
            algorithmType,
            pageType: "history",
            testType: [
                {Name: "Normal Form", Icon: "list_view"},
                {Name: "Kafka", Icon: "kafka"},
            ],
            chosenTestType: "Normal Form",
            viewTestHistoryInput: [
                {
                    Title: "Prediction 1",
                    IsChecked: false,
                    InputList: [
                        {
                            ColumnName: 'Image',
                            DataType: 'image',
                            value: [
                                {
                                    Image: '',
                                    ImageName: ''
                                },
                                {
                                    Image: '',
                                    ImageName: ''
                                }
                            ]
                        },
                        {
                            ColumnName: 'Chess_Set_Type',
                            DataType: 'string',
                            value: 'Staunton'
                        },
                        {
                            ColumnName: 'Chess_Material',
                            DataType: 'array',
                            value: ['Wooden', 'Clay', 'Ivory']
                        },
                        {
                            ColumnName: 'Column 4',
                            DataType: 'int',
                            value: 1111,
                        },
                        {
                            ColumnName: 'Column 5',
                            DataType: 'range',
                            value: {
                                MinValue: 10,
                                MaxValue: 150
                            }
                        }

                    ]
                },
            ],
            testDataList: [
                {
                    Title: "Prediction 1",
                    isExpand: false,
                },

            ],
            columnCount: 10,
            dataType: "image",
            dropItemList: [
                // {scr:SampleImage, Name: "Testing image 101.png"},
                // {scr:SampleImage, Name: "Testing image 101.png"},
                // {scr:SampleImage, Name: "Testing image 101.png"},
            ],
            inputColumnList: ModelTesterColumnList,
            isExpand: true,
            currentModelInfo,
            TenantID,
            ApplicationID,
            ModelID,
            AlgorithmID,
            RunID,
            CkptFile,
            modelTestResultData: [],
            chooseOption: [
                {"name": "Single"},
                {"name": "Multi"}
            ],
            multiSelectType: 'Single',
            initialLoading: true,
            featureList: this.props.featureList,
            showPredictResult: false,
            Dimentionality: this.props.Dimentionality,
            githubInfo: [],
            inputColumnNameArray: [],
            outputFeatureInfoHM: new HashMap(),
            pageSizeForCategoricalData: 20,
            inputFeatureInfoHM: new HashMap(),
            outputColumnNameArray: [],
            disabledModelTesterType: true,
            inputView: true
        }
    }

    componentDidMount() {
        const {currentModelInfo, TenantID, ApplicationID, ModelID} = this.props;
        console.log(">> model tester props in did mount :: ", this.props)
        this.checkAndPrepareModelInfo(ModelID, currentModelInfo, TenantID, ApplicationID)
    }

    componentWillReceiveProps(nextProps, nextContext) {
        const {currentModelInfo, TenantID, ApplicationID, ModelID} = nextProps
        console.log(">> model tester will receives :: ", nextProps)
        if (this.state.ModelID !== ModelID) {
            this.checkAndPrepareModelInfo(ModelID, currentModelInfo, TenantID, ApplicationID)
        }
    }

    checkAndPrepareModelInfo = (modelID, currentModelInfo, TenantID, ApplicationID) => {
        let params = "?modelID=" + modelID
        renderSwitchMLPService('getInstantModelInfoByModelID', '', '', params).then(
            response => {
                console.log("response of get model info : ", response.data)
                if (response.data['ResponseCode'] === 1000 && checkValidity(response.data["Results"])) {
                    let modelInfo = response.data["Results"];
                    this.prepareInitialData(modelInfo, TenantID, ApplicationID)
                } else {
                    this.setState({
                        dataReady: false,
                        initialLoading: false,
                        waitingScreen2: true,
                        testLoaded: false,
                        popupOpen: true,
                        popupType: "fail",
                        popupTitle: "Request Error",
                        popupMessage: "Currently, we're unable to process your request, please try again later!"
                    })
                }
            }).catch(e => {
                console.log('catch error in calling model info by id ', e)
                this.setState({
                    dataReady: false,
                    emptyScreen: true
                })
                this.showSystemError()
            }
        )
    }

    prepareInitialData = (currentModelInfo, TenantID, ApplicationID) => {
        renderSwitchOrgService('getTenantInfoByTenantID', '', this.state.TenantID, '').then(
            response => {
                console.log("response of checking github info >>>>>", response.data)
                if (response.data['ResponseCode'] === 1000) {
                    let tenantInfo = response.data["Results"];
                    let githubInf = {
                        "GithubUserName": tenantInfo['GithubUserName'],
                        "GithubEmail": tenantInfo['GithubEmail'],
                        "Token": tenantInfo['Token']
                    }
                    this.setState({
                        githubInfo: githubInf,
                        currentModelInfo
                    })
                    if (this.checkValidity(currentModelInfo["MachineLearningID"])) {
                        this.prepareForModelRangeTest(currentModelInfo, currentModelInfo["MachineLearningID"], TenantID, ApplicationID, this.state.algorithmType)
                    }
                }
            }).catch(e => {
                console.log('catch error >>', e)
                this.setState({
                    emptyScreen: true,
                    dataReady: false
                })
                this.showSystemError()
            }
        )
    }

    prepareForModelRangeTest(modelInfo, machineLearningID, tenantID, applicationID, AlgorithmType) {
        console.log(">> reached to prepare model range test ....")
        let featureList = this.state.featureList
        let FeatureDetailsInfoArr = featureList.FeatureDetailsInfoArr
        let allInputFeatures = []
        let foundOutputColumn = false
        let featureCount = 0;
        let dataTypeHM = new HashMap()
        let outputTraceIDHM = new HashMap()
        let inputDataTypeHM = new HashMap()
        let isIncludeCategoricalInputCol = false
        let modelTestResultData = []
        let inputColumnNameArray = []
        let outputColumnNameArray = []
        let outputFeatureInfoHM = new HashMap()
        let inputFeatureInfoHM = new HashMap()

        FeatureDetailsInfoArr.map(feature => {
            let prepareData = {
                DataType: feature.OriginalDataType,
                Values: [],
                ColumnName: feature.ColumnName,
                OutputColumn: false,
                multiSelectType: 'Single'
            }
            if (feature.Key !== "output") {
                allInputFeatures = allInputFeatures.concat(feature)
                if (feature.DataType === 'enum') {
                    inputDataTypeHM.set(feature.TraceID, feature.DataType)
                    isIncludeCategoricalInputCol = true
                }

                modelTestResultData = modelTestResultData.concat(prepareData)
                inputColumnNameArray = inputColumnNameArray.concat(feature.ColumnName)
                inputFeatureInfoHM.set(feature.ColumnName, feature)
            } else {
                foundOutputColumn = true
                outputTraceIDHM.set(feature.TraceID, feature.ColumnName)
                prepareData.OutputColumn = true
                modelTestResultData = modelTestResultData.concat(prepareData)
                outputFeatureInfoHM.set(feature.ColumnName, feature)
                outputColumnNameArray = outputColumnNameArray.concat(feature.ColumnName)

            }
            dataTypeHM.set(feature.ColumnName, feature.DataType)

            if (featureCount === FeatureDetailsInfoArr.length - 1) {
                if (!foundOutputColumn) {
                    if (AlgorithmType === 'Clustering') {
                        let prepareClusterData = {
                            ColumnName: 'Cluster',
                            Values: [],
                            DataType: "int",
                            OutputColumn: true,
                            multiSelectType: 'Single'
                        }
                        modelTestResultData = modelTestResultData.concat(prepareClusterData)
                        outputColumnNameArray = outputColumnNameArray.concat('Cluster')
                    }
                }
                this.prepareMlDataFeatureRangeAndColumnResultFormat(
                    modelInfo,
                    tenantID, applicationID,
                    machineLearningID,
                    AlgorithmType,
                    allInputFeatures,
                    featureList,
                    dataTypeHM,
                    inputDataTypeHM,
                    outputTraceIDHM,
                    isIncludeCategoricalInputCol,
                    modelTestResultData,
                    inputColumnNameArray, outputFeatureInfoHM, inputFeatureInfoHM, outputColumnNameArray)
            }
            featureCount++;
        })
    }

    getFeatureDataRange(mlFeaturesInput) {
        console.log(">> reached to get features data range :: ", mlFeaturesInput)
        mlFeaturesInput.FixedCategoricalData = true
        mlFeaturesInput.CategoricalCount = 20
        renderSwitchDOceanService("getFeatureDataRangeForInstantModel", mlFeaturesInput, "", "").then(response => {
            console.log("feature data range :::::::::::: ", response.data)
            if (response.data !== "") {
                if (response.data["ResponseCode"] === 1000) {
                    let featuresDataRangeInfo = response.data["Results"];
                    console.log("response data range ", featuresDataRangeInfo)
                    let noTestingCase = false
                    let categoricalData = featuresDataRangeInfo["CategoricalDataHashMap"]
                    let featureRangeData = featuresDataRangeInfo["FeatureDataRange"]
                    featuresDataRangeInfo["FeaturesDetailsInfo"].map(eachFeature => {
                        if (eachFeature.Key === "input") {
                            if ((categoricalData[eachFeature.ColumnName] !== undefined && categoricalData[eachFeature.ColumnName] !== null && categoricalData[eachFeature.ColumnName].length > 0) ||
                                (featureRangeData[eachFeature.ColumnName] !== undefined)) {

                            } else {
                                console.log("entered to no testing case :: ", eachFeature.TraceID, eachFeature)
                                noTestingCase = true
                            }
                        }
                    })
                    if (noTestingCase) {
                        console.log("no testing data case")
                        let textInputDataHashMap = this.state.textInputDataHashMap
                        featuresDataRangeInfo["FeaturesDetailsInfo"].map(eachFeature => {
                            textInputDataHashMap[eachFeature.ColumnName] = ""
                        })
                        this.setState({
                            noTestingDataCase: true,
                            disabledModelTesterType: true,
                            textInputDataHashMap,
                            mlFeaturesInfo: featuresDataRangeInfo,
                            CategoricalDataHashMap: {},
                            featuresRangeHashMap: {},
                            notReady: false,
                            initialLoading: false,
                            dataReady: true
                        })
                    } else {
                        let CategoricalDataHashMap = featuresDataRangeInfo["CategoricalDataHashMap"]
                        let changedCategoryMap = {}
                        if (CategoricalDataHashMap !== null) {
                            for (const [key, featureRange] of Object.entries(CategoricalDataHashMap)) {
                                if (featureRange !== null) {
                                    changedCategoryMap[key] = {
                                        CurrentSelected: featureRange[0],
                                        CategoriesValues: featureRange,
                                        SelectedList: [featureRange[0]]
                                    }
                                } else {
                                    changedCategoryMap[key] = {
                                        CurrentSelected: "",
                                        CategoriesValues: [],
                                        SelectedList: []
                                    }
                                }
                            }
                        }

                        let notReady = false;
                        if (changedCategoryMap === {} && featuresDataRangeInfo["FeatureDataRange"] === {}) {
                            notReady = true
                        }
                        console.log("after prepared :: ", changedCategoryMap)
                        this.setState({
                            mlFeaturesInfo: featuresDataRangeInfo,
                            CategoricalDataHashMap: changedCategoryMap,
                            notReady,
                            initialLoading: false,
                            dataReady: true
                        })
                        this.prepareDataInputFlow(featuresDataRangeInfo)
                    }
                } else {
                    this.setState({
                        emptyScreen: true
                    })
                    this.showSystemError()
                }
            } else {
                this.setState({
                    emptyScreen: true
                })
                this.showSystemError()
            }
        }).catch(e => {
            console.log('catch error >>', e)
            this.setState({
                emptyScreen: true
            })
            this.showSystemError()
        })
    }

    prepareMlDataFeatureRangeAndColumnResultFormat = (modelInfo, tenantID, applicationID,
                                                      machineLearningID,
                                                      AlgorithmType, allInputFeatures,
                                                      featureList, dataTypeHM,
                                                      inputDataTypeHM,
                                                      outputTraceIDHM,
                                                      isIncludeCategoricalInputCol,
                                                      modelTestResultData,
                                                      inputColumnNameArray,
                                                      outputFeatureInfoHM, inputFeatureInfoHM, outputColumnNameArray) => {

        let prepareData = this.checkForModelExplain(modelInfo, featureList)
        console.log("checking explainable data or not :: ", this.state.featureList)
        this.getFeatureDataRange(this.state.featureList)
        this.setState({
            outputFeatureInfoHM: outputFeatureInfoHM,
            inputFeatureInfoHM: inputFeatureInfoHM,
            inputColumnNameArray: inputColumnNameArray,
            outputColumnNameArray: outputColumnNameArray,
            showModelExplain: prepareData.showModelExplain,
            explainableData: prepareData.explainableData,
            outputTraceIDHM: outputTraceIDHM,
            dataTypeHM: dataTypeHM,
            inputDataTypeHM: inputDataTypeHM,
            isIncludeCategoricalInputCol: isIncludeCategoricalInputCol,
            modelTestResultData: modelTestResultData
        })
    }

    prepareDataInputFlow(featuresDataRangeInfo) {
        console.log("featuresDataRangeInfo in preparing :: ", featuresDataRangeInfo)
        let featuresRangeList = featuresDataRangeInfo["FeatureDataRange"]
        let equalMinMaxHM = new HashMap()
        console.log("features range list :: ", featuresRangeList)
        if (featuresRangeList !== null) {
            for (const [key, featureRange] of Object.entries(featuresRangeList)) {
                if (featureRange["MinValue"] !== featureRange["MaxValue"]) {
                    let endValue = featureRange["MaxValue"]
                    if (featureRange["MinValue"] + featureRange["RangeValue"] < featureRange["MaxValue"]) {
                        endValue = featureRange["MinValue"] + featureRange["RangeValue"]
                    }

                    // let middleValue = (featureRange["MaxValue"] - featureRange["MinValue"]) / 2
                    // if (featureRange["MinValue"] + middleValue < endValue){
                    //     endValue = featureRange["MinValue"] + middleValue
                    // }

                    featureRange["CurrentRangeValue"] = [featureRange["MinValue"], endValue]
                    featureRange["FixedData"] = true
                    featureRange["FixedValue"] = featureRange["MinValue"]
                    featuresRangeList[key] = featureRange
                    equalMinMaxHM.set(key, false)
                } else {
                    featureRange["CurrentRangeValue"] = [featureRange["MinValue"], featureRange["MaxValue"]]
                    featureRange["FixedData"] = true
                    featureRange["FixedValue"] = featureRange["MinValue"]
                    featuresRangeList[key] = featureRange
                    equalMinMaxHM.set(key, true)
                }
            }

            console.log("after prepared :: ", featuresRangeList)
            this.setState({
                featuresRangeHashMap: featuresRangeList,
                initialLoading: false,
                equalMinMaxHM: equalMinMaxHM
            })
        } else {
            this.setState({
                initialLoading: false,
                featuresRangeHashMap: "null"
            })
        }
    }

    checkValidity = (checkItem) => {
        return (checkItem !== undefined && checkItem !== null && checkItem !== "")
    }

    checkEmpty = (checkItem) => {
        return (checkItem.length !== 0)
    }

    getFormHeader = () => {
        return (
            <div className="model-tester-header--tool-bar">
                <div className="model-tester-header--div">
                    Columns
                </div>
                {
                    !this.state.noTestingDataCase ?
                        this.checkShowingValueType() ?
                            <div className="model-tester-header--div">
                                <div className="model-tester-header--label">
                                    Value Type
                                </div>
                                <ToggleButton options={this.state.chooseOption}
                                              onClickOptionHandler={this.handleChooseOption.bind(this)}
                                              activeOption={this.state.multiSelectType}/>
                            </div>
                            :
                            null
                        :
                        null

                }

            </div>
        )
    }

    handleChooseOption(option) {
        let featuresRangeHashMap = this.state.featuresRangeHashMap
        this.setState({
            multiSelectType: option
        })
        let resultData = this.state.modelTestResultData
        resultData.map((data) => {
            data.multiSelectType = option
            if (featuresRangeHashMap[data.ColumnName] !== undefined) {
                featuresRangeHashMap[data.ColumnName]['FixedData'] = option === 'Single'
            }
        })
        this.setState({
            featuresRangeHashMap,
            modelTestResultData: resultData
        })
    }

    handleChooseColumnOption(index, option) {
        let resultData = this.state.modelTestResultData
        let multiSelectType = this.state.multiSelectType
        let foundCount = 0;
        let count = 0;
        let featuresRangeHashMap = this.state.featuresRangeHashMap
        resultData.map((data, dataIndex) => {
            if (dataIndex === index) {
                data.multiSelectType = option
                if (featuresRangeHashMap[data.ColumnName] !== undefined) {
                    featuresRangeHashMap[data.ColumnName]['FixedData'] = option === 'Single'
                }
            }
            if (option === 'Single') {
                foundCount++
            } else {
                foundCount++
            }
            if (count === resultData.length - 1) {
                if (foundCount === resultData.length - 1) {
                    multiSelectType = option
                }
            }
            count++;
        })
        this.setState({
            featuresRangeHashMap,
            modelTestResultData: resultData,
            multiSelectType: multiSelectType
        })
    }

    checkForModelExplain = (modelInfo, featureList) => {
        let showModelExplain = false
        let nlpCount = 0
        let featuresCount = 0
        let explainableData = false
        if (featureList !== undefined && featureList !== null) {
            for (let index = 0; index < featureList.length; index++) {
                if (featureList[index].Key === "input") {
                    featuresCount++
                    if (featureList[index].DataType === "nlp") {
                        nlpCount++
                    }
                }
            }
        }
        if (modelInfo.Status === "success") {
            showModelExplain = true
            if (nlpCount > 0) {
                if (nlpCount > 1 || featuresCount !== nlpCount) {
                    showModelExplain = false
                }
            }
        }

        //only allow single nlp explanation and no nlp explanations
        if (nlpCount === 0 || (nlpCount === featuresCount && nlpCount === 1)) {
            explainableData = true
        }

        console.log("showModelExplain ??", showModelExplain, modelInfo.Status)
        return {
            showModelExplain,
            explainableData
        }
    }

    showSystemError = () => {
        this.setState({
            initialLoading: false,
            waitingScreen2: true,
            testLoaded: false,
            popupOpen: true,
            popupType: "fail",
            popupTitle: "Request Error",
            popupMessage: "Currently, we're unable to process your request, please try again later!"
        })
    }

    handleClosePopup = (eventName) => {
        this.setState({
            [eventName]: false,
        })
    }

    prepareInputDataForResult() {
        let width = window.innerWidth; //992
        this.state.inputView = width > 991
        console.log(">> reached to prepare input data :: ", this.state.currentModelInfo, this.state.outputFeatureInfoHM)
        let modelInfo = this.state.currentModelInfo
        let startTimeForPrediction = new Date();
        let selectedClassInfo = this.state.selectedClassInfo
        console.log("Start api call for prediction...", startTimeForPrediction);
        this.setState({
            testResultLoading: true,
            testLoaded: true,
            waitingScreen: false,
            disabledExplainability: true,
            selectedRow: 0
        })
        let featuresList = this.state.mlFeaturesInfo["FeaturesDetailsInfo"]
        console.log(">> feature list when preparing ::: ", featuresList)
        let featuresRangeHashMap = this.state.featuresRangeHashMap;
        let inputDataValue = [];
        let traceIDs = [];
        let inputFeatures = []
        let outputFeatureInfoHM = this.state.outputFeatureInfoHM
        for (let index = 0; index < featuresList.length; index++) {
            if (featuresList[index].Key !== "output") { //so, the data is already
                featuresList[index].SourceID = this.state.userInfo
                let featureInfo = featuresList[index];
                //check if categorical or not
                inputFeatures = inputFeatures.concat(featureInfo)
                let inputValueRangeInfo = {};
                let fixedValue = 0
                let minValue = 0;
                let maxValue = 0;
                let categoricalVal = "";
                if (this.state.noTestingDataCase === true) {
                    console.log("this.state.textInputDataHashMap :: ", this.state.textInputDataHashMap)
                    if (featureInfo["Categorical"]) {
                        categoricalVal = this.state.textInputDataHashMap[featureInfo.ColumnName]
                    } else {
                        fixedValue = this.state.textInputDataHashMap[featureInfo.ColumnName]
                    }
                    inputValueRangeInfo = {
                        TraceID: featureInfo["TraceID"],
                        ColumnName: featureInfo["ColumnName"],
                        CategoricalValue: categoricalVal,
                        FixedValue: fixedValue,
                        MinValue: minValue,
                        MaxValue: maxValue,
                        FixedData: true,
                    }
                } else {
                    if (featureInfo["Categorical"]) {
                        if (this.state.CategoricalDataHashMap[featureInfo["ColumnName"]] !== undefined) {
                            categoricalVal = this.state.CategoricalDataHashMap[featureInfo["ColumnName"]]["CurrentSelected"]
                        }
                        inputValueRangeInfo = {
                            TraceID: featureInfo["TraceID"],
                            ColumnName: featureInfo["ColumnName"],
                            CategoricalValue: categoricalVal,
                            FixedValue: fixedValue,
                            MinValue: minValue,
                            MaxValue: maxValue,
                            FixedData: true,
                        }
                    } else {
                        let inputFeatureInfo = featuresRangeHashMap[featureInfo["ColumnName"]]
                        if (inputFeatureInfo !== undefined) {
                            let fixedData = inputFeatureInfo["FixedData"]

                            if (fixedData) {
                                if (!isNaN(inputFeatureInfo["FixedValue"])) {
                                    fixedValue = parseFloat(inputFeatureInfo["FixedValue"])
                                }
                            } else {
                                if (!isNaN(inputFeatureInfo["CurrentRangeValue"][0])) {
                                    minValue = parseFloat(inputFeatureInfo["CurrentRangeValue"][0])
                                }
                                if (!isNaN(inputFeatureInfo["CurrentRangeValue"][1])) {
                                    maxValue = parseFloat(inputFeatureInfo["CurrentRangeValue"][1])
                                }
                            }

                            inputValueRangeInfo = {
                                TraceID: featureInfo["TraceID"],
                                ColumnName: featureInfo["ColumnName"],
                                FixedData: inputFeatureInfo["FixedData"],
                                CategoricalValue: categoricalVal,
                                FixedValue: fixedValue,
                                MinValue: minValue,
                                MaxValue: maxValue,
                                Range: inputFeatureInfo["RangeValue"]
                            }
                        }
                    }
                }

                traceIDs = traceIDs.concat(featureInfo["TraceID"])
                inputDataValue = inputDataValue.concat(inputValueRangeInfo)

            }
        }
        let actualDataInput = {
            ID: uuidv4(),
            TenantID: this.state.TenantID,
            GithubInfo: this.state.githubInfo,
            InputData: inputDataValue,
            ModelID: modelInfo["ID"],
            MachineLearningID: modelInfo["MachineLearningID"],
            AlgorithmID: modelInfo["AlgorithmID"],
            FrameWorkID: modelInfo["FrameworkID"],
            Dimentionality: this.state.Dimentionality,
            InputRangeInfo: inputDataValue,
            DataElement: inputFeatures,
            UserInfo: this.state.userInfo,
            Path: modelInfo["Path"]
        }
        console.log("input data for  generate predict api::", actualDataInput)
        renderSwitchMLPService('callMlPredictionForInstantModel', actualDataInput, "", "").then(response => {
            console.log('callMlPredictionForInstantModel : ', response.data)
            if (response.data["ResponseCode"] === 1000 || response.data["ResponseCode"] === 2048) {
                let predictionDataResult = response.data["Results"];
                let endTimeForPrediction = new Date();
                let rSeconds = (endTimeForPrediction.getTime() - startTimeForPrediction.getTime()) / 1000;
                console.log("Time taken in seconds for prediction: ", rSeconds)
                this.prepareRangeOutputResult(predictionDataResult, outputFeatureInfoHM)
                selectedClassInfo["indexList"] = predictionDataResult.PredictDataInput
                let disableForwardBackward = false
                let disableForward = false
                let disableBackward = false
                if (selectedClassInfo["indexList"].length === 1) {
                    disableForwardBackward = true
                    disableForward = true
                    disableBackward = true
                }
                this.setState({
                    predictionDataResult,
                    selectedClassInfo,
                    disableForward,
                    disableForwardBackward,
                    disableBackward,
                    testResultLoading: false
                })
            } else {
                this.setState({
                    testResultLoading: false,
                    testLoaded: false,
                    resultData: []
                })
            }
        }).catch((e) => {
            console.log("catch error after calling predict api : ", e)
            this.setState({
                testResultLoading: false,
                testLoaded: false,
                resultData: []
            })
        })
    }

    prepareRangeOutputResult(testOutput, outputFeatureInfoHM) {
        console.log("outputFeatureInfoHM  =>", testOutput, outputFeatureInfoHM)
        let outPutValue = ""
        let resultData = this.state.modelTestResultData;
        let resultDataHM = new HashMap()
        let outputIndexColumn = []
        let outputIndexColumnHM = new HashMap()
        let count = 0;
        let currentResultColumnName = ""
        let inputColumnNameArray = this.state.inputColumnNameArray
        this.setState({
            loaded1: false,
            loaded2: false
        })
        console.log('inputColumnNameArray >>>>', inputColumnNameArray)
        resultData.map((result, res) => {
                if (!result.OutputColumn) {
                    let concatResults = []
                    testOutput.PredictDataInput.map((predictData) => {
                            inputColumnNameArray.map((inputCol, inputColIndex) => {
                                if (inputCol === result.ColumnName) {
                                    concatResults = concatResults.concat(predictData[inputColIndex])
                                    result.Values = concatResults
                                }
                            })
                        }
                    )
                } else {
                    outputIndexColumn = outputIndexColumn.concat(result.ColumnName)
                    if (currentResultColumnName === "") {
                        currentResultColumnName = result.ColumnName
                    }
                    let columnDataType = result.DataType
                    if (columnDataType === "enum") {
                        console.log('output column that are enum data type =>', result.ColumnName, outputIndexColumn)
                        if (testOutput.PredictOutput !== undefined && testOutput.PredictOutput !== null) {
                            let enumOutputArray = []
                            let allEnumOutputArray = []
                            let outputData;
                            let outputCount = 0;
                            testOutput.PredictOutput.map(output => {
                                    isNaN(output[outputIndexColumn.length - 1]) ?
                                        outputData = output[outputIndexColumn.length - 1]
                                        :
                                        Number.isInteger(Number(output[outputIndexColumn.length - 1])) ?
                                            outputData = output[outputIndexColumn.length - 1]
                                            :
                                            outputData = parseFloat(output[outputIndexColumn.length - 1].toFixed(5))
                                    if (outputData !== null) {
                                        enumOutputArray = enumOutputArray.concat(outputData)
                                    }
                                    allEnumOutputArray = allEnumOutputArray.concat(outputData)
                                    if (outputCount === testOutput.PredictOutput.length - 1) {
                                        let isFoundAllNull = []
                                        let count = 0;
                                        allEnumOutputArray.map(arr => {
                                                if (arr === null) {
                                                    isFoundAllNull = isFoundAllNull.concat(null)
                                                }
                                                if (count === allEnumOutputArray.length - 1) {
                                                    if (isFoundAllNull.length === allEnumOutputArray.length) {
                                                        let concatResults = []
                                                        console.log("> output map :: ", testOutput.PredictOutput)
                                                        let count = 0;
                                                        if (testOutput.PredictOutput !== undefined && testOutput.PredictOutput !== null) {
                                                            testOutput.PredictOutput.map((predictOutput) => {
                                                                    console.log('predict output =>', predictOutput)
                                                                    concatResults = concatResults.concat(predictOutput[outputIndexColumn.length - 1])
                                                                    result.Values = concatResults
                                                                    console.log('concatResults =>', concatResults)
                                                                    if (count === testOutput.PredictOutput.length - 1) {
                                                                        this.setState({
                                                                            loaded2: true
                                                                        })
                                                                        this.handleTestedLoaded(this.state.loaded1, true)
                                                                    }
                                                                    count++;
                                                                }
                                                            )
                                                        }
                                                    } else {
                                                        console.log('outputIndexColumn =>', outputIndexColumn)
                                                        let outputInfo = outputFeatureInfoHM.get(result.ColumnName)
                                                        console.log('outputInfo =>', outputInfo)
                                                        let inputJson = {
                                                            SourceID: this.state.userInfo,
                                                            TableName: outputInfo.TableName,
                                                            ColumnName: outputInfo.ColumnName,
                                                            NumericalValue: enumOutputArray,
                                                            MultiPunch: true
                                                        }
                                                        console.log("input For scaled : ", inputJson, outputIndexColumn)
                                                        renderSwitchScaledService('getNumericalCategoricalValues', inputJson, '', '').then(
                                                            resp => {
                                                                console.log("response from scaled : ", resp.data, outputIndexColumn)
                                                                let results;
                                                                if (resp.data["ResponseCode"] === 1000) {
                                                                    results = resp.data["Results"]
                                                                    let concatResults = []
                                                                    console.log("> output map :: ", testOutput.PredictOutput, outputIndexColumn, outputIndexColumnHM, outputIndexColumnHM.get(result.ColumnName))
                                                                    let count = 0;
                                                                    testOutput.PredictOutput.map((predictOutput, predictOutputIndex) => {
                                                                            if (enumOutputArray[predictOutputIndex] !== null) {
                                                                                if (results[enumOutputArray[predictOutputIndex]] !== undefined) {
                                                                                    outPutValue = results[enumOutputArray[predictOutputIndex]]
                                                                                    if (outPutValue !== undefined && outPutValue !== null) {
                                                                                        if (outPutValue.length > 0) {
                                                                                            concatResults = concatResults.concat(outPutValue[0])
                                                                                        }
                                                                                    }
                                                                                } else {
                                                                                    concatResults = concatResults.concat(predictOutput[outputIndexColumnHM.get(result.ColumnName).length - 1])
                                                                                }
                                                                            } else {
                                                                                concatResults = concatResults.concat(predictOutput[outputIndexColumnHM.get(result.ColumnName).length - 1])
                                                                            }
                                                                            result.Values = concatResults
                                                                            if (count === testOutput.PredictOutput.length - 1) {
                                                                                this.setState({
                                                                                    loaded2: true
                                                                                })
                                                                                this.handleTestedLoaded(this.state.loaded1, true)
                                                                            }
                                                                            count++;

                                                                        }
                                                                    )
                                                                }
                                                                console.log('resultData =>', resultData, resultDataHM, outputIndexColumn)
                                                            }
                                                        )
                                                    }
                                                }
                                                count++;
                                            }
                                        )
                                    }
                                    outputCount++;
                                }
                            )
                        }
                    } else {
                        console.log('output column that are not enum data type =>', result.ColumnName, testOutput.PredictOutput, res, outputFeatureInfoHM, outputIndexColumn)
                        console.log('resultData >>', resultData)
                        console.log('output Index =>', outputIndexColumn)
                        let concatResults = []
                        console.log("> output map :: ", testOutput.PredictOutput)
                        let count = 0;
                        if (testOutput.PredictOutput !== undefined && testOutput.PredictOutput !== null) {
                            testOutput.PredictOutput.map((predictOutput) => {
                                    console.log('predict output =>', predictOutput)
                                    concatResults = concatResults.concat(predictOutput[outputIndexColumn.length - 1])
                                    result.Values = concatResults
                                    console.log('concatResults =>', concatResults)
                                    if (count === testOutput.PredictOutput.length - 1) {
                                        this.setState({
                                            loaded2: true
                                        })
                                        this.handleTestedLoaded(this.state.loaded1, true)
                                    }
                                    count++;
                                }
                            )
                        }

                    }
                }
                resultDataHM.set(result.ColumnName, result)
                if (count === resultData.length - 1) {
                    this.setState({
                        showPredictResult: true,
                        modelTestResultData: resultData,
                        resultDataHM: resultDataHM,
                        disabledExplainability: false,
                        currentResultColumnName,
                        loaded1: true
                    })
                    this.handleTestedLoaded(true, this.state.loaded2)
                }
                count++;
            }
        )
    }

    handleTestedLoaded(loaded1, loaded2) {
        if (loaded1 && loaded2) {
            this.setState({
                testLoaded: false
            })
        }
    }

    openCategoricalSelectionPopup = (currentCategoricalColName, selectedValue, mlFeature) => {
        console.log("selected value before opening categorical selection popup : ", selectedValue, mlFeature)
        let currentSourceTableInfo = ""
        if (this.state.inputFeatureInfoHM !== "" && this.state.inputFeatureInfoHM.get(mlFeature.ColumnName) !== undefined) {
            currentSourceTableInfo = this.state.inputFeatureInfoHM.get(mlFeature.ColumnName)
        }
        let pageNumber = 1
        this.setState({
            openCategoricalPopup: true,
            noOfPagesForCategorical: 1,
            currentCategoricalColName: currentCategoricalColName,
            currentSourceTableInfo,
            selectedMlFeature: mlFeature,
            previousSelectedValue: selectedValue,
            selectedCategoricalValue: selectedValue,
            chooseValueLoading: true,
            pageNumberForCategoricalValue: pageNumber
        }, () => {
            console.log("selected source table info :: ", currentSourceTableInfo)
            this.getCategoricalValueWithPagination(currentSourceTableInfo, pageNumber, true)
        })
    }

    getCategoricalValueWithPagination = (sourceTableColumnInfo, pageNumber, initialCase) => {
        let pageSizeForCategoricalData = this.state.pageSizeForCategoricalData
        //feature info hashMap
        let inputData = {
            SourceID: this.state.userInfo,
            TableName: sourceTableColumnInfo.TableName,
            ColumnName: sourceTableColumnInfo.ColumnName,
            PageNumber: pageNumber,
            PageSize: pageSizeForCategoricalData
        }
        renderSwitchDipService("getCategoryDataWithPaginationForInstantModel", inputData, "", "").then(response => {
            console.log("response data for categorical : ", response.data)
            if (response.data["ResponseCode"] === 1000) {
                if (response.data["Results"] !== null) {
                    let noOfPages = response.data["Results"]["NoOfPages"]
                    let categoricalInfoDataArr = response.data["Results"]["Data"]
                    let pageItemsForCategoricalValues = []
                    let categoricalValues = []
                    if (categoricalInfoDataArr !== null && categoricalInfoDataArr.length > 0) {
                        categoricalInfoDataArr.map(eachCat => {
                            categoricalValues = categoricalValues.concat(eachCat.CategoricalValue)
                        })
                        console.log("categorical values : ", categoricalValues)
                        //TODO to show warning for the case of categorical values cannot be returned
                        this.setState({
                            valueList: categoricalValues,
                            pageItemsForCategoricalValues,
                            showPopupLoading: false,
                            chooseValueLoading: false,
                            openCategoricalPopup: true,
                            noOfPagesForCategorical: noOfPages
                        })
                    } else {
                        //TODO to show warning for the case of categorical values cannot be returned
                        console.log("no categorical values case : ", categoricalValues)
                        let openCategoricalPopup = true
                        if (initialCase) {
                            openCategoricalPopup = false
                        }
                        this.setState({
                            noOfPagesForCategorical: noOfPages,
                            valueList: [],
                            pageItemsForCategoricalValues,
                            chooseValueLoading: false,
                            openCategoricalPopup,
                            showPopupLoading: false,
                            errorMessagePop: true,
                            errorMessageTitle: "Request Error",
                            errorMessage: "Currently, we’re unable to process requests. Please try again later."
                        })
                    }
                }
            } else {
                console.log("response code not 1000 for categorical data")
                let openCategoricalPopup = true
                if (initialCase) {
                    openCategoricalPopup = false
                }
                this.setState({
                    valueList: [],
                    chooseValueLoading: false,
                    openCategoricalPopup,
                    showPopupLoading: false,
                    errorMessagePop: true,
                    errorMessageTitle: "Request Error",
                    errorMessage: "Currently, we’re unable to process requests. Please try again later."
                })
            }
        }).catch(err => {
            console.log("catch err in call categorical values with page : ", err)
            let openCategoricalPopup = true
            if (initialCase) {
                openCategoricalPopup = false
            }
            this.setState({
                chooseValueLoading: false,
                openCategoricalPopup,
                showPopupLoading: false,
                errorMessagePop: true,
                errorMessageTitle: "Request Error",
                errorMessage: "Currently, we’re unable to process requests. Please try again later."
            })
        })
    }

    handleChangeSelectedCategoricalValue = (selectedValue) => {
        console.log("handleChangeSelectedCategoricalValue : ", selectedValue)
        let CategoricalDataHashMap = this.state.CategoricalDataHashMap
        let currentCategoricalColName = this.state.currentCategoricalColName
        CategoricalDataHashMap[currentCategoricalColName].CurrentSelected = selectedValue
        this.setState({
            selectedCategoricalValue: selectedValue,
            CategoricalDataHashMap
        })
    }

    handleChangePageForCategoricalValues = (pageNumber) => {
        this.setState({
            chooseValueLoading: true,
            pageNumberForCategoricalValue: pageNumber,
            showPopupLoading: true,
        }, () => {
            this.getCategoricalValueWithPagination(this.state.currentSourceTableInfo, pageNumber, false)
        })
    }

    handleCancelSelectedCategoricalValue = () => {
        this.setState({
            previousSelectedValue: "",
            selectedCategoricalValue: "",
            pageNumberForCategoricalValue: 1,
            openCategoricalPopup: false,
        })
    }

    handleSubmitSelectedCategoricalValue = () => {
        console.log("handle submit categorical value : ", this.state.selectedCategoricalValue)
        let CategoricalDataHashMap = this.state.CategoricalDataHashMap
        let currentCategoricalColName = this.state.currentCategoricalColName
        CategoricalDataHashMap[currentCategoricalColName].CurrentSelected = this.state.selectedCategoricalValue
        this.setState({
            previousSelectedValue: "",
            selectedCategoricalValue: "",
            pageNumberForCategoricalValue: 1,
            openCategoricalPopup: false,
            CategoricalDataHashMap
        })
    }


    handleChangeSelectedTextInput(event) {
        this.setState({
            selectedCategoricalValue: event.target.value
        })
    }

    handleClickSelectItem() {

    }

    slideRangeHandler(mlFeature, event) {
        let values = event.target.value
        let featuresRangeHashMap = this.state.featuresRangeHashMap;
        let min = featuresRangeHashMap[mlFeature["ColumnName"]]["MinValue"]
        let max = featuresRangeHashMap[mlFeature["ColumnName"]]["MaxValue"]
        let currentMin = featuresRangeHashMap[mlFeature["ColumnName"]]["CurrentRangeValue"][0]
        let currentMax = featuresRangeHashMap[mlFeature["ColumnName"]]["CurrentRangeValue"][1]
        if (values[0] <= max && values[1] >= min && values[0] >= min && values[1] <= max) {
            if (values[0] <= currentMax && values[1] >= currentMin) {
                featuresRangeHashMap[mlFeature["ColumnName"]]["CurrentRangeValue"] = values;
            }
        }
        this.setState({
            featuresRangeHashMap
        })
    }

    changeMinValue(value, mlFeature) {
        let featuresRangeHashMap = this.state.featuresRangeHashMap;
        let currentRangeVal = featuresRangeHashMap[mlFeature["ColumnName"]]["CurrentRangeValue"]
        let min = this.state.featuresRangeHashMap[mlFeature["ColumnName"]]["MinValue"]
        let currentMax = this.state.featuresRangeHashMap[mlFeature["ColumnName"]]["CurrentRangeValue"][1]
        if (value !== "") {
            if (Number(value) <= Number(currentMax) && Number(value) >= Number(min)) {
                featuresRangeHashMap[mlFeature["ColumnName"]]["CurrentRangeValue"] = [value, currentRangeVal[1]]
            }
        }
        this.setState({
            featuresRangeHashMap
        })
    }

    handleChangeInput(value, mlFeature) {
        let featuresRangeHashMap = this.state.featuresRangeHashMap;
        let min = this.state.featuresRangeHashMap[mlFeature["ColumnName"]]["MinValue"]
        let currentMax = this.state.featuresRangeHashMap[mlFeature["ColumnName"]]["CurrentRangeValue"][1]
        if (value !== "") {
            if (Number(value) <= Number(currentMax) && Number(value) >= Number(min)) {
                featuresRangeHashMap[mlFeature["ColumnName"]]["FixedValue"] = Number(value)
            }
        }
        this.setState({
            featuresRangeHashMap
        })
    }

    changeMaxValue(value, mlFeature) {
        let featuresRangeHashMap = this.state.featuresRangeHashMap;
        let currentRangeVal = featuresRangeHashMap[mlFeature["ColumnName"]]["CurrentRangeValue"]
        let max = this.state.featuresRangeHashMap[mlFeature["ColumnName"]]["MaxValue"]
        let currentMin = this.state.featuresRangeHashMap[mlFeature["ColumnName"]]["CurrentRangeValue"][0]
        if (value !== "") {
            if (Number(value) >= Number(currentMin) && Number(value) <= Number(max)) {
                featuresRangeHashMap[mlFeature["ColumnName"]]["CurrentRangeValue"] = [currentRangeVal[0], value]
            }
        }
        this.setState({
            featuresRangeHashMap
        })
    }

    handleCallPrediction() {

    }

    handleCloseTestPopup() {
        this.props.onClose()
    }

    handleChangeViewOutput(columnName) {
        this.setState({
            currentResultColumnName: columnName
        })
    }

    handleChangeTextForNoTestData(value, mlFeature) {
        let textInputDataHashMap = this.state.textInputDataHashMap
        textInputDataHashMap[mlFeature.ColumnName] = value
        let disabledModelTesterType = false
        console.log('Object.keys(textInputDataHashMap) =>', Object.keys(textInputDataHashMap))
        Object.keys(textInputDataHashMap).map(input => {
                if (textInputDataHashMap[input] === "" && !this.state.outputColumnNameArray.includes(input)) {
                    disabledModelTesterType = true
                }
            }
        )
        this.setState({
            textInputDataHashMap,
            disabledModelTesterType
        })
    }

    handleChangeCategoricalData(value, mlFeature) {
        let CategoricalDataHashMap = this.state.CategoricalDataHashMap
        let currentCategoricalColName = mlFeature.ColumnName
        CategoricalDataHashMap[currentCategoricalColName].CurrentSelected = value
        this.setState({
            selectedCategoricalValue: value,
            CategoricalDataHashMap
        })
    }

    handleBackView() {
        this.setState({
            inputView: true
        })
    }

    checkShowingValueType() {
        let show = false
        let inputColumnNameArray = this.state.inputColumnNameArray
        let featuresRangeHashMap = this.state.featuresRangeHashMap
        if (this.checkValidity(inputColumnNameArray)) {
            if (this.checkEmpty(inputColumnNameArray)) {
                inputColumnNameArray.map(col => {
                        if (this.checkValidity(featuresRangeHashMap)) {
                            if (featuresRangeHashMap[col] !== undefined) {
                                show = true
                            }
                        }
                    }
                )
            }
        }
        return show
    }

    render() {
        console.log('modelTestResultData >>>>>>>', this.state.inputView, this.state.disabledModelTesterType, this.state.textInputDataHashMap, this.state.outputColumnNameArray, this.state.currentResultColumnName, this.state.CategoricalDataHashMap, this.state.featuresRangeHashMap,
            this.state.modelTestResultData);
        let width = window.innerWidth; //992
        console.log(">> width ", width)
        let {
            modelTestResultData
        } = this.state;
        return (
            <>
                {
                    this.state.initialLoading ?
                        <div className="model-tester-popup">
                            <div className="model-tester-loading">
                                <RotatingCircle theme="light" loadingText="Initializing"/>
                            </div>
                        </div>
                        :
                        this.state.dataReady !== true ?
                            <div className="model-tester-popup">
                                <VerticalEmptyScreen
                                    image={WaitingResultImage}
                                    bodyContext={"Currently, we're unable to process your request, please try again later."}/>
                            </div>
                            :
                            this.state.emptyScreen ?
                                <div className="model-tester-popup">
                                    <VerticalEmptyScreen
                                        image={WaitingResultImage}
                                        bodyContext={"No Test Results."}/>
                                </div>
                                :
                                <div className="model-tester-popup">
                                    <div className="model-tester-layout">
                                        {
                                            this.state.inputView === true || width > 991 ?
                                                <div className="model-tester-division">
                                                    <div className="model-tester-mul-col-form">
                                                        {
                                                            this.getFormHeader("Multi")
                                                        }
                                                        <div className="model-tester-form-body"
                                                             style={{
                                                                 height: "calc(100% - 40px)"
                                                             }}>
                                                            < Scrollbars
                                                                renderThumbVertical={({style, ...props}) =>
                                                                    <div {...props} style={{
                                                                        ...style,
                                                                        width: '5px',
                                                                        borderRadius: '4px',
                                                                        backgroundColor: '#76a1d5'
                                                                    }}/>
                                                                } style={{height: "calc(100% - 50px)"}}>
                                                                <div className="model-tester-col-list">
                                                                    {
                                                                        this.checkValidity(modelTestResultData) ?
                                                                            this.checkEmpty(modelTestResultData) ?
                                                                                modelTestResultData.map((col, cid) =>
                                                                                    !col.OutputColumn ?
                                                                                        <div
                                                                                            className="model-tester-col-row">
                                                                                            <div
                                                                                                className="model-tester-label"
                                                                                                title={col.ColumnName}>
                                                                                                <div
                                                                                                    className="model-tester-label-text">{col.ColumnName}</div>
                                                                                            </div>
                                                                                            <div
                                                                                                className="model-tester-value">
                                                                                                {
                                                                                                    col.multiSelectType === 'Multi' && this.state.featuresRangeHashMap[col.ColumnName] !== undefined ?
                                                                                                        <MultiInput
                                                                                                            CategoricalDataHashMap={this.state.CategoricalDataHashMap}
                                                                                                            changeMinValue={this.changeMinValue.bind(this)}
                                                                                                            changeMaxValue={this.changeMaxValue.bind(this)}
                                                                                                            mlFeature={col}
                                                                                                            slideRangeHandler={this.slideRangeHandler.bind(this)}
                                                                                                            featuresRangeHashMap={this.state.featuresRangeHashMap}
                                                                                                            range={col.multiSelectType === 'Multi'}
                                                                                                            onChange={this.handleChangeInput.bind(this)}
                                                                                                            onClick={this.handleClickSelectItem.bind(this)}
                                                                                                            type={col.DataType}
                                                                                                            info={col}/>
                                                                                                        :
                                                                                                        <SingleInput
                                                                                                            noTestingDataCase={this.state.noTestingDataCase}
                                                                                                            textInputDataHashMap={this.state.textInputDataHashMap}
                                                                                                            CategoricalDataHashMap={this.state.CategoricalDataHashMap}
                                                                                                            mlFeature={col}
                                                                                                            slideRangeHandler={this.slideRangeHandler.bind(this)}
                                                                                                            featuresRangeHashMap={this.state.featuresRangeHashMap}
                                                                                                            range={col.multiSelectType === 'Multi'}
                                                                                                            onChange={this.state.noTestingDataCase ?
                                                                                                                this.handleChangeTextForNoTestData.bind(this)
                                                                                                                :
                                                                                                                this.handleChangeInput.bind(this)}
                                                                                                            changeCategorical={this.handleChangeCategoricalData.bind(this)}
                                                                                                            onClick={this.handleClickSelectItem.bind(this)}
                                                                                                            type={col.DataType}
                                                                                                            info={col}
                                                                                                            chooseCategory={() => this.openCategoricalSelectionPopup(col["ColumnName"],
                                                                                                                this.state.CategoricalDataHashMap[col["ColumnName"]].CurrentSelected, col)}

                                                                                                        />
                                                                                                }
                                                                                                {
                                                                                                    !this.state.noTestingDataCase ?
                                                                                                        this.checkValidity(this.state.featuresRangeHashMap) ?
                                                                                                            this.state.featuresRangeHashMap[col.ColumnName] !== undefined ?
                                                                                                                <ToggleButton
                                                                                                                    options={this.state.chooseOption}
                                                                                                                    onClickOptionHandler={this.handleChooseColumnOption.bind(this, cid)}
                                                                                                                    activeOption={col.multiSelectType}/>
                                                                                                                :
                                                                                                                null
                                                                                                            :
                                                                                                            null
                                                                                                        :
                                                                                                        null

                                                                                                }


                                                                                            </div>
                                                                                        </div>
                                                                                        :
                                                                                        null
                                                                                )
                                                                                :
                                                                                null
                                                                            :
                                                                            null
                                                                    }
                                                                </div>

                                                            </Scrollbars>
                                                            <div className="model-multi-tester-bottom-row">
                                                                <button className="model-tester-test-btn"
                                                                        disabled={
                                                                            this.state.noTestingDataCase ?
                                                                                this.state.testLoaded ?
                                                                                    true
                                                                                    :
                                                                                    this.state.disabledModelTesterType
                                                                                :
                                                                                this.state.testLoaded
                                                                        }
                                                                        onClick={this.prepareInputDataForResult.bind(this)}>Test
                                                                </button>
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                                :
                                                <div className="model-tester-result-page">
                                                    {
                                                        !this.state.testLoaded ?
                                                            <ModelTesterResult
                                                                width={width}
                                                                inputView={this.state.inputView}
                                                                onBack={this.handleBackView.bind(this)}
                                                                outputColumnNameArray={this.state.outputColumnNameArray}
                                                                currentResultColumnName={this.state.currentResultColumnName}
                                                                showPredictResult={this.state.showPredictResult}
                                                                modelTestResultData={this.state.modelTestResultData}
                                                                updateViewOutputColumn={this.handleChangeViewOutput.bind(this)}
                                                            />
                                                            :
                                                            <div className="model-tester-loading">

                                                                <RotatingCircle theme="light"
                                                                                loadingText="Calculating..."/>
                                                            </div>
                                                    }
                                                </div>

                                        }

                                        {
                                            width < 992 ?
                                                null
                                                :
                                                <div className="model-tester-result-page">
                                                    {
                                                        !this.state.testLoaded ?
                                                            <ModelTesterResult
                                                                width={width}
                                                                inputView={this.state.inputView}
                                                                onBack={this.handleBackView.bind(this)}
                                                                outputColumnNameArray={this.state.outputColumnNameArray}
                                                                currentResultColumnName={this.state.currentResultColumnName}
                                                                showPredictResult={this.state.showPredictResult}
                                                                modelTestResultData={this.state.modelTestResultData}
                                                                updateViewOutputColumn={this.handleChangeViewOutput.bind(this)}
                                                            />
                                                            :
                                                            <div className="model-tester-loading">
                                                                <RotatingCircle theme="light"
                                                                                loadingText="Calculating..."/>
                                                            </div>
                                                    }
                                                </div>
                                        }

                                    </div>

                                    <div className="model-tester-close-row">
                                        <button className="model-tester-close-button"
                                                onClick={this.handleCloseTestPopup.bind(this)}>
                                            Close
                                        </button>
                                    </div>
                                </div>
                }
                {
                    this.state.openCategoricalPopup === true ?
                        <SysPopup Open={this.state.openCategoricalPopup} Title={"Choose Value"}
                                  onClose={() => this.handleClosePopup("openCategoricalPopup")}>
                            <div className="categorical-choose-value-popup">
                                <div className="categorical-choose-value-header">
                                    <div className="categorical-choose-value-header-left-side">
                                        Current Selected Value :
                                    </div>
                                    {
                                        this.state.selectedCategoricalValue.toString().length >= 90 ?
                                            <textarea
                                                rows={this.state.selectedCategoricalValue.toString().length >= 120 ? 5 : 3}
                                                onChange={this.handleChangeSelectedTextInput.bind(this)}
                                                value={
                                                    this.state.selectedCategoricalValue !== "" ?
                                                        this.state.selectedCategoricalValue
                                                        :
                                                        null
                                                }
                                                className="categorical-choose-selected-value-text-box">
                                                                {
                                                                    this.state.selectedCategoricalValue !== "" ?
                                                                        this.state.selectedCategoricalValue
                                                                        :
                                                                        null
                                                                }
                                                            </textarea>
                                            :
                                            <div className="choose-value-header-right-side">
                                                {
                                                    this.state.selectedCategoricalValue !== "" ?
                                                        this.state.selectedCategoricalValue
                                                        :
                                                        null
                                                }
                                            </div>
                                    }
                                </div>

                                {
                                    this.state.chooseValueLoading ?
                                        <div className="categorical-choose-value-body">
                                            {/*<Loading title="Loading"/>*/}
                                            <div className="model-tester-loading">
                                                <RotatingCircle theme="light" loadingText="Loading"/>
                                            </div>
                                        </div>
                                        :
                                        this.state.valueList !== undefined && this.state.valueList !== null ?
                                            this.state.valueList.length !== 0 ?
                                                <div className="categorical-choose-value-body">
                                                    < Scrollbars
                                                        renderThumbVertical={({style, ...props}) =>
                                                            <div {...props} style={{
                                                                ...style,
                                                                width: '5px',
                                                                borderRadius: '4px',
                                                                backgroundColor: '#76a1d5'
                                                            }}/>
                                                        }
                                                        autoHideTimeout={500}
                                                        autoHideDuration={200}
                                                        style={{
                                                            overflow: 'hidden',
                                                            minHeight: '100%',
                                                            height: 'auto',
                                                            width: '100%',
                                                        }}>
                                                        <div className="categorical-choose-value-list">
                                                            {
                                                                this.state.valueList.map((valList) =>
                                                                    <div
                                                                        className="categorical-choose-value-item">
                                                                        <div
                                                                            className="categorical-choose-value-radio-container">
                                                                            <Radio
                                                                                className="categorical-choose-value-radio"
                                                                                checked={this.state.selectedCategoricalValue === valList}
                                                                                onChange={() => this.handleChangeSelectedCategoricalValue(valList)}
                                                                                size="small"/>

                                                                        </div>
                                                                        <div
                                                                            className="categorical-choose-value">{valList}</div>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </Scrollbars>

                                                </div>
                                                :
                                                <div className="categorical-choose-value-body">
                                                    <VerticalEmptyScreen
                                                        bodyContext={"There is no value defined in this column"}/>
                                                </div>
                                            :
                                            <div className="categorical-choose-value-body">
                                                <VerticalEmptyScreen
                                                    bodyContext={"There is no value defined in this column"}/>
                                            </div>
                                }
                                {
                                    <div className="categorical-choose-value-pagination">
                                        {
                                            this.state.noOfPagesForCategorical > 1 ?
                                                <Pagination
                                                    noOfPages={this.state.noOfPagesForCategorical}
                                                    onChangePage={(pageNumber) => this.handleChangePageForCategoricalValues(pageNumber)}
                                                    currentPage={this.state.pageNumberForCategoricalValue}
                                                />
                                                :
                                                null
                                        }
                                    </div>
                                }
                                <div className="categorical-choose-value-bottom-row">
                                    <button className="pop-cancel-btn"
                                            onClick={() => this.handleCancelSelectedCategoricalValue()}>Cancel
                                    </button>
                                    <button className="pop-save-btn"
                                            onClick={() => this.handleSubmitSelectedCategoricalValue()}>Choose
                                    </button>
                                </div>
                            </div>
                        </SysPopup>
                        :
                        null
                }

                <ResponsePopup open={this.state.popupOpen}
                               type={this.state.popupType}
                               title={this.state.popupTitle}
                               bodyContext={this.state.popupMessage}
                               onClose={() => this.handleClosePopup("popupOpen")}
                />
            </>
        );
    }

}

class SingleInput extends React.Component {
    constructor(props) {
        super(props);
        let {
            type,
            mlFeature,
            featuresRangeHashMap,
            handleChangeSingleInput,
            CategoricalDataHashMap,
            noTestingDataCase,
            textInputDataHashMap
        } = this.props;
        this.state = {
            type,
            mlFeature,
            featuresRangeHashMap,
            handleChangeSingleInput,
            CategoricalDataHashMap,
            noTestingDataCase,
            textInputDataHashMap
        }
    }

    componentDidMount() {
        let {
            type,
            mlFeature,
            featuresRangeHashMap,
            handleChangeSingleInput,
            CategoricalDataHashMap,
            noTestingDataCase,
            textInputDataHashMap
        } = this.props;
        this.setState({
            type,
            mlFeature,
            featuresRangeHashMap,
            handleChangeSingleInput,
            CategoricalDataHashMap,
            noTestingDataCase,
            textInputDataHashMap
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        let {
            type,
            mlFeature,
            featuresRangeHashMap,
            handleChangeSingleInput,
            CategoricalDataHashMap,
            noTestingDataCase,
            textInputDataHashMap
        } = nextProps;
        this.setState({
            type,
            mlFeature,
            featuresRangeHashMap,
            handleChangeSingleInput,
            CategoricalDataHashMap,
            noTestingDataCase,
            textInputDataHashMap
        })
    }

    handleChangeInput(event) {
        this.props.onChange(event.target.value, this.props.mlFeature)
    }

    handleChooseCategory() {
        this.props.chooseCategory()
    }

    checkValidity = (checkItem) => {
        return (checkItem !== undefined && checkItem !== null && checkItem !== "")
    }

    handleChangeCategorical(event) {
        this.props.changeCategorical(event.target.value, this.props.mlFeature)
    }

    render() {
        let {
            type,
            mlFeature,
            featuresRangeHashMap,
            CategoricalDataHashMap,
            noTestingDataCase,
            textInputDataHashMap
        } = this.state;
        if (!noTestingDataCase) {
            if (type === "enum") {
                return (
                    this.checkValidity(CategoricalDataHashMap[mlFeature['ColumnName']]) ?
                        CategoricalDataHashMap[mlFeature['ColumnName']].CategoriesValues.length < 6 ?
                            <select className="model-single-input"
                                    onChange={this.handleChangeCategorical.bind(this)}
                                    value={CategoricalDataHashMap[mlFeature['ColumnName']].CurrentSelected}>
                                {
                                    CategoricalDataHashMap[mlFeature['ColumnName']].CategoriesValues.map((value) =>
                                        <option>{value}</option>
                                    )
                                }
                            </select>
                            :
                            <div className="model-single-input-item-list-division">
                                <div className="model-single-input-box">
                                    <div
                                        className="model-single-input--place-holder">{CategoricalDataHashMap[mlFeature['ColumnName']].CurrentSelected}</div>
                                    <button className="model-single-select-btn"
                                            onClick={this.handleChooseCategory.bind(this)}>Select
                                    </button>
                                </div>

                            </div>
                        :
                        null
                )

            } else if (type === "int" || type === "double" || type === 'float') {
                return (
                    <div className="model-single-input-block">
                        <input type="number"
                               onChange={this.handleChangeInput.bind(this)}
                               value={
                                   this.checkValidity(featuresRangeHashMap) ?
                                       this.checkValidity(featuresRangeHashMap[mlFeature["ColumnName"]]) !== undefined ?
                                           featuresRangeHashMap[mlFeature["ColumnName"]]["FixedValue"]
                                           :
                                           ""
                                       : ""
                               }
                               className="model-single-input"
                        />
                        <div className="model-single-input-range-info-row">
                            <div className="model-single-input-range-info">
                                <div className="model-single-input-range-info--label">
                                    Min :
                                </div>
                                <div className="model-single-input-range-info--value">
                                    {
                                        this.checkValidity(featuresRangeHashMap) ?
                                            this.checkValidity(featuresRangeHashMap[mlFeature["ColumnName"]]) !== undefined ?
                                                featuresRangeHashMap[mlFeature["ColumnName"]]["CurrentRangeValue"][0]
                                                :
                                                null
                                            :
                                            null
                                    }
                                </div>

                            </div>
                            <div className="model-single-input-range-info">
                                <div className="model-single-input-range-info--label">
                                    Max :
                                </div>
                                <div className="model-single-input-range-info--value">
                                    {
                                        this.checkValidity(featuresRangeHashMap) ?
                                            this.checkValidity(featuresRangeHashMap[mlFeature["ColumnName"]]) !== undefined ?
                                                featuresRangeHashMap[mlFeature["ColumnName"]]["CurrentRangeValue"][1]
                                                :
                                                null
                                            :
                                            null
                                    }
                                </div>

                            </div>

                        </div>
                    </div>
                )
            } else {
                return (
                    <div>{""}</div>
                )
            }
        } else {
            return (
                <input type="number"
                       onChange={this.handleChangeInput.bind(this)}
                       value={
                           this.checkValidity(textInputDataHashMap) ?
                               this.checkValidity(textInputDataHashMap[mlFeature["ColumnName"]]) !== undefined ?
                                   featuresRangeHashMap[mlFeature["ColumnName"]]
                                   :
                                   ""
                               : ""
                       }
                       className="model-single-input"
                />
            )
        }

    }

}


class MultiInput extends React.Component {
    constructor(props) {
        super(props);
        let {
            type,
            info,
            mlFeature,
            featuresRangeHashMap,
            changeMaxValue,
            changeMinValue,
            CategoricalDataHashMap
        } = this.props;
        this.state = {
            type,
            info,
            mlFeature,
            featuresRangeHashMap,
            changeMaxValue,
            changeMinValue,
            CategoricalDataHashMap
        }
    }

    componentDidMount() {
        let {
            type,
            info,
            mlFeature,
            featuresRangeHashMap,
            changeMaxValue,
            changeMinValue,
            CategoricalDataHashMap
        } = this.props;
        this.setState({
            type,
            info,
            mlFeature,
            featuresRangeHashMap,
            changeMaxValue,
            changeMinValue,
            CategoricalDataHashMap
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        let {
            type,
            info,
            mlFeature,
            featuresRangeHashMap,
            changeMaxValue,
            changeMinValue,
            CategoricalDataHashMap
        } = nextProps;
        this.setState({
            type,
            info,
            mlFeature,
            featuresRangeHashMap,
            changeMaxValue,
            changeMinValue,
            CategoricalDataHashMap
        })
    }

    changeRangeValue = (event, newValue) => {
        this.setState({
            rangeValue: newValue
        })
    }

    slideRangeHandler(mlFeature, values) {
        console.log('mlFeature, values =>', mlFeature, values)
        this.props.slideRangeHandler(mlFeature, values)
    }

    handleChangeMinValue(event) {
        this.props.changeMinValue(event.target.value, this.props.mlFeature)
    }

    handleChangeMaxValue(event) {
        this.props.changeMaxValue(event.target.value, this.props.mlFeature)
    }

    checkValidity = (checkItem) => {
        return (checkItem !== undefined && checkItem !== null && checkItem !== "")
    }
    checkEmpty = (checkItem) => {
        return (checkItem.length !== 0)
    }

    render() {
        let {
            type,
            info,
            mlFeature,
            featuresRangeHashMap,
            CategoricalDataHashMap
        } = this.state;
        if (type === "enum") {
            return (
                this.checkValidity(info.OptionList) ?
                    this.checkEmpty(info.OptionList) ?
                        info.OptionList.length < 30 ?
                            <div className="model-single-input-item-list-division">
                                <div className="model-single-input-box">
                                    <div className="model-single-input--place-holder">Select Item</div>
                                    <button className="model-single-select-btn">Select</button>
                                </div>
                                <div className="model-single-input-selected-item-list">
                                    <div className="model-single-input-item">
                                        <div className="model-single-input-item-name">{
                                            CategoricalDataHashMap[mlFeature['ColumnName']] !== undefined ?
                                                CategoricalDataHashMap[mlFeature['ColumnName']].CurrentSelected
                                                :
                                                ""
                                        }</div>
                                        <button className="model-single-input-item-delete-btn"
                                                title={"Click to remove item"}>
                                            <Icon icon="close"/>
                                        </button>
                                    </div>
                                    {
                                        CategoricalDataHashMap[mlFeature['ColumnName']] !== undefined ?
                                            CategoricalDataHashMap[mlFeature['ColumnName']].CategoriesValues.length !== 0 ?
                                                CategoricalDataHashMap[mlFeature['ColumnName']].CategoriesValues.map(() =>
                                                    <div className="model-single-input-item">
                                                        <div
                                                            className="model-single-input-item-name">value
                                                        </div>
                                                        <button className="model-single-input-item-delete-btn"
                                                                title={"Click to remove item"}>
                                                            <Icon icon="close"/>
                                                        </button>
                                                    </div>
                                                )
                                                :
                                                null
                                            :
                                            null
                                    }

                                </div>
                            </div>
                            :
                            <div className="model-single-input-item-list-division">
                                <div className="model-single-input-box">
                                    <div className="model-single-input--place-holder">Select Item</div>
                                    <button className="model-single-select-btn">Select</button>
                                </div>
                                <div className="model-single-input-selected-item-list">
                                    <div className="model-single-input-item">
                                        <div className="model-single-input-item-name">ORange</div>
                                        <button className="model-single-input-item-delete-btn"
                                                title={"Click to remove item"}>
                                            <Icon icon="close"/>
                                        </button>
                                    </div>
                                    <div className="model-single-input-item">
                                        <div
                                            className="model-single-input-item-name">ORangeORangeORangeORangeORangeORangeORangeORangeORangeORangeORangeORangeORangeORangeORange
                                        </div>
                                        <button className="model-single-input-item-delete-btn"
                                                title={"Click to remove item"}>
                                            <Icon icon="close"/>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        :
                        null
                    :
                    null
            )

        } else if (type === "int" || type === "double" || type === 'float') {
            return (
                <>
                    <div className="model-single-input-test-rage-division">
                        <div className="model-single-input-range-slider">
                            <Box sx={{width: "100%"}}>
                                <Slider
                                    getAriaLabel={() => 'Temperature range'}
                                    valueLabelDisplay="auto"
                                    size={"small"}
                                    value={featuresRangeHashMap[mlFeature["ColumnName"]]["CurrentRangeValue"]}
                                    step={featuresRangeHashMap[mlFeature["ColumnName"]]["RangeValue"]}
                                    min={featuresRangeHashMap[mlFeature["ColumnName"]]["MinValue"]}
                                    max={featuresRangeHashMap[mlFeature["ColumnName"]]["MaxValue"]}
                                    onChange={this.slideRangeHandler.bind(this, mlFeature)}
                                />
                            </Box>
                        </div>

                        <div className="model-single-input-range-text-box-row">
                            <div className="model-single-input-range-text-box">
                                <div className="model-single-input-range--label">Min</div>
                                <input type="number"
                                       onChange={this.handleChangeMinValue.bind(this)}
                                       value={
                                           isNaN(featuresRangeHashMap[mlFeature["ColumnName"]]["CurrentRangeValue"][0]) ?
                                               featuresRangeHashMap[mlFeature["ColumnName"]]["CurrentRangeValue"][0]
                                               :
                                               Number.isInteger(Number(featuresRangeHashMap[mlFeature["ColumnName"]]["CurrentRangeValue"][0])) ?
                                                   featuresRangeHashMap[mlFeature["ColumnName"]]["CurrentRangeValue"][0]
                                                   :
                                                   Number(featuresRangeHashMap[mlFeature["ColumnName"]]["CurrentRangeValue"][0]).toFixed(5)}/>
                            </div>
                            <div className="model-single-input-range-text-box">
                                <div className="model-single-input-range--label">Max</div>
                                <input type="number"
                                       onChange={this.handleChangeMaxValue.bind(this)}
                                       value={
                                           isNaN(featuresRangeHashMap[mlFeature["ColumnName"]]["CurrentRangeValue"][1]) ?
                                               featuresRangeHashMap[mlFeature["ColumnName"]]["CurrentRangeValue"][1]
                                               :
                                               Number.isInteger(Number(featuresRangeHashMap[mlFeature["ColumnName"]]["CurrentRangeValue"][1])) ?
                                                   featuresRangeHashMap[mlFeature["ColumnName"]]["CurrentRangeValue"][1]
                                                   :
                                                   Number(featuresRangeHashMap[mlFeature["ColumnName"]]["CurrentRangeValue"][1]).toFixed(5)
                                       }/>
                            </div>
                        </div>
                    </div>
                </>

            )
        } else return null
    }

}

export default ModelTester;