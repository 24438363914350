import React from "react";
import "./app.css";
import SummaryText from "../Commoncomponents/SummaryText/SummaryText";
import Searchbox from "../../Assets/Searchbox/Searchbox";
import Pagination from "../../Assets/Pagination/Pagination";
import Icon from "../../Assets/IconLibrary/Icon";
import {CheckEmpty, CheckValidity} from "../../Assets/CommonMethods";
import Button from "../../Assets/Buttons/Button";
import ApplicationDetail from "./ApplicationDetail";
import {ApiList, checkValidity} from "../../api/ApiCallMethod";
import {renderSwitchAppStore} from "../../api/ResourcesApiLayer";
import RotatingCircle from "../../Assets/Loading/RotatingCircle";
import VerticalEmptyScreen from "../../Assets/EmptyScreen/VerticalEmptyScreen";
import emptyPage from "../../Assets/Images/no-result-found.png"
import MetaData from "../MetaData/MetaData";
import {IndustrySolutionList,IndustrySolutionLink} from "../../Assets/Contents/IndustrySolution";
import Linkify from "react-linkify";

let pageSize = 24;

class IndustryListPage extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            colorList:["var(--light-sky)","--var(--primary-color)","var(--orange)"],
            industryDescription:[],
            chosenAppList:[],
            RecommendedAppList: [],
            showAppDetail: false,
            chosenIconPath: "",
            isLoading:false,
            isEmpty: false,
            activeIndustryID: 0,
            industryList:[],
            activeIndustry: "aerospace",
            currentPage: 1,
            sortType:"desc",
            sortOrder:"asc",
            searchKeyword:"",
            detailedApp:{},
            pageKeyword: "aerospace",


            activePage: 1,

            appSectionList: [
                {
                    SectionName: "Free",
                    SectionKeyword: "FreeApps",
                    AppList: []
                },
                {
                    SectionName: "Paid",
                    SectionKeyword: "PaidApps",
                    AppList: []
                },
                {
                    SectionName: "Most Downloaded",
                    SectionKeyword: "MostDownloadedApps",
                    AppList: []
                }
            ],


        }
    }

    componentDidMount = () =>{
        this.preparePassedInfo(this.props)
    }

    preparePassedInfo = (data) =>{
        let currentChosenIndustry = data.currentChosenIndustry;
        let currentPage = data.currentPage;
        console.log("current chosen industry : ", currentChosenIndustry);

        if(currentPage === "application"){
            this.getIndustryList(currentChosenIndustry);
            this.getIndustryInfo(currentChosenIndustry)
            this.setState({
                activeIndustry: currentChosenIndustry,
            }, ()=>this.getApplicationsByActiveIndustry(this.state.activeIndustry))
        }else{
            this.getIndustryList();
        }

    }

    getIndustryList = (industry) =>{
        console.log("CALLING api to get industry list");

        this.setState({
            isLoading: true,

        })
        let params = "?sortBy=industry&sortOrder=asc"
        let searchKeyword = this.state.searchKeyword;
        let iconPath="";
        renderSwitchAppStore(ApiList().GetIndustryList, "", params, "").then(industryResponse => {
            console.log("get industry list response : ", industryResponse.data)
            if (industryResponse.data["ResponseCode"] === 1000) {
                let resultIndustryList = []
                let activeIndustry = ""
                let activeIndex = -1
                if (industryResponse.data["Results"] !== null && industryResponse.data["Results"].length > 0) {
                    resultIndustryList = industryResponse.data["Results"]
                    if (industry === undefined){
                        activeIndustry = resultIndustryList[0].Industry
                        iconPath = this.getApplicationIcon(activeIndustry, resultIndustryList);
                    }else{
                        activeIndustry = industry;
                        iconPath = this.getApplicationIcon(activeIndustry, resultIndustryList);
                    }
                }

                this.setState({
                    industryList:resultIndustryList,
                    activeIndustryID:activeIndex,
                    activeIndustry,
                    isEmpty: false,
                    isLoading: false,
                    chosenIconPath: iconPath
                })

            } else {
                this.setState({
                    industryList:[],
                    isEmpty: true,
                    isLoading: false,
                })
            }
        }).catch(err => {
            console.log("catch err in calling get industry list : ", err)
            this.setState({
                industryList:[],
                isEmpty: true,
                isLoading: false,
            })
        })
    }
    getIndustryListBySearch = (searchKeyword) =>{
        console.log("CALLING api to get industry list");

        this.setState({
            isLoading: true,

        })
        let params = "?sortBy=industry&sortOrder=asc"
        renderSwitchAppStore(ApiList().GetIndustryList, "", params, "").then(industryResponse => {
            console.log("get industry list response : ", industryResponse.data)
            if (industryResponse.data["ResponseCode"] === 1000) {
                let resultIndustryList = []
                let activeIndustry = ""
                let activeIndex = -1
                if (industryResponse.data["Results"] !== null && industryResponse.data["Results"].length > 0) {
                    resultIndustryList = industryResponse.data["Results"]
                    activeIndustry = resultIndustryList[0].Industry
                    activeIndex = 0
                }
                let tempIndustryList =[];

                console.log("Search Value >> keyword",searchKeyword)
                console.log("Search Value >> CheckValidity",CheckValidity(searchKeyword))
                console.log("Search Value >> CheckEmpty",CheckEmpty(searchKeyword))
                console.log("Search Value >> search keyword length",searchKeyword.length)
                if(CheckValidity(searchKeyword) && CheckEmpty(searchKeyword)){


                    for(let i=0;i<resultIndustryList.length;i++){
                        console.log("Checking >>> ")
                        if(resultIndustryList[i].Industry.toLowerCase().includes(searchKeyword.toLowerCase())){
                            tempIndustryList.push(resultIndustryList[i])
                        }
                    }
                    this.setState({
                        industryList:tempIndustryList,
                        activeIndustryID:activeIndex,
                        activeIndustry,
                        isEmpty: false,
                        isLoading: false,
                    })

                }

                else{
                    console.log("Nope")
                    this.setState({
                        industryList:resultIndustryList,
                        activeIndustryID:activeIndex,
                        activeIndustry,
                        isEmpty: false,
                        isLoading: false,



                    })
                }
            } else {
                this.setState({
                    industryList:[],
                    isEmpty: true,
                    isLoading: false,
                })
            }
        }).catch(err => {
            console.log("catch err in calling get industry list : ", err)
            this.setState({
                industryList:[],
                isEmpty: true,
                isLoading: false,
            })
        })
    }


    getApplicationsByActiveIndustry = (activeIndustry, searchKey) => {
        console.log("reached to prepareInitialData : ", activeIndustry, searchKey);

        let inputData = {
            Keyword: "industry",
            Industry: activeIndustry,
            PageNumber: this.state.currentPage,
            PageSize: pageSize,
            SortType: "app_name",
            SortOrder: "asc", //asc, desc
            SearchValue: searchKey
        }

        renderSwitchAppStore("getAppStoreData", inputData, "", "").then(response => {
            console.log("app store data response : ", response.data)
            if (response.data["ResponseCode"] === 1000) {
                let responseData = response.data["Results"]
                let appList = []
                let noOfPages = 1
                if (responseData!==null){
                    appList = responseData["AppInfo"]
                    noOfPages = responseData["NoOfPages"]
                }

                console.log("app list for the given industry : ", appList);

                this.setState({
                    appList,
                    noOfPages,
                    isLoading: false,
                })

            } else {
                this.setState({
                    isLoading: false,
                    emptyPage: true
                })
            }
        })
            .catch(error => {
                    console.log("get error : ", error)
                    this.setState({
                        isLoading: false,
                    })
                }
            );
    }


    getApplicationIcon = (industryName, industryList) =>{
        console.log("Get App icon", industryList)
        for(let i=0;i<industryList.length;i++){
            if(industryList[i].Industry === industryName){
                return(industryList[i].Icon)
            }
        }
    }

    onSearchIndustry = (searchKeyword) =>{
        console.log("search >> ",searchKeyword)
        this.setState({
            searchKeyword
        })
        this.getIndustryListBySearch(searchKeyword)
    }

    openIndustryApps = (industry, activeIndustryID) =>{
        this.manualScroll11(0);
        let link = industry.Industry.replaceAll(" ", "-").toLowerCase();
        let url="ai-appstore/artificial-intelligence-in-"+link;
        this.setState({
            isLoading: true,
            chosenIconPath: industry.Icon,
            activePage: 1,
            activeIndustry: industry.Industry,
            searchKeyword: "",
            pageKeyword: link,
            activeIndustryID
        })

        let {getAppURL} = this.props;
        getAppURL(url, industry.Industry)
        this.props.history.push(url);

        this.getApplicationsByActiveIndustry(industry.Industry, "");


        this.getIndustryInfo(industry.Industry)

    }


    getColor = (id) =>{
        if(id % 2 === 0){
            return("#76A1D5")
        }
        else if(id % 4 === 1){
            return ("#FC7F3A")
        }
        else{
            return ("#FC7F3A")
        }
    }

    openAppDetail = (appInfo) =>{
        this.setState({
            showAppDetail: true,
            detailedApp: appInfo,
        })
    }

    searchAppHandler = (keyword) =>{
        this.setState({
            searchKeyword:keyword,
            isLoading: true,
        }, ()=>this.getApplicationsByActiveIndustry(this.state.activeIndustry, keyword))
    }

    closeAppDetails = () =>{
        this.setState({
            showAppDetail: false
        })
    }

    goToInudstryList = () =>{
        this.manualScroll11( 400)
        this.setState({
            isLoading: true,
            searchKeyword: ""
        })
        this.getIndustryList();

        this.props.history.push("/ai-app-store");
    }


    onChangePageForSeeMoreView = (pageNumber) =>{
        this.setState({
            currentPage: pageNumber,
            isLoading: true,
        }, ()=>{
            this.getApplicationsByActiveIndustry(this.state.activeIndustry)
        })

        this.manualScroll11(400)

    }

    getApplication = (chosenIndustry) =>{
        this.setState({
            isLoading: true,
            activeIndustry: chosenIndustry
        }, ()=>this.getApplicationsByActiveIndustry(chosenIndustry))
    }

    manualScroll11 = (location) =>{
        let {manualScroll} = this.props;
        manualScroll(location)
    }

    getMeta = (activeIndustry) =>{
        let keyword = activeIndustry.replaceAll(" ", "-").toLowerCase();
        return keyword
    }

    getIndustryInfo = (activeIndustry) =>{
        let description = [];

        for(let i=0;i<IndustrySolutionList.length;i++){

            if( IndustrySolutionList[i].Type === activeIndustry){

                for(let j=0;j<IndustrySolutionList[i].industryDescription.length;j++){

                    if(checkValidity(IndustrySolutionList[i].industryDescription[j]) && CheckEmpty(IndustrySolutionList[i].industryDescription[j])){
                        description.push(IndustrySolutionList[i].industryDescription[j]);
                    }

                }
                this.setState({
                    industryDescription:description,
                })
                break;
            }
        }
    }

    componentDecorator = (href, text) => {

        IndustrySolutionLink.map(link=>{
            if(text === link.Link){
                text=link.Display
            }
        })
        return(
            <a href={href} className="inner-link">
                {text}
            </a>
        )
    }

    seeMore = (industry) =>{
        this.props.history.push({
            pathname: '/solutions/industries',
            state: {activeIndustry: this.state.activeIndustry, activeIndustryID: this.state.activeIndustryID}
        })
    }
    render() {

        let {showAppDetail, appList, isLoading,activeIndustry,pageKeyword} = this.state;
        let {industryList} = this.state;
        let {currentPage} = this.props;

        return (
            <div className="industry-list-layout">

                <MetaData keyword={
                    currentPage === "ai-app-store"?
                        "ai-app-store"
                        :
                    this.getMeta(activeIndustry)
                }/>
                {
                    currentPage === "industry"?
                        <SummaryText topic="application"/>
                        :
                        null
                }

                {
                    currentPage === "industry"?
                        <div className="industry-list-container">
                            <div className="industry-search-bar">
                                <Searchbox placeholder={"Search by Industry"} onSearch={this.onSearchIndustry.bind(this)}/>
                            </div>
                            {
                                isLoading?
                                    <div className="industry-list-empty-layout">
                                        <RotatingCircle loadingText="Loading"/>
                                    </div>
                                    :

                                    CheckValidity(industryList)?
                                        CheckEmpty(industryList)?
                                            <div className="industry-list">
                                                {
                                                    industryList.map((industry, id)=>
                                                        <div className="industry-card"
                                                             title={industry.Industry}
                                                             key={industry.Industry} onClick={this.openIndustryApps.bind(this, industry, id)}>
                                                            <div className="industry-card-icon"  style={{background: this.getColor(id)}}>
                                                                <Icon path={industry.Icon} size={50}/>
                                                            </div>
                                                            <div className="industry-card-name">
                                                                {industry.Industry}
                                                            </div>
                                                        </div>)

                                                }
                                            </div>
                                            :
                                            <div className="industry-list-empty-layout">
                                                <VerticalEmptyScreen bodyContext="There is no data corresponding to your search!" image={emptyPage}/>
                                            </div>
                                        :
                                        <div className="industry-list-empty-layout">
                                            <VerticalEmptyScreen bodyContext="There is no data corresponding to your search!" image={emptyPage}/>
                                        </div>
                            }
                        </div>
                        :
                        <div className="application-list-container">
                            <div className="application-list-title">{this.state.activeIndustry} Applications</div>
                            <div className="application-list-description-body">
                                <Linkify componentDecorator={this.componentDecorator}>
                                    <div className="application-list-description-body--context">
                                        {
                                            this.state.industryDescription.map((context) =>
                                                <p key={context}>{context}</p>
                                            )
                                        }
                                    </div>
                                    <button className="industry-description-see-more-btn" onClick={this.seeMore.bind(this,this.state.activeIndustry)}>See more</button>
                                </Linkify>
                            </div>
                            <div className="application-list-tool-bar">
                                <div className="application-list-back-row">
                                    <Icon icon={"back_arrow3"}  onClick={this.goToInudstryList.bind(this)}/>
                                    <button className="app-list-pagination-link" onClick={this.goToInudstryList.bind(this)}>Industry List</button>
                                    &nbsp;<Icon icon="forward"/>
                                    &nbsp;
                                    <div className="application-list-breadcrumb-current-location">
                                        {this.state.activeIndustry}
                                    </div>
                                </div>

                                <div className="application-list-search-bar">
                                    <Searchbox  placeholder={"Search Application"} onSearch={this.searchAppHandler.bind(this)}/>
                                </div>
                            </div>


                            {
                                isLoading?
                                    <div className="industry-list-empty-layout">
                                        <RotatingCircle loadingText="Loading"/>
                                    </div>
                                    :

                                    <>
                                        {
                                            CheckValidity(appList)?
                                                CheckEmpty(appList)?
                                                    <div className={appList.length > 3? "application-list":"application-list less-data"}>

                                                        {
                                                            appList.map((app, id)=>
                                                                <div className="application-card"
                                                                     style={{fill: this.getColor(id), backgroundColor: this.getColor(id)+"20"}}
                                                                     title={app.AppName}
                                                                     key={app.AppName} onClick={this.openAppDetail.bind(this, app)}>
                                                                    <div className="application-card-icon"  style={{fill: this.getColor(id), backgroundColor: this.getColor(id)+"20"}}>
                                                                        <Icon  path={this.state.chosenIconPath} size={50}/>
                                                                    </div>
                                                                    <div className="app-card-info">
                                                                        <div className="app-card-name">
                                                                            {app.AppName}
                                                                        </div>
                                                                        <div className="app-card-download-row">
                                                                            <div className="app-card-download-count">
                                                                                <Icon icon="download"/> {app.DownloadCount}
                                                                            </div>

                                                                            <Button onClick={()=>{this.openAppDetail(app)}} labelText={"Download"} height={"30px"} backgroundColor={"var(--primary-color)"} />
                                                                        </div>
                                                                    </div>

                                                                    <Icon icon={"blinx_website"} className="app-card-logo"/>

                                                                </div>

                                                            )
                                                        }
                                                    </div>

                                                    :

                                                    <div className="industry-list-empty-layout">
                                                        <VerticalEmptyScreen bodyContext="There is no data corresponding to your search!" image={emptyPage}/>
                                                    </div>
                                                :
                                                <div className="industry-list-empty-layout">
                                                    <VerticalEmptyScreen bodyContext="There is no data corresponding to your search!" image={emptyPage}/>
                                                </div>

                                        }

                                        {
                                            this.state.noOfPages > 1?
                                                <div className="pagination-row">

                                                    <Pagination noOfPages={this.state.noOfPages}
                                                                onChangePage={(pageNumber) => this.onChangePageForSeeMoreView(pageNumber)}
                                                                currentPage={this.state.currentPage}/>
                                                </div>
                                                :
                                                null
                                        }


                                    </>
                            }

                            <div className="application-list-banner">

                            </div>


                            {
                                showAppDetail?
                                    <ApplicationDetail appInfo={this.state.detailedApp} onClose={this.closeAppDetails.bind(this)}/>
                                    :
                                    null
                            }
                        </div>
                }
            </div>
        );
    }

}

export default IndustryListPage;