import React, {createRef} from "react";
import "./instantModel.css";
import "./sources.css"
import SummaryText from "../Commoncomponents/SummaryText/SummaryText";
import Button from "../../Assets/Buttons/Button";
import Icon from "../../Assets/IconLibrary/Icon";
import {Checkbox} from "@mui/material";
import {Scrollbars} from "react-custom-scrollbars";
import ConfusionMatrix from "../../Assets/MatrixLibrary/ConfusionMatrix";
import StepperArrow from "../../Assets/SysStepper/StepperArrow";
import Radio from "@mui/material/Radio";
import Dropzone from 'react-dropzone';
import RotatingCircle from "../../Assets/Loading/RotatingCircle";
import {
    renderSessionForCookie,
    renderSwitchConnectorService,
    renderSwitchDipService,
    renderSwitchDOceanService,
    renderSwitchMLPService,
    renderSwitchRoeService
} from "../../api/ResourcesApiLayer";
import Popup from "reactjs-popup";
import LottieAnimation from "../../Assets/Lotties/LottieAnimation";
import {checkValidity, GetUserSession, SetUserSession} from "../../api/ApiCallMethod";
import VerticalEmptyScreen from "../../Assets/EmptyScreen/VerticalEmptyScreen";
import dropFileImage from "../../Assets/Images/DropHere.png";
import noData from "../../Assets/Images/noData.svg";
import {CheckEmpty, getMlDataTypeSafety, getSortingDataByAlphabetically} from "../../Assets/CommonMethods";
import ChooseCategoricalValue from "./ChooseCategoricalValue";
import SysPopup from "../../Assets/SysPopup/SysPopup";
import ModelTester from "./ModelTester/ModelTester";
import GhostButton from "../../Assets/Buttons/GhostButton";
import {goToProduct} from "../../api/Utilities";
import SysTablePreview from "./DataPreviewTable";
import MetaData from "../MetaData/MetaData";

const uuidv4 = require('uuid').v4;
const dropzoneRef = createRef();
const browseRef = createRef();

class InstantModel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: "columns",
            previewData: [],
            viewCase: false,
            previewDataLoading: false,
            initialModelResultLoading: false,
            processingDataLoading: false,
            initialLoading: true,
            initialModelViewLoading: false,
            algorithmInfoMap: {},
            predictLabels: [],
            confusionMetricValue: [],
            emptyModelResult: false,
            modelList: [],
            modelListCase: false,
            showModelInfo: false,
            openModelTester: false,
            defaultUserID: "00000000-0000-0000-0000-000000000000",
            defaultTenantID: "00000000-0000-0000-0000-000000000000",
            userInfo: "", //userSession_timestamp
            timeStamp: "",
            disabledContinueBtn: false,
            defaultErrorPopup: false,
            dndStatus: "undrop",
            trainStatus: "",
            dataLoadStatus: "",
            resultStatus: "",
            renameStatus: false,
            fileName: "house_price_prediction.csv",
            columnWithDataTypeInfo: [],
            stepperSteps: [
                {name: "Data Ingestion", Icon: "upload"},
                {name: "Model Configuration and Creation", Icon: "processing"},
                {name: "Model Test Results", Icon: "view_result"},
            ],

            activeStep: 0,
            currentStep: "Data Ingestion", //Data, Process, Results
            fileDropStatus: "initial", //initial, dropping, process
            processStatus: "config", //config, process
            processStepList: [
                {Name: "Uploading", Icon: "upload", Status: "inprogress", color: "var(--light-sky)"}, //inprogress, pending, success, fail
                {Name: "Training", Icon: "train", Status: "pending", color: "var(--pink)"},
                {Name: "Testing", Icon: "test", Status: "pending", color: "var(--purple)"},
                {Name: "Generate", Icon: "generate", Status: "pending", color: "var(--body-color)"},
            ],
            algorithmTypeList: [
                {
                    name: "Classification",
                    value: "Classification",
                    checked: true,
                    disabled: false,
                },
                {
                    name: "Prediction",
                    value: "Regression",
                    checked: false,
                    disabled: false,
                },
                {
                    name: "Clustering",
                    value: "Clustering",
                    checked: false,
                    disabled: false,
                },
                {
                    name: "Time Series(Classification)",
                    value: "Classification(Time_Series)",
                    checked: false,
                    disabled: true,
                },
                {
                    name: "Time Series(Prediction)",
                    value: "Regression(Time_Series)",
                    checked: false,
                    disabled: true,
                },
            ],
            selectedAlgorithmType: "Classification",
            resultTabList: ["Confusion Matrix", "Metrics"],
            chosenResultTab: "Metrics",
            metricValue: [],
            productDefaultTenantID: "00000000-0000-0000-0000-000000000001"

        }
    }

    componentDidMount() {
        let session = GetUserSession()
        console.log("checking session in instant model ", session)
        if (checkValidity(session)) {
            this.prepareInitialData()
        } else {
            renderSessionForCookie("getSession", "").then(response => {
                console.log("response of get session :: ", response.data)
                if (response.data["ResponseCode"] === 1000) {
                    SetUserSession(response.data["Results"].SessionID)
                    this.prepareInitialData()
                }
            }).catch(err => {
                console.log("catch error in calling to get session : ", err)
                this.setState({
                    initialLoading: false
                })
            })
        }
    }

    prepareInitialData = (check) => {
        //get model list by session
        let params = "?userIPAddress=" + GetUserSession()
        renderSwitchMLPService("getModelListBySessionID", "", params, "").then(response => {
            console.log("response of get model status info : ", response.data)
            if (response.data !== "") {
                if (response.data["ResponseCode"] === 1000) {
                    let modelListCase = false
                    let userInfoList = []
                    if (checkValidity(response.data["Results"])) {
                        let modelStatusInfo = response.data["Results"]
                        if (checkValidity(modelStatusInfo.UserInfo) && modelStatusInfo.UserInfo.length > 0) {
                            modelListCase = true
                            userInfoList = modelStatusInfo.UserInfo
                        } else if (checkValidity(modelStatusInfo["StatusInfo"]) && checkValidity(modelStatusInfo["StatusInfo"]["UserInfo"])) {
                            modelListCase = true
                            userInfoList = [modelStatusInfo["StatusInfo"]["UserInfo"]]
                        }
                    }
                    console.log("user info list :: ", userInfoList)
                    if (modelListCase) {
                        renderSwitchMLPService("getInstantModelInfoMapByUserInfoArr", userInfoList, "", "").then(response => {
                            console.log("response of get model list by user info arr: ", response.data)
                            if (response.data !== "") {
                                if (response.data["ResponseCode"] === 1000) {
                                    let modelHashMap = {}
                                    let modelList = []
                                    let algorithmIDs = []
                                    if (checkValidity(response.data["Results"])) {
                                        modelHashMap = response.data["Results"]
                                        userInfoList.map(eachUserInfo => {
                                            if (checkValidity(modelHashMap[eachUserInfo])) {
                                                modelList.push(modelHashMap[eachUserInfo])
                                                if (!algorithmIDs.includes(modelHashMap[eachUserInfo].AlgorithmID)) {
                                                    algorithmIDs.push(modelHashMap[eachUserInfo].AlgorithmID)
                                                }
                                            }
                                        })
                                    }
                                    console.log("model list :: ", modelList)
                                    if (modelListCase && modelList.length > 0) {
                                        if (algorithmIDs.length > 0) {
                                            renderSwitchMLPService("getAlgorithmInfoByAlgorithmIDs", algorithmIDs, "", "").then(response => {
                                                console.log("response of algorithm info map : ", response.data)
                                                if (response.data !== "") {
                                                    if (response.data["ResponseCode"] === 1000) {
                                                        let algoMap = response.data["Results"]
                                                        modelList.map(eachModel => {
                                                            if (checkValidity(algoMap[eachModel.AlgorithmID])) {
                                                                eachModel.AlgorithmType = algoMap[eachModel.AlgorithmID].AlgorithmType
                                                            }
                                                        })
                                                        console.log("modelList after defined algo type : ", modelList)
                                                        let modelListCase = this.state.modelListCase
                                                        if (check === undefined) {
                                                            modelListCase = true
                                                        }
                                                        this.setState({
                                                            algorithmInfoMap: algoMap,
                                                            modelList,
                                                            modelListCase: modelListCase,
                                                            initialLoading: false
                                                        })
                                                    } else {
                                                        this.setState({
                                                            modelListCase: false
                                                        })
                                                    }
                                                } else {
                                                    this.setState({
                                                        initialLoading: false,
                                                        modelListCase: false
                                                    })
                                                }
                                            }).catch(err => {
                                                console.log("catch error in calling to get algo map : ", err)
                                                this.setState({
                                                    initialLoading: false,
                                                    modelListCase: false
                                                })
                                            })
                                        }
                                    } else {
                                        this.setState({
                                            initialLoading: false,
                                            modelListCase: false
                                        })
                                    }
                                } else {
                                    this.setState({
                                        initialLoading: false,
                                        modelListCase: false
                                    })
                                }
                            } else {
                                this.setState({
                                    initialLoading: false,
                                    modelListCase: false
                                })
                            }
                        })
                    } else {
                        this.setState({
                            initialLoading: false,
                            modelListCase: false
                        })
                    }
                } else {
                    this.setState({
                        initialLoading: false,
                        modelListCase: false
                    })
                }
            } else {
                this.setState({
                    initialLoading: false,
                    modelListCase: false
                })
            }
        })
            .catch(err => {
                console.log("catch error in calling to get model status list : ", err)
                this.setState({
                    initialLoading: false,
                    modelListCase: false
                })
            })
    }

    componentWillUnmount() {
        clearInterval(this.modelStatusInterval)
    }

    createNewInstantModel = () => {
        clearInterval(this.modelStatusInterval)
        this.setState({
            activeTab: "columns",
            previewData: [],
            viewCase: false,
            showModelInfo: false,
            modelListCase: false,
            activeStep: 0,
            currentStep: "Data Ingestion",
            fileDropStatus: "initial",
            processStatus: "config",
            processStepList: [
                {Name: "Uploading", Icon: "upload", Status: "inprogress", color: "var(--light-sky)"}, //inprogress, pending, success, fail
                {Name: "Training", Icon: "train", Status: "pending", color: "var(--pink)"},
                {Name: "Testing", Icon: "test", Status: "pending", color: "var(--purple)"},
                {Name: "Generate", Icon: "generate", Status: "pending", color: "var(--body-color)"},
            ],
        })
    }

    getTableBody = (count) => {
        let height = count * 50 + 20;
        if (height < 350) {
            return height
        } else return 350
    }

    stepDesign = (stepInfo) => {
        return (
            <button
                className={stepInfo.Name === this.state.currentStep ? "instant-model-step active" : "instant-model-step"}
                key={stepInfo}>
                <Icon icon={stepInfo.Icon} className="instant-model-step-icon"/>
                <div className="step-info-name">{stepInfo.Name}</div>
            </button>
        )
    }

    handleOpenErrorDetails = (info, id) => {
        if (info.Status === "fail") {
            let defaultErrorTitle = ""
            if (id !== 0) {
                defaultErrorTitle = ""
            }
            this.setState({
                defaultErrorPopup: true,
                defaultErrorTitle: ""
            })
        }
    }
    progressNode = (info, id) => {
        return (
            <div className="process-progress-step">
                <div
                    // onClick={()=>this.handleOpenErrorDetails(info, id)}
                    className="process-progress-node">
                    <Icon icon={info.Icon} size={30} style={{fill: info.color}}/>
                    <div className={`process-progress-circle ${info.Status}`}>
                        <Icon
                            size={16}
                            icon={
                                info.Status === "success" ?
                                    "check"
                                    :
                                    info.Status === "fail" ?
                                        "exclamation"
                                        :
                                        info.Status === "pending" ?
                                            "waiting"
                                            :
                                            "gear"
                            }/>
                    </div>
                </div>

                <div className="process-progress-node-name">{info.Name}</div>
            </div>
        )
    }

    progressBarLine = (stepInfo) => {
        let status = ""
        if (stepInfo.Status === "success") {
            status = "complete"
        }
        return (
            <div
                className={status === "complete" ? "process-progress-bar-line complete" : "process-progress-bar-line"}>
                <div className="process-progress-icon">
                    <Icon icon="stepper_arrow" size={35}/>
                </div>
                <div className="process-progress-icon-downward">
                    <Icon icon="stepperArrow_downward" size={35}/>
                </div>

            </div>
        )
    }

    changeResultTab = (tabName) => {
        this.setState({chosenResultTab: tabName})
    }

    /**-----------------File Upload Case-----------------**/
    zipFileUploadOnDrop(event) {
        console.log("zipFileUploadOnDrop")
        let dragArea = document.getElementById("instant-droppable-zone");
        let dragFrame = document.getElementById("instant-drop-zone");
        dragArea.style.display = "none";
        dragFrame.style.border = "2px dashed #cdcdcd"
        console.log("event files in onDrop : ", event)
    }

    zipFileUploadDragOver() {
        let dragArea = document.getElementById("instant-droppable-zone");
        let dragFrame = document.getElementById("instant-drop-zone");
        dragArea.style.display = "block";
        dragFrame.style.border = "2px dashed #cdcdcd"
    }

    zipFileUploadDragLeave() {
        let dragArea = document.getElementById("instant-droppable-zone");
        let dragFrame = document.getElementById("instant-drop-zone");
        dragArea.style.display = "none";
        dragFrame.style.border = "2px dashed #cdcdcd"
    }

    handleBrowseOpenFiles() {
        console.log("reached to open files", browseRef.current)
        if (dropzoneRef.current) {
            dropzoneRef.current.open()
        }
    }

    handleCancelBrowseFile() {
        console.log("entered to handleCancelBrowseFile")
    }

    handleSaveFileInfo(event) {
        console.log("handleSaveFileInfo :: ", event.target)
        let browsedFiles = event.target.files;
        console.log("browsedFiles >> ", browsedFiles)
        let acceptedFiles = []
        for (let i = 0; i < browsedFiles.length; i++) {
            acceptedFiles = acceptedFiles.concat(browsedFiles[i])
        }
        this.fileUploadOnDrop("browse", acceptedFiles)
    }

    onFileInputChange(event) {
        console.log("entered to onFileInputChange", event.target.files)
    }

    fileUploadOnDrop(screen, acceptedFiles) {
        console.log("dropZoneRef =>", dropzoneRef, acceptedFiles.length, acceptedFiles.length < 11, acceptedFiles, this.state.acceptedFiles)
        let userSession = GetUserSession()
        console.log("user session for upload file : ", userSession)
        console.log("dropZoneRef =>", dropzoneRef, acceptedFiles.length, acceptedFiles.length < 11, acceptedFiles)
        if (screen === "screen") {
            // let dragArea = document.getElementById("instant-droppable-zone");
            // let dragFrame = document.getElementById("instant-drop-zone");
            // dragArea.style.display = "block";
            // dragFrame.style.border = "2px dashed #cdcdcd"
        } else if (screen === "browse") {
            console.log("checking accepted files key :: ", acceptedFiles)
        } else {
            let draggingScreen = document.getElementById("file-drag-popup");
            if (draggingScreen !== null) {
                draggingScreen.style.display = "none";
            }
        }
        if (acceptedFiles !== '') {
            if (acceptedFiles.length < 2) {
                this.setState({
                    defaultLoadingPopup: true,
                    defaultLoadingText: "Uploading",
                    acceptedFiles: acceptedFiles,
                    fileDropStatus: "process",
                    detailFrame: false,
                    listWidth: '100%',
                    detailWidth: '0%',
                    preview_input_data: [],
                    chosenSourceType: "file",
                    openDefineHeader: false,
                    setFirstRow: false,
                    showFirstRowHeaderList: false,
                    firstRowHeaderList: [],
                    setHeaderLoading: false,
                    setHeaderLoadingMessage: "",
                    setCustomizeLoading: false,
                    disabledGoToPreview: true,
                })
                const data = new FormData()
                let fileCount = 0
                let allowFile = false
                let rootFolderName = ""
                let zipName = ""
                let originalUploadFile = ""
                acceptedFiles.forEach(file => {
                    console.log("each file >> ", file)
                    fileCount += 1
                    if (file !== undefined && file.name !== undefined) {
                        let fileName = file.name
                        zipName = file.name
                        if (fileName.endsWith(".csv")) {
                            console.log("end with zip ")
                            allowFile = true
                            let splitArr = fileName.split(".csv")
                            if (splitArr.length > 0) {
                                rootFolderName = splitArr[0]
                            }
                        }
                    }

                    originalUploadFile = file
                    data.append("FileName", file);
                });
                console.log("allowFile?? ", allowFile, fileCount)

                if (!allowFile) {
                    this.setState({
                        fileDropStatus: "initial",
                        defaultLoadingPopup: false,
                        defaultErrorPopup: true,
                        defaultErrorTitle: "Invalid Format",
                        defaultErrorText: "The uploaded file format doesn't support for instant model. Please change the file type to upload."
                    })
                } else {
                    let maxFileSize = 20 * 1024
                    console.log("uploaded file size : ", originalUploadFile.size / 1024, maxFileSize)
                    let fileSizeInKiloBytes = originalUploadFile.size / 1024
                    if (fileSizeInKiloBytes > maxFileSize) {
                        this.setState({
                            fileDropStatus: "initial",
                            defaultLoadingPopup: false,
                            defaultErrorPopup: true,
                            defaultErrorTitle: "Limited Exceeded",
                            defaultErrorText: "File upload failed- This file exceeds 20 MB."
                        })
                        return
                    }
                    renderSwitchConnectorService("uploadFile", data, "", '')
                        .then(response => {
                            console.log("response of file upload results : ", response.data);
                            if (response.data !== "") {
                                if (response.data.ResponseCode === 1000) {
                                    let results = response.data.Results;
                                    let fileUploadInfo = ""
                                    if (results !== null && results.length > 0) {
                                        fileUploadInfo = results[0]
                                        let uploadedFileInfo = {
                                            DisplayName: fileUploadInfo.DisplayName,
                                            ID: fileUploadInfo.ID,
                                            isSelected: false,
                                            FileType: fileUploadInfo.FileType,
                                            Engine: fileUploadInfo.FileType,
                                            SourceName: "File",
                                            FileName: fileUploadInfo.ID,
                                            TableName: fileUploadInfo.FileType !== 'json' ? fileUploadInfo.ID : fileUploadInfo.TableName,
                                            Host: "",
                                            Post: "",
                                            UserName: "",
                                            Password: "",
                                            Directory: "",
                                            Protocol: "",
                                            FirstRowAsHeader: false,
                                            firstRowHeaderList: [],
                                            headerSuccess: true,
                                            setFirstRow: false,
                                            currentChoosingDelimiter: "",
                                            viewDelimiterCase: false,
                                            possibleDelimiter: [],
                                            headerAlertMessage: "",
                                            Delimiter: "",
                                            DataInfo: [],
                                            DefineHeader: false,
                                            disabledDefine: true,
                                            disabledFirstRowAsHeader: false,
                                            EmptyHeaderList: true,
                                            actualColumnCount: 0,
                                            AutoDefineHeader: false,
                                        }
                                        this.setState({
                                            chosenResultTab: "Metrics",
                                            fileConfigurationPopup: false,
                                            sourceConfigurationStep: 3,
                                            doneFirstStep: true,
                                            doneSecondStep: true,
                                            doneThirdStep: true,
                                            stepBarFillHeight: "100%",
                                            FileID: fileUploadInfo.ID,
                                            FileType: fileUploadInfo.FileType,
                                            DisplayName: fileUploadInfo.DisplayName,
                                            uploadedFileInfo: uploadedFileInfo,
                                            defaultLoadingText: "Uploading " + fileUploadInfo.DisplayName
                                        })
                                        this.getHeaderList(uploadedFileInfo)
                                    } else {
                                        this.setState({
                                            fileDropStatus: "initial",
                                            defaultLoadingPopup: false,
                                            defaultLoadingText: "Initializing",
                                            defaultErrorTitle: "System Error",
                                            defaultErrorPopup: true,
                                            defaultErrorText: "Currently, we're unable to process your request, please try again later.",
                                        })
                                    }
                                } else {
                                    if (response.data.ResponseCode === 2000) {
                                        if (response.data["Results"] === "Exceed Limit Row Count") {
                                            this.setState({
                                                fileDropStatus: "initial",
                                                defaultLoadingPopup: false,
                                                defaultLoadingText: "Initializing",
                                                defaultErrorTitle: "Limited Exceeded",
                                                defaultErrorPopup: true,
                                                defaultErrorText: "File upload failed- This file exceeds 20 MB or row count limit is greater than 10000.",
                                            })
                                        } else if (response.data["Results"] === "Unsupported FileType") {
                                            this.setState({
                                                fileDropStatus: "initial",
                                                defaultLoadingPopup: false,
                                                defaultLoadingText: "Initializing",
                                                defaultErrorTitle: "Invalid Format",
                                                defaultErrorPopup: true,
                                                defaultErrorText: "The uploaded file format doesn't support for instant model. Please change the file type to upload",
                                            })
                                        } else if (response.data["Results"] === "Can't Upload Multi File") {
                                            this.setState({
                                                fileDropStatus: "initial",
                                                defaultLoadingPopup: false,
                                                defaultLoadingText: "Initializing",
                                                defaultErrorTitle: "Upload failed",
                                                defaultErrorPopup: true,
                                                defaultErrorText: "You are allowed to upload one file only for instant model.",
                                            })
                                        } else {
                                            this.setState({
                                                fileDropStatus: "initial",
                                                defaultLoadingPopup: false,
                                                defaultLoadingText: "Initializing",
                                                defaultErrorTitle: "System Error",
                                                defaultErrorPopup: true,
                                                defaultErrorText: "Currently, we're unable to process your request, please try again later.",
                                            })
                                        }
                                    } else if (response.data["ResponseCode"] === 2300) {
                                        this.setState({
                                            fileDropStatus: "initial",
                                            defaultLoadingPopup: false,
                                            defaultLoadingText: "Initializing",
                                            defaultErrorTitle: "Upload Failed",
                                            defaultErrorPopup: true,
                                            defaultErrorText: "Currently, we're unable to process your request, please check the connection and try again later.",
                                        })
                                    } else {
                                        this.setState({
                                            fileDropStatus: "initial",
                                            defaultLoadingPopup: false,
                                            defaultLoadingText: "Initializing",
                                            defaultErrorTitle: "System Error",
                                            defaultErrorPopup: true,
                                            defaultErrorText: "Currently, we're unable to process your request, please try again later.",
                                        })
                                    }
                                }
                            } else {
                                console.log('cannot upload file =>')
                                this.setState({
                                    fileDropStatus: "initial",
                                    defaultLoadingPopup: false,
                                    defaultErrorPopup: true,
                                    defaultErrorText: "You can\'t connect to your server at the moment. Please try again later.",
                                    sourceConfigurationStep: 3,
                                    defaultErrorTitle: "System Error"
                                })
                            }
                        }).catch(error => {
                        console.log("File Upload Result =>", error);
                        this.setState({
                            fileDropStatus: "initial",
                            defaultLoadingPopup: false,
                            defaultLoadingText: "Initializing",
                            defaultErrorPopup: true,
                            defaultErrorText: "You can\'t connect to your server at the moment. Please try again later.",
                            defaultErrorTitle: "System Error",
                        })
                    });
                }
            } else {
                this.setState({
                    fileDropStatus: "initial",
                    defaultErrorPopup: true,
                    defaultErrorTitle: "Upload failed",
                    defaultErrorText: "You are allowed to upload one file only for instant model",
                })
            }
        } else {
            this.setState({
                fileDropStatus: "initial",
                defaultLoadingPopup: false,
                defaultErrorPopup: true,
                defaultErrorTitle: "Invalid Format",
                defaultErrorText: "The upload format is not supported by the system. Please make sure you are uploading a file that is compatible and try again.",
            })
        }
    }

    getHeaderList(uploadedFileInfo) {
        if (uploadedFileInfo !== undefined && uploadedFileInfo !== null) {
            uploadedFileInfo.setFirstRow = true
            uploadedFileInfo.headerSuccess = false
            uploadedFileInfo.DefineHeader = false
            uploadedFileInfo.viewDelimiterCase = false
            uploadedFileInfo.possibleDelimiter = []
            uploadedFileInfo.AutoDefineHeader = false
            let Directory = ""
            let Host = ""
            let Port = ""
            let UserName = ""
            let Password = ""
            let Protocol = ""
            let FileName = uploadedFileInfo.ID;
            let passData = {
                FileType: uploadedFileInfo.FileType,
                Engine: uploadedFileInfo.FileType,
                SourceName: "FILE",
                FileName: FileName,
                TableName: FileName,
                Filtering: ["_id"],
                Directory: Directory,
                Host: Host,
                Port: Port,
                UserName: UserName,
                Password: Password,
                Protocol: Protocol,
                FirstRowAsHeader: true,
                Delimiter: "",
                DataInfo: [],
                NewDelimiter: ""
            }
            console.log("data preview json :", JSON.stringify(passData))
            renderSwitchConnectorService('getFirstRowAsHeader', passData, '', '').then(
                getResults => {
                    console.log("response of get first row as header =>", getResults.data);
                    if (getResults.data === "") {
                        this.setState({
                            fileDropStatus: "initial",
                            defaultLoadingPopup: false,
                            defaultErrorPopup: true,
                            defaultErrorTitle: "System Error",
                            defaultErrorText: "You can\'t connect to your server at the moment. Please try again later.",
                        })
                        return
                    }
                    let headerResults = getResults.data.Results
                    if (getResults.data["ResponseCode"] === 1000) {
                        uploadedFileInfo.possibleDelimiter = headerResults["PossibleDelimiter"]
                        let columnDList = headerResults["ColumnInfo"]
                        if (columnDList.length !== 0) {
                            columnDList.map(
                                (cInfo, cIndex) => {
                                    let disColumnName = cInfo.ColumnName
                                    let cName = ReplaceColumnName(cInfo.ColumnName)
                                    columnDList[cIndex] = Object.assign({}, cInfo, {
                                        DisplayName: disColumnName,
                                        ColumnName: cName
                                    })
                                }
                            )
                        }
                        uploadedFileInfo.DataInfo = columnDList
                        uploadedFileInfo.firstRowHeaderList = columnDList
                        uploadedFileInfo.headerSuccess = true
                        uploadedFileInfo.viewDelimiterCase = true
                        uploadedFileInfo.currentChoosingDelimiter = uploadedFileInfo.possibleDelimiter[0]
                        uploadedFileInfo.Delimiter = uploadedFileInfo.possibleDelimiter[0]
                        uploadedFileInfo.headerAlertMessage = "Header defined successfully."
                        uploadedFileInfo.DefineHeader = true
                        uploadedFileInfo.FirstRowAsHeader = true
                        uploadedFileInfo.disabledDefine = false
                        uploadedFileInfo.actualColumnCount = headerResults["ActualColumnCount"]
                        uploadedFileInfo.AutoDefineHeader = true
                        uploadedFileInfo.FixHeader = true
                        uploadedFileInfo.EmptyHeaderList = false
                        this.getPreviewSheet(uploadedFileInfo)
                    } else if (getResults.data["ResponseCode"] === 3666) {
                        uploadedFileInfo.possibleDelimiter = headerResults["PossibleDelimiter"]
                        uploadedFileInfo.headerSuccess = true
                        uploadedFileInfo.viewDelimiterCase = true
                        uploadedFileInfo.currentChoosingDelimiter = uploadedFileInfo.possibleDelimiter[0]
                        uploadedFileInfo.Delimiter = uploadedFileInfo.possibleDelimiter[0]
                        uploadedFileInfo.headerAlertMessage = getResults.data.ResponseMessage
                        uploadedFileInfo.DefineHeader = true
                        uploadedFileInfo.FirstRowAsHeader = true
                        uploadedFileInfo.disabledDefine = false
                        uploadedFileInfo.actualColumnCount = headerResults["ActualColumnCount"]
                        uploadedFileInfo.AutoDefineHeader = true
                        uploadedFileInfo.FixHeader = true
                        uploadedFileInfo.EmptyHeaderList = false
                        if (headerResults["ColumnInfo"].length !== 0) {
                            let columnDList = headerResults["ColumnInfo"]
                            if (columnDList.length !== 0) {
                                let emptyIndex = 1
                                columnDList.map(
                                    (cInfo, cIndex) => {
                                        if (cInfo.ColumnName === "" && getResults.data["ResponseCode"] === 3666) {
                                            cInfo.ColumnName = "column" + emptyIndex
                                            emptyIndex++
                                        }

                                        let disColumnName = cInfo.ColumnName
                                        let cName = ReplaceColumnName(cInfo.ColumnName)
                                        columnDList[cIndex] = Object.assign({}, cInfo, {
                                            DisplayName: disColumnName,
                                            ColumnName: cName
                                        })
                                    }
                                )
                            }
                            uploadedFileInfo.DataInfo = columnDList
                            uploadedFileInfo.firstRowHeaderList = columnDList
                        } else {
                            uploadedFileInfo.EmptyHeaderList = false
                            uploadedFileInfo.firstRowHeaderList = []
                        }
                        this.getPreviewSheet(uploadedFileInfo)
                    } else {
                        if (getResults.data.ResponseCode === 2122) {
                            this.setState({
                                fileDropStatus: "initial",
                                defaultLoadingPopup: false,
                                defaultErrorPopup: true,
                                defaultErrorText: "File upload failed - Does not have plugin file for the given engine!",
                            })
                        } else {
                            this.setState({
                                fileDropStatus: "initial",
                                defaultLoadingPopup: false,
                                defaultErrorPopup: true,
                                defaultErrorText: "File upload failed - Does not support for the uploaded file, please try with another file.",
                            })
                        }
                    }
                }
            ).catch(() => {
                this.setState({
                    fileDropStatus: "initial",
                    defaultLoadingPopup: false,
                    defaultErrorPopup: true,
                    defaultErrorText: "File upload failed - Does not support for the uploaded file, please try with another file.",
                })
            })
        }
    }

    getPreviewSheet(uploadedFileInfo) {
        console.log("getPreviewSheet - uploadedFileInfo : ", uploadedFileInfo)
        let FileName = uploadedFileInfo.ID;
        let Directory = ""
        let Host = ""
        let Port = ""
        let UserName = ""
        let Password = ""
        let Protocol = ""
        let previewInputData = {
            FileType: uploadedFileInfo.FileType,
            Engine: uploadedFileInfo.FileType,
            SourceName: "FILE",
            FileName: uploadedFileInfo.ID,
            TableName: uploadedFileInfo.FileType !== 'json' ? uploadedFileInfo.ID : uploadedFileInfo.TableName,
            PageSize: 50,
            Filtering: ["_id"],
            FirstRowAsHeader: uploadedFileInfo.setFirstRow,
            Delimiter: uploadedFileInfo.Delimiter,
            DataInfo: uploadedFileInfo.DataInfo,
            Directory: Directory,
            Host: Host,
            Port: Port,
            Protocol: Protocol,
            UserName: UserName,
            Password: Password,
            FixHeader: uploadedFileInfo.FixHeader,
            NewDelimiter: ",",
            DefinePrimaryKey: true
        };
        this.setState({
            Engine: uploadedFileInfo.FileType,
            FileType: uploadedFileInfo.FileType,
            FileName: FileName,
        })
        renderSwitchConnectorService('getPreviewFileData', previewInputData, '', '').then(resData => {
            console.log("response of get preview file data =>", resData.data)
            let categoryValue = false
            if (resData.data !== "") {
                if (resData.data["ResponseCode"] === 1000 || resData.data["ResponseCode"] === 3004 || resData.data["ResponseCode"] === 3002 || resData.data["ResponseCode"] === 3615) {
                    let totalRowCount = 0;
                    let previewInfo = resData.data["Results"];
                    if (previewInfo === null) {
                        previewInfo = {
                            PreviewData: [],
                            Delimiter: [","],
                            ColumnWithDataType: [{Column: "", DataType: "", "DisplayName": ""}]
                        }
                    }
                    let headerNameHashmap = {}
                    if (uploadedFileInfo.Engine !== 'json') {     // no need when get header from define header api
                        let passDataInfo = JSON.parse(JSON.stringify(previewInputData))
                        if (passDataInfo !== undefined && passDataInfo !== null && passDataInfo.DataInfo !== undefined && passDataInfo.DataInfo !== null && passDataInfo.DataInfo.length !== 0) {
                            passDataInfo.DataInfo.map(
                                dInfo => headerNameHashmap[dInfo.ColumnName] = dInfo.DisplayName
                            )
                        }
                    }

                    let ColumnName = [];
                    let dataInfo = [];
                    let primaryKeyList = [];
                    let timeSeriesColumnList = []
                    let enumColumnList = []
                    if (previewInfo["NoOfRows"] !== undefined) {
                        totalRowCount = previewInfo["NoOfRows"]
                    }
                    uploadedFileInfo.DataInfo.map(eachColumn => {
                        ColumnName = ColumnName.concat(eachColumn.ColumnName)
                    })
                    let columnInfoHashMap = {}
                    previewInfo.ColumnWithDataType.map((colData) => {
                            colData.DisplayName = headerNameHashmap[colData.Column]
                            colData.ColumnName = colData.Column
                            colData.OriginalDataType = colData.DataType
                            colData.ColumnDataType = getMlDataTypeSafety(colData.DataType)
                            if (colData["PossibleDataType"] !== undefined) {
                                if (colData.DataType === "enum") {
                                    categoryValue = true
                                    enumColumnList = enumColumnList.concat(colData)
                                }
                                primaryKeyList = primaryKeyList.concat({
                                    label: headerNameHashmap[colData.Column],
                                    value: colData.Column
                                })
                                colData["PossibleDataType"].map(posType => {
                                        if (posType === "date") {
                                            timeSeriesColumnList = timeSeriesColumnList.concat(colData)
                                            colData.DataType = "date"
                                        }
                                    }
                                )
                            }
                            columnInfoHashMap[colData.ColumnName] = colData
                        }
                    )

                    for (let j = 0; j < ColumnName.length; j++) {
                        let disColumnName = headerNameHashmap[ColumnName[j]]
                        let columnName = ColumnName[j]
                        let columnInfo = columnInfoHashMap[columnName]
                        dataInfo[j] = {
                            DisplayName: disColumnName,
                            ColumnName: columnName,
                            DataType: columnInfo.DataType,
                            Categorical: columnInfo.DataType === "enum",
                            Multipunch: false,
                            Delimiter: "",
                            CategoricalValue: [],
                            PossibleDataType: columnInfo.PossibleDataType,
                            ColumnIndex: columnInfo.ColumnIndex,
                            OriginalDataType: columnInfo.DataType,
                            ColumnDataType: getMlDataTypeSafety(columnInfo.DataType),
                            isCheckedForInput: false,
                            isCheckedForOutput: false,
                        }
                    }

                    console.log("data info : ", dataInfo)
                    previewInputData.DataInfo = dataInfo
                    let currentCategoricalValue = this.state.categoryValue || categoryValue

                    //save file info
                    let timeStamp = Math.floor(new Date().getTime() / 1000)
                    let userInfo = GetUserSession() + "_" + timeStamp
                    let fileSaveInfo = {
                        UserInfo: userInfo,
                        Engine: uploadedFileInfo.FileType,
                        FileType: uploadedFileInfo.FileType,
                        FileName: uploadedFileInfo.ID,
                        Name: uploadedFileInfo.DisplayName,
                        Delimiter: checkValidity(previewInfo.NewDelimiter) ? previewInfo.NewDelimiter : previewInfo.Delimiter !== null ? previewInfo.Delimiter[0] : null,
                        FirstRowAsHeader: true,
                        DataInfo: dataInfo,
                        OriginalDelimiter: ",",
                    }

                    console.log("fileSaveInfo :: ", fileSaveInfo)
                    renderSwitchDipService("saveFileSourceInfo", fileSaveInfo, "", "").then(response => {
                        console.log("response of file saving info : ", response.data)
                        if (response.data === "") {
                            this.setState({
                                fileDropStatus: "initial",
                                defaultErrorTitle: "Request Error",
                                defaultLoadingPopup: false,
                                defaultErrorPopup: true,
                                defaultErrorText: "The requested information is not available at the moment, please try later!",
                            })
                            return
                        }

                        if (response.data["ResponseCode"] === 1000) {
                            let convertedDataSorted = getSortingDataByAlphabetically(JSON.parse(JSON.stringify(dataInfo)), "ColumnName")
                            let preparedData = this.handlePrepareDataColumnsAndAlgorithmList(convertedDataSorted, enumColumnList, timeSeriesColumnList)
                            let modifiedAlgoTypeList = this.modifyAlgorithmList(this.state.algorithmTypeList, preparedData.selectedAlgorithmType, preparedData.includeEnum, preparedData.timeSeriesIncluded)
                            console.log("returned prepared data :: ", preparedData)
                            uploadedFileInfo.Delimiter = fileSaveInfo.Delimiter
                            uploadedFileInfo.Engine = fileSaveInfo.Engine
                            uploadedFileInfo.FileType = fileSaveInfo.FileType
                            uploadedFileInfo.FileName = fileSaveInfo.FileName
                            uploadedFileInfo.Name = fileSaveInfo.Name
                            uploadedFileInfo.DataInfo = fileSaveInfo.DataInfo
                            uploadedFileInfo.OriginalDelimiter = fileSaveInfo.OriginalDelimiter
                            this.setState({
                                uploadedFileInfo,
                                userInfo: userInfo,
                                timeStamp: timeStamp,
                                timeSeries: preparedData.timeSeriesIncluded,
                                ValidationStatus: "",
                                OriginalValidationStatus: "",
                                TimeSeriesStatus: "",
                                CheckValidate: false,
                                enumColumnList: enumColumnList,
                                timeSeriesColumnList: timeSeriesColumnList,
                                chosenTimeSeriesColumn: timeSeriesColumnList.length !== 0 ? timeSeriesColumnList[0] : null,
                                totalRowCount: totalRowCount,
                                uniquePrimaryKey: primaryKeyList,
                                categoryValue: currentCategoricalValue,
                                previewInputData: previewInputData,
                                columnWithDataTypeInfo: preparedData.columnInfoWithDataType,
                                selectedAlgorithmType: preparedData.selectedAlgorithmType,
                                primaryKeyList: primaryKeyList,
                                defaultLoadingPopup: false,
                                fileDropStatus: "initial",
                                activeStep: 1,
                                currentStep: "Model Configuration and Creation",
                                processStatus: "config",
                                algorithmTypeList: modifiedAlgoTypeList
                            })
                        } else {
                            this.setState({
                                fileDropStatus: "initial",
                                defaultErrorTitle: "Request Error",
                                defaultLoadingPopup: false,
                                defaultErrorPopup: true,
                                defaultErrorText: "The uploaded file cannot be processed, please try later or use another file!",
                            })
                        }
                    })
                        .catch(err => {
                            console.log("catch error in calling to save source info : ", err)
                            this.setState({
                                fileDropStatus: "initial",
                                defaultErrorTitle: "Request Error",
                                defaultLoadingPopup: false,
                                defaultErrorPopup: true,
                                defaultErrorText: "The requested information is not available at the moment, please try later!",
                            })
                        })
                } else {
                    this.setState({
                        fileDropStatus: "initial",
                        defaultErrorTitle: "Request Error",
                        defaultLoadingPopup: false,
                        defaultErrorPopup: true,
                        defaultErrorText: "The requested information is not available at the moment, please try later!",
                    })
                }
            } else {
                this.setState({
                    fileDropStatus: "initial",
                    defaultErrorTitle: "Request Error",
                    defaultLoadingPopup: false,
                    defaultErrorPopup: true,
                    defaultErrorText: "The requested information is not available at the moment, please try later!",
                })
            }
        }).catch((e) => {
            console.log("error in catch :", e)
            this.setState({
                fileDropStatus: "initial",
                defaultLoadingPopup: false,
                defaultErrorPopup: true,
                defaultErrorTitle: "Request Error",
                defaultErrorText: "The requested information is not available at the moment, please try later!"
            })
        })
    }


    /**------------------for model configuration-----------------**/
    getFilterAlgorithmType(selectedFeatureList, timeSeries) {
        let InputFeatureDataTypes = []
        let OutputFeatureDataTypes = []
        let algorithms = ""
        let foundEnumOutput = false
        if (selectedFeatureList !== undefined && selectedFeatureList !== null && selectedFeatureList.length !== 0) {
            selectedFeatureList.map((feature) => {
                let dataType = feature.ColumnDataType.toLowerCase()
                if (feature.Key === "output") {
                    if (feature.OriginalDataType === 'enum') {
                        foundEnumOutput = true
                    }
                    OutputFeatureDataTypes = OutputFeatureDataTypes.concat(dataType === 'double' ? 'float' : dataType)
                } else {
                    InputFeatureDataTypes = InputFeatureDataTypes.concat(dataType === 'double' ? 'float' : dataType)
                }
            })
        }

        let postData = {
            "InputFeatureDataTypes": InputFeatureDataTypes,
            "OutputFeatureDataTypes": OutputFeatureDataTypes,
            "AlgoCount": 1,
            "TenantID": this.state.TenantID,
            "UserID": this.state.UserID,
            "MultiOutput": OutputFeatureDataTypes.length !== 1
        }
        console.log('post data =>', postData)
        renderSwitchMLPService("GetFilteredAlgorithmSpecific", postData, "", "")
            .then(response => {
                console.log(">> get algo type map : ", response.data)
                if (response.data !== "") {
                    if (response.data["ResponseCode"] === 1000) {
                        console.log('response code is 1000 =>')
                        let fastAlgoTypeMap = response.data["Results"]
                        let fastAlgoTypeList = []
                        let recommandedAlgoType = []
                        if (!OutputFeatureDataTypes.includes('float')) {
                            if (checkValidity(fastAlgoTypeMap['Classification'])) {
                                fastAlgoTypeList = fastAlgoTypeList.concat({
                                    type: 'Classification',
                                    isChecked: false,
                                    isSelected: true,
                                    icon: "classification",
                                    iconColor: ["", "#ECFDFF", "#0a53a5", "#89A8F6", "#6AD1B8", "#ED8FE9", "#AEC176", "#F69489", "#ADA8A7", "#8EC176", "#89A7F6"]
                                })
                                recommandedAlgoType = recommandedAlgoType.concat('Classification')
                            }
                            if (timeSeries) {
                                if (fastAlgoTypeMap['Classification(Time_Series)'] !== undefined && fastAlgoTypeMap['Classification(Time_Series)'] !== null && fastAlgoTypeMap['Classification(Time_Series)'] !== "") {
                                    fastAlgoTypeList = fastAlgoTypeList.concat({
                                        type: 'Classification(Time_Series)',
                                        isChecked: false,
                                        isSelected: false,
                                        icon: "times_series",
                                        iconColor: ["", "", ""]
                                    })
                                    recommandedAlgoType = recommandedAlgoType.concat('Classification(Time_Series)')
                                }
                            }

                        }
                        if (fastAlgoTypeMap['Clustering'] !== undefined && fastAlgoTypeMap['Clustering'] !== null && fastAlgoTypeMap['Clustering'] !== "") {
                            fastAlgoTypeList = fastAlgoTypeList.concat({
                                type: 'Clustering',
                                isChecked: false,
                                isSelected: false,
                                icon: "clustering",
                                iconColor: ["", "#1B71D1", "#0A53A5", "#CFD4DC", "#B0B6C5", "#1A71D3"]
                            })
                            recommandedAlgoType = recommandedAlgoType.concat('Clustering')
                        }

                        if (fastAlgoTypeMap['Regression'] !== undefined && fastAlgoTypeMap['Regression'] !== null && fastAlgoTypeMap['Regression'] !== "") {
                            if (!foundEnumOutput) {
                                fastAlgoTypeList = fastAlgoTypeList.concat({
                                    type: 'Regression',
                                    isChecked: false,
                                    isSelected: false,
                                    icon: "regression",
                                    iconColor: ["", "#ECFDFF", "#F69489", "#0a53a5"]
                                })
                                recommandedAlgoType = recommandedAlgoType.concat('Regression')
                            } else {
                                fastAlgoTypeMap['Regression'] = null
                            }
                        }
                        if (timeSeries) {
                            if (fastAlgoTypeMap['Regression(Time_Series)'] !== undefined && fastAlgoTypeMap['Regression(Time_Series)'] !== null && fastAlgoTypeMap['Regression(Time_Series)'] !== "") {
                                if (!foundEnumOutput) {
                                    fastAlgoTypeList = fastAlgoTypeList.concat({
                                        type: 'Regression(Time_Series)',
                                        isChecked: false,
                                        isSelected: false,
                                        icon: "times_series",
                                        iconColor: ["", "", ""]
                                    })
                                    recommandedAlgoType = recommandedAlgoType.concat('Regression(Time_Series)')
                                } else {
                                    fastAlgoTypeMap['Regression(Time_Series)'] = null
                                }
                            }
                        }
                        // this.props.handleUpdate(fastAlgoTypeList, fastAlgoTypeMap, "", this.state.invalidWarning)
                        if (fastAlgoTypeList.length !== 0) {
                            fastAlgoTypeList[0].isChecked = true
                            algorithms = fastAlgoTypeList[0].type
                        } else {
                            this.setState({
                                defaultLoadingPopup: false,
                                defaultErrorPopup: true,
                                defaultErrorText: "There are no algorithms that can apply to your input/output data type."
                            })
                        }
                        this.setState({
                            fastAlgoTypeMap: fastAlgoTypeMap,
                            fastAlgoTypeList: fastAlgoTypeList,
                            recommandedAlgoType: recommandedAlgoType,
                            algorithms
                        })
                        // if (fastAlgoTypeList.length > 0) {
                        //     console.log('more than one =>')
                        //     renderSwitchMLPService("getMetricInfoWithAlgoTypeHM", '', "", "")
                        //         .then(metric => {
                        //             console.log("response for get algo metric info with algo =>", metric.data, fastAlgoTypeList);
                        //             if (metric.data.ResponseCode === 1000) {
                        //                 let metricInfoHM = metric.data["Results"];
                        //                 let filterTypeCount = 0;
                        //                 fastAlgoTypeList.map(list => {
                        //                         if (metricInfoHM[list.type] !== undefined) {
                        //                             let resultsModels =
                        //                                 metricInfoHM[list.type].map((mInfo) =>
                        //                                     (
                        //                                         {
                        //                                             ...mInfo,
                        //                                             Type: 'percentage',
                        //                                             disabledEditableMetric: false,
                        //                                             editableValue: list.type === "Classification" || list.type === 'Classification(Time_Series)' ?
                        //                                                 90
                        //                                                 :
                        //                                                 list.type === "Regression" || list.type === 'Regression(Time_Series)' || list.type === 'Time_Series' ?
                        //                                                     mInfo["MatrixName"] === 'R2Score' ?
                        //                                                         90 : 0.1
                        //                                                     :
                        //                                                     list.type === "Clustering" ?
                        //                                                         90
                        //                                                         :
                        //                                                         90,
                        //                                             invalidAccuracy: "",
                        //                                             isCheckedMetric: list.type === "Classification" || list.type === 'Classification(Time_Series)' ?
                        //                                                 mInfo["MatrixName"] === 'Accuracy'
                        //                                                 :
                        //                                                 list.type === "Regression" || list.type === 'Regression(Time_Series)' || list.type === 'Time_Series' ?
                        //                                                     mInfo["MatrixName"] === 'R2Score'
                        //                                                     :
                        //                                                     list.type === "Clustering" ?
                        //                                                         mInfo["MatrixName"] === 'Silhouette'
                        //                                                         :
                        //                                                         false,
                        //                                             defaultCheckedMetric: list.type === "Classification" || list.type === 'Classification(Time_Series)' ?
                        //                                                 mInfo["MatrixName"] === 'Accuracy'
                        //                                                 :
                        //                                                 list.type === "Regression" || list.type === 'Regression(Time_Series)' || list.type === 'Time_Series' ?
                        //                                                     mInfo["MatrixName"] === 'R2Score'
                        //                                                     :
                        //                                                     list.type === "Clustering" ?
                        //                                                         mInfo["MatrixName"] === 'Silhouette'
                        //                                                         :
                        //                                                         false
                        //
                        //                                         }
                        //                                     ))
                        //                             let modelCount = 0
                        //                             let convertedResults = []
                        //                             let needShiftIndex = 0
                        //                             JSON.parse(JSON.stringify(resultsModels)).map((result, resultIndex) => {
                        //                                     if (result["MatrixName"] === 'Accuracy' || result["MatrixName"] === 'R2Score' || result["MatrixName"] === 'Silhouette') {
                        //                                         needShiftIndex = resultIndex
                        //                                     }
                        //                                     if (modelCount === resultsModels.length - 1) {
                        //                                         // convertedResults = insertAndShift(JSON.parse(JSON.stringify(resultsModels)), needShiftIndex, 0);
                        //                                         metricInfoHM[list.type] = convertedResults
                        //                                     }
                        //                                     modelCount++;
                        //                                 }
                        //                             )
                        //                         }
                        //                         if (filterTypeCount === fastAlgoTypeList.length - 1) {
                        //                             console.log('metricInfoHM =>', metricInfoHM, fastAlgoTypeMap, fastAlgoTypeList)
                        //                             let algoTypeArr = Object.keys(fastAlgoTypeMap)
                        //                             let algorithms = ""
                        //                             let foundDefault = false
                        //                             algoTypeArr.map(arr => {
                        //                                 if (fastAlgoTypeMap[arr] !== undefined && fastAlgoTypeMap[arr] !== null) {
                        //                                     if (!foundDefault) {
                        //                                         algorithms = arr
                        //                                         foundDefault = true
                        //                                     }
                        //                                 }
                        //
                        //                             })
                        //                             this.setState({
                        //                                 algoTypeArr,
                        //                                 algorithms,
                        //                                 retrieveLoading: false,
                        //                                 metricInfoHM
                        //                             })
                        //                             this.props.handleUpdate(fastAlgoTypeList, fastAlgoTypeMap, metricInfoHM, this.state.invalidWarning)
                        //                         }
                        //                         filterTypeCount++;
                        //                     }
                        //                 )
                        //
                        //             }
                        //         })
                        // } else {
                        //     console.log('recommanded is one =>')
                        //     this.setState({
                        //         retrieveLoading: false
                        //     })
                        // }

                    } else {
                        console.log(">> algo type map response error case")
                        this.setState({
                            defaultErrorPopup: true,
                            defaultErrorText: response.data.ResponseMessage,
                            retrieveLoading: false
                        })
                    }
                } else {
                    this.setState({
                        defaultErrorPopup: true,
                        defaultErrorText: 'Connection Lost!',
                        retrieveLoading: false
                    })
                }
            }).catch(() => {
            this.setState({
                defaultErrorPopup: true,
                defaultErrorText: 'Connection Lost!',
                retrieveLoading: false
            })
        })
    }

    checkDisableContinueBtn = (algorithmType, columnWithDataTypeInfo) => {
        let disabled = false
        if (algorithmType !== "Clustering") {
            disabled = true
            for (let index = 0; index < columnWithDataTypeInfo.length; index++) {
                if (columnWithDataTypeInfo[index].isCheckedForOutput === true) {
                    disabled = false
                    break
                }
            }
        }

        if (!disabled){
            //check for input
            let foundInput = false
            for (let index = 0; index < columnWithDataTypeInfo.length; index++) {
                if (columnWithDataTypeInfo[index].isCheckedForInput === true) {
                    foundInput = true
                    break
                }
            }
            if (foundInput !== true){
                disabled = true
            }
        }
        return disabled
    }

    handleCheckedAlgoType = (algorithmTypeInfo, index) => {
        let algorithmTypeList = this.state.algorithmTypeList
        algorithmTypeList.map(eachAlgo => {
            eachAlgo.checked = false
        })
        algorithmTypeList[index].checked = true
        let columnWithDataTypeInfo = this.state.columnWithDataTypeInfo
        if (algorithmTypeInfo.value === "Clustering") {
            columnWithDataTypeInfo.map((eachFeature => {
                eachFeature.isCheckedForOutput = false
            }))
        } else if (algorithmTypeInfo.value === "Regression" || algorithmTypeInfo.value === "Regression(Time_Series)") {
            columnWithDataTypeInfo.map((eachFeature => {
                if (eachFeature.isCheckedForOutput === true && eachFeature.OriginalDataType === "enum") {
                    eachFeature.isCheckedForOutput = false
                }
            }))
        } else if (algorithmTypeInfo.value === "Classification") {
            //check if there is at least one output or not
        }
        this.setState({
            algorithmTypeList,
            selectedAlgorithmType: algorithmTypeInfo.value,
            columnWithDataTypeInfo,
            disabledContinueBtn: this.checkDisableContinueBtn(algorithmTypeInfo.value, columnWithDataTypeInfo)
        })
    }

    handleCheckForInputOutputSelector = (key, columnInfo, index) => {
        let columnWithDataTypeInfo = this.state.columnWithDataTypeInfo
        if (key === "input") {
            columnInfo.isCheckedForInput = !columnInfo.isCheckedForInput
            if (columnInfo.isCheckedForInput === true) {
                columnInfo.isCheckedForOutput = false
            }
        } else {
            columnInfo.isCheckedForOutput = !columnInfo.isCheckedForOutput
            if (columnInfo.isCheckedForOutput === true) {
                columnWithDataTypeInfo.map(eachColumn => {
                    if (eachColumn.isCheckedForOutput === true) {
                        eachColumn.isCheckedForOutput = false
                        eachColumn.isCheckedForInput = true
                    }
                })
                columnInfo.isCheckedForInput = false
                columnInfo.isCheckedForOutput = true
            }
        }

        columnWithDataTypeInfo[index] = columnInfo
        this.setState({
            columnWithDataTypeInfo,
            disabledContinueBtn: this.checkDisableContinueBtn(this.state.selectedAlgorithmType, columnWithDataTypeInfo)
        })
    }

    handlePrepareDataColumnsAndAlgorithmList = (columnInfoWithDataType, enumColumnList, timeSeriesColumnList) => {
        //check if enum data type is included or not
        console.log("handlePrepareDataColumnsAndAlgorithmList : ", columnInfoWithDataType)
        let includeEnum = false
        let timeSeriesIncluded = false
        // let timeSeriesColumnList = this.state.timeSeriesColumnList
        // let enumColumnList = this.state.enumColumnList
        if (enumColumnList.length > 0) {
            includeEnum = true
        }
        if (timeSeriesColumnList.length > 0) {
            timeSeriesIncluded = true
        }

        let selectedAlgorithmType = ""
        let definedOutput = false
        columnInfoWithDataType.map(eachColumnInfo => {
            if (eachColumnInfo.OriginalDataType === "date" || eachColumnInfo.OriginalDataType === "string") {
                eachColumnInfo.disabled = true
            }
            if (includeEnum && timeSeriesIncluded) {
                selectedAlgorithmType = "Classification(Time_Series)"
                if (eachColumnInfo.OriginalDataType !== "date") {
                    if (eachColumnInfo.OriginalDataType !== "enum" && eachColumnInfo.OriginalDataType !== "string") {
                        eachColumnInfo.isCheckedForInput = true
                    } else {
                        if (definedOutput === false) {
                            eachColumnInfo.isCheckedForOutput = true
                            definedOutput = true
                        } else {
                            eachColumnInfo.isCheckedForInput = true
                        }
                    }
                }
            } else if (includeEnum) {
                selectedAlgorithmType = "Classification"
                if (eachColumnInfo.OriginalDataType !== "enum") {
                    if (eachColumnInfo.OriginalDataType !== "string") {
                        eachColumnInfo.isCheckedForInput = true
                    }
                } else {
                    if (definedOutput === false) {
                        eachColumnInfo.isCheckedForOutput = true
                        definedOutput = true
                    } else {
                        eachColumnInfo.isCheckedForInput = true
                    }
                }
            } else if (timeSeriesIncluded) {
                selectedAlgorithmType = "Regression(Time_Series)"
                if (eachColumnInfo.OriginalDataType !== "date" && eachColumnInfo.OriginalDataType !== "string") {
                    if (eachColumnInfo.OriginalDataType !== "string") {
                        if (definedOutput === false) {
                            eachColumnInfo.isCheckedForOutput = true
                            definedOutput = true
                        } else {
                            eachColumnInfo.isCheckedForInput = true
                        }
                    }
                }
            } else {
                selectedAlgorithmType = "Regression"
                if (eachColumnInfo.OriginalDataType !== "string") {
                    if (definedOutput === false) {
                        eachColumnInfo.isCheckedForOutput = true
                        definedOutput = true
                    } else {
                        eachColumnInfo.isCheckedForInput = true
                    }
                }
            }
        })

        return {
            selectedAlgorithmType,
            columnInfoWithDataType,
            includeEnum,
            timeSeriesIncluded
        }
    }

    modifyAlgorithmList = (algorithmTypeInfoList, selectedAlgorithm, enumIncluded, timeSeriesIncluded) => {
        algorithmTypeInfoList.map(eachAlgorithmInfo => {
            eachAlgorithmInfo.checked = eachAlgorithmInfo.value === selectedAlgorithm;
            if (eachAlgorithmInfo.value.includes("Time_Series")) {
                eachAlgorithmInfo.disabled = timeSeriesIncluded !== true;
            }
        })
        return algorithmTypeInfoList
    }

    handleClickContinueForModelConfig = () => {
        if (this.state.viewCase === true) {
            this.handleForwardStep()
            return
        }
        this.setState({
            modelStatus: "",
            processingDataLoading: true,
            defaultLoadingText: "Initializing"
        })
        let timeSeries = this.state.timeSeries
        let selectedFeatureList = this.state.columnWithDataTypeInfo
        //call filter algorithm specific
        let selectedAlgorithmType = this.state.selectedAlgorithmType
        let InputFeatureDataTypes = []
        let OutputFeatureDataTypes = []
        let outputFeatureList = []
        let inputFeatureList = []
        let foundEnumOutput = false
        let mlDataSetList = []
        if (selectedFeatureList !== undefined && selectedFeatureList !== null && selectedFeatureList !== "" && selectedFeatureList.length !== 0) {
            selectedFeatureList.map(eachFeatureInfo => {
                let dataType = eachFeatureInfo["ColumnDataType"].toLowerCase()
                if (eachFeatureInfo["isCheckedForOutput"] === true) {
                    if (!this.checkDisabled(eachFeatureInfo, true)) {
                        if (eachFeatureInfo["OriginalDataType"] === 'enum') {
                            foundEnumOutput = true
                        }
                        eachFeatureInfo.Key = "output"
                        OutputFeatureDataTypes = OutputFeatureDataTypes.concat(dataType === 'double' ? 'float' : dataType)
                        outputFeatureList = outputFeatureList.concat(eachFeatureInfo)
                    }
                } else if (eachFeatureInfo["isCheckedForInput"] === true) {
                    if (!this.checkDisabled(eachFeatureInfo, false)) {
                        eachFeatureInfo.Key = "input"
                        InputFeatureDataTypes = InputFeatureDataTypes.concat(dataType === 'double' ? 'float' : dataType)
                        inputFeatureList = inputFeatureList.concat(eachFeatureInfo)
                    }
                }
            })
        }

        mlDataSetList = inputFeatureList.concat(outputFeatureList)

        let postData = {
            "InputFeatureDataTypes": InputFeatureDataTypes,
            "OutputFeatureDataTypes": OutputFeatureDataTypes,
            "AlgoCount": 1,
            "TenantID": this.state.TenantID,
            "UserID": this.state.UserID,
            "MultiOutput": OutputFeatureDataTypes.length > 1
        }
        console.log('post data =>', postData)
        let fastAlgoTypeList = []
        renderSwitchMLPService("GetFilteredAlgorithmSpecific", postData, "", "")
            .then(response => {
                console.log("get algo type map : ", response.data)
                if (response.data !== "") {
                    if (response.data["ResponseCode"] === 1000) {
                        let selectedAlgorithmInfo = {}
                        console.log('response code is 1000 =>')
                        let fastAlgoTypeMap = response.data["Results"]
                        if (checkValidity(fastAlgoTypeMap[selectedAlgorithmType])) {
                            if (fastAlgoTypeMap[selectedAlgorithmType].length > 0) {
                                let algorithmList = fastAlgoTypeMap[selectedAlgorithmType]
                                selectedAlgorithmInfo = algorithmList[0]
                                fastAlgoTypeList = fastAlgoTypeList.concat(selectedAlgorithmType)
                            }
                        } else {
                            let foundAlgo = false
                            if (!OutputFeatureDataTypes.includes('float') && OutputFeatureDataTypes.length > 0) {
                                if (checkValidity(fastAlgoTypeMap['Classification']) && fastAlgoTypeMap['Classification'].length > 0) {
                                    selectedAlgorithmInfo = fastAlgoTypeMap['Classification'][0]
                                    foundAlgo = true
                                    fastAlgoTypeList = fastAlgoTypeList.concat("Classification")
                                    selectedAlgorithmType = "Classification"
                                }
                                if (timeSeries) {
                                    if (checkValidity(fastAlgoTypeMap['Classification(Time_Series)']) && fastAlgoTypeMap['Classification(Time_Series)'].length > 0) {
                                        selectedAlgorithmInfo = fastAlgoTypeMap['Classification(Time_Series)'][0]
                                        foundAlgo = true
                                        fastAlgoTypeList = fastAlgoTypeList.concat("Classification(Time_Series)")
                                        selectedAlgorithmType = "Classification(Time_Series)"
                                    }
                                }
                            }
                            if (!foundAlgo) {
                                if (checkValidity(fastAlgoTypeMap['Clustering']) && fastAlgoTypeMap['Clustering'].length > 0) {
                                    selectedAlgorithmInfo = fastAlgoTypeMap['Clustering'][0]
                                    foundAlgo = true
                                    fastAlgoTypeList = fastAlgoTypeList.concat("Clustering")
                                    selectedAlgorithmType = "Classification"
                                }
                            }
                            if (!foundAlgo) {
                                if (checkValidity(fastAlgoTypeMap['Regression']) && fastAlgoTypeMap['Regression'].length > 0) {
                                    selectedAlgorithmInfo = fastAlgoTypeMap['Regression'][0]
                                    fastAlgoTypeList = fastAlgoTypeList.concat("Regression")
                                    foundAlgo = true
                                    selectedAlgorithmType = "Regression"
                                } else if (timeSeries && checkValidity(fastAlgoTypeMap['Regression(Time_Series)']) && fastAlgoTypeMap['Regression(Time_Series)'].length > 0) {
                                    selectedAlgorithmInfo = fastAlgoTypeMap['Regression(Time_Series)'][0]
                                    fastAlgoTypeList = fastAlgoTypeList.concat("Regression(Time_Series)")
                                    foundAlgo = true
                                    selectedAlgorithmType = "Regression(Time_Series)"
                                }
                            }

                            if (!foundAlgo) {
                                console.log("didn't inf any applicable algorithms")
                                this.setState({
                                    viewCase: false,
                                    processingDataLoading: false,
                                    defaultLoadingPopup: false,
                                    defaultErrorTitle: "Incompatible Features with selected algorithm",
                                    defaultErrorPopup: true,
                                    defaultErrorText: "There are no algorithms that can apply to your input/output data type."
                                })
                                return
                            }
                        }

                        // this.props.handleUpdate(fastAlgoTypeList, fastAlgoTypeMap, "", this.state.invalidWarning)
                        if (checkValidity(selectedAlgorithmInfo)) {
                            this.setState({
                                selectedAlgorithmInfo,
                                selectedAlgorithmType: selectedAlgorithmType,
                            })
                            this.getNumberOfClass(selectedAlgorithmType, selectedAlgorithmInfo, outputFeatureList, InputFeatureDataTypes.length, mlDataSetList)
                        } else {
                            this.setState({
                                viewCase: false,
                                defaultLoadingPopup: false,
                                defaultErrorTitle: "Incompatible Features with selected algorithm",
                                defaultErrorPopup: true,
                                defaultErrorText: "There are no algorithms that can apply to your input/output data type."
                            })
                        }
                    } else {
                        console.log("algo type map response error case")
                        this.setState({
                            viewCase: false,
                            processingDataLoading: false,
                            defaultErrorPopup: true,
                            defaultErrorTitle: "Request Error",
                            defaultErrorText: response.data.ResponseMessage,
                            retrieveLoading: false
                        })
                    }
                } else {
                    this.setState({
                        viewCase: false,
                        processingDataLoading: false,
                        defaultErrorPopup: true,
                        defaultErrorTitle: "Request Error",
                        defaultErrorText: 'Connection Lost!',
                        retrieveLoading: false
                    })
                }
            }).catch(() => {
            this.setState({
                viewCase: false,
                processingDataLoading: false,
                defaultErrorPopup: true,
                defaultErrorTitle: "Request Error",
                defaultErrorText: 'Connection Lost!',
                retrieveLoading: false
            })
        })
    }

    handleGoBackToBeforeStep = () => {
        let activeStep = this.state.activeStep
        let currentStep = this.state.currentStep
        let processStatus = this.state.processStatus

        if (activeStep === 1) {
            if (processStatus === "process") {
                processStatus = "config"
            } else {
                activeStep = 0
                currentStep = "Data Ingestion"
            }
        } else if (activeStep === 2) {
            activeStep = 1
            currentStep = "Model Configuration and Creation"
            processStatus = "process"
        }

        if (activeStep !== 0) {
            this.setState({
                viewCase: true,
                activeStep,
                currentStep,
                processStatus
            })
        } else {
            this.setState({
                // viewCase: true,
                activeTab: "columns",
                activeStep,
                currentStep,
                processStatus
            })
        }
    }

    handleForwardStep = () => {
        let activeStep = this.state.activeStep
        let currentStep = this.state.currentStep
        let processStatus = this.state.processStatus
        if (activeStep === 1) {
            if (processStatus === "config") {
                processStatus = "process"
            } else {
                activeStep = 2
                currentStep = "Model Test Results"
            }
        } else if (activeStep === 0) {
            activeStep = 1
            currentStep = "Model Configuration and Creation"
            processStatus = "config"
        }

        this.setState({
            activeStep,
            currentStep,
            processStatus
        })
    }

    //get number of class
    getNumberOfClass = (selectedAlgorithmType, selectedAlgorithmInfo, outputFeatureList, inputCount, mlDataSetInfo) => {
        console.log("entered to getNumberOfClass : ", selectedAlgorithmType, outputFeatureList)
        if (selectedAlgorithmType === "Classification" || selectedAlgorithmType === "Classification(Time_Series)") {
            let outputWithNoOfClassHashmap = {}
            let outputCountArray = []
            outputFeatureList.map((currentOutputInfo) => {
                let inputClassInfo = {
                    SourceID: this.state.userInfo,
                    SourceName: "file",
                    ColumnName: currentOutputInfo.ColumnName,
                    DataType: currentOutputInfo.DataType,
                    TableName: this.state.uploadedFileInfo.ID,
                }
                renderSwitchDipService('getNumberOfClassByColumn', inputClassInfo, '', '').then(
                    resp => {
                        console.log("get categorical data list", resp.data);
                        if (resp.data['ResponseCode'] === 1000) {
                            let outputClassNumberInfo = resp.data['Results'];
                            let maxValue = parseInt(outputClassNumberInfo["MaxNumber"]) + 1;
                            outputWithNoOfClassHashmap[currentOutputInfo.ColumnName] = maxValue
                            outputCountArray = outputCountArray.concat(maxValue)

                            let numberOfClassStr = ""
                            if (outputCountArray.length === outputFeatureList.length) {
                                console.log("output count hash map : ", outputWithNoOfClassHashmap)
                                let outputArrays = []
                                outputFeatureList.map(
                                    outInfo => {
                                        if (outputWithNoOfClassHashmap[outInfo.ColumnName] !== undefined) {
                                            outputArrays = outputArrays.concat(outputWithNoOfClassHashmap[outInfo.ColumnName])
                                        }
                                    }
                                )
                                numberOfClassStr = outputArrays.join(",")
                                this.prepareModelConfigurationInfo(selectedAlgorithmType, selectedAlgorithmInfo, inputCount, numberOfClassStr, mlDataSetInfo)
                            }
                        } else {
                            this.setState({
                                viewCase: false,
                                processingDataLoading: false,
                                defaultErrorPopup: true,
                                defaultErrorTitle: "Warning",
                                defaultErrorText: "There must be class values of the output column for the algorithm(s).",
                                defaultLoadingPopup: false,
                            })
                        }
                    });
            })
        } else {
            this.prepareModelConfigurationInfo(selectedAlgorithmType, selectedAlgorithmInfo, inputCount, "", mlDataSetInfo)
        }
    }

    //prepare training parameters
    prepareModelConfigurationInfo = (selectedAlgorithmType, selectedAlgorithmInfo, inputCount, numberOfClass, mlDataSetInfo) => {
        console.log("entered to prepareModelConfigurationInfo :: ", selectedAlgorithmInfo)
        let weightRowCount = inputCount
        let trainingParameters = selectedAlgorithmInfo.TrainingParameters
        let convertedTrainingParameters = []
        let hyperParameterInfo = []
        trainingParameters.map(eachParameter => {
            //for layer info
            let layerInfo = []
            let nestedInfo = []
            if (checkValidity(eachParameter["LayerInfo"])) {

                for (const [layerKey, layerVal] of Object.entries(eachParameter["LayerInfo"])) {
                    if (layerVal["SameValueStatus"]) {
                        let splitArr = layerVal["DefaultValue"].split(",")
                        if (splitArr.length > 0) {
                            layerInfo.push({
                                Key: layerKey,
                                Value: splitArr[0]
                            })
                        }
                    } else {
                        if (checkValidity(layerVal["DefaultValue"])) {
                            layerInfo.push({
                                Key: layerKey,
                                Value: layerVal["DefaultValue"]
                            })
                        }
                    }
                }
            }

            if (checkValidity(eachParameter["NestedInfo"] && eachParameter["NestedInfo"].length > 0)) {
                eachParameter["NestedInfo"].map(info => {
                    nestedInfo.push({
                        Name: info.Name,
                        DefaultValue: info.DefaultValue,
                        PossibleValues: info.PossibleValues,
                        StartValueRange: info.StartValueRange,
                        EndValueRange: info.EndValueRange
                    })
                })
            }

            if (eachParameter.Name === "weight_row") {
                convertedTrainingParameters.push({
                    Key: eachParameter.Name,
                    Value: weightRowCount.toString(),
                    PossibleValues: "",
                    StartValueRange: 0,
                    EndValueRange: 0,
                })

                hyperParameterInfo.push({
                    HyperParameterKey: eachParameter.Name,
                    HyperParameterValue: weightRowCount.toString(),
                    StartRangeValue: 0,
                    EndRangeValue: 0,
                })
            } else if (eachParameter.Name === "number_of_class_label") {
                convertedTrainingParameters.push({
                    Key: eachParameter.Name,
                    Value: numberOfClass,
                    PossibleValues: "",
                    StartValueRange: 0,
                    EndValueRange: 0,
                })

                hyperParameterInfo.push({
                    HyperParameterKey: eachParameter.Name,
                    HyperParameterValue: numberOfClass,
                    StartRangeValue: 0,
                    EndRangeValue: 0,
                })
            } else {
                let parameterInfo = {
                    Key: eachParameter.Name,
                    Value: eachParameter.DefaultValue,
                    PossibleValues: eachParameter.PossibleValues,
                    StartValueRange: eachParameter.StartValueRange,
                    EndValueRange: eachParameter.EndValueRange,
                    LayerInfo: layerInfo,
                    NestedInfo: nestedInfo,
                }

                if (parameterInfo.Value === "") {
                    let possibleValueArr = eachParameter.PossibleValues.split(",")
                    if (possibleValueArr.length > 0) {
                        parameterInfo.Value = possibleValueArr[0]
                    }
                }

                convertedTrainingParameters.push(parameterInfo)
                hyperParameterInfo.push({
                    HyperParameterKey: parameterInfo.Key,
                    HyperParameterValue: parameterInfo.Value,
                    StartRangeValue: parameterInfo.StartValueRange,
                    EndRangeValue: parameterInfo.EndValueRange,
                })
            }
        })

        console.log("converted training parameters and hyper parameters : ", trainingParameters, hyperParameterInfo)

        let mlInfo = {
            MlID: uuidv4(),
            AlgorithmID: selectedAlgorithmInfo.ID,
            TrainingParameters: convertedTrainingParameters,
            DataSet: mlDataSetInfo,
        }
        //prepare training parameters
        let generatedModelID = uuidv4()
        let modelName = this.state.timeStamp + "_" + selectedAlgorithmInfo.DisplayName
        let modelInfo = {
            ID: generatedModelID,
            Name: generatedModelID,
            DisplayName: modelName,
            FrameworkID: "",
            AlgorithmName: selectedAlgorithmInfo["Algorithm"],
            AlgorithmID: selectedAlgorithmInfo.ID,
            MachineLearningID: mlInfo.MlID,
            ValidationSetPercent: "10",
            TrainingDataPercent: 0.8,
            UserInfo: this.state.userInfo,
        }

        //check to apply algorithm

        console.log("prepared machine learning info and Data : ", mlInfo, modelInfo, checkAlgo)
        let checkAlgo = [
            {
                MlID: mlInfo.MlID,
                AlgorithmID: mlInfo.AlgorithmID,
                TrainingParameters: mlInfo.TrainingParameters,
                DataSet: mlInfo.DataSet,
                ModelName: modelName,
                ModelVersion: "1.1.1",
                ApplicationID: "",
            }
        ]
        renderSwitchMLPService("checkAllAlgorithms", checkAlgo, "", "").then(res => {
            console.log("response of check algorithm : ", res.data)
            if (res.data === "") {
                this.showSystemError(true)
            } else {
                if (res.data["ResponseCode"] === 1000) {
                    let results = res.data["Results"]
                    if (results[0] === true) {
                        //save machine learning info
                        renderSwitchRoeService("createInstantMachineLearningInfo", mlInfo, "", "").then(response => {
                            console.log("response of create machine learning info : ", response.data)
                            if (response.data === "") {
                                this.showSystemError(true)
                                return
                            }
                            if (response.data["ResponseCode"] === 1000) {

                                renderSwitchMLPService("getFrameworkInfosByAlgorithmID", "", "?algorithmID=" + selectedAlgorithmInfo.ID, "").then(response => {
                                    console.log("framework infos : ", response.data)
                                    if (response.data["ResponseCode"] === 1000 && response.data["Results"] !== null) {
                                        let frameworkInfo = response.data["Results"]
                                        //prepare Data and create
                                        modelInfo.FrameworkID = frameworkInfo.ID
                                        modelInfo.DisplayName = this.state.timeStamp + "_" + selectedAlgorithmInfo.DisplayName + frameworkInfo.Name
                                        renderSwitchMLPService("createModelInfo", modelInfo, "", "")
                                            .then(response => {
                                                console.log("response of create Data : ", response.data)
                                                if (response.data === "") {
                                                    this.showSystemError(true)
                                                    return
                                                }
                                                if (response.data["ResponseCode"] === 1000) {
                                                    let modelList = this.state.modelList.concat(modelInfo)
                                                    this.setState({
                                                        modelList,
                                                        processingDataLoading: false,
                                                        currentModelInfo: modelInfo,
                                                        currentMlInfo: mlInfo,
                                                        processStatus: "process",
                                                        viewCase: true
                                                    })
                                                    let {manualScroll} = this.props;
                                                    manualScroll(400)
                                                    //change the state for model status, call auto ml status (to call to dOcean)
                                                    let inputData = {
                                                        TenantID: this.state.defaultTenantID,
                                                        UserInfo: this.state.userInfo
                                                    }
                                                    renderSwitchDOceanService("callAutoProcess", inputData, "", "").then(response => {
                                                        console.log("response of get auto process : ", response.data)
                                                        if (response.data["ResponseCode"] === 1000) {
                                                            this.setState({
                                                                processStatus: "process"
                                                            })
                                                        } else {
                                                            // this.showSystemError()
                                                        }
                                                    })
                                                        .catch(err => {
                                                            console.log("catch error in calling to do auto process : ", err)
                                                            // this.showSystemError()
                                                        })

                                                    this.modelStatusInterval = setInterval(() => {
                                                        console.log("calling model status interval")
                                                        this.getModelStatusByUserInfo()
                                                    }, 20000)
                                                } else {
                                                    this.showSystemError()
                                                }
                                            })
                                            .catch(err => {
                                                console.log("catch error in calling to save Data : ", err)
                                                this.showSystemError(true)
                                            })

                                    }
                                }).catch(err => {
                                    console.log("catch error in getFrameworksByAlgorithmID : ", err)
                                    this.showSystemError(true)
                                })
                            } else {
                                console.log("error in creating machine learning info ")
                                this.showSystemError(true)
                            }
                        })
                            .catch(err => {
                                console.log("catch error in calling to create instant machine learning info : ", err)
                                this.setState({
                                    viewCase: false,
                                    processingDataLoading: false,
                                    defaultErrorPopup: true,
                                    defaultErrorTitle: "Request Error",
                                    defaultErrorText: "Currently, we're unable to process, please try again later.",
                                    defaultLoadingPopup: false,
                                })
                            })
                    } else {
                        this.setState({
                            viewCase: false,
                            processingDataLoading: false,
                            defaultErrorPopup: true,
                            defaultErrorText: 'Your input/output properties are not match for the chosen algorithm type.',
                            defaultLoadingPopup: false,
                            defaultErrorTitle: "Warning",
                        })
                    }
                } else if (res.data["ResponseCode"] === 2055) {
                    this.setState({
                        viewCase: false,
                        processingDataLoading: false,
                        defaultErrorPopup: true,
                        defaultErrorText: 'Model name or version already existed!',
                        defaultLoadingPopup: false,
                        defaultErrorTitle: "Warning",
                    })

                } else if (res.data["ResponseCode"] === 2347) {
                    this.setState({
                        viewCase: false,
                        processingDataLoading: false,
                        defaultErrorPopup: true,
                        defaultErrorText: 'Number of Class value exceed the maximum number of class label.',
                        defaultLoadingPopup: false,
                        defaultErrorTitle: "Warning",
                    })

                } else if (res.data["ResponseCode"] === 2057) {
                    this.setState({
                        viewCase: false,
                        processingDataLoading: false,
                        defaultErrorPopup: true,
                        defaultErrorText: 'Weight row should be equal to the number of input.',
                        defaultLoadingPopup: false,
                        defaultErrorTitle: "Warning",
                    })
                } else {
                    this.setState({
                        viewCase: false,
                        processingDataLoading: false,
                        defaultErrorPopup: true,
                        defaultErrorText: res.data["Results"],
                        defaultLoadingPopup: false,
                        defaultErrorTitle: "Request Error",
                    })
                }
            }
        })
            .catch(err => {
                console.log("catch error in calling to check : ", err)
                this.showSystemError(true)
            })
    }

    showSystemError = (disableViewCase) => {
        this.setState({
            viewCase: disableViewCase !== undefined ? disableViewCase : this.state.viewCase,
            initialModelResultLoading: false,
            processingDataLoading: false,
            defaultErrorPopup: true,
            defaultErrorTitle: "Request Error",
            defaultErrorText: "Currently, we're unable to process, please try again later.",
            defaultLoadingPopup: false,
        })
    }

    getModelStatusByUserInfo = () => {
        let userInfo = this.state.userInfo
        let params = "?userInfo=" + userInfo
        //type InstantModelStatus struct {
        // 	UserInfo     string `gorm:"primary_key;not null;unique"`
        // 	Status       string
        // 	defaultErrorText string
        // }
        renderSwitchMLPService("getModelStatusByUserInfo", "", params, "").then(response => {
            console.log("response of model status : ", response.data)
            if (response.data !== "") {
                if (response.data["ResponseCode"] === 1000) {
                    let statusInfo = response.data["Results"]
                    if (checkValidity(statusInfo)) {
                        let status = statusInfo.Status
                        // training in progress
                        // testing in progress
                        // success
                        // training fail
                        // testing fail
                        let processStepList = this.state.processStepList
                        if (status === "success") {
                            clearInterval(this.modelStatusInterval)
                            processStepList.map(eachStepInfo => {
                                eachStepInfo.Status = "success"
                            })
                        } else if (status === "training fail" || status === "testing fail") {
                            clearInterval(this.modelStatusInterval)
                            if (status === "training fail") {
                                processStepList[0].Status = "success"
                                processStepList[1].Status = "fail"
                                processStepList[2].Status = "pending"
                                processStepList[3].Status = "pending"
                            } else if (status === "testing fail") {
                                processStepList[0].Status = "success"
                                processStepList[1].Status = "success"
                                processStepList[2].Status = "fail"
                                processStepList[3].Status = "pending"
                            }
                        } else if (status === "training in progress") {
                            processStepList[0].Status = "success"
                            processStepList[1].Status = "inprogress"
                            processStepList[2].Status = "pending"
                            processStepList[3].Status = "pending"
                        } else if (status === "testing in progress") {
                            processStepList[0].Status = "success"
                            processStepList[1].Status = "success"
                            processStepList[2].Status = "inprogress"
                            processStepList[3].Status = "pending"
                        }
                        if (status === "success") {
                            this.setState({
                                modelStatus: status,
                                viewCase: true,
                                processStepList,
                                showModelInfo: true,
                                initialModelResultLoading: true,
                                activeStep: 2,
                                currentStep: "Model Test Results",
                            }, () => {
                                //prepare for result page
                                this.prepareModelResultData(this.state.userInfo)
                            })
                        } else {
                            this.setState({
                                viewCase: true,
                                modelStatus: status,
                                processStepList,
                                showModelInfo: true
                            })
                        }
                    }
                }
            }
        })
            .catch(err => {
                console.log("catch error in calling to get model status", err)
            })
    }

    prepareModelResultData = (userInfo) => {
        //check for algorithm and show the performance metrics info
        console.log("prepareModelResultData ", userInfo)
        let currentModelInfo = this.state.currentModelInfo
        let mlInfo = this.state.currentMlInfo
        let algorithmInfo = this.state.selectedAlgorithmInfo
        let algorithmType = algorithmInfo["AlgorithmType"]
        let fileInfo = this.state.uploadedFileInfo
        console.log("uploaded file info :: ", fileInfo)
        //prepare model metrics values
        let inputData = [currentModelInfo.ID]
        renderSwitchRoeService("getConfusionMetricValue", inputData, "", "").then(response => {
            console.log("response of get metric info : ", response.data)
            if (response.data["ResponseCode"] === 1000) {
                let responseMap = response.data["Results"]
                if (checkValidity(responseMap)) {
                    let modelMetricInfo = responseMap[currentModelInfo.ID]
                    console.log("modelMetricInfo : ", modelMetricInfo)
                    //prepare confusionMetric, metric value
                    if (checkValidity(modelMetricInfo)) {
                        let metricValue = []
                        if (checkValidity(modelMetricInfo["MetricInfo"]) && CheckEmpty(modelMetricInfo["MetricInfo"])) {
                            metricValue = modelMetricInfo["MetricInfo"]
                            metricValue = getSortingDataByAlphabetically(metricValue, "MetricKey")
                        }
                        console.log("checking algo type : ", algorithmType)
                        //get performance metrics values
                        if (algorithmType === "Classification" || algorithmType === "Classification(Time_Series)") {
                            //prepare confusion metrics info
                            let confusionMetricValue = []
                            if (checkValidity(modelMetricInfo["ConfusionValue"]) && CheckEmpty(modelMetricInfo["ConfusionValue"]) && modelMetricInfo["ConfusionValue"].length > 0) {
                                confusionMetricValue = modelMetricInfo["ConfusionValue"][0]
                            }

                            let outputColumnInfo = ""
                            mlInfo.DataSet.map(eachFeature => {
                                if (eachFeature.Key === "output") {
                                    outputColumnInfo = eachFeature
                                }
                            })
                            if (checkValidity(outputColumnInfo)) {
                                //to retrieve all categorical columns
                                if (outputColumnInfo.OriginalDataType === "date") {
                                    let inputData = {
                                        SourceID: currentModelInfo.UserInfo,
                                        TableName: fileInfo.ID,
                                        ColumnName: outputColumnInfo.ColumnName,
                                        PageNumber: 0,
                                        PageSize: 0,
                                    }

                                    renderSwitchDipService("getCategoricalDataWithPagination", inputData, "", "").then(response => {
                                        console.log("response of get categorical data columns : ", response.data)
                                        let predictLabels = []
                                        if (response.data === "") {

                                        } else {
                                            if (response.data["ResponseCode"] === 1000) {
                                                let resultData = response.data["Results"]["Data"]
                                                if (checkValidity(resultData) && CheckEmpty(resultData)) {
                                                    resultData.map(eachCategorical => {
                                                        predictLabels.push(eachCategorical.CategoricalValue)
                                                    })
                                                }
                                            }
                                        }
                                        console.log("prepared predictLabels : ", predictLabels)
                                        this.setState({
                                            viewCase: true,
                                            initialModelResultLoading: false,
                                            modelMetricInfo,
                                            metricValue,
                                            confusionMetricValue,
                                            predictLabels,
                                            emptyModelResult: false,
                                            activeStep: 2,
                                            currentStep: "Model Test Results",
                                        })
                                    })
                                        .catch(err => {
                                            console.log("catch error in calling to get categorical values : ", err)
                                            this.setState({
                                                initialModelResultLoading: false,
                                                chosenResultTab: "Metrics",
                                                modelMetricInfo,
                                                metricValue,
                                                confusionMetricValue,
                                                emptyModelResult: false,
                                                activeStep: 2,
                                                currentStep: "Model Test Results",
                                            })
                                        })
                                } else {
                                    let dataInfo = fileInfo.DataInfo
                                    for (let index = 0; index < dataInfo.length; index++) {
                                        if (dataInfo[index].ColumnName === outputColumnInfo.ColumnName) {
                                            dataInfo[index].ColumnIndex = index
                                            outputColumnInfo = dataInfo[index]
                                            break
                                        }
                                    }
                                    console.log("uploadedFileInfo for confusion : ", fileInfo)
                                    let inputData = {
                                        FileType: "csv",
                                        FileName: fileInfo.FileName,
                                        Engine: "csv",
                                        SourceName: "file",
                                        OriginalDelimiter: checkValidity(fileInfo.OriginalDelimiter) ? fileInfo.OriginalDelimiter : fileInfo.Delimiter,
                                        Delimiter: fileInfo.Delimiter,
                                        FirstRowAsHeader: false,
                                        // DataInfo: fileInfo.DataInfo,
                                        TableName: fileInfo.FileName,
                                        ColumnName: outputColumnInfo.ColumnName,
                                        DataType: outputColumnInfo.DataType,
                                        ColumnDataList: [outputColumnInfo]
                                    }
                                    renderSwitchConnectorService("getCategoricalDataValues", inputData, "", "").then(response => {
                                        console.log("response of get categorical data columns : ", response.data)
                                        let predictLabels = []
                                        if (response.data === "") {

                                        } else {
                                            if (response.data["ResponseCode"] === 1000) {
                                                let resultData = response.data["Results"][outputColumnInfo.ColumnName]
                                                if (checkValidity(resultData) && checkValidity(resultData["CategoricalData"]) && CheckEmpty(resultData["CategoricalData"])) {
                                                    predictLabels = resultData["CategoricalData"]
                                                }
                                            }
                                        }
                                        console.log("prepared predictLabels : ", predictLabels)
                                        this.setState({
                                            viewCase: true,
                                            initialModelResultLoading: false,
                                            modelMetricInfo,
                                            metricValue,
                                            confusionMetricValue,
                                            predictLabels,
                                            emptyModelResult: false,
                                            activeStep: 2,
                                            currentStep: "Model Test Results",
                                        })
                                    })
                                        .catch(err => {
                                            console.log("catch error in calling to get categorical values : ", err)
                                            this.setState({
                                                initialModelResultLoading: false,
                                                chosenResultTab: "Metrics",
                                                modelMetricInfo,
                                                metricValue,
                                                confusionMetricValue,
                                                emptyModelResult: false,
                                                activeStep: 2,
                                                currentStep: "Model Test Results",
                                            })
                                        })
                                }
                            } else {
                                this.setState({
                                    initialModelResultLoading: false,
                                    chosenResultTab: "Metrics",
                                    modelMetricInfo,
                                    metricValue,
                                    confusionMetricValue,
                                    emptyModelResult: false,
                                    activeStep: 2,
                                    currentStep: "Model Test Results",
                                })
                            }
                        } else {
                            this.setState({
                                initialModelResultLoading: false,
                                chosenResultTab: "Metrics",
                                modelMetricInfo,
                                metricValue,
                                emptyModelResult: false,
                                activeStep: 2,
                                currentStep: "Model Test Results",
                            })
                        }
                    } else {
                        console.log("empty modelMetricInfo : ", modelMetricInfo)
                        this.setState({
                            chosenResultTab: "Metrics",
                            initialModelResultLoading: false,
                            emptyModelResult: true,
                            activeStep: 2,
                            currentStep: "Model Test Results",
                        })
                    }
                } else {
                    this.setState({
                        initialModelResultLoading: false,
                        emptyModelResult: true,
                        activeStep: 2,
                        currentStep: "Model Test Results",
                    })
                }
            } else {
                this.setState({
                    initialModelResultLoading: false,
                    emptyModelResult: true,
                    activeStep: 2,
                    currentStep: "Model Test Results",
                })
            }
        }).catch(err => {
            console.log("catch error in calling to get metric infos : ", err)
            this.showSystemError()
        })
    }

    /**----------------------------------------------------------**/
    handleCloseOpenPopup = (eventName) => {
        this.setState({
            [eventName]: false
        })
    }

    onClickStepHandler(value) {
        this.setState({
            activeStep: parseInt(value)
        })
    }

    downloadModel = (modelInfo) => {
        this.setState({ //for download in progress
            showDownloadInProgress: true,
        })

        let modelID = modelInfo.ID
        // let passingParams = modelID + "/" + modelID + ".onnx"
        // let downloadFile = modelID + ".onnx"
        let downloadFile = modelID + ".zip"
        let passingParams = "/" + downloadFile + "?modelID=" + modelID
        renderSwitchMLPService("downloadInstantModel", "", passingParams, "").then(response => {
            let blob = response.data;
            const url = window.URL.createObjectURL(
                new Blob([blob], {type: 'application/zip'}),
            );
            //,{ type: 'application/zip' }
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                downloadFile,
            );

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);

            this.setState({
                showDownloadInProgress: false
            })
        })
            .catch(error => {
                console.log("get error : ", error)
                this.setState({
                    showDownloadInProgress: false,
                    defaultErrorTitle: "Request Error",
                    defaultLoadingPopup: false,
                    defaultErrorPopup: true,
                    defaultErrorText: "The requested information is not available at the moment, please try later!",
                })
            });
    }

    viewModelResult = (modelInfo) => {
        //TODO to show loading
        //update the info for current Data, selected algorithm type, selected algorithm info
        let {manualScroll} = this.props;
        manualScroll(200)
        this.setState({
            activeTab: "columns",
            previewData: [],
            viewCase: true,
            chosenResultTab: "Metrics",
            modelListCase: false,
            showModelInfo: true,
            initialModelViewLoading: true
        })
        //check the status
        let userInfo = modelInfo.UserInfo
        let params = "?userInfo=" + userInfo
        let selectedAlgorithmInfo = this.state.algorithmInfoMap[modelInfo.AlgorithmID]
        renderSwitchMLPService("getModelStatusByUserInfo", "", params, "").then(response => {
            console.log("response of model status : ", response.data)
            if (response.data !== "") {
                if (response.data["ResponseCode"] === 1000) {
                    let statusInfo = response.data["Results"]
                    let viewCase = this.state.viewCase
                    if (checkValidity(statusInfo)) {
                        let status = statusInfo.Status
                        let processStepList = this.state.processStepList
                        if (status === "success") {
                            viewCase = true
                            processStepList.map(eachStepInfo => {
                                eachStepInfo.Status = "success"
                            })
                        } else if (status === "training fail" || status === "testing fail") {
                            clearInterval(this.modelStatusInterval)
                            viewCase = true
                            if (status === "training fail") {
                                processStepList[0].Status = "success"
                                processStepList[1].Status = "fail"
                                processStepList[2].Status = "pending"
                                processStepList[3].Status = "pending"
                            } else if (status === "testing fail") {
                                processStepList[0].Status = "success"
                                processStepList[1].Status = "success"
                                processStepList[2].Status = "fail"
                                processStepList[3].Status = "pending"
                            }
                        } else if (status === "training in progress") {
                            processStepList[0].Status = "success"
                            processStepList[1].Status = "inprogress"
                            processStepList[2].Status = "pending"
                            processStepList[3].Status = "pending"
                        } else if (status === "testing in progress") {
                            processStepList[0].Status = "success"
                            processStepList[1].Status = "success"
                            processStepList[2].Status = "inprogress"
                            processStepList[3].Status = "pending"
                        }

                        let splitArr = userInfo.split("_")
                        let timeStamp = ""
                        if (splitArr.length > 0) {
                            timeStamp = splitArr[splitArr.length - 1]
                        }

                        //get for ml info
                        let params = "?mlID=" + modelInfo.MachineLearningID
                        renderSwitchRoeService("getInstantMachineLearningInfo", "", params, "").then(response => {
                            console.log("response of get instant ml info : ", response.data)
                            if (response.data["ResponseCode"] === 1000) {
                                let mlInfo = response.data["Results"]
                                let params = "?userInfo=" + userInfo

                                renderSwitchDipService("getFileSourceInfo", "", params, "").then(response => {
                                    console.log('response of source info : ', response.data)
                                    if (response.data !== "" && response.data["ResponseCode"] === 1000) {
                                        let fileSourceInfo = response.data["Results"]
                                        if (checkValidity(fileSourceInfo)) {
                                            let uploadedFileInfo = fileSourceInfo
                                            uploadedFileInfo.ID = fileSourceInfo.FileName
                                            let columnWithDataTypeInfo = this.prepareColumnDataFromMlDataSet(JSON.parse(JSON.stringify(fileSourceInfo.DataInfo)), mlInfo.DataSet)
                                            if (status === "success") {
                                                this.setState({
                                                    modelStatus: status,
                                                    viewCase,
                                                    columnWithDataTypeInfo,
                                                    uploadedFileInfo,
                                                    modelListCase: false,
                                                    currentMlInfo: mlInfo,
                                                    selectedAlgorithmInfo,
                                                    selectedAlgorithmType: selectedAlgorithmInfo.AlgorithmType,
                                                    currentModelInfo: modelInfo,
                                                    initialModelViewLoading: false,
                                                    // activeStep: 1,
                                                    // currentStep: "Model Configuration and Creation",
                                                    processStatus: "process",
                                                    processStepList,
                                                    showModelInfo: true,
                                                    userInfo: modelInfo.UserInfo,
                                                    timeStamp,
                                                    initialModelResultLoading: true,
                                                    activeStep: 2,
                                                    currentStep: "Model Test Results",
                                                }, () => {
                                                    //prepare for result page
                                                    this.prepareModelResultData(modelInfo.UserInfo)
                                                })
                                            } else {
                                                this.setState({
                                                    modelStatus: status,
                                                    uploadedFileInfo,
                                                    viewCase,
                                                    columnWithDataTypeInfo,
                                                    modelListCase: false,
                                                    currentModelInfo: modelInfo,
                                                    currentMlInfo: mlInfo,
                                                    selectedAlgorithmInfo,
                                                    selectedAlgorithmType: selectedAlgorithmInfo.AlgorithmType,
                                                    activeStep: 1,
                                                    currentStep: "Model Configuration and Creation",
                                                    processStatus: "process",
                                                    processStepList,
                                                    showModelInfo: true,
                                                    userInfo: modelInfo.UserInfo,
                                                    timeStamp,
                                                    initialModelViewLoading: false,
                                                }, () => {
                                                    if (status.includes("in progress")) {
                                                        this.modelStatusInterval = setInterval(() => {
                                                            console.log("calling model status interval in view case")
                                                            this.getModelStatusByUserInfo()
                                                        }, 20000)
                                                    }
                                                })
                                            }
                                        } else {
                                            this.setState({
                                                viewCase,
                                                modelStatus: status,
                                                modelListCase: true,
                                                initialModelViewLoading: false,
                                                defaultErrorPopup: true,
                                                defaultErrorTitle: "Request Error",
                                                defaultErrorText: "Currently, we're unable to process, please try again later.",
                                                defaultLoadingPopup: false,
                                            })
                                        }
                                    } else {
                                        this.setState({
                                            viewCase,
                                            modelStatus: status,
                                            modelListCase: true,
                                            initialModelViewLoading: false,
                                            defaultErrorPopup: true,
                                            defaultErrorTitle: "Request Error",
                                            defaultErrorText: "Currently, we're unable to process, please try again later.",
                                            defaultLoadingPopup: false,
                                        })
                                    }
                                })
                                    .catch(() => {
                                        this.setState({
                                            viewCase,
                                            modelStatus: status,
                                            modelListCase: true,
                                            initialModelViewLoading: false,
                                            defaultErrorPopup: true,
                                            defaultErrorTitle: "Request Error",
                                            defaultErrorText: "Currently, we're unable to process, please try again later.",
                                            defaultLoadingPopup: false,
                                        })
                                    })
                            } else {
                                this.setState({
                                    viewCase,
                                    modelStatus: status,
                                    modelListCase: true,
                                    initialModelViewLoading: false,
                                    defaultErrorPopup: true,
                                    defaultErrorTitle: "Request Error",
                                    defaultErrorText: "Currently, we're unable to process, please try again later.",
                                    defaultLoadingPopup: false,
                                })
                            }
                        })
                            .catch(err => {
                                console.log("catch error in calling to get instant machine learning info : ", err)
                                this.setState({
                                    modelStatus: status,
                                    modelListCase: true,
                                    initialModelViewLoading: false,
                                    defaultErrorPopup: true,
                                    defaultErrorTitle: "Request Error",
                                    defaultErrorText: "Currently, we're unable to process, please try again later.",
                                    defaultLoadingPopup: false,
                                })
                            })
                    } else {
                        this.setState({
                            modelListCase: true,
                            initialModelViewLoading: false,
                            defaultErrorPopup: true,
                            defaultErrorTitle: "Request Error",
                            defaultErrorText: "Currently, we're unable to process, please try again later.",
                            defaultLoadingPopup: false,
                        })
                    }
                } else {
                    this.setState({
                        modelListCase: true,
                        initialModelViewLoading: false,
                        defaultErrorPopup: true,
                        defaultErrorTitle: "System Error",
                        defaultErrorText: "You can\'t connect to your server at the moment. Please try again later.",
                        defaultLoadingPopup: false,
                    })
                }
            }
        })
            .catch(err => {
                console.log("catch error in calling to get model status", err)
                this.setState({
                    modelListCase: true,
                    initialModelViewLoading: false,
                    defaultErrorPopup: true,
                    defaultErrorTitle: "Request Error",
                    defaultErrorText: "Currently, we're unable to process, please try again later.",
                    defaultLoadingPopup: false,
                })
            })
    }

    prepareColumnDataFromMlDataSet = (dataInfo, mlDataInfo) => {
        console.log("prepareColumnDataFromMlDataSet :: ", dataInfo, ", ", mlDataInfo)
        let keyInfoMap = {}
        if (checkValidity(dataInfo) && checkValidity(mlDataInfo)) {
            mlDataInfo.map(eachColumnInfo => {
                keyInfoMap[eachColumnInfo.ColumnName] = eachColumnInfo
            })
        }
        let columnWithDatatype = getSortingDataByAlphabetically(dataInfo, "ColumnName")
        columnWithDatatype.map(eachColumn => {
            if (keyInfoMap[eachColumn.ColumnName] !== undefined) {
                let mlColumn = keyInfoMap[eachColumn.ColumnName]
                eachColumn.Key = mlColumn.Key
                if (mlColumn.Key === "input") {
                    eachColumn.isCheckedForInput = true
                    eachColumn.isCheckedForOutput = false
                } else {
                    eachColumn.isCheckedForInput = false
                    eachColumn.isCheckedForOutput = true
                }
            } else {
                eachColumn.isCheckedForInput = false
                eachColumn.isCheckedForOutput = false
            }
            eachColumn.disabled = true
        })
        console.log("returned columnWithDatatype :: ", columnWithDatatype)
        return columnWithDatatype
    }

    handleClickForCreateApp = () => {
        goToProduct(this.state.userInfo, "app")
    }

    handleClickForBetterResult = () => {
        goToProduct(this.state.userInfo, "studio")
    }

    handleRenameModelName = () => {

    }

    handleClickModelTest = () => {
        let modelInfo = this.state.currentModelInfo
        let mlInfo = this.state.currentMlInfo
        let fileInfo = this.state.uploadedFileInfo
        let featureDetailsInfoArr = mlInfo.DataSet
        featureDetailsInfoArr.map(eachFeature => {
            eachFeature.SourceName = "FILE"
            eachFeature.TableName = fileInfo.ID
            eachFeature.Categorical = eachFeature.OriginalDataType === "enum"
        })
        let passingData = {
            "AlgorithmType": this.state.selectedAlgorithmInfo.AlgorithmType,
            "TenantID": this.state.defaultTenantID,
            "MachineLearningID": modelInfo.MachineLearningID,
            "FeatureDetailsInfoArr": featureDetailsInfoArr,
            "FixedCategoricalData": true,
            "CategoricalCount": 100,
            "TimeStampQueryDetails": null,
            "ModelID": modelInfo.ID,
            "FileName": fileInfo.ID,
            "DataInfo": fileInfo.DataInfo,
            "Delimiter": fileInfo.Delimiter
        }

        console.log("passing info for model feature range : ", passingData)
        // renderSwitchDOceanService("getFeatureDataRange", passingData, "", "").then(response => {
        //     console.log("response of get feature range : ", response.data)
        // }).catch(err => {
        //
        // })
        this.setState({
            passingData,
            openModelTester: true
        })
    }

    showFixedValue = (inputValue) => {
        let fixedValue = inputValue
        if (checkValidity(fixedValue)) {
            fixedValue = Number.parseFloat(fixedValue).toFixed(4)
        }
        return fixedValue
    }

    checkCreateNew = () => {
        let createNew = true
        let disableNewBtn = false
        if (this.state.modelListCase !== true && this.state.modelList.length > 0) {
            createNew = false
        } else if (this.state.modelListCase !== true && this.state.modelList.length === 0) {
            disableNewBtn = true
        }

        return {
            createNew,
            disableNewBtn
        }
    }

    viewModelList = () => {
        clearInterval(this.modelStatusInterval)
        this.prepareInitialData(true)
        this.setState({
            showModelInfo: false,
            modelListCase: true,
            activeStep: 0,
            currentStep: "Data Ingestion",
            fileDropStatus: "initial",
            processStatus: "config",
            processStepList: [
                {Name: "Uploading", Icon: "upload", Status: "inprogress", color: "var(--light-sky)"}, //inprogress, pending, success, fail
                {Name: "Training", Icon: "train", Status: "pending", color: "var(--pink)"},
                {Name: "Testing", Icon: "test", Status: "pending", color: "var(--purple)"},
                {Name: "Generate", Icon: "generate", Status: "pending", color: "var(--body-color)"},
            ],
        })
    }

    checkDisabled = (columnInfo, outputCase) => {
        let disabled = false
        if (columnInfo.OriginalDataType === "enum") {
            if (outputCase === true) {
                if (this.state.selectedAlgorithmType === "Regression" || this.state.selectedAlgorithmType === "Regression(Time_Series)") {
                    disabled = true
                }
            }
        } else if (columnInfo.OriginalDataType === "date") {
            disabled = true
        }
        if (this.state.selectedAlgorithmType === "Clustering" && outputCase === true) {
            disabled = true
        }
        if (columnInfo.disabled === true) {
            disabled = true
        } else if (this.state.viewCase === true) {
            disabled = true
        }
        return disabled
    }

    handleCloseModelTest() {
        this.setState({
            openModelTester: false,
            ModelTesterPopup: false
        })
    }

    checkMetricStatus = () => {
        let metricValue = this.state.metricValue
        let metricKey;
        let checkRange = {
            "Accuracy": {
                "very_good": [0.874, 1],
                "good": [0.74, 0.874],
                "satisfactory": [0.624, 0.74],
                "bad": [0.49, 0.624],
                "very_bad": [0.49]
            },
            "Silhouette": {
                "very_good": [0.79, 1],
                "good": [0.70, 0.79],
                "satisfactory": [0.5, 0.70],
                "bad": [0.25, 0.5],
                "very_bad": [0.25]
            },
            "R2Score": {
                "very_good": [0.79, 1],
                "good": [0.69, 0.79],
                "satisfactory": [0.49, 0.69],
                "bad": [0.29, 0.49],
                "very_bad": [0.29]
            }
        }
        let selectedAlgorithmType = this.state.selectedAlgorithmType
        if (selectedAlgorithmType === "Classification" || selectedAlgorithmType === "Classification(Time_Series)") {
            metricKey = "Accuracy"
        } else if (selectedAlgorithmType === "Clustering") {
            metricKey = "Silhouette"
        } else {
            metricKey = "R2Score"
        }

        let checkStatus = ""
        for (let index = 0; index < metricValue.length; index++) {
            if (metricValue[index].MetricKey === metricKey) {
                let metricRangeInfo = checkRange[metricKey]
                for (const [key, value] of Object.entries(metricRangeInfo)) {
                    if (value.length === 2) {
                        if (metricValue[index].MetricValue > value[0] && metricValue[index].MetricValue <= value[1]) {
                            checkStatus = key
                            console.log("found status : ", checkStatus)
                            break
                        }
                    } else if (value.length === 1) {
                        if (key === "very_bad") {
                            if (metricValue[index].MetricValue <= value[0]) {
                                checkStatus = key
                                console.log("found status : ", checkStatus)
                                break
                            }
                        } else {
                            if (metricValue[index].MetricValue >= value[0]) {
                                checkStatus = key
                                console.log("found status : ", checkStatus)
                                break
                            }
                        }
                    }
                }
            }
        }
        let status = checkStatus
        if (checkStatus === "very_good") {
            status = "very good"
        } else if (checkStatus === "very_bad") {
            status = "very bad"
        }
        console.log('returned status : ', status, checkStatus, selectedAlgorithmType, metricKey, JSON.parse(JSON.stringify(this.state.metricValue)))
        return status
    }

    getColTable = (count) => {
        let height = count * 40;
        if (height > 400) return 400
        else return height
    }

    handleChangeActiveTab = (activeTab) => {
        if (activeTab === "columns") {
            this.setState({
                activeTab
            })
        } else {
            this.setState({
                activeTab,
                previewDataLoading: true
            }, () => {
                this.getPreviewData(this.state.uploadedFileInfo)
            })
        }
    }

    getPreviewData(uploadedFileInfo) {
        console.log("getPreviewData - uploadedFileInfo : ", uploadedFileInfo)
        let previewInputData = {
            FileType: "csv",
            Engine: "csv",
            SourceName: "FILE",
            FileName: uploadedFileInfo.ID,
            TableName: uploadedFileInfo.ID,
            PageSize: 50,
            Filtering: ["_id"],
            FirstRowAsHeader: uploadedFileInfo.FirstRowAsHeader,
            Delimiter: uploadedFileInfo.Delimiter,
            DataInfo: uploadedFileInfo.DataInfo,
            NewDelimiter: uploadedFileInfo.Delimiter
        };
        renderSwitchConnectorService('getPreviewFileData', previewInputData, '', '').then(resData => {
            console.log("response of get preview file data : ", resData.data)
            if (resData.data !== "") {
                if (resData.data["ResponseCode"] === 1000 || resData.data["ResponseCode"] === 3004 || resData.data["ResponseCode"] === 3002 || resData.data["ResponseCode"] === 3615) {
                    let noOfRows = 0;
                    let previewInfo = resData.data["Results"];
                    let previewData = []

                    if (checkValidity(previewInfo) && checkValidity(previewInfo.PreviewData)) {
                        noOfRows = previewInfo["NoOfRows"]
                        for (let j = 0; j < previewInfo.PreviewData.length; j++) {
                            let resData = previewInfo.PreviewData[j];
                            try {
                                resData = resData.replaceAll("NaN", "\"NaN\"")
                                resData = resData.replaceAll("+Inf", "\"+Inf\"")
                                resData = resData.replaceAll("-Inf", "\"-Inf\"")
                                let str = JSON.parse(resData)
                                previewData = previewData.concat(str)
                            } catch (e) {
                                console.log("This is catch error of wrong json format", e)
                            }
                        }
                    }

                    this.setState({
                        previewDataLoading: false,
                        noOfRows,
                        previewData
                    })

                } else {
                    this.setState({
                        previewDataLoading: false,
                        previewData: [],
                        defaultErrorTitle: "Request Error",
                        defaultLoadingPopup: false,
                        defaultErrorPopup: true,
                        defaultErrorText: "The requested information is not available at the moment, please try later!",
                    })
                }
            } else {
                this.setState({
                    previewDataLoading: false,
                    previewData: [],
                    defaultErrorTitle: "Request Error",
                    defaultLoadingPopup: false,
                    defaultErrorPopup: true,
                    defaultErrorText: "The requested information is not available at the moment, please try later!",
                })
            }
        }).catch((e) => {
            console.log("error in catch :", e)
            this.setState({
                previewDataLoading: false,
                previewData: [],
                defaultLoadingPopup: false,
                defaultErrorPopup: true,
                defaultErrorTitle: "Request Error",
                defaultErrorText: "The requested information is not available at the moment, please try later!"
            })
        })
    }

    getDataValue = (dataRow, columnName) => {
        let value = ""
        if (checkValidity(columnName)) {
            let colLower = columnName.toLowerCase()
            if (checkValidity(dataRow[colLower])) {
                value = dataRow[colLower]
            }
        }
        if (value === "") {
            value = "-"
        }
        return value
    }

    render() {
        let {
            predictLabels,
            confusionMetricValue,
            columnWithDataTypeInfo,
            currentModelInfo,
            currentStep,
            fileDropStatus,
            processStatus,
            algorithmTypeList,
            resultTabList,
            chosenResultTab,
            metricValue,
            processStepList,
            modelList,
            viewCase,
            activeTab
        } = this.state;
        let checkData = this.checkCreateNew()
        // console.log("preview data :: ", this.state.previewDataLoading, this.state.previewData)
        return (
            <div className="instant-model-layout ">
                <MetaData keyword={'instant-model'}/>
                <SummaryText topic="instant_model" theme={'light'}/>
                <div className="instant-model-new-button-row">
                    {
                        this.state.initialLoading || this.state.initialModelViewLoading ?
                            null
                            :
                            checkData.createNew ?
                                checkData.disableNewBtn ?
                                    null
                                    :
                                    <Button onClick={this.createNewInstantModel.bind(this)}
                                            labelText="New Instant Model"/>
                                :
                                <Button onClick={this.viewModelList.bind(this)} labelText="See Model List"/>
                    }
                </div>
                {
                    this.state.initialLoading ?
                        <div className="instant-model-file-drop-zone">
                            <RotatingCircle theme='light'
                                            loadingText={this.state.defaultLoadingText}/>
                        </div>
                        :
                        this.state.modelListCase && modelList.length > 0 ?
                            <div className="instant-model-body">
                                <div className="instant-model-body--title">
                                    Recently Created Instant Models
                                </div>
                                <div className="instant-model-list">
                                    {
                                        modelList.map((model) =>
                                            <div className="instant-model-card" key={model}>
                                                <div className="instant-model-name"
                                                     title={model.DisplayName}>{model.DisplayName}</div>
                                                <div className="instant-model-type">
                                                    Type : {model.AlgorithmType}
                                                </div>
                                                <div className="instant-model-bottom-row">
                                                    <GhostButton onClick={() => this.downloadModel(model)}
                                                                 color={"var(--body-color)"} label={"Download"}/>
                                                    <Button onClick={() => this.viewModelResult(model)}
                                                            labelText={"View"}/>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                            :
                            this.state.initialModelViewLoading ?
                                <div className="instant-model-file-drop-zone">
                                    <RotatingCircle theme='light'
                                                    loadingText={this.state.defaultLoadingText}/>
                                </div>
                                :
                                <div className="instant-model-body">
                                    {
                                        this.state.showModelInfo ?
                                            <div className="instant-model-body--title">
                                                Your instant model
                                                <div className="instant-model-id-row">
                                                    Model ID:
                                                    <input type="text" className="instant-model-id-input"
                                                           disabled={true}
                                                           value={currentModelInfo.DisplayName}/>
                                                    {/*<button onClick={() => this.handleRenameModelName()}*/}
                                                    {/*        className="instant-model-id-edit-btn">*/}
                                                    {/*    <Icon icon="edit"/>*/}
                                                    {/*</button>*/}
                                                </div>
                                            </div>
                                            :
                                            null
                                    }
                                    <div className="instant-model-stepper-container">
                                        <div className="instant-model-stepper">
                                            <StepperArrow steps={this.state.stepperSteps}
                                                          gap={5}
                                                          height={50}
                                                          width={950}
                                                          activeAll={true}
                                                          activeStepId={this.state.activeStep}
                                                          stepHandler={this.onClickStepHandler}/>
                                        </div>
                                    </div>
                                    <div className="instant-model-detailed-container">
                                        {
                                            currentStep === "Data Ingestion" ?
                                                viewCase ?
                                                    <div className="instant-model-file-info-division">
                                                        <div className="instant-model-file-info-card">
                                                            <div className="instant-model-file-info-card--header">
                                                                <div className="instant-model-file-info-card--left">
                                                                    <div className="instant-model-file-label">
                                                                        Uploaded File:
                                                                    </div>
                                                                    <div
                                                                        className="instant-model-file-name">
                                                                        {
                                                                            checkValidity(this.state.uploadedFileInfo) ?
                                                                                checkValidity(this.state.uploadedFileInfo.Name) ?
                                                                                    this.state.uploadedFileInfo.Name
                                                                                    :
                                                                                    checkValidity(this.state.uploadedFileInfo.FileName) ?
                                                                                        this.state.uploadedFileInfo.FileName
                                                                                        :
                                                                                        null
                                                                                :
                                                                                null
                                                                        }
                                                                    </div>
                                                                </div>
                                                                {/*<div className="instant-model-file-info-card-right">*/}
                                                                {/*    <div className="instant-model-file-label">*/}
                                                                {/*        View Data*/}
                                                                {/*    </div>*/}
                                                                {/*    <Icon icon="dropDown" size={16}*/}
                                                                {/*          className="instant-model-view-data-drop-down"/>*/}
                                                                {/*</div>*/}
                                                            </div>

                                                            <div className="instant-model-file-info-data-division">
                                                                <div className="instant-model-view-tab-bar">
                                                                    <button
                                                                        onClick={() => this.handleChangeActiveTab("columns")}
                                                                        className={this.state.activeTab === "columns" ? "instant-model-view-tab active" : "instant-model-view-tab"}>
                                                                        Columns
                                                                    </button>
                                                                    <button
                                                                        onClick={() => this.handleChangeActiveTab("data")}
                                                                        className={this.state.activeTab !== "columns" ? "instant-model-view-tab active" : "instant-model-view-tab"}>
                                                                        Data
                                                                    </button>
                                                                </div>
                                                                {
                                                                    activeTab === "columns" ?
                                                                        <div className="instant-model-view-info">
                                                                            <div
                                                                                className="instant-model-view--column-table">
                                                                                <div
                                                                                    className="instant-model-view-column-table--header">
                                                                                    <div
                                                                                        className="instant-model-view-column-table-col name">
                                                                                        Column Name
                                                                                    </div>
                                                                                    <div
                                                                                        className="instant-model-view-column-table-col type">
                                                                                        Data Type
                                                                                    </div>
                                                                                </div>
                                                                                <Scrollbars
                                                                                    style={{
                                                                                        minHeight: this.getColTable(columnWithDataTypeInfo.length),
                                                                                        width: "100%"
                                                                                    }}
                                                                                    autoHide={false}>
                                                                                    {
                                                                                        columnWithDataTypeInfo.map(eachColumnInfo =>
                                                                                            <div
                                                                                                className="instant-model-view-column-table-row">
                                                                                                <div
                                                                                                    className="instant-model-view-column-table-col name">
                                                                                                    <div
                                                                                                        className="instant-model-view-col-table-col--text"
                                                                                                        title={eachColumnInfo.ColumnName}>
                                                                                                        {eachColumnInfo.ColumnName}
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="instant-model-view-column-table-col type">
                                                                                                    {checkValidity(eachColumnInfo.DataType) ? eachColumnInfo.DataType : checkValidity(eachColumnInfo.OriginalDataType) ? eachColumnInfo.OriginalDataType : "-"}
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                </Scrollbars>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <div className="instant-model-view-info">
                                                                            {
                                                                                this.state.previewDataLoading === true ?
                                                                                    <div
                                                                                        className="instant-model-view-info-loading">
                                                                                        <RotatingCircle
                                                                                            loadingText={"Loading"}/>
                                                                                    </div>
                                                                                    :
                                                                                    this.state.previewData.length === 0 ?
                                                                                        <VerticalEmptyScreen
                                                                                            image={noData}
                                                                                            theme={'light'}
                                                                                            bodyContext="There is no data to show as preview."/>
                                                                                        :
                                                                                        this.state.previewData.length > 0 ?
                                                                                            <SysTablePreview
                                                                                                columnInfoList={columnWithDataTypeInfo}
                                                                                                containerWidth={window.innerWidth * (4 / 5)}
                                                                                                dataList={this.state.previewData}/>
                                                                                            :
                                                                                            <div
                                                                                                className="instant-model-view--column-table">
                                                                                                {/*<div*/}
                                                                                                {/*    className="instant-model-view-column-table--header">*/}
                                                                                                {/*    {*/}
                                                                                                {/*        columnWithDataTypeInfo.map(eachColumnInfo =>*/}
                                                                                                {/*            <div*/}
                                                                                                {/*                className="instant-model-view-column-table-col name">*/}
                                                                                                {/*                {checkValidity(eachColumnInfo.DisplayName) ? eachColumnInfo.DisplayName : eachColumnInfo.ColumnName}*/}
                                                                                                {/*            </div>*/}
                                                                                                {/*        )*/}
                                                                                                {/*    }*/}
                                                                                                {/*    /!*<div*!/*/}
                                                                                                {/*    /!*    className="instant-model-view-column-table-col type">*!/*/}
                                                                                                {/*    /!*    Data Type*!/*/}
                                                                                                {/*    /!*</div>*!/*/}
                                                                                                {/*</div>*/}
                                                                                                {/*<Scrollbars*/}
                                                                                                {/*    style={{*/}
                                                                                                {/*        minHeight: this.getColTable(columnWithDataTypeInfo.length),*/}
                                                                                                {/*        width: "100%"*/}
                                                                                                {/*    }}*/}
                                                                                                {/*    autoHide={false}>*/}

                                                                                                {/*    {*/}
                                                                                                {/*        this.state.previewData.map(eachDataRow =>*/}
                                                                                                {/*            checkValidity(eachDataRow) ?*/}
                                                                                                {/*                <div*/}
                                                                                                {/*                    className="instant-model-view-column-table-row">*/}
                                                                                                {/*                    {*/}
                                                                                                {/*                        columnWithDataTypeInfo.map(eachColumnInfo =>*/}
                                                                                                {/*                            <div*/}
                                                                                                {/*                                className="instant-model-view-column-table-col name">*/}
                                                                                                {/*                                <div*/}
                                                                                                {/*                                    className="instant-model-view-col-table-col--text"*/}
                                                                                                {/*                                    title={this.getDataValue(eachDataRow, eachColumnInfo.ColumnName)}>*/}
                                                                                                {/*                                    {this.getDataValue(eachDataRow, eachColumnInfo.ColumnName)}*/}
                                                                                                {/*                                </div>*/}
                                                                                                {/*                            </div>*/}
                                                                                                {/*                        )*/}
                                                                                                {/*                    }*/}
                                                                                                {/*                </div>*/}
                                                                                                {/*                :*/}
                                                                                                {/*                null*/}
                                                                                                {/*        )*/}
                                                                                                {/*    }*/}
                                                                                                {/*</Scrollbars>*/}
                                                                                            </div>
                                                                            }
                                                                        </div>
                                                                }

                                                                <div
                                                                    className="instant-model-info-button-row">
                                                                    <Button
                                                                        onClick={() => this.handleForwardStep()}
                                                                        disabled={false}
                                                                        labelText={"Next"}/>
                                                                </div>

                                                            </div>
                                                            {/*<div className="instant-model-data-pagination-row">*/}
                                                            {/*    Pagination*/}
                                                            {/*</div>*/}
                                                        </div>
                                                    </div>
                                                    :
                                                    fileDropStatus === "initial" ?
                                                        <div className="instant-model-file-drop-zone">
                                                            <Dropzone
                                                                onDrop={this.fileUploadOnDrop.bind(this, "screen")}
                                                                onDragEnter={this.zipFileUploadDragOver.bind(this)}
                                                                onDragLeave={this.zipFileUploadDragLeave.bind(this)}
                                                                noClick={true}
                                                                disabled={false}
                                                                ref={dropzoneRef}
                                                            >
                                                                {({getRootProps, getInputProps}) => (
                                                                    <>
                                                                        <div {...getRootProps()}
                                                                             className="instant-model-file-drop-dotted-line"
                                                                             id='instant-drop-zone'>
                                                                            {/*<button*/}
                                                                            {/*    onClick={() => {*/}
                                                                            {/*        this.setState({showFolderStructureFormat: true})*/}
                                                                            {/*    }}*/}
                                                                            {/*    className="drag-drop-help-button">*/}
                                                                            {/*    <Icon*/}
                                                                            {/*        icon={"dragDropHelp"}*/}
                                                                            {/*        size={20}/>*/}
                                                                            {/*</button>*/}
                                                                            <Icon
                                                                                icon="file_upload"
                                                                                size={50}/>
                                                                            <p>{"Drag and Drop your CSV file"}</p>

                                                                            <div
                                                                                className="instant-model-file-drop-middle-label">
                                                                                <div
                                                                                    className="instant-model-file-drop-or-line-decoration"/>
                                                                                <div
                                                                                    className="instant-model-label--or">OR
                                                                                </div>
                                                                            </div>
                                                                            <Button
                                                                                onClick={() => this.handleBrowseOpenFiles()}
                                                                                labelText="Upload your CSV file"
                                                                                backgroundColor={"var(--primary-color)"}/>

                                                                            <input {...getInputProps()}
                                                                                   type="file"
                                                                                   accept={"csv/*"}
                                                                                   name="browseFileInformation"
                                                                                   onChange={this.handleSaveFileInfo.bind(this)}/>
                                                                            {/*<Dropzone*/}
                                                                            {/*    ref={browseRef}*/}
                                                                            {/*    onFileDialogCancel={() => this.handleCancelBrowseFile()}*/}
                                                                            {/*>*/}
                                                                            {/*    {({getRootProps, getInputProps}) => (*/}
                                                                            {/*        <div {...getRootProps()}*/}
                                                                            {/*             className="source-container-frame">*/}
                                                                            {/*            <input {...getInputProps()}*/}
                                                                            {/*                   type="file"*/}
                                                                            {/*                   name="browseFileInformation"*/}
                                                                            {/*                   onChange={this.handleCancelBrowseFile.bind(this)}*/}
                                                                            {/*            />*/}
                                                                            {/*        </div>*/}
                                                                            {/*    )}*/}
                                                                            {/*</Dropzone>*/}
                                                                            <div className="instant-model-limit-note">
                                                                                Maximum Row Count: 10000 & Maximum File
                                                                                Size: 20 MB
                                                                            </div>
                                                                            <div id="instant-droppable-zone">
                                                                                <VerticalEmptyScreen
                                                                                    image={dropFileImage}
                                                                                    theme={'light'}
                                                                                    bodyContext="Drop the file to upload"/>
                                                                            </div>
                                                                        </div>
                                                                        <input {...getInputProps()}
                                                                               className="upload-zip-file-input-box"
                                                                        />
                                                                    </>
                                                                )}
                                                            </Dropzone>
                                                        </div>
                                                        :
                                                        fileDropStatus === "dropping" ?
                                                            <div className="instant-model-file-drop-dotted-line">
                                                                <VerticalEmptyScreen image={dropFileImage}
                                                                                     theme={'light'}
                                                                                     bodyContext="Drop your file here"/>
                                                            </div>
                                                            :
                                                            <div className="instant-model-file-drop-zone">
                                                                <RotatingCircle theme='light'
                                                                                loadingText={this.state.defaultLoadingText}/>
                                                            </div>
                                                :
                                                currentStep === "Model Configuration and Creation" ?
                                                    <div className="instant-model-model-info-block">
                                                        <div className="instant-model-model-info-block-header">
                                                            <div className="instant-model-info-block-title">
                                                                {
                                                                    processStatus === "config" ?
                                                                        "Model Configuration"
                                                                        :
                                                                        "Data"
                                                                }

                                                            </div>
                                                        </div>
                                                        {
                                                            processStatus === "config" ?
                                                                <div className="instant-model-info-block-body">
                                                                    <div className="instant-model-info-config-row">
                                                                        <div
                                                                            className="instant-model-info-config--label">
                                                                            Algorithm Type
                                                                        </div>
                                                                        <div
                                                                            className="instant-model-info-config--algo-list">
                                                                            {
                                                                                algorithmTypeList.map((algorithmTypeInfo, index) =>
                                                                                    <div className="instant-model-algo"
                                                                                         style={{color: algorithmTypeInfo.disabled || (this.state.viewCase && !algorithmTypeInfo.checked) ? "#cdcdcd" : "black"}}
                                                                                         key={algorithmTypeInfo}>
                                                                                        <Radio
                                                                                            checked={algorithmTypeInfo.checked}
                                                                                            disabled={algorithmTypeInfo.disabled || ((this.state.viewCase || this.state.processingDataLoading) && !algorithmTypeInfo.checked)}
                                                                                            onChange={() => this.handleCheckedAlgoType(algorithmTypeInfo, index)}
                                                                                            size="small"/>
                                                                                        {algorithmTypeInfo.name}
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className="instant-model-feature-table-container">
                                                                        <div
                                                                            className="instant-model-feature-table-title">Features
                                                                        </div>
                                                                        <div
                                                                            className="instant-model-feature-selection-table">
                                                                            <div
                                                                                className="instant-model-feature-selection-table--header">
                                                                                <div
                                                                                    className="instant-model-feature-selection-col">
                                                                                    Column Name
                                                                                </div>

                                                                                <div
                                                                                    className="instant-model-feature-selection-col type">
                                                                                    Data Type
                                                                                </div>
                                                                                <div
                                                                                    className="instant-model-feature-selection-col checkbox">
                                                                                    Output
                                                                                </div>
                                                                                <div
                                                                                    className="instant-model-feature-selection-col checkbox">
                                                                                    Inputs
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                className="instant-model-feature-table--body">
                                                                                <Scrollbars
                                                                                    style={{
                                                                                        height: this.getTableBody(columnWithDataTypeInfo.length) + "px",
                                                                                        width: "100%"
                                                                                    }} //column count
                                                                                    autoHide={false}>
                                                                                    <div
                                                                                        className="instant-model-feature-table-inner-body">
                                                                                        {
                                                                                            columnWithDataTypeInfo.map((columnInfo, cid) =>
                                                                                                <div
                                                                                                    className="instant-model-feature-table-row"
                                                                                                    key={columnInfo.ColumnName}>
                                                                                                    <div
                                                                                                        className="instant-model-feature-selection-col"
                                                                                                        title={columnInfo.DisplayName}>
                                                                                                        <div
                                                                                                            className="instant-model-feature-selection-col-name">
                                                                                                            {columnInfo.DisplayName}
                                                                                                        </div>

                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="instant-model-feature-selection-col type">
                                                                                                        {columnInfo.DataType}
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="instant-model-feature-selection-col checkbox">
                                                                                                        <Checkbox
                                                                                                            disabled={this.checkDisabled(columnInfo, true) || this.state.processingDataLoading}
                                                                                                            checked={columnInfo.isCheckedForOutput}
                                                                                                            onChange={() => this.handleCheckForInputOutputSelector("output", columnInfo, cid)}
                                                                                                            size="small"/>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="instant-model-feature-selection-col checkbox">
                                                                                                        <Checkbox
                                                                                                            disabled={this.checkDisabled(columnInfo, false) || this.state.processingDataLoading}
                                                                                                            checked={columnInfo.isCheckedForInput}
                                                                                                            onChange={() => this.handleCheckForInputOutputSelector("input", columnInfo, cid)}
                                                                                                            size="small"/>
                                                                                                    </div>

                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                    </div>
                                                                                </Scrollbars>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="instant-model-info-button-row">
                                                                        <Button labelText="Back"
                                                                                onClick={() => this.handleGoBackToBeforeStep()}
                                                                                backgroundColor={"white"}
                                                                                color={"var(--body-color)"}
                                                                                borderColor={"var(--light-color)"}/>
                                                                        <Button
                                                                            onClick={() => this.handleClickContinueForModelConfig()}
                                                                            disabled={this.state.disabledContinueBtn || this.state.processingDataLoading}
                                                                            labelText={
                                                                                this.state.viewCase === true ?
                                                                                    "Next"
                                                                                    :
                                                                                    this.state.processingDataLoading ?
                                                                                        "Processing"
                                                                                        :
                                                                                        "Continue"
                                                                            }/>
                                                                    </div>
                                                                </div>
                                                                :
                                                                this.state.processingDataLoading ?
                                                                    <div className="instant-model-file-drop-zone">
                                                                        <RotatingCircle theme='light'
                                                                                        loadingText={this.state.defaultLoadingText}/>
                                                                    </div>
                                                                    :
                                                                    <div className="instant-model-info-block-body">
                                                                        <div className="instant-model-progress-bar">
                                                                            {
                                                                                processStepList.map((step, sid) =>
                                                                                    <>
                                                                                        {sid !== 0 ? this.progressBarLine(processStepList[sid - 1]) : null}
                                                                                        {this.progressNode(step, sid)}
                                                                                    </>
                                                                                )
                                                                            }
                                                                        </div>
                                                                        {
                                                                            this.state.viewCase === true ?
                                                                                <div
                                                                                    className="instant-model-info-button-row">
                                                                                    <Button labelText="Back"
                                                                                            onClick={() => this.handleGoBackToBeforeStep()}
                                                                                            backgroundColor={"white"}
                                                                                            color={"var(--body-color)"}
                                                                                            borderColor={"var(--light-color)"}/>
                                                                                    {console.log("checking next status : ", this.state.disabledContinueBtn, this.state.processingDataLoading, this.state.modelStatus)}
                                                                                    <Button
                                                                                        onClick={() => this.handleForwardStep()}
                                                                                        disabled={this.state.disabledContinueBtn || this.state.processingDataLoading || this.state.modelStatus !== "success"}
                                                                                        labelText={"Next"}/>
                                                                                </div>
                                                                                :
                                                                                null
                                                                        }
                                                                    </div>
                                                        }
                                                    </div>
                                                    :
                                                    <div className="instant-model-result-container">
                                                        <div className="instant-model-detailed-tab-list-bar">
                                                            <div className="instant-model-detailed-tab-list">
                                                                {
                                                                    checkValidity(this.state.selectedAlgorithmInfo) && this.state.selectedAlgorithmInfo.AlgorithmType === "Classification" || this.state.selectedAlgorithmInfo.AlgorithmType === "Classification(Time_Series)" ?
                                                                        resultTabList.map((tab) =>
                                                                            <button
                                                                                className={tab === chosenResultTab ? "instant-model-detailed-tab active" : "instant-model-detailed-tab"}
                                                                                onClick={this.changeResultTab.bind(this, tab)}>
                                                                                {tab}
                                                                            </button>
                                                                        )
                                                                        :
                                                                        <button
                                                                            className={"instant-model-detailed-tab"}>
                                                                            {"Metrics"}
                                                                        </button>
                                                                }
                                                            </div>
                                                            <div className="instant-model-result-menu-list">
                                                                <Button labelText="Test Model"
                                                                        onClick={() => this.handleClickModelTest()}
                                                                        backgroundColor={"white"}
                                                                        color={"var(--body-color)"}
                                                                        borderColor={"var(--light-color)"}/>
                                                                <Button labelText="Download Model"
                                                                        onClick={() => this.downloadModel(this.state.currentModelInfo)}
                                                                        backgroundColor={"var(--light-color)"}
                                                                        color={"var(--body-color)"}
                                                                        borderColor={"var(--light-sky)"}/>
                                                                <Button labelText="Create App"
                                                                        onClick={() => this.handleClickForCreateApp()}
                                                                        backgroundColor={"var(--light-color)"}
                                                                        color={"var(--body-color)"}
                                                                        borderColor={"var(--orange)"}/>
                                                            </div>
                                                            <div
                                                                className="instant-model-result-menu-list-small-screen">
                                                                <button className="instant-model-result-menu"
                                                                        title="Test Model"
                                                                        onClick={() => this.handleClickModelTest()}>
                                                                    <Icon icon="test"
                                                                          className="instant-result-menu-icon test"/>
                                                                </button>
                                                                <button className="instant-model-result-menu"
                                                                        title="Download Model"
                                                                        onClick={() => this.downloadModel(this.state.currentModelInfo)}>
                                                                    <Icon icon="download"
                                                                          className="instant-result-menu-icon test"/>
                                                                </button>
                                                                <button className="instant-model-result-menu"
                                                                        title="Create Application"
                                                                        onClick={() => this.handleClickForCreateApp()}>
                                                                    <Icon icon="createApp"
                                                                          className="instant-result-menu-icon test"/>
                                                                </button>

                                                            </div>
                                                        </div>
                                                        <div className="instant-model-result-body">
                                                            {
                                                                this.state.initialModelResultLoading ?
                                                                    <div className="instant-model-file-drop-zone">
                                                                        <RotatingCircle theme='light'
                                                                                        loadingText={this.state.defaultLoadingText}/>
                                                                    </div>
                                                                    :
                                                                    this.state.emptyModelResult === true ?
                                                                        <VerticalEmptyScreen
                                                                            image={noData}
                                                                            theme={'light'}
                                                                            bodyContext="There is no data to display as model result."/>
                                                                        :
                                                                        chosenResultTab === "Confusion Matrix" ?
                                                                            <div
                                                                                className="instant-model-confusion-matrix-container">
                                                                                {
                                                                                    CheckEmpty(confusionMetricValue) ?
                                                                                        <ConfusionMatrix
                                                                                            predictLabel={predictLabels}
                                                                                            matrixData={confusionMetricValue}
                                                                                            matrixCenterWidth={"1000px"}
                                                                                            actualLabel={predictLabels}/>
                                                                                        :
                                                                                        <VerticalEmptyScreen
                                                                                            image={noData}
                                                                                            theme={'light'}
                                                                                            bodyContext="There is no data to display as confusion matrix."/>
                                                                                }
                                                                            </div>
                                                                            :
                                                                            <div className="instant-model-result-table">
                                                                                <div
                                                                                    className="instant-model-result-table--header">
                                                                                    <div
                                                                                        className="instant-model-result-col label">
                                                                                        Metrics
                                                                                    </div>
                                                                                    <div
                                                                                        className="instant-model-result-col value">
                                                                                        Value
                                                                                    </div>
                                                                                </div>

                                                                                <Scrollbars
                                                                                    style={{height: this.getTableBody(metricValue.length) + "px"}} //column count
                                                                                    autoHide={false}>
                                                                                    <div
                                                                                        className="instant-model-result-table--body">
                                                                                        {
                                                                                            metricValue.map((metric) =>
                                                                                                <div
                                                                                                    className="instant-model-result-row"
                                                                                                    key={"metric"}>
                                                                                                    <div
                                                                                                        title={metric.MetricKey}
                                                                                                        className="instant-model-result-col label">
                                                                                                        <div
                                                                                                            className="instant-model-result-col-text">
                                                                                                            {metric.MetricKey}
                                                                                                        </div>

                                                                                                    </div>
                                                                                                    <div
                                                                                                        title={metric.MetricValue}
                                                                                                        className="instant-model-result-col value">
                                                                                                        <div
                                                                                                            className="instant-model-result-col-text">
                                                                                                            {this.showFixedValue(metric.MetricValue)}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                        }

                                                                                    </div>
                                                                                </Scrollbars>
                                                                            </div>
                                                            }

                                                            {
                                                                this.state.initialModelResultLoading === true || this.state.emptyModelResult === true ?
                                                                    null
                                                                    :
                                                                    <>
                                                                        <div className="instant-model-feature-link-row">
                                                                            Your model's metrics
                                                                            are {this.checkMetricStatus()}. For better
                                                                            results&nbsp;
                                                                            <button
                                                                                onClick={() => this.handleClickForBetterResult()}
                                                                                className="instant-model-feature-link-btn">{"Click here"}</button>
                                                                            !
                                                                        </div>

                                                                        <div className="instant-model-info-button-row">
                                                                            <Button labelText="Back"
                                                                                    onClick={() => this.handleGoBackToBeforeStep()}
                                                                                    backgroundColor={"white"}
                                                                                    color={"var(--body-color)"}
                                                                                    borderColor={"var(--light-color)"}/>

                                                                        </div>
                                                                    </>
                                                            }
                                                        </div>
                                                    </div>
                                        }
                                    </div>
                                </div>
                }


                {/*<Popup contentStyle={{*/}
                {/*    width: "auto",*/}
                {/*    background: "transparent",*/}
                {/*    padding: 0,*/}
                {/*    border: "none"*/}
                {/*}}*/}
                {/*       open={this.state.openSendRequestPopup}*/}
                {/*       closeOnDocumentClick={false}*/}
                {/*       lockScroll={false}>*/}

                {/*    <div className="email-send-popup">*/}
                {/*        {*/}
                {/*            sendingRequest ?*/}
                {/*                null*/}
                {/*                :*/}
                {/*                <button onClick={this.handleCloseSendingRequestPopup.bind(this)}*/}
                {/*                        className="email-send-close-btn">*/}
                {/*                    X*/}
                {/*                </button>*/}
                {/*        }*/}
                {/*        {*/}
                {/*            sendingRequest ?*/}
                {/*                <div className="email-send-image">*/}

                {/*                    <LottieAnimation lotti={"SendingMessage"}/>*/}
                {/*                </div>*/}
                {/*                :*/}
                {/*                <div className="email-send-image success">*/}

                {/*                    <LottieAnimation lotti={"SuccessMark"}/>*/}
                {/*                </div>*/}

                {/*        }*/}
                {/*        <div className={sendingRequest ? "email-send-text-title success" : "email-send-text-title"}>*/}
                {/*            {*/}
                {/*                sendingRequest ?*/}
                {/*                    "Sending Request"*/}
                {/*                    :*/}
                {/*                    "Successful"*/}
                {/*            }*/}
                {/*        </div>*/}
                {/*        <div className="email-send-text-body">*/}

                {/*            {*/}
                {/*                sendingRequest ?*/}
                {/*                    "We're in the process of sending your request. Please wait a few seconds..."*/}
                {/*                    :*/}
                {/*                    "Your request for a demo was successful. We'll be sending you an e-mail shortly. If you need any help in the meantime, feel free to contact us at any time."*/}
                {/*            }*/}

                {/*        </div>*/}
                {/*    </div>*/}

                {/*</Popup>*/}

                {/*================for fail case==================*/}
                <Popup contentStyle={{
                    width: "auto",
                    background: "transparent",
                    padding: 0,
                    border: "none"
                }}
                       open={this.state.defaultErrorPopup}
                       closeOnDocumentClick={false}
                       lockScroll={false}>

                    <div className="email-send-popup">
                        <button onClick={() => this.handleCloseOpenPopup("defaultErrorPopup")}
                                className="email-send-close-btn">
                            X
                        </button>
                        <div className="email-send-image success">
                            <LottieAnimation lotti={"Fail"}/>
                        </div>
                        <div className={"email-send-text-title fail"}>
                            {this.state.defaultErrorTitle}
                        </div>
                        <div className="email-send-text-body">
                            {this.state.defaultErrorText}
                        </div>
                    </div>
                </Popup>


                {
                    this.state.chooseCategoricalPopup ?
                        <ChooseCategoricalValue/>
                        :
                        null
                }
                {
                    this.state.openModelTester ?
                        <SysPopup Open={this.state.openModelTester}
                                  Title={"Model Tester for " + this.state.currentModelInfo.DisplayName}
                                  onClose={this.handleCloseModelTest.bind(this)}>
                            <ModelTester
                                algorithmType={this.state.selectedAlgorithmInfo.AlgorithmType}
                                algorithmInfo={this.state.selectedAlgorithmInfo}
                                userInfo={this.state.userInfo}
                                sourceInfo={this.state.uploadedFileInfo}
                                Dimentionality={this.state.selectedAlgorithmInfo.Dimentionality}
                                onClose={this.handleCloseModelTest.bind(this)}
                                currentModelInfo={this.state.currentModelInfo}
                                TenantID={this.state.productDefaultTenantID}
                                ApplicationID={this.state.ApplicationID}
                                ModelID={this.state.currentModelInfo.ID}
                                AlgorithmID={this.state.currentModelInfo.AlgorithmID}
                                RunID={this.state.currentModelInfo.RunID}
                                CkptFile={this.state.currentModelInfo.CkptFile}
                                featureList={this.state.passingData}
                            />
                        </SysPopup>
                        :
                        null
                }
            </div>
        );
    }
}

function ReplaceColumnName(columnName) {
    let cName = columnName.replaceAll(" ", "_")
    cName = cName.replaceAll(",", "_")
    cName = cName.replaceAll("[", "_")
    cName = cName.replaceAll("]", "_")
    cName = cName.replaceAll("(", "_")
    cName = cName.replaceAll(")", "_")
    cName = cName.replaceAll("{", "_")
    cName = cName.replaceAll("}", "_")
    cName = cName.replaceAll("/", "_")
    cName = cName.replaceAll("!", "_")
    cName = cName.replaceAll("@", "_")
    cName = cName.replaceAll("#", "_")
    cName = cName.replaceAll("\\", "_")
    cName = cName.replaceAll("$", "_")
    cName = cName.replaceAll("%", "-")
    cName = cName.replaceAll("^", "-")
    cName = cName.replaceAll("&", "_")
    cName = cName.replaceAll("*", "_")
    cName = cName.replaceAll(";", "_")
    cName = cName.replaceAll(":", "_")
    cName = cName.replaceAll("\t", "_")
    cName = cName.replaceAll("\n", "_")
    cName = cName.replaceAll("\r", "_")
    cName = cName.replaceAll(".", "_")
    cName = cName.replaceAll("-", "_")
    cName = cName.replaceAll("|", "_")
    cName = cName.replaceAll(">", "_")
    cName = cName.replaceAll("~", "_")
    cName = cName.replaceAll("<", "_")
    cName = cName.replaceAll("¥", "_")
    cName = cName.replaceAll("\'", "_")
    cName = cName.replaceAll("\"", "_")
    cName = cName.replaceAll("=", "_")
    cName = cName.replaceAll("₹", "_")
    cName = cName.replaceAll("€", "_")
    if (cName !== "") {
        let firstCol = cName[0]
        if (!isNaN(firstCol)) {
            cName = "col_" + cName
        }

        if (cName.endsWith("_")) {
            let splitArr = cName.split("_")
            let returnedName = ""
            splitArr.map((eachSplitValue, index) => {
                if (index === 0 || returnedName === "") {
                    returnedName = eachSplitValue
                } else {
                    if (checkValidity(eachSplitValue)) {
                        returnedName += "_" + eachSplitValue
                    }
                }
            })
            cName = returnedName
        }
    }
    return cName
}

export default InstantModel