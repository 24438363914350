export const ModelTesterColumnList = [
    {
        ColumnName: "Column 1",
        DataType: "enum",
        OptionList:[
            {Name: "Orange"},
            {Name: "Apple"},
        ],
    },
    {
        ColumnName: "Column 1",
        DataType: "enum",
        OptionList:[
            {Name: "Orange"},
            {Name: "Apple"},
            {Name: "Apple"},
            {Name: "Apple"},
            {Name: "Apple"},
            {Name: "Apple"},
            {Name: "Apple"},
            {Name: "Apple"},
            {Name: "Apple"},
            {Name: "Apple"},
            {Name: "Apple"},
            {Name: "Apple"},
            {Name: "Apple"},
            {Name: "Apple"},
            {Name: "Apple"},
            {Name: "Apple"},
            {Name: "Apple"},
            {Name: "Apple"},
            {Name: "Apple"},
            {Name: "Apple"},
            {Name: "Apple"},
            {Name: "Apple"},
            {Name: "Apple"},
            {Name: "Apple"},
            {Name: "Apple"},
            {Name: "Apple"},
            {Name: "Apple"},
            {Name: "Apple"},
            {Name: "Apple"},
            {Name: "Apple"},
            {Name: "Apple"},
            {Name: "Apple"},
            {Name: "Apple"},
            {Name: "Apple"},
            {Name: "Apple"},
            {Name: "Apple"},
            {Name: "Apple"},
            {Name: "Apple"},
            {Name: "Apple"},
            {Name: "Apple"},
            {Name: "Apple"},
            {Name: "Apple"},
            {Name: "Apple"},
            {Name: "Apple"},
            {Name: "Apple"},
            {Name: "Apple"},
            {Name: "Apple"},
            {Name: "Apple"},
        ],
    },
    {
        ColumnName: "Column 2",
        DataType: "int",
        Min: 0,
        Max: 1
    },
    {
        ColumnName: "Column 3",
        DataType: "image",
    },
    {
        ColumnName: "Column 4",
        DataType: "audio",
    },

]