import React from "react";
import "./home.css";
import Button from "../../Assets/Buttons/Button";
import SummaryText from "../Commoncomponents/SummaryText/SummaryText";
import Icon from "../../Assets/IconLibrary/Icon";
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import {DefaultParameters} from "../../api/ApiCallMethod";
import MetaData from "../MetaData/MetaData";
import {renderSwitchOrgService} from "../../api/ResourcesApiLayer";
import HomeImage from '../../Assets/Images/home.svg'
// import {Carousel} from "react-responsive-carousel";
import {ReactComponent as Development} from "../../Assets/Images/icons/Home/Development.svg";
import {ReactComponent as Deploy} from "../../Assets/Images/icons/Home/Deploy.svg";
import {ReactComponent as Server} from "../../Assets/Images/icons/Home/Server.svg";
import {ReactComponent as Operation} from "../../Assets/Images/icons/Home/Operation.svg";
import {CompanyCarousel} from "../Commoncomponents/CompanyCarousel";
import SysPopup from "../../Assets/SysPopup/SysPopup";
import Popup from "reactjs-popup";
import Radio from "@material-ui/core/Radio";
import GhostButton from "../../Assets/Buttons/GhostButton";
import AnalystIcon from "../../Assets/Images/Persona/analyst.svg"
import ScientistIcon from "../../Assets/Images/Persona/scientist.svg"
import EngineerIcon from "../../Assets/Images/Persona/engineer.svg"
import {goToProduct} from "../../api/Utilities";
import {TrustedCompanyList} from "./TrustedCompanyList";
import Loading from "../../Assets/Loading/Loading";

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            choosePersonaPopup: false,
            propertiesList: [
                {
                    Icon: <Development className="home-property-icon"/>,
                    Label: <div className="home-property-text-info">
                        <span className="home-property-text--type"> Develop:</span> <span
                        className="home-property-text-highlight">10X</span> Faster
                    </div>
                },
                {
                    Icon: <Deploy className="home-property-icon"/>,
                    Label:
                        <div className="home-property-text-info">
                            <span className="home-property-text--type"> Deploy:</span> <span
                            className="home-property-text-highlight">15X</span> Faster
                        </div>
                },
                {
                    Icon: <Server className="home-property-icon"/>,
                    Label:
                        <div className="home-property-text-info">
                            <span className="home-property-text--type"> Save:</span> <span
                            className="home-property-text-highlight">90%</span> Server Costs
                        </div>
                },
                {
                    Icon: <Operation className="home-property-icon"/>,
                    Label:
                        <div className="home-property-text-info">
                            <span className="home-property-text--type"> Save:</span> <span
                            className="home-property-text-highlight">95%</span> Operational Costs
                        </div>
                },
            ],
            activePropertyID: 0,
            activeSlide: 0,
            didViewCountUp: false,
            extraViewPort: false,
            personaList: [
                {
                    Name: "Analyst",
                    disabled: false,
                    Icon: "analyst",
                    Illustration: AnalystIcon,
                    tag: "No-code",
                    color: "var(--light-sky)",
                    description: "lorem ipsum dip amet com dolor illus sit amet jusorem ipsum dip amet com dolor illus sit amet jus",
                    option: [
                        {value: "analyst_no_code", disabled: false, label: "No Code"}
                    ]
                },

                {
                    Name: "Engineer",
                    Icon: "engineer",
                    disabled: true,
                    Illustration: EngineerIcon,
                    tag: "Code",
                    color: "var(--orange)",
                    description: "lorem ipsum dip amet com dolor illus sit amet jusorem ipsum dip amet com dolor illus sit amet jus",
                    option: [
                        {value: "java", disabled: true, label: "Java"},
                        {value: "csharp", disabled: true, label: "C#"},
                        {value: "python", disabled: true, label: "Python"},
                    ]
                },


                {
                    Name: "Scientist",
                    Icon: "scientist",
                    disabled: false,
                    Illustration: ScientistIcon,
                    tag: "Low-code",
                    color: "var(--gray-color)",
                    description: "lorem ipsum dip amet com dolor illus sit amet jusorem ipsum dip amet com dolor illus sit amet jus",
                    option: [
                        {value: "scientist_no_code", disabled: false, label: "No Code"},
                        {value: "jupyter", disabled: true, label: "Jupyter"},
                    ],

                },

            ],
            chosenPersona: "Analyst",
            chosenLoginOption: "analyst_no_code",
            activePersonaID: 0,
            summarySolutionCard: [
                {
                    Title: "Optimize AI Operations",
                    Icon: "blinx_website",
                    Description: [
                        "Automate your most expensive AI lifecycle bottlenecks, including managing live AI models"
                    ]
                },
                {
                    Title: "Expand AI Talent Pool",
                    Icon: "blinx_website",
                    Description: [
                        "Make your entire data and app talent ecosystem AI-Ready. The platform enables simple workflows for every role"
                    ]
                },
                {
                    Title: "Marketing Effectiveness",
                    Icon: "blinx_website",
                    Description: [
                        "What does your data tell you about how good you are at lead generation to close?"
                    ]
                },
                {
                    Title: "Reduce AI Runtime/Run time Expenses",
                    Icon: "blinx_website",
                    Description: [
                        "Purpose-built model server delivers order of magnitude performance improvement for concurrent learning and inferencing"
                    ]
                },
                {
                    Title: "Customer 360",
                    Icon: "blinx_website",
                    Description: [
                        "Use pre-built apps with your data or build your own for a new level of customer-centricity"
                    ]
                },
                {
                    Title: "Sales Forecasting",
                    Icon: "blinx_website",
                    Description: [
                        "Can you bring predictability to your sales pipeline?"
                    ]
                },
            ],
            count: [

                {Name: "Data Sources", Count: 500},
                {Name: "Models", Count: 100000},
                {Name: "Users", Count: 1},
                {Name: "Algorithms", Count: 200},
                {Name: "AI Apps", Count: 2000},
            ],
            currentDateTime: Date().toLocaleString(),
            updateTime: "10:05am",
            updateDate: "12 Aug 2021"

        }
    }

    changePersonaToggleHandler = (activePersonaID) => {
        this.setState({activePersonaID})
    };

    getStartedHandler = () => {
        // let productUrl = DefaultParameters().ProductURL;
        // productUrl += DefaultParameters().LoginRoute;
        // let persona = this.state.chosenPersona.toLowerCase();
        // let language = this.state.chosenLoginOption;
        // if (language === "analyst_no_code" || language === "scientist_no_code") {
        //     language = "no_code";
        // }
        // productUrl += "?persona=" + persona + "&language=" + language;
        // window.open(productUrl, '_blank');
        // this.setState({
        //     choosePersonaPopup: false
        // })

        window.open("https://calendly.com/blinx_sales", "_blank")

    };
    goToInstantModel = () => {
        this.props.history.push("/instant-model");
        this.props.history.go(this.props.history);
    };

    getCount = (limit) => {
        if (limit > 999) {
            limit = limit / 1000
        }
        return (limit)
    };

    onVisibilityChange = (isVisible) => {
        if (isVisible) {
            this.setState({didViewCountUp: true});
        } else {
            this.setState({didViewCountUp: false});
        }
    };

    onVisibilityChangeForExtra = (isVisible) => {
        // console.log("hello Extra", isVisible);
        if (isVisible) {
            this.setState({didViewCountUp: true});
        }
    };


    changeRoute = (route) => {
        this.props.history.push(`/${route}`);
        // this.props.history.go(this.props.history)
    };

    componentDidMount() {
        this.getInitialDataInfo();


    }


    changeProp = () => {
        let {activePropertyID} = this.state;
        if (activePropertyID === this.state.propertiesList.length - 1) {
            activePropertyID = 0;
        } else {
            activePropertyID++;
        }
        this.setState({
            activePropertyID
        })

        // this.getShowProps();
    }


    getInitialDataInfo() {

        renderSwitchOrgService('registeredUserCountAndLastTime', '', '', '').then(
            response => {
                // console.log("response of checking registered UserCountAndLastTime >>>>>", response.data);
                if (response.data['ResponseCode'] === 1000) {
                    let registeredData = response.data['Results'];
                    let userCount = registeredData['UserCount'];
                    let lastTime = registeredData['LastTime'];
                    let countArr = this.state.count;
                    countArr.map(data => {
                        if (data.Name === 'Users') {
                            data.Count = userCount
                        }
                    });

                    let timeStamp = new Date(lastTime);
                    console.log("original time stamp : ", timeStamp)
                    // Parse our locale string to [date, time]
                    let localDateTimeArr = timeStamp.toLocaleString('en-US', {hour12: true}).split(" ");

                    console.log("new date with given format : ", localDateTimeArr)

                    let date = localDateTimeArr[0].split(",")[0];
                    let time = localDateTimeArr[1];
                    let ampm = localDateTimeArr[2]

                    let splitTime = time.split(":");
                    let hours = splitTime[0];
                    let minutes = splitTime[1];
                    let updateTime = hours + ':' + minutes + ' ' + ampm;

                    let dateArr = date.split("/");
                    let convertedDate = new Date(dateArr[2], (dateArr[0] - 1), dateArr[1]);
                    let shortMonth = convertedDate.toLocaleString('default', {month: 'short'}); /* Jun */
                    let updateDate = dateArr[1] + " " + shortMonth + " " + dateArr[2];

                    console.log("final prepared date and time : ", updateDate, ", ", updateTime)

                    this.setState({
                        count: countArr,
                        currentDateTime: lastTime,
                        updateTime,
                        updateDate,
                        loaded: true
                    })
                }
                else{
                    this.setState({
                        loaded: true
                    })
                }
                // this.setIntervalForPersonaChange();
                this.getShowProps();
            }).catch(error=>{
                console.log("Error :::: ", error);
                this.setState({
                    loaded: true
                })
            })

    }

    getShowProps = () => {
        setTimeout(this.changeProp.bind(this), 3000)
    }

    setIntervalForPersonaChange = () => {
        setTimeout(this.changePersonaToggleButton.bind(this), 5000)
    };

    changePersonaToggleButton = () => {

        let {activePersonaID} = this.state;
        if (activePersonaID < 2) {
            this.setState({
                activePersonaID: activePersonaID + 1
            })
        } else {
            this.setState({
                activePersonaID: 0
            })
        }
        this.setIntervalForPersonaChange();

    };

    createImage = (info) => {
        return (
            React.createElement("button", {})
        )
    };

    openChoosingPersonaPopup = () => {
        // this.setState({
        //     choosePersonaPopup: true
        // })
        window.open("https://calendly.com/blinx_sales", "_blank");
    }

    render() {
        let {
            summarySolutionCard,
            didViewCountUp,
        } = this.state;

        // console.log("User Info ::::: ", this.state.count[2].Count)

        return (
            <div className="main-home-layout">
                <MetaData keyword={"home"}/>
                {
                    this.state.loaded?
                        <>
                            <div className="home-layout">
                                <div className="home-left">
                                    <img src={HomeImage}
                                         onClick={() => this.setState({fullScreenHeroImage: true})}
                                         className="home-left-image" alt='Screenshot of Model Prediction in Blinx'/>
                                </div>
                                <div className="home-right">
                                    <div className="home-right-content">
                                        <div className="home-header--top">
                                            Leverage AI Outcomes&nbsp;
                                            <span className="home-header--top-underline-text">
                                    Today
                                    <span className="exclamation-mark">!</span>
                                    <span className="home-header--top-underline"/>
                                </span>
                                        </div>

                                        <div className="home-header--bottom">
                                            Outpace&nbsp;
                                            <span className="home-today">Others<span className="exclamation-mark">!</span>
                                   <div className="home-today-line"/>
                               </span>
                                        </div>

                                        <div className="home-properties-division">
                                            {
                                                this.state.propertiesList.map((res, index) => {
                                                    return (
                                                        <div className={"home-properties-card card" + index} key={index}>
                                                            <div className="home-properties-card-icon">
                                                                {res.Icon}

                                                            </div>
                                                            <div className="home-property-text">
                                                                {res.Label}

                                                            </div>

                                                        </div>
                                                    )
                                                })
                                            }


                                        </div>

                                        <div className="home-persona-main-btn-row">

                                            <Button onClick={this.goToInstantModel.bind(this)}
                                                    backgroundColor={"transparent"}
                                                    labelText={"Instant Model"}
                                                    borderColor={"var(--primary-color)"}
                                                    color={"white"}
                                                    width={"170px"}
                                                    iconSize={20}
                                                    icon={"instant_model"}
                                                    fill={"var(--primary-color)"}

                                                    height={"50px"} borderRadius={25}/>

                                            <Button onClick={this.openChoosingPersonaPopup.bind(this)}
                                                    backgroundColor={"var(--primary-color)"}
                                                    labelText={"Get Started"}
                                                    width={"150px"}
                                                    height={"50px"} borderRadius={25}/>


                                        </div>
                                    </div>


                                </div>

                            </div>

                            <VisibilitySensor onChange={this.onVisibilityChange} partialVisibility={true}>
                                <div className="home-resources-count-container">

                                    <div className="home-resources-count--inner-container">
                                        {
                                            this.state.count.map((res, index) =>
                                                <div
                                                    className={res.Name === "Users" ? "home-resources-count-card active" : "home-resources-count-card"}
                                                    key={index}>

                                                    {
                                                        res.Name === "Users" ?
                                                            <div className="home-resource-card-trusted-by-company-info">
                                                                Trusted by <span
                                                                className="home-resource-card-highlight"> {TrustedCompanyList.length} </span>
                                                                <span className="home-resource-card-unit">Companies</span>
                                                            </div>
                                                            :
                                                            null
                                                    }
                                                    <div className={res.Name === "Users" ? "count-up-row" : "count-up-column"}>
                                                        {
                                                            res.Name === "Users"?
                                                                res.Count <= 1?
                                                                    <CountUp
                                                                        className="home-resources-count"
                                                                        start={0}
                                                                        end={didViewCountUp ? 2000 : null}
                                                                        duration={1.5}
                                                                        decimals={0}
                                                                        decimal="."
                                                                        separator={","}
                                                                        minTopValue={0}
                                                                        suffix={""}/>
                                                                    :
                                                                    <CountUp
                                                                        className="home-resources-count"
                                                                        start={0}
                                                                        end={didViewCountUp ? res.Name === "Users" || res.Name === "AI Apps"? res.Count : this.getCount(res.Count) : null}
                                                                        duration={1.5}
                                                                        decimals={0}
                                                                        decimal="."
                                                                        separator={","}
                                                                        minTopValue={0}
                                                                        suffix={res.Name === "Users"? "" : res.Count > 1000 && res.Name !== "AI Apps"? "K+" : "+"}/>

                                                                :
                                                                <CountUp
                                                                    className="home-resources-count"
                                                                    start={0}
                                                                    end={didViewCountUp ? res.Name === "Users" || res.Name === "AI Apps"? res.Count : this.getCount(res.Count) : null}
                                                                    duration={1.5}
                                                                    decimals={0}
                                                                    decimal="."
                                                                    separator={","}
                                                                    minTopValue={0}
                                                                    suffix={res.Name === "Users"? "" : res.Count > 1000 && res.Name !== "AI Apps"? "K+" : "+"}/>

                                                        }

                                                        <div className="home-resources-name">{res.Name}</div>
                                                    </div>
                                                    {
                                                        res.Name === "Users" ?
                                                            <div className="home-resource-info-btn">
                                                                <button className="home-resource-info-icon" id='info'>
                                                                    <Icon icon="info" size={20}/>
                                                                    <div className="home-resource-custom-tooltip">
                                                                        <div className="home-resource-custom-tooltip-container">
                                                                            <div className="home-resource-custom-tooltip-tip"/>
                                                                            <div className="home-resource-custom-tooltip-text">
                                                                                <div className="home-resource-custom-tooltip-label">
                                                                                    Last User signedup on&nbsp;
                                                                                </div>
                                                                                <div className="home-resources-timestamp-label">
                                                                      <span className="home-resources-timestamp">
                                                                            {this.state.updateDate}
                                                                        </span>

                                                                                    &nbsp;at&nbsp;<span
                                                                                    className="home-resources-timestamp">
                                                                            {this.state.updateTime}
                                                                        </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </button>


                                                            </div>
                                                            :
                                                            null
                                                    }
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </VisibilitySensor>

                            <div className="home-summary-block white-bg">
                                {/*<div className="trusted-by-title">*/}
                                {/*    /!*Trusted by {TrustedCompanyList.length} Companies*!/*/}
                                {/*    Trusted by <span className={"trusted-count"}>97</span> Companies*/}
                                {/*</div>*/}
                                <div className="trusted-by-division">
                                    <CompanyCarousel/>
                                </div>
                            </div>

                            <div className="home-summary-block">
                                <SummaryText topic={"lifeCycle"} theme={"light"}/>
                            </div>

                            <div className="home-summary-block">
                                <SummaryText topic={"aiAppPlatform"}/>
                                <div className="home-summary-button-row">
                                    <Button onClick={this.changeRoute.bind(this, "platform")} labelText="Learn More"/>
                                </div>
                            </div>
                            <div className="home-summary-block bg-white">
                                <SummaryText topic={"solution"} theme={"light"}/>
                                <div className="home-summary-solution-card-list">
                                    {
                                        summarySolutionCard.map((solution, sid) =>
                                            <div className="home-summary-solution-card" key={sid}>
                                                <Icon icon={solution.Icon} size={50} className="home-summary-solution-icon"/>
                                                <div className="home-summary-solution-title">{solution.Title}</div>
                                                <div className="home-summary-solution-description">
                                                    {
                                                        solution.Description.map(description =>
                                                            <p key={description}>{description}</p>)
                                                    }
                                                </div>
                                            </div>
                                        )
                                    }

                                    <div className="home-summary-button-row">
                                        <Button onClick={this.changeRoute.bind(this, "solutions/it")} labelText="Learn More"/>
                                    </div>

                                </div>


                            </div>
                            <div className="home-summary-block">
                                <SummaryText topic={"collaboration"}/>
                                <div className="home-summary-button-row">
                                    <Button onClick={this.changeRoute.bind(this, "platform")} labelText="Learn More"/>
                                </div>

                            </div>

                            <Popup
                                style={{
                                    background: "transparent",
                                    padding: 0,
                                    width: "auto",
                                }} closeOnDocumentClick={true}
                                lockScroll={true}
                                onClose={() => {
                                    this.setState({fullScreenHeroImage: false})
                                }}
                                open={this.state.fullScreenHeroImage}>
                                <div className="hero-image-full-screen">
                                    <div className="hero-image-toolbar">
                                        <button className="hero-image-exit-btn" onClick={() => {
                                            this.setState({fullScreenHeroImage: false})
                                        }}>
                                            <Icon icon={"close"} size={24}/>
                                        </button>

                                    </div>
                                    <div className="hero-image-container">
                                        <img src={HomeImage} className="hero-image-full" alt={"Model Prediction Screenshot"}/>
                                    </div>
                                </div>
                            </Popup>

                            <SysPopup Open={this.state.choosePersonaPopup} Title={"Get Started"} onClose={() => {
                                this.setState({choosePersonaPopup: false})
                            }}>
                                <div className="choose-persona-popup">
                                    <div className="choose-persona-popup-header">
                                        <div className="choose-persona-popup-header--title">
                                            Persona
                                        </div>
                                        {/*<div className="choose-persona-popup-description">*/}
                                        {/*    lorem ipsum dip amet silir dol kun jusd lorem ipsum dip amet silir dol kun jusd lorem ipsum dip amet silir dol kun jusd*/}
                                        {/*    <ul className="choose-persona-popup-persona-list">*/}
                                        {/*        {*/}
                                        {/*            this.state.personaList.map((res,index)=>{*/}
                                        {/*                return(*/}
                                        {/*                    <li className="choose-persona-list-item">*/}
                                        {/*                        <span className="choose-persona-list-item-type">*/}
                                        {/*                            <div className="choose-persona-list-item-type-name">*/}
                                        {/*                                {res.Name}:*/}
                                        {/*                            </div>*/}
                                        {/*                            <div className="choose-persona-list-item-type-description">*/}
                                        {/*                                {res.description}*/}
                                        {/*                            </div>*/}
                                        {/*                        </span>*/}

                                        {/*                    </li>*/}
                                        {/*                )*/}
                                        {/*            })*/}
                                        {/*        }*/}

                                        {/*    </ul>*/}
                                        {/*</div>*/}
                                    </div>
                                    <div className="choose-persona-popup-body">
                                        <div className="choose-persona-card-list">
                                            {
                                                this.state.personaList.map((res, index) =>
                                                    <div
                                                        className={res.Name === this.state.chosenPersona ? "choose-persona-card-item active" : res.disabled ? "choose-persona-card-item disabled" : "choose-persona-card-item"}
                                                        title={res.disabled ? res.Name + " is not available yet." : "click to choose " + res.Name}
                                                        onClick={() => {
                                                            this.setState({
                                                                chosenPersona: res.Name,
                                                                chosenLoginOption: res.option[0].value
                                                            })
                                                        }

                                                        }
                                                        key={index}>
                                                        <div className="choose-persona-card-header">
                                                            <div className="choose-persona-card-header-title">
                                                                {res.Name}
                                                                {/*<Icon icon={"info"} className={"choose-persona-title-info-icon"}/>*/}
                                                            </div>
                                                            <div className="choose-persona-card-illustration-container">
                                                                <img alt={res.Name + " Image"} src={res.Illustration}
                                                                     className="choose-persona-card-illustration"/>
                                                            </div>
                                                        </div>
                                                        <div className="choose-persona-card-body">
                                                            {
                                                                res.option.map((option, oid) => {
                                                                    return (
                                                                        <div
                                                                            key={index}
                                                                            className={this.state.chosenPersona === res.Name ? "choose-persona-card-option-item" : "choose-persona-card-option-item disabled"}>
                                                                            <Radio size={"small"}
                                                                                   color="primary"
                                                                                   disabled={this.state.chosenPersona !== res.Name || option.disabled}
                                                                                   checked={option.value === this.state.chosenLoginOption}
                                                                                   onChange={() => {
                                                                                       console.log(option.value);
                                                                                       this.setState({chosenLoginOption: option.value})
                                                                                   }}
                                                                                   className={this.state.chosenPersona !== res.Name || option.disabled ? "choose-persona-card-option-item-radio disabled" : "choose-persona-card-option-item-radio"}/>
                                                                            <span
                                                                                style={{color: this.state.chosenPersona !== res.Name || option.disabled ? "#404040" : "var(--light-sky)"}}>{option.label}
                                                                                {
                                                                                    option.disabled && <span
                                                                                        className="choose-persona-card-coming-soon-mark">*</span>
                                                                                }
                                                            </span>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                            {
                                                                this.state.chosenPersona === res.Name ?
                                                                    <Icon icon={"check"}
                                                                          className="choose-persona-card-check-mark"/>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>

                                    <div className="choose-persona-footer-row">
                                        <span className="choose-persona-card-coming-soon-mark">*</span> Coming Soon
                                    </div>
                                    <div className="choose-persona-bottom-row">
                                        <GhostButton onClick={() => {
                                            this.setState({choosePersonaPopup: false})
                                        }} backgroundColor={"white"} color={"#09092d"} label={"Cancel"}/>
                                        <Button onClick={this.getStartedHandler.bind(this)} backgroundColor={"var(--primary-color)"}
                                                labelText={"Continue"}/>
                                    </div>
                                </div>
                            </SysPopup>
                        </>
                        :
                        <div className="home-loading-page">
                            <div className="main-page-loading">
                                <div className='two-circle-loading-container'>
                                    <div className='rotating-loading-spinner'>
                                    </div>
                                </div>
                            </div>
                        </div>

                }
            </div>
        );
    }
}

export default Home;