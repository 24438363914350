import React from 'react';

class YourLicenseToUseTheServices extends React.Component{
    render() {
        return (
            <div className="terms-and-conditions-sub-section">
                <div className="sub-section-title">3.&nbsp;Your License to Use the Services</div>
                <div className="align-terms-paragraph">
                    Subject to your strict compliance with these Terms and Conditions, BlinX grants you a limited, personal, non-exclusive, revocable, non-assignable, and non-transferable right and license use the Services.
                </div>
                <div className="align-terms-paragraph">
                    You may use the Services in order to generate text, software code, graphics, software, photographs, videos, data, and other materials (collectively “Content”), view Content, share and download Content using the features of the Services where the appropriate functionality has been enabled.
                </div>
                <div className="align-terms-paragraph">
                    The above licenses are conditional upon your strict compliance with these Terms and Conditions including, without limitation, the following:
                </div><br/>
                <ol type={"i"} className="align-terms-paragraph" style={{gap:15}}>
                    <li>
                        You must not copy, rip or capture, or attempt to copy, rip or capture, any Content from the Services or any part of the Services, other than by means of download or sharing in circumstances where we have elected to permit downloads and sharing of the relevant Content.
                    </li><br/>
                    <li>
                        You must not employ scraping or similar techniques to aggregate, repurpose, republish or otherwise make use of any Content.
                    </li><br/>
                    <li>
                        You must not alter or remove, attempt to alter or remove any trademark, copyright or other proprietary or legal notices contained in, or appearing on, the Services or any Content appearing on the Services (other than your Content).
                    </li><br/>
                    <li>
                        You must not, and must not permit any third party to, copy or adapt the object code of the Website or any of the Services, or reverse engineer, reverse assemble, decompile, modify or attempt to discover any source or object code of any part of the Services, or circumvent or attempt to circumvent or copy any copy protection mechanism or access any rights management information pertaining to Content other than your Content.
                    </li><br/>
                    <li>
                        You must not use the Services to upload, post, store, transmit, display, copy, distribute, promote, make available or otherwise communicate to the public:
                        <ul type="circle"><br/>
                            <li>
                                any Content that is offensive, abusive, libelous, defamatory, obscene, racist, ethnically or culturally offensive, indecent, that promotes violence, terrorism, or illegal acts, incites hatred on grounds of race, gender, religion or sexual orientation, or is otherwise objectionable in the Company’s reasonable discretion;
                            </li><br/>
                            <li>
                                any information, Content, or other material that violates, plagiarizes, misappropriates, or infringes the rights of third parties including, without limitation, copyright, trademark rights, rights of privacy or publicity, confidential information or any other right; or
                            </li><br/>
                            <li>
                                any Content that violates, breaches, or is contrary to any law, rule, regulation, court order, or is otherwise is illegal or unlawful in the Company’s reasonable opinion;
                            </li><br/>
                            <li>
                                any material of any kind that contains any virus, Trojan horse, spyware, adware, malware, bot, time bomb, worm, or other harmful or malicious component, which actually or potentially could overburden, impair or disrupt the Services or servers or networks forming part of, or connected to, the Services, or which actually or potentially could restrict or inhibit any other user's use and enjoyment of the Services; or
                            </li><br/>
                            <li>
                                any unsolicited or unauthorized advertising, promotional messages, spam or any other form of solicitation.
                            </li>
                        </ul>
                    </li><br/>
                    <li>
                        You must not commit or engage in, or encourage, induce, solicit or promote, any conduct that would constitute a criminal offence, give rise to civil liability or otherwise violate any law or regulation.
                    </li><br/>
                    <li>
                        You must not deliberately impersonate any person or entity or otherwise misrepresents your affiliation with a person or entity, for example, by registering an account in the name of another person or company or sending messages or making comments using the name of another person.
                    </li>
                </ol>
                <div className="align-terms-paragraph">
                    You agree to comply with the above conditions and acknowledge and agree that BlinX has the right, in its sole discretion, to terminate your account or take such other action as we see fit if you breach any of the above conditions or any of the other terms of these Terms and Conditions. This may include taking court action and/or reporting offending users to the relevant authorities.
                </div>
            </div>
        );
    }
}

export default YourLicenseToUseTheServices;