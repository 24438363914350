import React from "react";
import './termsAndCondition.css';

class CompleteAgreement extends React.Component{
    render() {
        return (
            <div className="terms-and-conditions-sub-section">
                <div className="sub-section-title">9.&nbsp;Complete Agreement</div>
                <div className="align-terms-paragraph">
                    These Terms constitute the entire agreement between you and BlinX with respect to the use of the BlinX Site and Content. Your use of the BlinX Services is also subject to the BlinX Privacy Policy. If any provision of these Terms is found to be invalid by any court having competent jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions, which shall remain in full force and effect. No waiver of any of these Terms shall be deemed a further or continuing waiver of such term or condition or any other term or condition.
                </div>
            </div>
        );
    }
}
export default CompleteAgreement;