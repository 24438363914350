import React from "react";
import axios from 'axios';
import "./resources.css"
import Searchbox from "../../Assets/Searchbox/Searchbox";
import SummaryText from "../Commoncomponents/SummaryText/SummaryText";
import {CheckEmpty, CheckValidity} from "../../Assets/CommonMethods";
import VerticalEmptyScreen from "../../Assets/EmptyScreen/VerticalEmptyScreen";
import emptyVideo from "../../Assets/Images/no-result-found.png"
import RotatingCircle from "../../Assets/Loading/RotatingCircle";
import MetaData from "../MetaData/MetaData";



const API = 'AIzaSyCIVOpJPRqnUWP1-tsc9f8l4VQA1ZmVmZM';
const shortsId = 'PLOQ-vBdlMumShree4UCioJ7dUVwWJnQUX';
const productsId = 'PLOQ-vBdlMumRjDrdBXLdQ60ToGtY4nv4q';
const useCasesId = 'PLOQ-vBdlMumQU-crCwbHlPuv5Q7bvL0zF';
const result = 100;

const URLShorts = `https://www.googleapis.com/youtube/v3/playlistItems?key=${API}&playlistId=${shortsId}&part=snippet,id&order=date&maxResults=${result}`;
const URLProducts = `https://www.googleapis.com/youtube/v3/playlistItems?key=${API}&playlistId=${productsId}&part=snippet,id&order=date&maxResults=${result}`;
const URLUseCases= `https://www.googleapis.com/youtube/v3/playlistItems?key=${API}&playlistId=${useCasesId}&part=snippet,id&order=date&maxResults=${result}`;
class Resource extends React.Component {
    constructor() {
        super();
        this.state = {
            videos: [],
            mainVideoList:[],
            videoTitle:[],
            tabList: [
                {Label: "Product Videos", Value: "product-videos"},
                {Label: "Use Cases", Value: "use-cases"},
                {Label: "Shorts", Value: "shorts"},
            ],
            chosenTab: "Product Videos",
            searchValue: "",
            res: "",
            searchResultCount: 10,
            showEmptyPage: false,
            isLoaded: false,
        }
    }
    componentDidMount(){
        let {chosenTab} = this.props;
        let URL = chosenTab === "Product Videos"? URLProducts : chosenTab === "Use Cases"? URLUseCases : URLShorts;
        axios.get(URL)
            .then(res => {
                const video = res.data.items.map(videoList => videoList.snippet.resourceId.videoId);    //playlist
                const videoTitle = res.data.items.map(videoList => videoList.snippet.title);    //playlist
                this.filterVideo(video, videoTitle)
                console.log(video)
            })


    }

    changePlaylist=(tab) =>{
        this.setState({
            isLoaded: false
        })

        this.props.history.push(`/resources/${tab}`)

        if(tab === 'shorts'){
            axios.get(URLShorts)
                .then(res => {
                    const video = res.data.items.map(videoList => videoList.snippet.resourceId.videoId);    //playlist
                    const videoTitle = res.data.items.map(videoList => videoList.snippet.title);    //playlist
                    this.filterVideo(video, videoTitle)
                })
            this.setState({chosenTab:tab})
        }
        else if(tab === 'product-videos'){
            axios.get(URLProducts)
                .then(res => {
                    const video = res.data.items.map(videoList => videoList.snippet.resourceId.videoId);    //playlist
                    const videoTitle = res.data.items.map(videoList => videoList.snippet.title);    //playlist
                    this.filterVideo(video, videoTitle)
                })
            this.setState({chosenTab:tab})
        }
        else if(tab === 'use-cases'){
            axios.get(URLUseCases)
                .then(res => {
                    const video = res.data.items.map(videoList => videoList.snippet.resourceId.videoId);    //playlist
                    const videoTitle = res.data.items.map(videoList => videoList.snippet.title);    //playlist
                    this.filterVideo(video, videoTitle)
                    console.log(video)
                })
            this.setState({chosenTab:tab})
        }

    }

    filterVideo = (videos, videoTitle) =>{
        console.log(">> Filter Video")

        let videoList = [];
        let mainVideoList = videos;
        let{searchValue} = this.state;

        if(CheckValidity(searchValue) && CheckEmpty(searchValue)){
            for(let i=0;i<videos.length;i++){
                if(videoTitle[i].toLowerCase().includes(searchValue.toLowerCase())){
                    videoList.push(videos[i])
                }
            }

            this.setState({
                videos: videoList,
                mainVideoList: mainVideoList,
                videoTitle: videoTitle,
                isLoaded: true,
            })
        }
        else{

            this.setState({
                videos: videos,
                mainVideoList: mainVideoList,
                videoTitle: videoTitle,
                isLoaded: true,
            })
        }
    }

    onSearch = (searchValue) =>{
        let {videos, videoTitle, mainVideoList} = this.state;
        console.log("OnSearch >> ", searchValue)
        console.log("OnSearch >> mainVideoList", mainVideoList)

        let videoList = [];
        this.setState({

            isLoaded: false,
        })

        if(CheckValidity(searchValue) && CheckEmpty(searchValue)){

            for(let i=0;i<mainVideoList.length;i++){
                if(videoTitle[i].toLowerCase().includes(searchValue.toLowerCase())){
                    videoList.push(mainVideoList[i])
                }
            }
            this.setState({
                videos:videoList,
                searchValue,
                isLoaded: true
            })

        }
        else{
            this.setState({
                isLoaded: true,
                videos: mainVideoList,
                searchValue,
            })
        }
    }

    render() {
        let {tabList, searchValue, isLoaded, videos} = this.state;
        let {chosenTab} = this.props;
        return (
            <div className="resources-layout">
                <MetaData keyword={
                    chosenTab === "Product Videos"?
                        "product-videos"
                        :
                        chosenTab === "Use Cases"?
                            "use-cases"
                            :
                            "shorts"}/>
                <SummaryText topic="resources"/>
                <div className="resources-tab-bar">
                    <div className="resources-tab-list">
                        {
                            tabList.map((tab) =>
                                <button className={tab.Label === chosenTab ? "resources-tab active" : "resources-tab"}
                                        onClick={() => this.changePlaylist(tab.Value)}>
                                    {tab.Label}
                                </button>
                            )
                        }
                    </div>
                    <div className="resource-search-bar">
                        <Searchbox placeholder={"Search Video"} onSearch={this.onSearch.bind(this)}/>
                    </div>

                </div>
                {
                    isLoaded?
                        <div className='resources-video-layout'>
                            {
                                CheckValidity(videos) && CheckEmpty(videos)?
                                    videos.map((videoList, vid) =>
                                        <div className="video-iframe-container">
                                            <div className="iframe-loading"/>
                                            <iframe
                                                width="400"
                                                height="230"
                                                src={`https://www.youtube.com/embed/${videoList}`}
                                                frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullScreen
                                                title="Embedded youtube"
                                            />
                                        </div>

                                    )
                                    :
                                    <VerticalEmptyScreen image={emptyVideo} bodyContext={"There is no video corresponding to your search"} header={"No Result!"}/>

                            }

                        </div>
                        :
                        <div className="resource-empty-page">
                            <RotatingCircle loadingText={"Loading"}/>
                        </div>
                }

            </div>
        )
    }
}

export default Resource;