import React from "react";
import "./requestDemo.css";
import Icon from "../../Assets/IconLibrary/Icon";
import Button from "../../Assets/Buttons/Button";
import GhostButton from "../../Assets/Buttons/GhostButton";
import Popup from "reactjs-popup";
import LottieAnimation from "../../Assets/Lotties/LottieAnimation";
import {renderSwitchEmailService, renderSwitchOrgService} from "../../api/ResourcesApiLayer";
import {ApiList} from "../../api/ApiCallMethod";
import {ReactComponent as RequestDemoImage} from "../../Assets/Images/requestDemo.svg";

class RequestDemo extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            fName: "",
            lName: "",
            companyName: "",
            email: "",
            phNo: "",
            meassage: "",

            openPopup: false,
            popupType: "",
            popupMessage: "",
            popupTitle: "",

            sendingRequest: true,
            openSendRequestPopup: false,
            disableBtn: true
        }
    }

    checkData = (firstName, lastName, companyName, email, phNo, message) => {
        let disableBtn = false
        if (firstName === "" || lastName === "" || companyName === "" || email === "" || phNo === "" || message === "") {
            disableBtn = true
        }
        return disableBtn
    }

    handleChangeValue(event) {
        let eventName = event.target.name
        let eventValue = event.target.value
        let disableBtn = this.checkData(eventName === "fName" ? eventValue : this.state.fName,
            eventName === "lName" ? eventValue : this.state.lName,
            eventName === "companyName" ? eventValue : this.state.companyName,
            eventName === "phNo" ? eventValue : this.state.phNo,
            eventName === "message" ? eventValue : this.state.message,
            eventName === "email" ? eventValue : this.state.email,)
        this.setState({
            [eventName]: eventValue,
            disableBtn: disableBtn
        })
    }

    handleCloseForm() {
      this.setState({
          fName: "",
          lName: "",
          companyName: "",
          email: "",
          phNo: "",
          message: "",
          disableBtn: true
      })
    }

    sendMailForRequestDemo() {
        //check all fields

        if (this.state.fName === "" || this.state.lName === "" || this.state.email === "" || this.state.phNo === "" || this.state.message === "") {
            this.setState({
                openPopup: true,
                popupType: "fail",
                popupTitle: "Warning",
                popupMessage: "Please fill all fields.",
            })
        } else {
            this.setState({
                disableBtn : true
            })
            //check phone number
            let isValid = true
            if (typeof this.state.phNo !== "undefined") {
                let pattern = new RegExp(/^[0-9\b]+$/);
                if (!pattern.test(this.state.phNo)) {
                    isValid = false;
                }
                // else if(this.state.phNo.length !== 10){
                //     isValid = false;
                // }
            }
            if (!isValid){
                this.setState({
                    openSendRequestPopup: false,
                    openPopup: true,
                    popupType: "fail",
                    popupTitle: "Invalid Phone Number",
                    popupMessage: "Please enter a valid phone number"
                })
            }else{
                //1# valid email
                let uri = `?email=${this.state.email}`
                renderSwitchEmailService(ApiList().checkEmail, "", '', uri).then(response => {
                    // console.log("response data : ", response.data)
                    if (response.data["ResponseCode"] === 1000) {
                        let result = response.data["Results"]
                        if (result['Deliverable'] === true && result['ValidFormat'] && result['HostExists'] === true) {
                            //valid case

                            this.setState({
                                openSendRequestPopup: true,
                                sendingRequest: true,
                                disableBtn : false
                            })
                            let inputData = {
                                FName: this.state.fName,
                                LName: this.state.lName,
                                PhNo: this.state.phNo,
                                Email: this.state.email,
                                Company: this.state.companyName,
                                Message: this.state.message
                            }

                            renderSwitchOrgService(ApiList().sendEmailForDemo, inputData, "", "").then(response => {
                                // console.log("send email response : ", response.data)
                                if (response.data["ResponseCode"] === 1000) {
                                    this.setState({
                                        sendingRequest: false
                                    })
                                } else {
                                    this.setState({
                                        openSendRequestPopup: false,
                                        openPopup: true,
                                        popupType: "fail",
                                        popupTitle: "System Error",
                                        popupMessage: "Sorry, we couldn't complete your request. Please try again and if it doesn't work, contact our support team."
                                    })
                                }
                            }).catch(err => {
                                // console.log("request demo sending email is failed", err)
                                this.setState({
                                    openSendRequestPopup: false,
                                    openPopup: true,
                                    popupType: "fail",
                                    popupTitle: "System Error",
                                    popupMessage: "Sorry, we are not able to proceed the process at the current. Please try again later."
                                })
                            })
                        } else {
                            this.setState({
                                openSendRequestPopup: false,
                                openPopup: true,
                                popupType: "fail",
                                popupTitle: "",
                                popupMessage: "Email is not valid. Please enter valid email."
                            })
                        }
                    } else {
                        this.setState({
                            openSendRequestPopup: false,
                            openPopup: true,
                            popupType: "fail",
                            popupTitle: "Error",
                            popupMessage: "Email is not valid. Please enter valid email."
                        })
                    }
                }).catch(err => {
                    this.setState({
                        disableBtn : false,
                        openPopup: true,
                        popupType: "fail",
                        popupTitle: "System Error",
                        popupMessage: "Cannot proceed to request the demo at the current."
                    })
                })
            }
        }
    }

    handleCloseSendingRequestPopup() {
        this.setState({
            openSendRequestPopup: false,
            sendingRequest: false
        }, () => {
            this.setState({
                openPopup: false,
            })
        })
    }

    handleCloseOpenPopup() {
        this.setState({
            openPopup: false
        })
    }

    render() {
        const {sendingRequest} = this.state;
        return (
            <div className="request-demo-layout">
                <div className="request-demo-image">
                    <RequestDemoImage alt={'Request Demo'}/>
                </div>

                <div className="request-demo-form">
                    <div className="request-demo-row">
                        <Icon icon="user" className="request-demo-icon"/>
                        <div className="request-demo-input-division">
                            <input required
                                   type="text"
                                   className="request-demo-input-box half"
                                   id="first_name"
                                   name={"fName"}
                                   value={this.state.fName}
                                   onChange={this.handleChangeValue.bind(this)}
                                   placeholder="First Name*"/>
                            <input required
                                   type="text"
                                   className="request-demo-input-box half"
                                   id="last_name"
                                   name="lName"
                                   value={this.state.lName}
                                   placeholder="Last Name*"
                                   onChange={this.handleChangeValue.bind(this)}/>
                        </div>
                    </div>
                    <div className="request-demo-row">
                        <Icon icon="company" className="request-demo-icon"/>
                        <input required type="text"
                               className="request-demo-input-box"
                               id="company_name"
                               placeholder="Company Name*"
                               name={"companyName"} value={this.state.companyName}
                               onChange={this.handleChangeValue.bind(this)}/>
                    </div>
                    <div className="request-demo-row">
                        <Icon icon="email" className="request-demo-icon"/>
                        <input required type="email"
                               className="request-demo-input-box"
                               id="email" placeholder="Email*"
                               name={"email"}
                               value={this.state.email} onChange={this.handleChangeValue.bind(this)}/>
                    </div>
                    <div className="request-demo-row">
                        <Icon icon="phone" className="request-demo-icon"/>
                        <input required type="text"
                               className="request-demo-input-box"
                               id="phone_number"
                               placeholder="Phone Number*"
                               name={"phNo"} value={this.state.phNo}
                               onChange={this.handleChangeValue.bind(this)}/>
                    </div>
                    <div className="request-demo-row">
                        <Icon icon="message" className="request-demo-icon"/>
                        <textarea className="request-demo-textarea"
                                  id="message_text"
                                  name="message"
                                  placeholder="Message*"
                                  onChange={this.handleChangeValue.bind(this)}
                                  value={this.state.message}/>
                    </div>

                    <div className="request-demo-bottom-row">
                        <GhostButton onClick={this.handleCloseForm.bind(this)} label="Cancel"/>
                        <Button disabled={this.state.disableBtn} onClick={this.sendMailForRequestDemo.bind(this)} labelText={"Submit"}/>
                    </div>
                </div>

                <Popup contentStyle={{
                    width: "auto",
                    background: "transparent",
                    padding: 0,
                    border: "none"
                }}
                       open={this.state.openSendRequestPopup}
                       closeOnDocumentClick={false}
                       lockScroll={false}>

                    <div className="email-send-popup">
                        {
                            sendingRequest ?
                                null
                                :
                                <button onClick={this.handleCloseSendingRequestPopup.bind(this)}
                                        className="email-send-close-btn">
                                    X
                                </button>
                        }
                        {
                            sendingRequest ?
                                <div className="email-send-image">

                                    <LottieAnimation lotti={"SendingMessage"}/>
                                </div>
                                :
                                <div className="email-send-image success">

                                    <LottieAnimation lotti={"SuccessMark"}/>
                                </div>

                        }
                        <div className={sendingRequest ? "email-send-text-title success" : "email-send-text-title"}>
                            {
                                sendingRequest ?
                                    "Sending Request"
                                    :
                                    "Successful"
                            }
                        </div>
                        <div className="email-send-text-body">

                            {
                                sendingRequest ?
                                    "We're in the process of sending your request. Please wait a few seconds..."
                                    :
                                    "Your request for a demo was successful. We'll be sending you an e-mail shortly. If you need any help in the meantime, feel free to contact us at any time."
                            }

                        </div>
                    </div>

                </Popup>

                <Popup contentStyle={{
                    width: "auto",
                    background: "transparent",
                    padding: 0,
                    border: "none"
                }}
                       open={this.state.openPopup}
                       closeOnDocumentClick={false}
                       lockScroll={false}>

                    <div className="email-send-popup">
                        <button onClick={this.handleCloseOpenPopup.bind(this)}
                                className="email-send-close-btn">
                            X
                        </button>
                        <div className="email-send-image success">
                            <LottieAnimation lotti={"Fail"}/>
                        </div>
                        <div className={"email-send-text-title fail"}>
                            {this.state.popupTitle}
                        </div>
                        <div className="email-send-text-body">
                            {this.state.popupMessage}
                        </div>
                    </div>

                </Popup>

            </div>

        );
    }
}

export default RequestDemo;