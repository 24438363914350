import React from 'react';
import './termsAndCondition.css'

class AcceptanceOfTermsAndCondition extends React.Component {
    render() {
        return (
            <div className="terms-and-conditions-sub-section">
                <div className="sub-section-title">1.&nbsp;Acceptance of Terms and Condition</div>
                <div className="align-terms-paragraph">
                    Please read these Terms and Conditions, and our Privacy Policy, very carefully. When prompted,
                    please click or press “Accept” if you agree to be legally bound by all the terms and conditions
                    herein. Your acceptance of these Terms and Conditions creates a legally binding contract between you
                    and the Company. If you do not agree with any aspect of these Terms and Conditions, then do not
                    click or press “Accept,” in which case you may not use the Services. By accepting the Terms and
                    Conditions and creating an account, you represent and warrant that the information you include on
                    the Website is accurate and that you have the capacity to enter into and abide by these terms and
                    conditions.
                </div>
            </div>
        )
    }
}

export default AcceptanceOfTermsAndCondition;