import React from 'react'
import './modelTester.css'
import {Scrollbars} from "react-custom-scrollbars";
import NotReadyDataImage from '../../../Assets/Images/pictures/notReadyData.svg'
import VerticalEmptyScreen from "../../../Assets/EmptyScreen/VerticalEmptyScreen";
import Button from "../../../Assets/Buttons/Button";


class ModelTesterResult extends React.Component {
    constructor(props) {
        super(props);
        let {showPredictResult,modelTestResultData,outputColumnNameArray,currentResultColumnName,width,inputView} = this.props
        this.state = {
            showPredictResult,
            modelTestResultData,
            outputColumnNameArray,
            currentResultColumnName,
            width,
            inputView
        }
    }

    componentDidMount() {
        let {showPredictResult,modelTestResultData,outputColumnNameArray,currentResultColumnName,width,inputView} = this.props
        this.state = {
            showPredictResult,
            modelTestResultData,
            outputColumnNameArray,
            currentResultColumnName,
            width,
            inputView
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        let {showPredictResult,modelTestResultData,outputColumnNameArray,currentResultColumnName,width,inputView} = nextProps
        this.state = {
            showPredictResult,
            modelTestResultData,
            outputColumnNameArray,
            currentResultColumnName,
            width,
            inputView
        }
    }
    checkValidity = (checkItem) => {
        return(checkItem !== undefined && checkItem !== null && checkItem !== "")
    }
    checkEmpty = (checkItem) => {
        return(checkItem.length !== 0)
    }

    handleChangeOutputColumn(event) {
        if(this.checkValidity(this.props.updateViewOutputColumn)){
            this.props.updateViewOutputColumn(event.target.value)
        }
        this.setState({
            currentResultColumnName: event.target.value
        })
    }
    handleClickBack(){
        this.props.onBack()
    }
    render() {
        let {showPredictResult,modelTestResultData,outputColumnNameArray,currentResultColumnName,width,inputView} = this.state
        let styleValue = width < 992? "calc(100% - 50px)" :"100%"
        return (
            <>
                {
                    showPredictResult?
                        this.checkValidity(modelTestResultData)?
                            this.checkEmpty(modelTestResultData)?
                                <div className='model-tester-result-container'>
                                    <div className='model-tester-result-header'>
                                        Prediction Result
                                    </div>
                                    {/*if back button is appear, height will be calc(100% - 50px) if not 100%*/}
                                    <div className='model-tester-result-table' style={{height: styleValue}}>
                                        < Scrollbars
 renderThumbVertical={({style, ...props}) =>
                                <div {...props} style={{...style, width: '5px', borderRadius: '4px',backgroundColor: '#76a1d5'}}/>
                            } style={{width: '100%', height: '100%'}}>
                                            <table>
                                                <thead>
                                                <tr>
                                                    {
                                                        modelTestResultData.map(inputColumnName =>
                                                            inputColumnName.OutputColumn === false?
                                                               <th>{inputColumnName.ColumnName}</th>
                                                                :
                                                                null
                                                        )
                                                    }
                                                    {
                                                        this.checkValidity(outputColumnNameArray)?
                                                            this.checkEmpty(outputColumnNameArray)?
                                                                outputColumnNameArray.length === 1?
                                                                    modelTestResultData.map(inputColumnName =>
                                                                        inputColumnName.OutputColumn === true?
                                                                            <th>{inputColumnName.ColumnName}</th>
                                                                            :
                                                                            null
                                                                    )
                                                                    :
                                                                    <th>
                                                                        <select className='model-tester-output-result'
                                                                            onChange={this.handleChangeOutputColumn.bind(this)}
                                                                            value={this.state.currentResultColumnName}>
                                                                            {
                                                                                outputColumnNameArray.map((outputColumn,index) =>
                                                                                    <option>{outputColumn}</option>
                                                                                )
                                                                            }
                                                                        </select>
                                                                    </th>
                                                                :
                                                                null
                                                            :
                                                            null
                                                    }


                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    this.checkValidity(modelTestResultData)?
                                                        this.checkEmpty(modelTestResultData)?
                                                            modelTestResultData[0].Values.map((row,rowIndex) =>
                                                                <tr>
                                                                    {
                                                                        modelTestResultData.map((modelTesterRowList,index) =>
                                                                            modelTesterRowList.Values.map((val,valIndex)=>
                                                                                valIndex === rowIndex?
                                                                                    modelTesterRowList.OutputColumn === false?
                                                                                        <td>{modelTesterRowList.Values[valIndex]}</td>
                                                                                        :
                                                                                       null
                                                                                    :
                                                                                    null
                                                                            )

                                                                        )

                                                                    }
                                                                    {
                                                                        modelTestResultData.map((modelTesterRowList,index) =>
                                                                            modelTesterRowList.Values.map((val,valIndex)=>
                                                                                valIndex === rowIndex?
                                                                                    modelTesterRowList.OutputColumn === true?
                                                                                        modelTesterRowList.ColumnName === currentResultColumnName?
                                                                                            <td className='model-tester-result-output'> {modelTesterRowList.Values[valIndex]}</td>
                                                                                            :
                                                                                            null
                                                                                        :
                                                                                        null
                                                                                    :
                                                                                    null
                                                                            )
                                                                        )
                                                                    }
                                                                </tr>
                                                              )
                                                            :
                                                            null
                                                        :
                                                        null
                                                }
                                                </tbody>

                                            </table>
                                        </Scrollbars>
                                    </div>
                                    {
                                        width < 992?
                                            <div className="model-tester-result-table-bottom-row">
                                                <Button onClick={this.handleClickBack.bind(this)}
                                                        labelText={"Back"} backgroundColor={"var(--primary-color)"}/>
                                            </div>
                                            :
                                            null
                                    }


                                </div>
                                :
                                <div className='model-tester-result-container'>
                                    <VerticalEmptyScreen
                                        theme={"light"}
                                        image={NotReadyDataImage}
                                        bodyContext={"No test results are displayed."}/>

                                </div>
                            :
                            <div className='model-tester-result-container'>
                                <VerticalEmptyScreen
                                    theme={"light"}
                                    image={NotReadyDataImage}
                                    bodyContext={"No test results are displayed."}/>
                            </div>
                        :
                        <div className='model-tester-result-container'>
                            <VerticalEmptyScreen
                                theme={"light"}
                                image={NotReadyDataImage}
                                bodyContext={"No test results are displayed."}/>
                        </div>

                }
            </>

        )
    }
}

export default ModelTesterResult;