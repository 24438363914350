import React from "react";
import './termsAndCondition.css'

class ApplicableLawJurisdictionDisputes extends React.Component{
    render() {
        return (
            <div className="terms-and-conditions-sub-section">
                <div className="sub-section-title">8.&nbsp;Applicable Law, Jurisdiction and Disputes</div>
                <div className="align-terms-paragraph">
                    If a dispute arises between you and BlinX, our goal is to provide you with a neutral and cost-effective means of resolving the issue quickly. In the event of a dispute, we encourage you to contact us first to resolve your problem directly with us. You may contact us regarding any complaints or disputes in the “Contact us” section below in these Terms.
                </div>
                <div className="align-terms-paragraph">
                    These Terms of Use shall be construed in accordance with and governed by the laws of the United States and the State of California, without reference to their rules regarding conflicts of law.
                </div>
                <div className="align-terms-paragraph">
                    ANY DISPUTE, CLAIM OR CONTROVERSY ARISING OUT OF OR RELATING TO THIS AGREEMENT OR THE BREACH, TERMINATION, ENFORCEMENT, INTERPRETATION OR VALIDITY THEREOF, INCLUDING THE DETERMINATION OF THE SCOPE OR APPLICABILITY OF THIS AGREEMENT TO ARBITRATE, SHALL BE DETERMINED BY ARBITRATION IN SAN FRANCISCO, CALIFORNIA BEFORE ARBITRATOR(S). THE ARBITRATION SHALL BE ADMINISTERED BY JAMS PURSUANT TO ITS COMPREHENSIVE ARBITRATION RULES AND PROCEDURES AND IN ACCORDANCE WITH THE EXPEDITED PROCEDURES IN THOSE RULES OR PURSUANT TO JAMS’ STREAMLINED ARBITRATION RULES AND PROCEDURES. JUDGMENT ON THE AWARD MAY BE ENTERED IN ANY COURT HAVING JURISDICTION. THIS CLAUSE SHALL NOT PRECLUDE PARTIES FROM SEEKING PROVISIONAL REMEDIES IN AID OF ARBITRATION FROM A COURT OF APPROPRIATE JURISDICTION.
                </div>
            </div>
        );
    }
}
export default ApplicableLawJurisdictionDisputes;