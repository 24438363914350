import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import "./index.css"
import AllRoutes from "./Components/Routes/AllRoutes";
import {BrowserRouter, Route} from "react-router-dom";

ReactDOM.render(
        <BrowserRouter>
            <Route path="/" component={AllRoutes} />
        </BrowserRouter>

    ,document.getElementById("root"));
reportWebVitals();
