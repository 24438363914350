import React from "react";
import {Route, Switch, withRouter} from 'react-router';
import Header from "../Header/Header";
import Home from "../Home/Home";
import "../../index.css"
import AboutBlinx from "../About/AboutBlinx";
import {Scrollbars} from "react-custom-scrollbars";
import IndustryList from "../Apps/IndustryList";
import Footer from "../Footer/Footer";
import Resource from "../Resource/Resource";
import DetailedAIPlatform from "../AIPlatform/DetailedAIPlatform";
import Solution from "../Solutions/Solution";
import RequestDemo from "../RequestDemo/RequestDemo";
import TermsAndCondition from "../TermsAndConditions/TermsAndCondition";
import PrivacyPolicy from "../PrivacyPolicy/PrivacyPolicy";
import BurgerMenu from "../BurgerMenu/BurgerMenu";
import InstantModel from "../InstantModel/InstantModel";
import FileUpload from "../InstantModel/FileUpload";
import {instanceOf} from 'prop-types';
import {Cookies} from "react-cookie";
import {checkValidity, GetUserSession, SetUserSession} from "../../api/ApiCallMethod";
import {DefaultParameters} from "../../api/ApiCallMethod";
import {renderSessionForCookie, revokeUserSession} from "../../api/ResourcesApiLayer";
import ErrorPages from "../ErrorPages/ErrorPages";
import PricingPlan from "../PricingPlan/PricingPlan";

class AllRoutes extends React.Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            inCheck: true,
            headerColor: "var(--body-color)",
            chosenSolutionTab: "IT",
            chosenVideoTab: "Product Videos",
            appURL: "",
            currentChosenIndustry: "aerospace",
            reloadAppListPage: true,
        }
    }

    componentDidMount() {
        this.checkURL()
        this.checkSession()
    }

    checkSession = () => {
        const {cookies} = this.props;
        // console.log("current cookies :: ", cookies)
        if (checkValidity(cookies) && checkValidity(cookies.cookies) && checkValidity(cookies.cookies[DefaultParameters().Cookie])) {
            // console.log("already defined cookie : ", cookies.cookies[DefaultParameters().Cookie])
            SetUserSession(cookies.cookies[DefaultParameters().Cookie])
        } else {
            let sessionInfo = GetUserSession()
            if (checkValidity(sessionInfo)) {
                console.log("there is already session : ", sessionInfo)
                revokeUserSession(sessionInfo)
            } else {
                // console.log("session is not there")
                this.createUserSession()
            }
        }
    }

    createUserSession = () => {
        renderSessionForCookie("getSession", "").then(response => {
            console.log("response of get session :: ", response.data)
            if (response.data["ResponseCode"] === 1000) {
                SetUserSession(response.data["Results"].SessionID)
            }
        }).catch(err => {
            console.log("catch error in calling to get session : ", err)
        })
    }


    checkURL = () => {
        let currentChosenIndustry = "";
        let currentURL = window.location.href;
        let urlArray = currentURL.split("/");
        let lastURL = urlArray[urlArray.length - 1];
        if (lastURL === "ai-app-store") {
            this.setState({
                currentPage: "industry",
            })
        } else {
            currentChosenIndustry = lastURL.replace("artificial-intelligence-in-", "");
            let currentChosenIndustryArr = currentChosenIndustry.split("-");
            for (let i = 0; i < currentChosenIndustryArr.length; i++) {
                currentChosenIndustryArr[i] = currentChosenIndustryArr[i].charAt(0).toUpperCase() + currentChosenIndustryArr[i].substr(1);
            }
            currentChosenIndustry = currentChosenIndustryArr.join(' ')
            this.setState({
                currentPage: "application",
                currentChosenIndustry,
                appURL: "ai-appstore/" + lastURL
            })
        }
    }


    onScrollHandler = (values) => {

        if (values.top === 0) {
            this.setState({headerColor: "var(--body-color)", reloadAppListPage: false})
        } else {
            this.setState({headerColor: "#1f274f", reloadAppListPage: false})
        }
    };

    manualScrollHandler = (location) => {
        console.log("location >> ", location)
        const {scrollbar} = this.refs;  // scrollbar has access to the internal API

        scrollbar.scrollTop(location); // 100px
        // or: scrollbar.scrollToBottom()
    }

    getAppURL = (appURL, currentChosenIndustry) => {
        this.setState({
            appURL,
            currentChosenIndustry,
        })
    }

    changeRoute = (route) => {
        if(route === "home"){
            this.props.history.push("/")
        }
       else  this.props.history.push(`/${route}`);
    };

    render() {
        let {appURL} = this.state;
        return (
            <div className="website-main-layout">
                <Header routingPage={(route) => this.changeRoute(route)}
                        manualScroll={this.manualScrollHandler.bind(this)}
                        backgroundColor={this.state.headerColor}/>
                <BurgerMenu routingPage={(route) => this.changeRoute(route)}
                            manualScroll={this.manualScrollHandler.bind(this)}
                            backgroundColor={this.state.headerColor}/>
                < Scrollbars
                    renderThumbVertical={({style, ...props}) =>
                        <div {...props} style={{...style, width: '5px', borderRadius: '4px',backgroundColor: '#76a1d5'}}/>
                    } style={{height: 'calc(100% - 80px)', width: '100%'}}
                    ref='scrollbar'
                    onScrollFrame={this.onScrollHandler.bind(this)}
                >
                    <div className="website-main-body">
                        <Switch>
                            <Route path='/' component={Home} exact/>
                            <Route path='/platform' component={DetailedAIPlatform} exact/>
                            <Route path='/ai-app-store'
                                   render={
                                       (props) => <IndustryList {...props}
                                                                manualScroll={this.manualScrollHandler.bind(this)}
                                                                currentPage={"industry"}
                                                                getAppURL={this.getAppURL.bind(this)}/>}
                                   exact/>
                            <Route path='/solutions/it'
                                   render={
                                       (props) => <Solution {...props} chosenTab="IT"/>}
                                   getAppURL={this.getAppURL.bind(this)} exact/>


                            <Route path='/solutions/business-function'
                                   render={
                                       (props) => <Solution {...props}
                                                            getAppURL={this.getAppURL.bind(this)}
                                                            chosenTab="Business Functions"/>} exact/>

                            <Route path='/solutions/industries'
                                   render={
                                       (props) => <Solution {...props}
                                                            getAppURL={this.getAppURL.bind(this)}
                                                            chosenTab="Industries"/>} exact/>

                            <Route path='/resources/product-videos'
                                   render={
                                       (props) => <Resource {...props}
                                                            chosenTab="Product Videos"/>} exact/>

                            <Route path='/resources/use-cases'
                                   render={
                                       (props) => <Resource {...props}
                                                            chosenTab="Use Cases"/>} exact/>


                            <Route path='/resources/shorts'
                                   render={
                                       (props) => <Resource {...props}
                                                            chosenTab="Shorts"/>} exact/>


                            <Route path='/about-us' component={AboutBlinx} exact/>
                            <Route path='/request-demo' component={RequestDemo} exact/>
                            <Route path='/terms-and-conditions' component={TermsAndCondition} exact/>
                            <Route path='/privacy-policy' component={PrivacyPolicy} exact/>
                            <Route path='/contact' component={AboutBlinx} exact/>
                            <Route path='/pricing-plan' component={PricingPlan} exact/>
                            <Route path='/instant-model'
                                   render={
                                       (props) => <InstantModel {...props}
                                                                manualScroll={this.manualScrollHandler.bind(this)}/>}
                                   exact
                            />
                            <Route path='/upload' component={FileUpload} exact/>

                            <Route path={"/" + appURL}
                                   render={
                                       (props) => <IndustryList {...props}
                                                                manualScroll={this.manualScrollHandler.bind(this)}
                                                                currentPage={"application"}
                                                                currentChosenIndustry={this.state.currentChosenIndustry}
                                                                getAppURL={this.getAppURL.bind(this)}/>}
                                   exact/>
                            <Route component={ErrorPages} exact/>
                        </Switch>
                    </div>

                    <Footer manualScroll={this.manualScrollHandler.bind(this)}/>

                </Scrollbars>
            </div>
        )

    }
}


export default withRouter(AllRoutes)