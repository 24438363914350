import * as React from "react";
import './AboutBlinx.css'
import {Helmet} from "react-helmet";
import Icon from "../../Assets/IconLibrary/Icon";
import Popup from "reactjs-popup";
import Button from "../../Assets/Buttons/Button";
import {Scrollbars} from "react-custom-scrollbars";
import MetaData from "../MetaData/MetaData";
import DonDuet from '../../Assets/Images/About/Don-Duet-300x300.svg'
import Suresh from '../../Assets/Images/About/suresh.svg'
import JiaZeng from '../../Assets/Images/About/jia-Zeng.png'
import Hari from "../../Assets/Images/About/Hari-Harikrishnan-300x300.svg";
import Vivian from "../../Assets/Images/About/Vivian-Schneck-Last.svg";
import {ReactComponent as StartUp} from "../../Assets/Images/About/tech_startup_team_meet_up.svg";
import Team from "../../Assets/Images/About/team_pic.jpg";

class AboutBlinx extends React.Component {
    constructor() {
        super();
        this.state = {
            autoPlay: false,
            contextPopup:false,
            contextPopupTitle: "About Us",
            contextPopupImage:StartUp,
            contextPopupTag: "About BLINX.AI",
            textInfo:[],
            showVideo:false,
            leadershipTeam: [
                {
                    Image: Suresh,
                    Name: 'Suresh Vallabhaneni',
                    Position: 'Founder & CEO',
                    Data: ['Suresh Vallabhaneni is a serial entrepreneur with over 24 years of experience in the software industry. Prior to Blinx AI, Suresh was the founder and CEO of Zing Wallet, a mobile payments startup and Adagesoft, a solutions provider. Before venturing into startups, Suresh has worked at various Fortune 500 companies like Goldman Sachs, Freddie Mac and technology companies like BEA Systems.',
                        'Suresh has a Masters in Computer Science from Andhra University India and Certificate in Entrepreneurship from Stanford Graduate School of Business.'
                    ]

                },
                {
                    Image: JiaZeng,
                    Name: 'Jia Zeng',
                    Position: 'Co-Founder',
                    Data: ['Jia Zeng holds a BS in Computer Science with a minor in Machine Learning; she is passionate about building high-performance computing systems in the space of AI and likes to paint to reenergize her.']
                },

            ],
            advisoryTeam: [
                {
                    Image: DonDuet,
                    Name: 'Don Duet',
                    Position: 'Chief Advisor',
                    Data: 'Don Duet holds a BS in Computer Science with a minor in Machine Learning; she is passionate about building high-performance computing systems in the space of AI and likes to paint to reenergize her.'
                },
                {
                    Image: Hari,
                    Name: 'Hari Harikrishnan',
                    Position: 'Marketing Advisor',
                    Data: 'Hari Harikrishnan holds a BS in Computer Science with a minor in Machine Learning; she is passionate about building high-performance computing systems in the space of AI and likes to paint to reenergize her.'
                },
                {
                    Image: Vivian,
                    Name: 'Vivian Schneck-Last',
                    Position: 'Strategy & Business Developement Advisor',
                    Data: 'Vivian Schneck-Last holds a BS in Computer Science with a minor in Machine Learning; she is passionate about building high-performance computing systems in the space of AI and likes to paint to reenergize her.'
                }
            ],
            teamMemberPopup: false,
            memberName: '',
            context:{
                about: [
                    "Blinx AI is a data-driven intelligence platform that helps people quickly turn\n" +
                    "their data into actionable insights. Founded to democratize AI and make it\n" +
                    "accessible to everyone, Blinx AI provides an intuitive, easy-to-use interface\n" +
                    "based on the user's skillset. With just a few clicks, anyone can create an\n" +
                    "intelligent application that learns from new inputs without ever writing code.",

                    "Since 2019, Blinx AI has been helping businesses turn their data into\n" +
                    "powerful, valuable insights in the blink of an eye. The perfect tool for anyone\n" +
                    "who wants to make their business smarter without learning how to code,\n" +
                    "Blinx AI empowers businesses of all sizes to create and use AI solutions in\n" +
                    "minutes.",

                    "Headquartered in Palo Alto, California, Blinx AI allows business executives,\n" +
                    "engineers, and organizational leaders to quickly and easily create custom AI\n" +
                    "solutions that identify, address, and solve unique, specific business\n" +
                    "problems. Designed to provide a streamlined, simplified, and intuitive user\n" +
                    "experience fueled by artificial intelligence, Blinx AI delivers an adaptive\n" +
                    "interface that makes it simple for anyone to create AI solutions quickly and\n" +
                    "effectively.",

                    "As the volume and complexity of the data grows, finding the signal in the noise\n" +
                    "becomes increasingly difficult. Without the help of AI, most businesses\n" +
                    "cannot make sense of their data and turn it into valuable insights. As enterprises are collecting more data than ever before, the collection of data has grown at an unprecedented rate.. This influx of data has made it difficult for\n" +
                    "businesses to keep up with the latest trends and make informed decisions.",

                    "Blinx AI was born out of a commitment to address and solve this problem by\n" +
                    "simplifying AI and making it more accessible and valuable for businesses of\n" +
                    "all sizes. We developed our groundbreaking, innovative AI Lifecycle platform\n" +
                    "to help our customers transform their data into actionable insights without\n" +
                    "coding or expertise. With Blinx AI, you can get insights from your data in\n" +
                    "minutes instead of months. You'll be able to make decisions quickly and\n" +
                    "efficiently to stay ahead of the competition.",
                ],
                vision:[
                    "Blinx AI is an AI Lifecycle platform that believes in something bigger. Our\n" +
                    "mission is to be an industry disrupter and a difference-maker by providing a\n" +
                    "powerful way for businesses to harness the power of their data. Blinx AI is\n" +
                    "the culmination of years of passion, determination, and personal experiences\n" +
                    "that have been developed throughout the career of Blinx’s founding team.\n" +
                    "Our love for startups and technology is at the core of what we do, and we\n" +
                    "have remained committed to revolutionizing the landscape of business and\n" +
                    "data in the 21st century.",

                    "We believe \"good enough\" is never enough, and this belief is at the very\n" +
                    "heart of what we do. At Blinx AI, our goal is to make sophisticated\n" +
                    "technology accessible to businesses ready to take their success to the next\n" +
                    "level by making AI technology more accessible to all.",
                ]
            }
        }
    }

    teamMemberInfo = (name) => {
        let advisoryTeam = [...this.state.advisoryTeam];

        advisoryTeam.map(advisoryTeam => {
                if (advisoryTeam.Name === name) {
                    this.setState({teamMemberPopup: !this.state.teamMemberInfo, memberName: name})
                }
            }
        );
        let leadershipTeam = [...this.state.leadershipTeam];

        leadershipTeam.map(leadershipTeam => {
                if (leadershipTeam.Name === name) {
                    this.setState({teamMemberPopup: !this.state.teamMemberInfo, memberName: name})
                }
            }
        )
    };

    openDetailedInfo = (textInfo, title, image, contextPopupTag) =>{
        this.setState({
            contextPopup: true,
            textInfo,
            contextPopupTitle: title,
            contextPopupImage: image,
            contextPopupTag,
        })
    }

    render() {
        return (
            <div className='about-blinx'>
                <MetaData keyword={"about-us"}/>
                <div className='about-blinx-header'>
                    {/*<div className='about-blinx-active-title'>ACCELERATING SUCCESSFUL OUTCOMES</div>*/}
                    <div className='about-blinx-main-title'>About Blinx AI</div>
                    <div className='about-blinx-text'>
                        Blinx AI is the fastest, easiest way to transform your data into intelligence in
                        minutes, with or without any coding.
                    </div>
                </div>

                <div className='about-blinx-about'>
                    <StartUp className={'about-blinx-about-image'} alt={'Blinx about us'}/>
                    <div className='about-blinx-about-content'>
                        <div className='about-blinx-main-title'
                             onClick={this.openDetailedInfo.bind(this, this.state.context.about, "Accelerating Successful Outcomes",'/static/media/tech_startup_team_meet_up.svg',"")}>Accelerating Successful Outcomes</div>
                        <div className='about-blinx-text about-blinx-text-about'>
                            Blinx AI is a data-driven intelligence platform that helps people quickly turn their data into actionable insights. Founded to democratize AI and make it accessible to everyone, Blinx AI provides an intuitive, easy-to-use interface based on the user's skillset.
                        </div>
                        <Button onClick={this.openDetailedInfo.bind(this, this.state.context.about, "Accelerating Successful Outcomes",'/static/media/tech_startup_team_meet_up.svg',"")}
                                width={"150px"}
                                labelText="See More"/>
                    </div>
                </div>
                <div className='about-blinx-about'>
                    <div className='about-blinx-about-content'>
                        <div className='about-blinx-main-title'
                             onClick={this.openDetailedInfo.bind(this, this.state.context.vision, "Our Mission/Vision",'/static/media/tech_startup_team_meet_up.svg', "")}>Our Mission/ Vision</div>
                        {/*<div className='about-blinx-main-title'>Accelerating Successful Outcomes</div>*/}
                        <div className='about-blinx-text about-blinx-text-about'>
                            {/*{*/}
                            {/*    this.state.context.vision.slice(0,1).map((info,id)=>*/}
                            {/*        <p>{info}</p>*/}
                            {/*    )*/}
                            {/*}*/}
                            Blinx AI is an AI Lifecycle platform that believes in something bigger. Our mission is to be an industry disrupter and a difference-maker by providing a powerful way for businesses to harness the power of their data.
                        </div>

                        <Button onClick={this.openDetailedInfo.bind(this, this.state.context.vision, "Our Mission/Vision",StartUp, "")}
                                width={"150px"}
                                labelText="See More"
                        />

                    </div>
                    <img src={Team} className='about-blinx-about-image' alt={'blinx team'}/>
                </div>

                <div className='about-blinx-video'>
                    <div className='about-blinx-video-background'>
                        <iframe
                            className="about-blinx-video-frame" src="https://www.youtube.com/embed/9LY0NK23Y8A"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen/>

                    </div>
                </div>
                <div className='team-blinx-container'>
                    <div className='about-blinx-active-title'>TEAM BLINX.AI</div>
                    <div className='about-blinx-main-title'>Our Leadership Team</div>
                    <div className='team-blinx-image-container'>
                        {
                            this.state.leadershipTeam.map(leaderTeam =>
                                <button className='team-blinx-image'
                                        onClick={() => this.teamMemberInfo(leaderTeam.Name)}>
                                    <img src={leaderTeam.Image} width={150} alt={leaderTeam.Name}/>

                                    <div className='about-blinx-main-title'
                                         style={{fontSize: 22, marginTop: 25}}>{leaderTeam.Name}
                                    </div>
                                    <div
                                        className='about-blinx-text about-blinx-position'>{leaderTeam.Position}</div>
                                </button>
                            )
                        }
                    </div>
                </div>
                <Popup open={this.state.teamMemberPopup}
                       overlayStyle={{backgroundColor: 'rgba(0,0,0,70%)'}}
                       onClose={()=>this.setState({teamMemberPopup: false})}
                       contentStyle={{
                           background: "transparent",
                           padding: 0,
                           border:"none",
                           borderRadius:"none",
                           minWidth:"100px"
                       }}
                >
                    {
                        this.state.leadershipTeam.map(leaderList =>
                            this.state.memberName === leaderList.Name &&
                            <div className='about-blinx-team-info-container'>
                                <button className='about-blinx-team-info-close'
                                        onClick={() => this.setState({teamMemberPopup: !this.state.teamMemberPopup})}>
                                    <Icon icon={'close'} size={20}/>
                                </button>
                                <div className='about-blinx-team-info-image-container'>
                                    <img src={leaderList.Image} width={150} alt={'Suresh'}/>

                                    <div style={{
                                        gap: 10,
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}>
                                        <div className='about-blinx-main-title'>{leaderList.Name}
                                        </div>
                                        <div
                                            className='about-blinx-position'>{leaderList.Position}
                                        </div>
                                    </div>
                                </div>
                                <Scrollbars style={{height: '350px'}}
                                            autoHide={false}>
                                <div className='about-blinx-team-info-body'
                                     style={{
                                         gap: 10,
                                         display: 'flex',
                                    flexDirection: 'column'
                                }}>

                                    {
                                        leaderList.Data.map(leaderInfo =>
                                            <div>{leaderInfo}</div>
                                        )
                                    }

                                </div>
                                </Scrollbars>
                            </div>
                        )
                    }


                </Popup>

                <Popup contentStyle={{
                    background: "transparent",
                    padding: 0,
                    border:"none",
                    borderRadius:"none",
                }}
                open={this.state.contextPopup}
                       overlayStyle={{backgroundColor: 'rgba(0,0,0,70%)'}}
                onClose={()=>this.setState({contextPopup: false})}>
                    <div className="context-popup">
                        <div className="context-popup--header">
                            <button className="context-popup-close-btn" onClick={()=>this.setState({contextPopup: false})}>
                                <Icon icon={'close'} size={20}/>
                            </button>
                            <div className="context-popup-tag">{this.state.contextPopupTag}</div>
                            <div className="context-popup-title">{this.state.contextPopupTitle}</div>
                        </div>
                        <Scrollbars style={{height: '72vh'}}
                                    autoHide
                                    autoHideTimeout={500}
                                    autoHideDuration={200}>
                        <div className="context-popup--body">


                            {
                                this.state.textInfo.map((text,index)=>
                                    <p>{text}</p>
                                )
                            }

                        </div>
                    </Scrollbars>
                    </div>

                </Popup>
            </div>
        )
    }
}

export default AboutBlinx;