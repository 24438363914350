import React from "react";
import Icon from "../../Assets/IconLibrary/Icon";
import Button from "../../Assets/Buttons/Button";
import GhostButton from "../../Assets/Buttons/GhostButton";
import { withRouter } from "react-router";
import {DefaultParameters} from "../../api/ApiCallMethod";
import { fallDown as Menu} from 'react-burger-menu'
import './BurgerMenu.css'
import Popup from "reactjs-popup";
import LottieAnimation from "../../Assets/Lotties/LottieAnimation";

class BurgerMenu extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            showResourceChild: false,
            openBurgerMenu: false,
            menuList:[
                {
                    Name: "AI App Platform",
                    Route: "platform",
                    Icon: "",
                    Disabled: false,
                    isActive: false,
                    Action: this.changeRouteHandler.bind(this, "platform")
                },
                {
                    Name: "Solutions",
                    Route: "solutions/it",
                    Icon: "",
                    Disabled: false,
                    isActive: false,
                    Action: this.changeRouteHandler.bind(this, "solutions/it")
                },
                {
                    Name: "AI App Store",
                    Route: "ai-app-store",
                    Icon: "",
                    Disabled: false,
                    isActive: false,
                    Action: this.changeRouteHandler.bind(this, "ai-app-store")
                },
                {
                    Name: "Resources",
                    Route: "resources",
                    Icon: "",
                    Disabled: false,
                    isActive: false,
                    Action: this.changeRouteHandler.bind(this, "resources/product-videos"),

                },
                {
                    Name: "Pricing",
                    Route: "pricing",
                    Icon: "",
                    Disabled: false,
                    isActive: false,
                    Action: this.changeRouteHandler.bind(this, "pricing-plan"),

                },
                {
                    Name: "About Us",
                    Route: "about_us",
                    Icon: "",
                    Disabled: false,
                    isActive: false,
                    Action: this.changeRouteHandler.bind(this,"about-us")
                },
            ],
        }
    }

    login =()=>{
        // let productUrl = DefaultParameters().ProductURL
        // productUrl += DefaultParameters().LoginRoute
        // window.open(productUrl, '_blank');
        // this.setState({
        //     openBurgerMenu: false
        // })

        window.open("https://calendly.com/blinx_sales", "_blank");
        this.setState({
            openBurgerMenu: false
        })
    }


    changeRouteHandler = (route) =>{
        let {menuList}  =this.state;
        for(let i =0;i<menuList.length;i++){
            menuList[i].isActive = menuList[i].Route === route
        }
        this.setState({
            menuList,
            openBurgerMenu: false,
        })

        let {routingPage} = this.props;
        routingPage(route);
        this.manualScroll11(0)

    }
    manualScroll11 = (location) =>{
        let {manualScroll} = this.props;
        manualScroll(location)
    }

    openRequestDemo = () =>{
        window.open("https://calendly.com/blinx_sales", "_blank");
        this.setState({
            openBurgerMenu: false
        })
    }
    openBlinxBlog = () =>{

        let blogURL = "https://blogs.blinx.ai"
        window.open(blogURL, "_self");
        this.setState({
            openBurgerMenu: false
        })
    }

    render() {
        let {menuList} = this.state;
        let {backgroundColor} = this.props;

        return (
            <div className='blinx-burger-header' style={{backgroundColor: backgroundColor}}>
                <div className='blinx-inner-burger-header'>
                    <div className='blinx-burger-header--left'>
                        <div className='blinx-logo-division' onClick={this.changeRouteHandler.bind(this,"home")}>
                            <Icon icon="logo" size={158} />
                        </div>

                    </div>
                    <div className="blinx-burger-header--right">
                        <Menu isOpen={this.state.openBurgerMenu} onOpen={()=>this.setState({openBurgerMenu: true})} onClose={()=>this.setState({openBurgerMenu: false})}>
                            <div className='blinx-burger-main-menu-list'>
                                <div className='blinx-logo-burger-division' onClick={this.changeRouteHandler.bind(this,"home")}>
                                    <Icon icon="logo" size={200} />
                                </div>
                                <div className="blinx-logo-buger-menu-list">
                                    {
                                        menuList.map((menu)=>
                                            menu.Name !== "Resources"?
                                            <button key={menu.Name}
                                                    className={menu.isActive?"blinx-main-menu active":"blinx-main-menu" }
                                                    disabled={menu.Disabled}
                                                    value={menu.Route}
                                                    onClick={menu.Action}>
                                                {menu.Name}
                                            </button>
                                                :
                                                <>
                                            <button key={menu.Name}
                                                    className={menu.isActive?"blinx-main-menu active":"blinx-main-menu" }
                                                    disabled={menu.Disabled}
                                                    value={menu.Route}
                                                    onClick={()=>this.setState({showResourceChild: !this.state.showResourceChild})}>
                                                {menu.Name}
                                                <Icon icon={"dropDown"} className="blinx-main-menu-drop-down"
                                                      onClick={()=>this.setState({showResourceChild: !this.state.showResourceChild})}/>
                                            </button>
                                                    {
                                                        this.state.showResourceChild?
                                                            <>
                                                                <button key={menu.Name}
                                                                        className={menu.isActive?"blinx-main-menu child active":"blinx-main-menu child" }
                                                                        disabled={menu.Disabled}
                                                                        value={menu.Route}
                                                                        onClick={menu.Action}>
                                                                    <Icon icon="forward" size={12} className="blinx-main-menu-drop-down"/>Blinx University
                                                                </button>
                                                                <button key={menu.Name}
                                                                        className={menu.isActive?"blinx-main-menu child active":"blinx-main-menu child" }
                                                                        disabled={menu.Disabled}
                                                                        value="blog"
                                                                        onClick={this.openBlinxBlog.bind(this)}>
                                                                    <Icon icon="forward" size={12}/>Blog
                                                                </button>
                                                                </>
                                                            :
                                                            null
                                                    }

                                            </>
                                        )
                                    }

                                    <Popup contentStyle={{
                                        width: "auto",
                                        background: "transparent",
                                        border: "none",
                                        padding: 0

                                    }}
                                           open={this.state.blogNotExistPopup}
                                           closeOnDocumentClick={true}
                                           onClose={()=>this.setState({blogNotExistPopup: false})}
                                           lockScroll={true}>
                                        <div className="blog-error-popup">
                                            <button onClick={()=>this.setState({blogNotExistPopup: false})}
                                                    className="email-send-close-btn">
                                                X
                                            </button>
                                            <div className="email-send-image success">
                                                <LottieAnimation lotti={"EmptyBlog"}/>
                                            </div>
                                            <div className={"email-send-text-title fail"}>
                                                Sorry
                                            </div>
                                            <div className="email-send-text-body">
                                                Currently there are no blogs!
                                            </div>
                                        </div>

                                    </Popup>

                                </div>
                                <div className='blinx-logo-burger-log-in'>
                                    <GhostButton onClick={this.login.bind(this)} label={"Login"}/>
                                    <Button onClick={this.openRequestDemo.bind(this)} backgroundColor={"var(--primary-color)"} labelText="Request Demo"/>
                                </div>

                            </div>
                        </Menu>
                    </div>
                </div>
            </div>
        );
    }

}


export default withRouter(BurgerMenu)