export const DuoIconColorList = [
    {
        image: 'check',
        colorList: ['#83BF4F', '#3E4347'],
    },
    // Social media
    {image:'facebook',colorList:["","#1877F2","white"]},
    {image:'twitter',colorList:["","#2DAAE1","white"]},
    {image:'reddit',colorList:["","#FF5700","white","white"]},
    {image:'quora',colorList:["","#B92B27"]},
    {image:'pinterest',colorList:["","#CC2127"]},
    {image:'medium',colorList:["","white","black"]},
    {image:'instagram',colorList:["","#F3825A","white"]},
    {image:'linked_in',colorList:["","#379AD0","#FEFEFE","#FEFEFE","#FEFEFE"]},
    {image:'youtube',colorList:["","white","#E8E0E0","#CD201F"]},

];