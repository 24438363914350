import React, { useState, StrictMode } from "react";
import * as ReactDOMClient from "react-dom/client";
import Carousel from "react-simply-carousel";
import {TrustedCompanyList} from "../Home/TrustedCompanyList";
import "../Home/home.css"
import { Swiper, SwiperSlide } from "swiper/react";
import "../Home/swiper.css"
// import "swiper/css";
// import "swiper/css/pagination";
// import "swiper/css/navigation";

import { Autoplay, Pagination, Navigation } from "swiper";
import TigerAnalytic from "../../Assets/Images/Companies/Tiger Analytic.png";

export function CompanyCarousel() {
    const [activeSlide, setActiveSlide] = useState(0);

    return (
        <div className="trusted-carousel">
            {/*<Carousel*/}
            {/*    infinite={false}*/}
            {/*    autoplay={true}*/}
            {/*    preventScrollOnSwipe={false}*/}
            {/*    // swipeTreshold={100}*/}
            {/*    activeSlideIndex={activeSlide}*/}
            {/*    centerMode={true}*/}
            {/*    onRequestChange={()=>{console.log(activeSlide)}}*/}
            {/*    itemsToShow={12}*/}
            {/*    autoplayDirection = {"forward"}*/}
            {/*    speed={2000}*/}
            {/*    forwardBtnProps={{*/}
            {/*        style: {*/}
            {/*            display: "none"*/}
            {/*        }*/}
            {/*    }}*/}
            {/*    backwardBtnProps={{*/}
            {/*        style: {*/}
            {/*            display: "none"*/}
            {/*        }*/}
            {/*    }}*/}
            {/*    dotsNav={{*/}
            {/*        show: false,*/}
            {/*        itemBtnProps: {*/}
            {/*            style: {*/}
            {/*                display: "none"*/}
            {/*            }*/}
            {/*        },*/}
            {/*        activeItemBtnProps: {*/}
            {/*            style: {*/}
            {/*                display: "none"*/}
            {/*            }*/}
            {/*        }*/}
            {/*    }}*/}
            {/*>*/}

            {/*        {*/}
            {/*            TrustedCompanyList.map((info =>*/}
            {/*                    <div className="trusted-carousel-card-container">*/}
            {/*                    <div className="trusted-carousel-card">*/}
            {/*                        {info}*/}
            {/*                    </div>*/}
            {/*                    </div>*/}

            {/*            ))*/}
            {/*        }*/}

            {/*</Carousel>*/}

            {/*<Swiper*/}
            {/*    autoplay={{*/}
            {/*        delay: 0,*/}
            {/*        disableOnInteraction: true,*/}
            {/*    }}*/}
            {/*    // waitForTransition={true}*/}
            {/*    speed={2000}*/}
            {/*    // cssMode={true}*/}
            {/*    slidesPerView={"auto"}*/}
            {/*    freeMode={true}*/}
            {/*    loop = {true}*/}
            {/*    loopPreventsSliding={true}*/}
            {/*    navigation={false}*/}
            {/*    modules={[Autoplay]}*/}
            {/*    className="company-swiper"*/}
            {/*    onClick={()=>{console.log(">>>>>> 111 on click")}}*/}
            {/*    onDoubleClick={()=>{}}*/}
            {/*    onDoubleTap={()=>{}}*/}
            {/*    // aria-disabled={true}*/}
            {/*    preventClicks={true}*/}
            {/*    preventClicksPropagation={true}*/}
            {/*    preventInteractionOnTransition={true}*/}

            {/*>*/}
            <Swiper
                spaceBetween= {0}
                centeredSlides= {true}
                speed= {2000}
                    modules={[Autoplay]}
                autoplay= {{
                    delay: 0,
                }}
                loop= {true}
                slidesPerView={'auto'}
                allowTouchMove= {false}
                disableOnInteraction= {true}
                preventClicks={true}
                preventClicksPropagation={true}
                preventInteractionOnTransition={true}

            >
                {
                    TrustedCompanyList.map((info =>
                            <SwiperSlide className="trusted-carousel-card-container">
                                <div className="trusted-carousel-card" title={info.Name}>
                                    <img src={info.Logo} className="trusted-company-image" alt={info.Name}/>
                                </div>
                            </SwiperSlide>
                    ))
                }
            </Swiper>
        </div>
    );
}