import Original1 from "../Images/original_1.png";
import Original2 from "../Images/Original_2.svg";
import Original3 from "../Images/Original_3.svg";

export const PlatformConcept = [
    {
        Tag: "BLINX FOR INNOVATION",
        Title: "Drive Business and IT Collaboration",
        Description: "Role-based workflows and artifacts make it easy for business analysts, data science, and app developers to collaborate on a unified platform to create and validate models embedded in apps.",
        Image: Original1,
        ImageAlt:'blinx Drive Business and IT Collaboration',
        DetailedInfo:false
    },
    {
        Tag: "Workflows for all",
        Title: "Blinx AI User Journey",
        Description: "Blinx allows role-based journeys for different users. Whether you are starting from your data, your features, or your models, the journey to building and managing the AI app is simple.",
        Image: Original2,
        ImageAlt:'blinx user journey',
        DetailedListInfo:{
            Title:"Blinx supports workflows including the following:",
            List: [
                "Import models and generate AI apps",
                "Containerize AI apps for deployment on any cloud",
                "Manage model drift to keep data and model in sync",
                "Publish models as services in your private AI app store",
                "Import models or use our algorithm library to develop models",
                "Connect to any data source using pluggable connectors"
            ]
        },
        DetailedInfo:false
    },
    {
        Tag: "INDUSTRY SOLUTIONS",
        Title: "Vertical Solutions Enabled by Blinx Platform",
        Description: "Rapidly create industry-specific solutions using Blinx. Bring your data, algorithms, or models and Blinx will generate industry applications for you to validate and deploy.",
        Image: Original3,
        ImageAlt:'blinx ai platform',
        DetailedInfo:true
    },
]