import React from "react";
import './termsAndCondition.css';

class LimitationOfLiability extends React.Component{
    render() {
        return (
            <div className="terms-and-conditions-sub-section">
                <div className="sub-section-title">7.&nbsp;Limitation of Liability</div>
                <div className="align-terms-paragraph">
                    THE COMPANY’S AND ITS SUBSIDIARIES, AFFILIATES, SUCCESSORS, AND ASSIGNS, AND THEIR RESPECTIVE EMPLOYEES, AGENTS, DIRECTORS, OFFICERS AND SHAREHOLDERS, LIABILITY TO YOU IS LIMITED. TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT SHALL THE COMPANY BE LIABLE FOR DAMAGES OF ANY KIND (INCLUDING, BUT NOT LIMITED TO, DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE DAMAGES, LOST PROFITS, LOST DATA, LOSS OF GOODWILL OR BUSINESS REPUTATION, ANY COST TO PROCURE SUBSTITUTE GOODS OR SERVICES, OR ANY INTANGIBLE LOSS, REGARDLESS OF THE FORESEEABILITY OF THOSE DAMAGES) ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF THE WEBSITE, OR ANY OTHER SERVICES PROVIDED TO YOU BY THE COMPANY.
                </div>
                <div className="align-terms-paragraph">
                    This limitation shall apply regardless of whether the damages arise out of breach of contract, tort, any form of error, or breakdown in the function of the service, or any other legal theory or form of action.
                </div>
                <div className="align-terms-paragraph">
                    ALTHOUGH NOT AN EXHAUSTIVE LIST AND WITHOUT LIMITING THE FOREGOING, THE COMPANY AND ITS SUBSIDIARIES, AFFILIATES, SUCCESSORS, AND ASSIGNS, AND THEIR RESPECTIVE EMPLOYEES, AGENTS, DIRECTORS, OFFICERS AND SHAREHOLDERS, SHALL HAVE NO LIABILITY FOR:
                </div>
                <div className="align-nested-terms-paragraph align-terms-paragraph">
                    ANY LOSS OR DAMAGE ARISING FROM:
                    <ol type="A">
                        <li>
                            YOUR RELIANCE ON THE CONTENT OF THE SERVICES, INCLUDING WITHOUT LIMITATION, CONTENT ORIGINATING FROM THIRD PARTIES, OR FROM ANY COMMUNICATION WITH THE SERVICES;
                        </li><br/>
                        <li>
                            YOUR INABILITY TO ACCESS OR USE THE SERVICES OR ANY PART OR PARTS THEREOF, INCLUDING DELETION OR CORRUPTION OF, OR FAILURE TO STORE, ANY CONTENT AND OTHER DATA MAINTAINED OR TRANSMITTED BY OR THROUGH YOUR USE OF THE SERVICE, OR YOUR ABILITY TO ACCESS ANY CONTENT OR ANY EXTERNAL SERVICES VIA THE SERVICES;
                        </li><br/>
                        <li>
                            ANY CHANGES THAT THE COMPANY MAY MAKE TO THE SERVICES OR ANY PART THEREOF, OR ANY TEMPORARY OR PERMANENT SUSPENSION OR CESSATION OF ACCESS TO THE SERVICES OR ANY CONTENT IN OR FROM ANY OR ALL TERRITORIES;
                        </li><br/>
                        <li>
                            ANY ACTION TAKEN AGAINST YOU BY THIRD PARTY RIGHTS HOLDERS WITH RESPECT TO ANY ALLEGED INFRINGEMENT OF SUCH THIRD PARTY’S RIGHTS RELATING TO YOUR CONTENT OR YOUR USE OF THE SERVICES, OR ANY ACTION TAKEN AS PART OF AN INVESTIGATION BY THE COMPANY OR ANY RELEVANT LAW ENFORCEMENT AUTHORITY REGARDING YOUR USE OF THE SERVICES;
                        </li><br/>
                        <li>
                            ANY ERRORS OR OMISSIONS IN THE SERVICES’S TECHNICAL OPERATION, OR FROM ANY INACCURACY OR DEFECT IN ANY CONTENT OR ANY INFORMATION RELATING TO CONTENT;
                        </li><br/>
                        <li>
                            YOUR FAILURE TO PROVIDE THE COMPANY WITH ACCURATE OR COMPLETE INFORMATION, OR YOUR FAILURE TO KEEP YOUR ACCOUNT LOGIN INFORMATION SUITABLY CONFIDENTIAL;
                        </li><br/>
                        <li>
                            ANY LOSS OR DAMAGE TO ANY COMPUTER HARDWARE OR SOFTWARE, ANY LOSS OF DATA, OR ANY LOSS OR DAMAGE FROM ANY SECURITY BREACH;
                        </li><br/>
                        <li>
                            ANY LOSS OF PROFITS, INCLUDING THOSE CAUSED BY YOUR RELIANCE ON THE SERVICES, OR ANY LOSS YOU SUFFER WHETHER OR NOT IT IS FORESEEABLE.
                        </li><br/>
                    </ol>
                </div>
                <div className="align-terms-paragraph">
                    ANY CLAIM OR CAUSE OF ACTION ARISING OUT OF OR RELATED TO YOUR USE OF THE SERVICES MUST BE NOTIFIED TO THE COMPANY AS SOON AS POSSIBLE.
                </div>
                <div className="align-terms-paragraph">E.
                    APPLICABLE LAW MAY NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATIONS OR EXCLUSIONS MAY NOT APPLY TO YOU. IN SUCH CASES, YOU ACKNOWLEDGE AND AGREE THAT SUCH LIMITATIONS AND EXCLUSIONS REFLECT A REASONABLE AND FAIR ALLOCATION OF RISK BETWEEN YOU AND THE COMPANY AND ARE FUNDAMENTAL ELEMENTS OF THE BARGAIN BETWEEN YOU AND THE COMPANY, AND THAT THE COMPANY’S LIABILITY WILL BE LIMITED ENTIRELY, TO THE MAXIMUM EXTENT PERMITTED BY LAW.
                </div>
            </div>
        );
    }
}
export default LimitationOfLiability;