import React from 'react'
import './ApplicationDetail.css'
import Popup from "reactjs-popup";
import {Scrollbars} from 'react-custom-scrollbars'
import Icon from "../../Assets/IconLibrary/Icon";
import Button from "../../Assets/Buttons/Button";
import {DefaultParameters} from "../../api/ApiCallMethod";

class ApplicationDetail extends React.Component {
    constructor() {
        super();
        this.state = {
            applicationDetailPopup: true,
        }
    }

    downloadApp = () =>{
        let appStoreURL = DefaultParameters().AppStoreURL
        window.open(appStoreURL, '_blank');
    }

    render() {
        let {onClose, appInfo} = this.props;
        return (
            <Popup open={this.state.applicationDetailPopup}
                   overlayStyle={{backgroundColor: 'rgba(0,0,0,70%)'}}
                   contentStyle={{
                       width: "100%",
                       height: "auto",
                       backgroundColor: 'transparent',
                       padding: 0,
                       borderRadius: 0,
                   }}
                   lockScroll={true}
                   closeOnDocumentClick={false}

            >
                <div className="application-detail-popup">
                    <button className="close-popup-btn" onClick={onClose}>
                        X
                    </button>
                < Scrollbars
 renderThumbVertical={({style, ...props}) =>
                                <div {...props} style={{...style, width: '5px', borderRadius: '4px',backgroundColor: '#76a1d5'}}/>
                            } style={{height: '100%'}}

                            autoHideTimeout={500}
                            autoHideDuration={200}>
                    <div className='blinx-application-detail-popup'>
                        <div className='blinx-application-detail-header'>
                            <div className='blinx-application-detail-header-icon'>
                                <Icon icon={'blinx_website'} size={50}/>
                            </div>
                            <div className='blinx-application-detail-header-title-container'>
                                <div className='blinx-application-detail-header-title'>
                                    {appInfo.AppName}
                                </div>
                                <div className='blinx-application-detail-header-download-count'>
                                    ({appInfo.DownloadCount}
                                    {
                                        appInfo.DownloadCount > 1?
                                            " downloads"
                                            :
                                            " download"
                                    }
                                    )
                                </div>
                            </div>
                            <Button backgroundColor={'#7FCDCD'} labelText={'Free Download'} color={'#09092D'}
                                    icon={'download'} iconSize={18} onClick={this.downloadApp.bind(this)}/>
                        </div>
                        <div className='blinx-application-detail-description'>
                            <div className='blinx-application-detail-description-title'>Description</div>
                            <div className='blinx-application-detail-description-info' style={{padding: 0}}>
                                {appInfo.Description}
                                <div className="blinx-application-version-row">
                                    {/*<div></div>*/}
                                    <div>Version {appInfo.AppVersion}</div>
                                </div>
                            </div>
                        </div>
                        <div className='blinx-application-detail-prerequisites'>
                            <div className='blinx-application-detail-description-title'>Prerequisites</div>
                            <div className='blinx-application-detail-description-info' style={{padding: 0}}>(Only for Windows)</div>
                            <ul className='blinx-application-detail-description-info'>
                                <li>Windows 11 64-bit: Home or Pro version 21H2 or higher, or Enterprise or Education version 21H2 or higher.
                                </li>
                                <li>Windows 10 64-bit: Home or Pro 2004 (build 19041) or higher, or Enterprise or Education 1909 (build 18363) or higher.

                                </li>
                                <li>Enable the WSL 2 feature on Windows

                                </li>
                                <li>Download and install the Linux kernel update package.
                                </li>
                                <li>The following hardware prerequisites are required to successfully run WSL 2 on Windows 10 or Windows 11:
                                    <ul>
                                        <li>64-bit processor with Second Level Address Translation (SLAT).
                                        </li>
                                        <li>4GB system RAM.
                                        </li>
                                        <li>BIOS-level hardware virtualization support must be enabled in the BIOS settings. For more information, see Virtualization.
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                        <div className='blinx-application-detail-preview'>
                            <div className='blinx-application-detail-description-title'>Preview</div>
                            <div className='blinx-application-detail-preview-image-container'>
                                {
                                    appInfo.PreviewPics.map((image,id)=>

                                        <img src={DefaultParameters().AppStoreURL + "/" + image} alt={image.Name +'preview '+id} className='blinx-application-detail-preview-image'/>
                                    )
                                }
                            </div>
                        </div>
                        <div className='blinx-application-detail-description'>
                            <div className='blinx-application-detail-description-title'>Algorithms</div>
                            <ul className='blinx-application-detail-description-info'>
                                {
                                    appInfo.FrameworkInfo.map((framework)=>
                                        framework.Algorithms.map((algo)=>
                                            <li>{algo.AlgorithmName}</li>
                                        )
                                    )
                                }

                            </ul>
                        </div>
                        <div className='blinx-application-detail-description'>
                            <div className='blinx-application-detail-description-title'>Framework</div>
                            <ul className='blinx-application-detail-description-info'>
                                {
                                    appInfo.FrameworkInfo.map((framework)=>
                                        <li>{framework.FrameworkName}</li>
                                    )
                                }
                            </ul>
                        </div>
                        <div className='blinx-application-detail-dataset-conainer'>
                            {
                                appInfo.FrameworkInfo.map((framework)=>

                                    framework.Algorithms.map((algo)=>
                                        <>
                                            <div className='blinx-application-detail-dataset-title-container'>
                                                <div className='blinx-application-detail-dataset-title'>
                                                    <div className='blinx-application-detail-description-title'>
                                                        Datasets
                                                    </div>
                                                </div>
                                                <div className='blinx-application-detail-dataset-title'>
                                                    <div className='blinx-application-detail-description-title'>
                                                       {/* Algorithm :*/}
                                                       {/*{algo.AlgorithmName}*/}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='blinx-application-detail-dataset-data-container'>
                                                <div className='blinx-application-detail-dataset-list'>
                                                    {
                                                        algo.DatasetDetails.map((dataset)=>

                                                            <div className="blinx-application-detailed-dataset">
                                                                <div className="blinx-app-detailed-bullet"/>
                                                                <div className="blinx-app-detailed-name" >
                                                                    {dataset.ColumnName} : [{dataset.DataType}]
                                                                </div>
                                                            </div>

                                                        )
                                                    }

                                                </div>
                                            </div>
                                        </>
                                    )

                                )
                            }

                        </div>

                    </div>
                </Scrollbars>
                </div>
            </Popup>
        )
    }
}

export default ApplicationDetail;