import React from 'react';
import './termsAndCondition.css'

class ChangesToTermsAndCondition extends React.Component{
    render() {
        return(
            <div className="terms-and-conditions-sub-section">
                <div className="sub-section-title">2.&nbsp;Changes to Terms and Conditions</div>
                <div className="align-terms-paragraph">
                    We reserve the right to change, alter, replace or otherwise modify (collectively “Changes”) these Terms and Conditions at any time. The date of last modification is stated in the footer of these Terms and Conditions.
                </div>
                <div className="align-terms-paragraph">
                    When we make any updates to these Terms and Conditions, we will highlight this fact on the website or online platform. In addition, if you register an account and these Terms and Conditions are subsequently changed in any material respect (for example, for security, legal, or regulatory reasons), we will notify you in advance by sending an email to the email address that you have provided to us. You will have no obligation to continue using the Services following any such notification, but if you do not terminate your account as described in the Termination section below, your continued use of the Services will constitute your acceptance of the revised Terms and Conditions.
                </div>
            </div>
        )
    }
}
export default ChangesToTermsAndCondition;