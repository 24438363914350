import React from 'react'
import './WebsiteButtons.css'
import PropTypes from "prop-types";

class GhostButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <button className='ghost-button' style={{
                border: this.props.border,
                color: this.props.color,
                height: `${this.props.height}px`,
                width: `${this.props.width}px`,
                borderRadius:`${this.props.borderRadius}px`,
                background: `${this.props.backgroundColor}`
            }}
                    onClick={this.props.onClick}>
                {this.props.label}
            </button>
        )
    }
}

GhostButton.propTypes = {
    label:          PropTypes.string.isRequired,
    width:          PropTypes.number,
    height:         PropTypes.number,
    border:         PropTypes.string,
    borderRadius:   PropTypes.number,
    color:          PropTypes.string,
    onClick:        PropTypes.func.isRequired,
    backgroundColor:PropTypes.string,
};

GhostButton.defaultProps = {
    label: 'Cancel',
    border: '1.2px solid var(--primary-color)',
    backgroundColor: "transparent"
};

export default GhostButton;
