import React from "react";
import './termsAndCondition.css'

class IntellectualPropertyRights extends React.Component{
    render() {
        return (
            <div className="terms-and-conditions-sub-section">
                <div className="sub-section-title">4.&nbsp;Intellectual Property Rights</div>
                <div className="align-terms-paragraph">
                    The Services and its entire contents, features, and functionality (including but not limited to all information, software, text, displays, images, video, and audio, and the design, selection, and arrangement thereof) are owned by the Company, its licensors, or other providers of such material and are protected by United States and international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.
                </div>
                <div className="align-terms-paragraph">
                    All related names, logos, product and service names, designs, and slogans are trademarks of the Company or its affiliates or licensors. You must not use such marks without the prior written permission of the Company. All other names, logos, product and service names, designs, and slogans on this Website are the trademarks of their respective owners.
                </div>
                <div className="align-terms-paragraph">
                    By providing photographs, video, written materials and other content to BlinX, you acknowledge that those materials do not infringe on the intellectual property rights of any third parties.
                </div>
            </div>
        );
    }
}
export default IntellectualPropertyRights;