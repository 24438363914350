import React from "react";
import "./solution.css";
import {KeyITSolutionList} from "../../Assets/Contents/SolutionContent";
import Icon from "../../Assets/IconLibrary/Icon";
import {BusinessFunctionList} from "../../Assets/Contents/DummyStructure/BusinessFunction";
import {IndustrySolutionList,IndustrySolutionLink} from "../../Assets/Contents/IndustrySolution";
import {Scrollbars} from "react-custom-scrollbars";
import {withRouter} from "react-router-dom";
import Linkify from "react-linkify"
import MetaData from "../MetaData/MetaData";
import {renderSwitchAppStore} from "../../api/ResourcesApiLayer";
import {ApiList, checkValidity} from "../../api/ApiCallMethod";
import {ReactComponent as Rectangle} from "../../Assets/Images/rectangle.svg";

class Solution extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            tabList: [
                {Label: "IT", Value: "it"},
                {Label: "Business Functions", Value: "business-function"},
                {Label: "Industries", Value: "industries"},

            ],
            chosenTab: "IT",
            businessFunctionList: BusinessFunctionList,
            chosenFunctionTab: "Marketing",
            chosenFunctionId: 0,

            industrySolutionList:IndustrySolutionList,
            chosenIndustryId: 0,
            chosenIndustryTab: "Aerospace",
            industryList:[],

        }
    }

    componentDidMount = () =>{


        let id=0;
        let activeIndustryName = "Aerospace"
        let {industrySolutionList}  =this.state;

        if(checkValidity(this.props.history)){
            if(checkValidity(this.props.history.location)){
                if(checkValidity(this.props.history.location.state)){
                    if(checkValidity(this.props.history.location.state)){

                        activeIndustryName = this.props.history.location.state.activeIndustry;
                        console.log("Component Did ", this.props.history.location.state);
                        for(let i=0;i<industrySolutionList.length;i++){
                            if(industrySolutionList[i].Type === activeIndustryName){
                                industrySolutionList[i].isExpand = true;
                                id = i;
                            }
                        }

                        this.setState({
                            chosenIndustryTab: activeIndustryName,
                            chosenIndustryId: id,
                            industrySolutionList
                        })
                    }
                }

            }

        }

        this.getIndustryList();

    }

    getIndustryList = (industry) =>{
        console.log("CALLING api to get industry list");

        let params = "?sortBy=industry&sortOrder=asc"
        renderSwitchAppStore(ApiList().GetIndustryList, "", params, "").then(industryResponse => {
            console.log("get industry list response : ", industryResponse.data)
            if (industryResponse.data["ResponseCode"] === 1000) {
                let resultIndustryList = [];
                if (industryResponse.data["Results"] !== null && industryResponse.data["Results"].length > 0) {
                    resultIndustryList = industryResponse.data["Results"]
                }

                this.setState({
                    industryList:resultIndustryList,
                })


            } else {
                this.setState({
                    industryList:[],
                    isEmpty: true,
                    isLoading: false,
                })
            }
        }).catch(err => {
            console.log("catch err in calling get industry list : ", err)
            this.setState({
                industryList:[],
                isEmpty: true,
                isLoading: false,
            })
        })
    }
    getApplicationIcon = (industryName, industryList) =>{
        console.log("Get App icon", industryList)
        for(let i=0;i<industryList.length;i++){
            if(industryList[i].Industry === industryName){
                return(industryList[i].Icon)
            }
        }
    }


    changeAccordionHandler = (id, event) =>{
        event.stopPropagation()
        let {businessFunctionList, chosenFunctionId} = this.state;
        businessFunctionList.map(res =>
            res.FunctionList.map((func,fid) =>
                func.isExpand = fid === id && !func.isExpand

            )

        )
        this.setState({
            businessFunctionList
        })

    }
    changeIndustriesHandler = (chosenID, chosenType, clickType) =>{
        let {industrySolutionList, chosenIndustryTab, chosenIndustryId} = this.state;
        industrySolutionList.map((res,index) =>
            clickType === "tab"?
            res.isExpand = index === chosenID
                :
            res.isExpand = index === chosenID && !res.isExpand

        )
        this.setState({
            industrySolutionList,
            chosenIndustryTab: chosenType,
            chosenIndustryId: chosenID
        })

    }

    changeMainFunctionHandler = (chosenID, chosenType) =>{
        let {businessFunctionList, chosenFunctionId} = this.state;
        businessFunctionList.map((res,index) =>

            res.isExpand = index === chosenID && !res.isExpand

        )
        this.setState({
            businessFunctionList,
            chosenFunctionId: chosenID,
            chosenFunctionTab:chosenType
        })

    }


    seeMore = (industry) =>{
        let link = industry.replaceAll(" ", "-").toLowerCase();
        let url="ai-appstore/artificial-intelligence-in-"+link;
        this.props.getAppURL(url, industry)
        this.props.history.push("/"+url)
    }

    changeRoute = (tab) =>{
        this.props.history.push(`/solutions/${tab.Value}`)
    }

    componentDecorator = (href, text) => {

        IndustrySolutionLink.map(link=>{
            if(text === link.Link){
                text=link.Display
            }
        })
        return(
            <a href={href} className="inner-link">
                {text}
            </a>
            )
    }

    getIconPath=(index)=>{
        if(this.state.industryList[index] !== undefined && this.state.industryList[index] !== null)
            return this.state.industryList[index].Icon
    }

    render() {
        let {
            tabList,
            businessFunctionList,
            chosenFunctionTab,
            industrySolutionList,
            chosenIndustryTab,
        } = this.state;

        let {chosenTab} = this.props;
        return(
            <div className="solution-main-layout">
                <MetaData keyword={
                    chosenTab === "IT"?
                        "it"
                        :
                        chosenTab === "Business Functions"?
                            "business-function"
                            :
                            "industries"
                }/>
                <div className="solution-tab-bar">
                    <div className="solution-tab-list">
                        {
                            tabList.map((tab)=>
                                <button className={tab.Label === chosenTab?"solution-tab active":"solution-tab"} onClick={this.changeRoute.bind(this, tab)}>
                                    {tab.Label}
                                </button>

                            )
                        }
                    </div>
                </div>
                {
                    chosenTab === "IT"?
                        <div className="solution-body">
                            <div className="solution-body--title">Key IT Solutions</div>
                            <div className="solution-it-list">
                                {
                                    KeyITSolutionList.map((item,id) =>
                                        <div className="solution-it-item" style={{backgroundColor: item.Color}}>
                                            <img src={Rectangle} alt={'rectangle background'}/>

                                            <Icon icon={item.Icon}

                                                  style={{fill: id === 3? "black":"white"}}
                                                  className="solution-it-icon"/>
                                            <div className="solution-it-title"
                                                 style={{color: id === 3? "black":"white"}}>{item.Title}</div>
                                            <div className="solution-it-description"

                                                 style={{color: id === 3? "black":"white"}}
                                            >{item.Description}</div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        :
                        chosenTab === "Business Functions"?
                            <div className="solution-body">
                                <div className="solution-tab-list-container">
                                    < Scrollbars
 renderThumbVertical={({style, ...props}) =>
                                <div {...props} style={{...style, width: '5px', borderRadius: '4px',backgroundColor: '#76a1d5'}}/>
                            } style={{height: '80vh', width: '100%'}}>
                                        <div className="solution-tab-list">
                                            {
                                                businessFunctionList.map((res,index)=>
                                                    <button key={res.Title}
                                                            className={res.Title === chosenFunctionTab?"solution-tab active":"solution-tab"}
                                                            onClick={this.changeMainFunctionHandler.bind(this, index, res.Title)}
                                                    >
                                                        <Icon icon={res.Icon}/>
                                                        <div className="solution-tab-name">{res.Title}</div>
                                                    </button>
                                                )
                                            }
                                        </div>
                                    </Scrollbars>
                                </div>
                                <div className="solution-tab-info-container">
                                    < Scrollbars
 renderThumbVertical={({style, ...props}) =>
                                <div {...props} style={{...style, width: '5px', borderRadius: '4px',backgroundColor: '#76a1d5'}}/>
                            } style={{height: '80vh', width: '100%'}}>
                                        <div className="solution-tab-info">
                                            {
                                                businessFunctionList.map((res,index)=>
                                                    <div className={chosenFunctionTab === res.Title?"solution-card-container":"solution-card-container hide"}
                                                         style={{maxHeight: chosenFunctionTab === res.Title? "250vh":"60px"}}>
                                                        <div className="solution-business-function-title"
                                                             onClick={this.changeMainFunctionHandler.bind(this, index, res.Title)}>
                                                            <div>{res.Title}</div>
                                                            {
                                                                res.isExpand?
                                                                    <div className="solution-card-container-expand-btn">
                                                                        -
                                                                    </div>
                                                                    :
                                                                    <div className="solution-card-container-expand-btn">
                                                                        +
                                                                    </div>
                                                            }
                                                        </div>
                                                        {
                                                            res.isExpand?
                                                                <div className="solution-business-function-list">
                                                                    {
                                                                        res.FunctionList.map((func, fid)=>
                                                                            <div className="solution-business-function-card" style={{maxHeight: func.isExpand? "250vh":"40px"}}>
                                                                                <div className="solution-business-function--header" onClick={this.changeAccordionHandler.bind(this, fid)}>
                                                                                    <div className="solution-business-function--title">{func.FunctionName}</div>

                                                                                    {
                                                                                        func.isExpand?
                                                                                            "-"
                                                                                            :
                                                                                            "+"
                                                                                    }
                                                                                </div>
                                                                                <div className="solution-business-function--body">
                                                                                    <div className="solution-business-function--container">
                                                                                        <div className="solution-business-function--body-title">
                                                                                            Challenges
                                                                                        </div>
                                                                                        <div className="solution-business-function-body--context">
                                                                                            {func.Challenges}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="solution-business-function--container">
                                                                                        <div className="solution-business-function--body-title solution">
                                                                                            Solutions
                                                                                        </div>
                                                                                        <div className="solution-business-function-body--context">
                                                                                            {func.Solutions}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        )
                                                                    }
                                                                </div>
                                                                :
                                                                null
                                                        }

                                                    </div>
                                                )
                                            }

                                        </div>
                                    </Scrollbars>
                                </div>
                            </div>
                            :
                            <div className="solution-body">
                                <div className="solution-tab-list-container">
                                    < Scrollbars
 renderThumbVertical={({style, ...props}) =>
                                <div {...props} style={{...style, width: '5px', borderRadius: '4px',backgroundColor: '#76a1d5'}}/>
                            } style={{height: '80vh', width: '100%'}}>
                                        <div className="solution-tab-list">
                                            {
                                                industrySolutionList.map((industry,index)=>
                                                    <button key={industry.Type}
                                                            title={industry.Type}
                                                            className={industry.Type === chosenIndustryTab?"solution-tab active":"solution-tab"}
                                                            onClick={this.changeIndustriesHandler.bind(this, index, industry.Type, "tab")}>
                                                        <Icon path={this.getIconPath(index)} className='solution-tab-icon' size={20}/>
                                                        <div className="solution-tab-name">
                                                            {industry.Type}
                                                        </div>
                                                    </button>
                                                )
                                            }
                                        </div>
                                    </Scrollbars>
                                </div>
                                <div className="solution-tab-info-container">
                                    < Scrollbars
 renderThumbVertical={({style, ...props}) =>
                                <div {...props} style={{...style, width: '5px', borderRadius: '4px',backgroundColor: '#76a1d5'}}/>
                            } style={{height: '80vh', width: '100%'}}>
                                        <div className="solution-tab-info">

                                            {
                                                industrySolutionList.map((industry,index)=>
                                                    <div className={chosenIndustryTab === industry.Type?"solution-card-container":"solution-card-container hide"}
                                                         style={{maxHeight: chosenIndustryTab === industry.Type? "250vh":"60px"}}>
                                                        <div className="solution-business-function-title">
                                                            <div>{industry.Type}</div>
                                                            <div className="solution-card-container-expand-btn"
                                                                 onClick={this.changeIndustriesHandler.bind(this, index, industry.Type, "expander")}>
                                                                {
                                                                    industry.isExpand? "-":"+"
                                                                }
                                                            </div>
                                                        </div>
                                                        {
                                                            industry.isExpand ?
                                                                <Linkify componentDecorator={this.componentDecorator}>
                                                                    {
                                                                        industry.Quote.length !== 0?
                                                                            <div className="solution-industry-quote-container">
                                                                                {
                                                                                    industry.Quote.map((quote)=>
                                                                                        <div style={{color:'#'}}>{quote}</div>
                                                                                    )
                                                                                }
                                                                            </div>
                                                                            :
                                                                            null
                                                                    }

                                                                <div className="solution-industry-documentation">
                                                                    {
                                                                        industry.DescriptionList.map((context) =>
                                                                            <p key={context}>{context}</p>
                                                                        )
                                                                    }
                                                                </div>
                                                                    <button className="solution-business-function-see-more" onClick={this.seeMore.bind(this,chosenIndustryTab)}>See more</button>
                                                                </Linkify>
                                                                :
                                                                null
                                                        }

                                                    </div>
                                                )
                                            }




                                        </div>
                                    </Scrollbars>
                                </div>
                            </div>
                }
            </div>
        )
    }
}

export default withRouter(Solution);