import React from "react";
import "./termsAndCondition.css";
import {withRouter} from 'react-router-dom';
import AcceptanceOfTermsAndCondition from "./AcceptanceOfTermsAndCondition";
import ChangesToTermsAndCondition from "./ChangesToTermsAndCondition";
import YourLicenseToUseTheServices from "./YourLicenseToUseTheServices";
import IntellectualPropertyRights from "./IntellectualPropertyRights";
import CopyrightInfringementAndDigitalMillennium from "./CopyrightInfringementAndDigitalMillennium";
import Disclaimer from "./Disclaimer";
import LimitationOfLiability from "./LimitationOfLiability";
import ApplicableLawJurisdictionDisputes from "./ApplicableLawJurisdictionDisputes";
import CompleteAgreement from "./CompleteAgreement";
import ContactInformation from "./ContactInformation";
import MetaData from "../MetaData/MetaData";

class TermsAndCondition extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <div className="terms-condition-layout">
                <MetaData keyword={'terms-and-conditions'}/>
                <div className="terms-condition-body">
                    <h1 className="terms-condition-context-header">
                        Terms And Conditions
                    </h1>
                    <div className="terms-and-conditions-sub-section">
                        <div className='align-terms-paragraph'  style={{paddingLeft: "0px"}}>
                            Welcome to BlinX! We take a full lifecycle approach to building, validating, and
                            managing AI models as integrated, manageable microservices. We democratize AI
                            application development by enabling business analysts, designers, and developers to
                            quickly build, validate, and deploy applications at scale.
                        </div>
                        <div className='align-terms-paragraph'  style={{paddingLeft: "0px"}}>
                            These Terms and Conditions (“Terms and Conditions,” “Agreement”) govern your use of
                            BlinX’s website, online platform and software owned by Sys Inc. (“BlinX,” “Company,”
                            “We,” “Our”), which also includes all related widgets, tools, data, software, and
                            other services provided by us (the “Services”).
                        </div>
                        <div className='align-terms-paragraph'  style={{paddingLeft: "0px"}}>
                            This document, together with our Privacy Policy and Cookie Policy and any other
                            terms specifically referred to therein, constitute a legally binding agreement (the
                            “Agreement”) between you and the Company in relation to your use of our Services. If
                            you do not agree with these Terms, do not use BlinX.
                        </div>
                    </div>

                    <AcceptanceOfTermsAndCondition/>
                    <ChangesToTermsAndCondition/>
                    <YourLicenseToUseTheServices/>
                    <IntellectualPropertyRights/>
                    <CopyrightInfringementAndDigitalMillennium/>
                    <Disclaimer/>
                    <LimitationOfLiability/>
                    <ApplicableLawJurisdictionDisputes/>
                    <CompleteAgreement/>
                    <ContactInformation/>
                    <p className="terms-and-conditions-update-date">Last Updated: July 31, 2022</p>
                </div>
            </div>
        );
    }
}

export default withRouter(TermsAndCondition);