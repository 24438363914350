import React from 'react';

class ContactInformation extends React.Component{
    render() {
        return (
            <div className="terms-and-conditions-sub-section">
                <div className="sub-section-title">10.&nbsp;Contact Information</div>
                <div className="align-terms-paragraph">
                    For general inquiries, complaints, questions or claims concerning the licensed Application, please contact <label className="legal-email-link">legal@blinx.ai.</label>
                </div>
            </div>
        );
    }
}
export default ContactInformation;