import React from 'react'
import axios from 'axios'
export const ApiCall = (data,url,uri,method)=>{
    const base = url + uri;
    console.log("the base url is ",base);
    console.log("method : ",method);
    console.log("the data : ", data);
    return (axios({
        url: base,
        method: method,
        data: data,
        // headers: {'Content-Type': 'multipart/form-data', 'Accept': '*/*'},
    }))
};

export const DownloadApiCall = (data,url,uri,method)=>{
    const base = url + uri;
    console.log("the base url is ",base);
    console.log("method : ",method);
    console.log("the data : ", data);
    return (axios({
        url: base,
        method: method,
        data: data,
        responseType: 'blob', // important
    }))
};