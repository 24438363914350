import Adobe from "../../Assets/Images/Companies/Adobe.svg";
import A3Data from "../../Assets/Images/Companies/a3data.png";
import aimsRawanda from "../../Assets/Images/Companies/aimsRawanda.png";
import americanFamilyInsurance from "../../Assets/Images/Companies/americanFamilyInsurance.png";
import CampIndia from "../../Assets/Images/Companies/campIndia.png";
import campSystem from "../../Assets/Images/Companies/campSystem.png";
import CapGemini from "../../Assets/Images/Companies/CapGemini.png";
import chaseBank from "../../Assets/Images/Companies/chaseBank.png";
import CHG from "../../Assets/Images/Companies/CHG.svg";
import Cisco from "../../Assets/Images/Companies/Cisco.svg";
import CityScape from "../../Assets/Images/Companies/cityScape.svg";
import codeFusion from "../../Assets/Images/Companies/codeFusion.png";
import Cognizant from "../../Assets/Images/Companies/Cognizant.svg";
import collectWise from "../../Assets/Images/Companies/collectWise.png";
import concouseLabs from "../../Assets/Images/Companies/concouseLabs.png";
import condeNast from "../../Assets/Images/Companies/condeNast.png";
import constomise from "../../Assets/Images/Companies/costomise.png";
import coveros from "../../Assets/Images/Companies/coveros.png";
import dataQA from "../../Assets/Images/Companies/dataQA.png";
import dayo from "../../Assets/Images/Companies/dayo.png";
import deluxe from "../../Assets/Images/Companies/deluxe.png";
import derana from "../../Assets/Images/Companies/derana.png";
import Dolcera from "../../Assets/Images/Companies/Dolcera.png";
import DTIQ from "../../Assets/Images/Companies/DTIQ.png";
import duckduckgo from "../../Assets/Images/Companies/duckduckgo.png";
import dukamneti from "../../Assets/Images/Companies/dukamneti.png";
import esi from "../../Assets/Images/Companies/esi.png";
import esprit from "../../Assets/Images/Companies/esprit.png";
import expertwine from "../../Assets/Images/Companies/expertWine.png";
import fiserv from "../../Assets/Images/Companies/fiserv.png";
import fulfillment from "../../Assets/Images/Companies/fulfillment.png";
import Hassan2UniversityOfCasablanca from "../../Assets/Images/Companies/Hassan2UniversityOfCasablanca.png";
import Hopsworks from "../../Assets/Images/Companies/Hopsworks.svg";
import hydrabad from "../../Assets/Images/Companies/hydrabad.png";
import idea from "../../Assets/Images/Companies/idea.svg";
import IHX from "../../Assets/Images/Companies/IHX.png";
import ING from "../../Assets/Images/Companies/ING.png";
import interSystem from "../../Assets/Images/Companies/intersystem.svg"
import jaseci from "../../Assets/Images/Companies/jaseci.svg"
import jpMorganChase from "../../Assets/Images/Companies/jpMorganChase.svg"
import line from "../../Assets/Images/Companies/line.svg"
import litmus from "../../Assets/Images/Companies/litmus.svg"
import LG from "../../Assets/Images/Companies/LG.png";
import loanGiant from "../../Assets/Images/Companies/loangiant.png"
import lumenore from "../../Assets/Images/Companies/lumenore.svg"
import matchEye from "../../Assets/Images/Companies/matchEye.svg";
import ONS from "../../Assets/Images/Companies/ONS.svg";
import PhelexIminingCorporation from "../../Assets/Images/Companies/PhelexIminingCorporation.png";
import PhilippineEducationDepartment from "../../Assets/Images/Companies/PhilippineEducationDepartment.png";
import PrismForce from "../../Assets/Images/Companies/prismForce.png"
import proton from "../../Assets/Images/Companies/proton.svg";
import PWC from "../../Assets/Images/Companies/pwc.svg";
import relanto from "../../Assets/Images/Companies/relanto.svg";
import restino from "../../Assets/Images/Companies/restino.png";
import RevenueRoll from "../../Assets/Images/Companies/RevenueRoll.svg";
import saarthi from "../../Assets/Images/Companies/saarthi.svg";
import sabana from "../../Assets/Images/Companies/sabana.svg";
import satya from "../../Assets/Images/Companies/satya.png";

import technoTask from "../../Assets/Images/Companies/technoTask.svg";
import technoVert from "../../Assets/Images/Companies/technovert.svg";
import technialHub from "../../Assets/Images/Companies/technicalHub.svg";

import TigerAnalytic from "../../Assets/Images/Companies/tigerAnalytic.svg";
import tisaneLabs from "../../Assets/Images/Companies/tisanelabs.png";
import toronto from "../../Assets/Images/Companies/torontoMetropolitanUniversity.svg";
import trek from "../../Assets/Images/Companies/trek.svg";
import tredence from "../../Assets/Images/Companies/Group 12.svg";
import uniwa from "../../Assets/Images/Companies/uniwa.png";
import validMind from "../../Assets/Images/Companies/validmind.svg"
import verteransUnited from "../../Assets/Images/Companies/veteransUnited.svg";
import virtualAnalytic from "../../Assets/Images/Companies/Group 9.svg";
import WorldDataLab from "../../Assets/Images/Companies/WorldDataLab.svg";
import Yahoo from "../../Assets/Images/Companies/yahoo.svg";

import zifo from "../../Assets/Images/Companies/zifo.png";


import ottawa from "../../Assets/Images/Companies/ottawa_catholic.svg"
import specificGroup from "../../Assets/Images/Companies/specific_group.svg"
import agnie from "../../Assets/Images/Companies/Agnie_Consulting.png"

import banter from "../../Assets/Images/Companies/banter.svg"
import xccelerate from "../../Assets/Images/Companies/xccelerate.svg"
import a5gnetwork from "../../Assets/Images/Companies/A5G_Nerworks.svg"
import techstars from "../../Assets/Images/Companies/techstarts.svg"
import dunesproperties from "../../Assets/Images/Companies/dunesproperties.svg"
import axiata from "../../Assets/Images/Companies/axiata.svg"
import cgol from "../../Assets/Images/Companies/Agnie_Consulting.png"
import vedanta from "../../Assets/Images/Companies/vedanta.svg"
import birmingham from "../../Assets/Images/Companies/University_of_birmingham.svg"
import glasgow from "../../Assets/Images/Companies/Univeristy_of_Glasgow.svg"
import kellog from "../../Assets/Images/Companies/Kellog_Community_college.svg";

import universityOfMaderia from "../../Assets/Images/Companies/Universidade_da_Madeira.png";
import hongkongQualityManagement from "../../Assets/Images/Companies/Hong_kong_quality_management.png";
import iiitBhubanseswar from "../../Assets/Images/Companies/IIIT_Bhubaneswar.png";
import stevenInstitute from "../../Assets/Images/Companies/Stevens_Institute_of_Technology_Logo_2022 1.png";
import kantipur from "../../Assets/Images/Companies/Kantipur_Engineering_College.png";
import indianInstituteOfTechnologyKharagpur from "../../Assets/Images/Companies/indian_institute_of_technology_kharagpur.png";
import indianInstituteOfMadras from "../../Assets/Images/Companies/Indian_Institute_of_madras.png";
import chittigongUniversity from "../../Assets/Images/Companies/Chittigong_university_of_engineering_technology.png";
import manratInternationalUniversity from "../../Assets/Images/Companies/manrat_international_University.png";
import pashchimanchalCampus from "../../Assets/Images/Companies/pashchimanchal Campus.png";
import monashUniversity from "../../Assets/Images/Companies/monash-logo 1.png";
import ushaMittal from "../../Assets/Images/Companies/usha_mittal_insstitute_of_technology.png";
import lebaneseUniversity from "../../Assets/Images/Companies/Lebanese_University.png";
import iiitRanchi from "../../Assets/Images/Companies/Inidain_Institute_of_IT_Ranchi.png";
import covenant from "../../Assets/Images/Companies/Covenant_University,.png";
import universityOfCaliforniaSanDiago from "../../Assets/Images/Companies/University_of_California_San_Diego_USA.png";
import texasStateUniversity from "../../Assets/Images/Companies/Texas_State_University_USA.png";
import universityOfJordan from "../../Assets/Images/Companies/Jordan.png";
import karatinaUniversityKenya from "../../Assets/Images/Companies/Karatina_University_Kenya.png";
import priceMohammadBinFahdUniversity from "../../Assets/Images/Companies/Prince_Mohammad_bin_Fahd_University_Saudi_Arabia.png";
import universidadTecnologicaDeBolivarColombia from "../../Assets/Images/Companies/Universidad_technological_de_bolivar_colombia.png";
import peopleUniversity from "../../Assets/Images/Companies/University_of_the_People_Online.png";
import universityOfIllinoisUrbana from "../../Assets/Images/Companies/illinois.png";
import loneStarCollege from "../../Assets/Images/Companies/lone_star_college.png";
import kingAbdulazizUniversity from "../../Assets/Images/Companies/King_Abdulaziz_University.png";
import bostonUniversity from "../../Assets/Images/Companies/Boston_University.png";
import universityOfCordilleras from "../../Assets/Images/Companies/University_of_Cordilieras.png";
import loganBusinessCollegeloganBusinessCollege from "../../Assets/Images/Companies/Logan_Business_College.png";
import Ilorin from "../../Assets/Images/Companies/University_of_ILorin.png";
import caFoscariUniversityOfVenice from "../../Assets/Images/Companies/Ca_ Foscari_University_of_Venice.png";
import bharatiVidyapeethUniversityCollegeOfEngineering from "../../Assets/Images/Companies/Bharathi_Vidyapeeth_Deemed_University.png";
import binusUniversity from "../../Assets/Images/Companies/BINUS_UNIVERSITY.png";
import rmit from "../../Assets/Images/Companies/rmit.png";
import limkok from "../../Assets/Images/Companies/limkok.png";
import manarat from "../../Assets/Images/Companies/manrat_international_University.png";
// import indianInstitueOfPatroleum from "../../Assets/Images/Companies/";




import React from "react";

export const TrustedCompanyList = [

    {Name: "A3Data",Logo: A3Data,},
    {Name: "Adobe",Logo: Adobe,},
    {Name: "Agnie Consulting",Logo: agnie,},
    {Name: "African Institute of Mathematical Science, Rwanda",Logo: aimsRawanda,},
    {Name: "American Family Insurance",Logo: americanFamilyInsurance,},
    {Name: "CAMP India",Logo: CampIndia,},
    {Name: "CAMP System",Logo: campSystem,},
    {Name: "CapGemini",Logo: CapGemini,},
    {Name: "CHASE",Logo: chaseBank,},
    {Name: "CHG Healthcare",Logo: CHG,},
    {Name: "Cisco",Logo: Cisco,},
    {Name: "Cityscape Technology",Logo: CityScape,},
    {Name: "Code Fusion",Logo: codeFusion,},
    {Name: "Cognizant",Logo: Cognizant,},
    {Name: "Collectwise",Logo: collectWise,},
    {Name: "Concourse Labs",Logo: concouseLabs,},
    {Name: "Condé Nast",Logo: condeNast,},
    {Name: "Costomise",Logo: constomise,},
    {Name: "Coveros",Logo: coveros,},
    {Name: "DataQA",Logo: dataQA,},
    {Name: "Dayotech",Logo: dayo,},
    {Name: "Delixus ",Logo: deluxe,},
    {Name: "Dolcera",Logo: Dolcera,},
    {Name: "DTiQ",Logo: DTIQ,},
    {Name: "DuckDuckGo",Logo: duckduckgo,},
    {Name: "Dukamneti Online Shops",Logo: dukamneti,},
    {Name: "ESI",Logo: esi,},
    {Name: "ESPRIT",Logo: esprit,},
    {Name: "ExpertWine Naturvin",Logo: expertwine,},
    {Name: "Fiserv.",Logo: fiserv,},
    {Name: "Fulfillment Center Services",Logo: fulfillment,},
    {Name: "Hassan II University Of Casablanca",Logo: Hassan2UniversityOfCasablanca,},
    {Name: "Hopswork",Logo: Hopsworks,},
    {Name: "Hydrabad Institute of Technology and Management",Logo: hydrabad,},
    {Name: "IDEAS For Us",Logo: idea,},
    {Name: "IHX",Logo: IHX,},
    {Name: "ING Bank",Logo: ING,},
    {Name: "InterSystems",Logo: interSystem,},
    {Name: "Jaseci",Logo: jaseci,},
    {Name: "JPMorgan Chase",Logo: jpMorganChase,},
    {Name: "Line Corporation",Logo: line,},
    {Name: "Litmus7",Logo: litmus,},
    {Name: "LG Electronic",Logo: LG,},
    {Name: "LOANGIANT",Logo: loanGiant,},
    {Name: "Lumenore",Logo: lumenore,},
    {Name: "MachEye",Logo: matchEye,},

    {Name: "Osaka Lead-Tin Refinery Co Ltd, Japan",Logo: ONS,},
    {Name: " Ottawa Catholic School Board",Logo: ottawa,},
    {Name: "Philex Mining Corporation",Logo: PhelexIminingCorporation,},
    {Name: "Philippine Education Department", Logo: PhilippineEducationDepartment},
    {Name: "PrismForce",Logo: PrismForce,},
    {Name: "Proton",Logo: proton,},
    {Name: "PWC(PricewaterhouseCoopers)",Logo: PWC,},
    {Name: "Relanto",Logo: relanto,},
    {Name: "Restino",Logo: restino,},
    // {Name: "RevenueRoll",Logo: RevenueRoll,},
    {Name: "Saarthi AI",Logo: saarthi,},
    {Name: "SABANA",Logo: sabana,},
    {Name: "Satya AI",Logo: satya,},
    {Name: "Specific Group",Logo: specificGroup,},
    {Name: "Technical Hub",Logo: technialHub,},
    {Name: "TECHNOTASK",Logo: technoTask,},
    {Name: "Technovert",Logo: technoVert,},
    {Name: "The University of Western Attica",Logo: uniwa,},

    {Name: "Tiger Analytics",Logo: TigerAnalytic,},
    {Name: "Tisane Labs",Logo: tisaneLabs,},
    {Name: "Toronto Metropolitan University",Logo: toronto,},
    {Name: "Tredence",Logo: tredence,},
    {Name: "TREK",Logo: trek,},
    {Name: "TV Derana",Logo: derana,},
    {Name: "Valid Mind",Logo: validMind,},
    {Name: "Veterans United",Logo: verteransUnited,},
    {Name: "Virtual Analytics",Logo: virtualAnalytic,},

    {Name: "World Data Lab",Logo: WorldDataLab,},
    {Name: "Yahoo!",Logo: Yahoo,},
    {Name: "Zifo",Logo: zifo,},

    {Name: "Banter",Logo: banter,},
    {Name: "Xceelerate",Logo: xccelerate,},
    {Name: "A5g Net",Logo: a5gnetwork,},
    {Name: "Techstars",Logo: techstars,},
    {Name: "Dunes Properties",Logo: dunesproperties,},
    {Name: "Axiata",Logo: axiata,},
    {Name: "C-Gol",Logo: cgol,},
    {Name: "Vedanta",Logo: vedanta,},
    {Name: "University of Birmingham",Logo: birmingham,},
    {Name: "University of Glasgow",Logo: glasgow,},
    {Name: "Kellog Community College",Logo: kellog,},

    {Name: "University of Maderia",Logo: universityOfMaderia,},
    {Name: "IIIT Bhubaneswar",Logo: iiitBhubanseswar,},
    {Name: "Steven institute of technology ",Logo: stevenInstitute,},
    {Name: "Kantipur Engineering College",Logo: kantipur,},
    {Name: "Indian Institute of Technology Kharagpur ",Logo: indianInstituteOfTechnologyKharagpur,},
    {Name: "Indian Institute of madras ",Logo: indianInstituteOfMadras,},
    {Name: "Chittigong University of Engineering Technology",Logo: chittigongUniversity,},
    {Name: "manrat international University ",Logo: manratInternationalUniversity,},
    {Name: "monash University ",Logo: monashUniversity,},
    {Name: "Hong Kong Quality Management  ",Logo: hongkongQualityManagement,},
    {Name: "Usha Mittal Institute of Technology ", Logo: ushaMittal},
    {Name: " Lebanese University", Logo: lebaneseUniversity},
    {Name: "Indian Institute of Information Technology (IIIT) Ranchi.", Logo: iiitRanchi},
    {Name: "Covenant University", Logo: covenant},
    {Name: "University of California, San Diego, USA", Logo: universityOfCaliforniaSanDiago},
    {Name: "Texas State University, USA", Logo: texasStateUniversity},
    {Name: "University of Jordan, Jordan", Logo: universityOfJordan},
    {Name: "Karatina University, Kenya", Logo: karatinaUniversityKenya},
    {Name: "Prince Mohammad bin Fahd University, Saudi Arabia", Logo: priceMohammadBinFahdUniversity},
    {Name: "Universidad Tecnológica de Bolívar, Colombia", Logo: universidadTecnologicaDeBolivarColombia},
    {Name: "University of the People, Online", Logo: peopleUniversity},
    {Name: "University of Illinois at Urbana", Logo: universityOfIllinoisUrbana},
    {Name: "Lone Star College, USA", Logo: loneStarCollege},
    {Name: "King Abdulaziz University, Saudi Arabia", Logo: kingAbdulazizUniversity},
    {Name: "Boston University, USA", Logo: bostonUniversity},
    {Name: "University of the Cordilleras, Philippines", Logo: universityOfCordilleras},
    {Name: "Logan Business College", Logo: loganBusinessCollegeloganBusinessCollege},
    {Name: "University of Ilorin", Logo: Ilorin},
    {Name: "Ca' Foscari University of Venice", Logo: caFoscariUniversityOfVenice},
    {Name: "Bharati Vidyapeeth University College Of Engineering", Logo: bharatiVidyapeethUniversityCollegeOfEngineering},
    {Name: "BINUS UNIVERSITY", Logo: binusUniversity},
    {Name: "RMIT", Logo: rmit},
    {Name: "Limkokwing University of Creative Technology, Botswana", Logo: limkok},
    {Name: "Manarat International University", Logo: manarat},
    {Name: "Pashchimanchal Campus ", Logo: pashchimanchalCampus},


];