import React from "react";
import './termsAndCondition.css';

class Disclaimer extends React.Component{
    render() {
        return (
            <div className="terms-and-conditions-sub-section">
                <div className="sub-section-title">6.&nbsp;Disclaimer</div>
                <div className="align-terms-paragraph">
                    THE SERVICES, INCLUDING, WITHOUT LIMITATION, THE WEBSITE, ONLINE PLATFORM AND ALL CONTENT AND SERVICES ACCESSED THROUGH OR VIA THE WEBSITE, ONLINE PLATFORM OR OTHERWISE, ARE PROVIDED “AS IS,” “AS AVAILABLE,” AND “WITH ALL FAULTS.”
                </div>
                <div className="align-terms-paragraph">
                    YOU AGREE AND ACKNOWLEDGE THAT YOU ASSUME FULL, EXCLUSIVE, AND SOLE RESPONSIBILITY FOR THE USE OF AND RELIANCE ON THE SERVICES, AND YOU FURTHER AGREE AND ACKNOWLEDGE THAT YOUR USE OF OR RELIANCE ON THE SERVICES IS MADE ENTIRELY AT YOUR OWN RISK. YOU FURTHER ACKNOWLEDGE THAT IT IS YOUR RESPONSIBILITY TO COMPLY WITH ALL APPLICABLE LAWS WHILE USING THE SERVICE.
                </div>
                <div className="align-terms-paragraph">
                    WHILE THE COMPANY USES REASONABLE ENDEAVOURS TO CORRECT ANY ERRORS OR OMISSIONS IN THE SERVICES AS SOON AS PRACTICABLE ONCE THEY HAVE BEEN BROUGHT TO THE COMPANY’S ATTENTION, THE COMPANY MAKES NO PROMISES, GUARANTEES, REPRESENTATIONS, OR WARRANTIES OF ANY KIND WHATSOEVER (EXPRESS OR IMPLIED) REGARDING THE SERVICES, OR ANY PART OR PARTS THEREOF, ANY CONTENT, OR ANY LINKED SERVICES OR OTHER EXTERNAL SERVICES. THE COMPANY DOES NOT WARRANT THAT YOUR USE OF THE SERVICES WILL BE ACCURATE OR RELIABLE, UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THE SERVICES OR ANY PART OR PARTS THEREOF, THE CONTENT, OR THE SERVERS ON WHICH THE SERVICES OPERATES ARE OR WILL BE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. THE COMPANY DOES NOT WARRANT THAT ANY TRANSMISSION OF CONTENT UPLOADED TO THE SERVICES WILL BE SECURE OR THAT ANY ELEMENTS OF THE SERVICES DESIGNED TO PREVENT UNAUTHORISED ACCESS, SHARING OR DOWNLOAD OF CONTENT WILL BE EFFECTIVE IN ANY AND ALL CASES, AND DOES NOT WARRANT THAT YOUR USE OF THE SERVICES IS LAWFUL IN ANY PARTICULAR JURISDICTION.
                </div>
                <div className="align-terms-paragraph">
                    THE COMPANY AND ITS SUBSIDIARIES, AFFILIATES, SUCCESSORS, AND ASSIGNS, AND THEIR RESPECTIVE EMPLOYEES, AGENTS, DIRECTORS, OFFICERS AND SHAREHOLDERS, SPECIFICALLY DISCLAIM ALL OF THE FOREGOING WARRANTIES AND ANY OTHER WARRANTIES NOT EXPRESSLY SET OUT HEREIN TO THE FULLEST EXTENT PERMITTED BY LAW, INCLUDING WITHOUT LIMITATION ANY EXPRESS OR IMPLIED WARRANTIES REGARDING NON-INFRINGEMENT, MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE.
                </div>
                <div className="align-terms-paragraph">
                    WHERE THE LAW OF ANY JURISDICTION LIMITS OR PROHIBITS THE DISCLAIMER OF IMPLIED OR OTHER WARRANTIES AS SET OUT ABOVE, THE ABOVE DISCLAIMERS SHALL NOT APPLY TO THE EXTENT THAT THE LAW OF SUCH JURISDICTION APPLIES TO THIS AGREEMENT.
                </div>
            </div>
        );
    }
}
export default Disclaimer;