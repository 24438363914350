import React from 'react';
import './ConfusionMatrix.css';
import PropTypes from 'prop-types';
import empty_confusion_matrix from '../Images/no-result-found.png'


const recursiveColorRange = (highest,lowest,colorRange,lengthOfArray)=>{
    colorRange[0] = highest;
    colorRange[lengthOfArray-1] = lowest;
    let middle = Math.ceil((lengthOfArray-1) / 2);
    colorRange[middle] = (highest+lowest)/2;
    console.log('RecursiveColorRange',colorRange[0],colorRange[lengthOfArray-1],colorRange[middle])
    if(colorRange[middle] >= 0 ){
        if(middle > 1){
            for(let x= 0; x < middle; x++){
                colorRange[Math.ceil((middle)/2)] = (highest + colorRange[middle])/2;
            }
            for(let x= lengthOfArray-1;x >= middle; x--){
                colorRange[Math.ceil((middle+ lengthOfArray-1)/2)] = (lowest+ colorRange[middle]) /2;
            }
        }
        console.log('RecursiveColorRange1',colorRange)
        return colorRange;
    }else{
        colorRange[1]=lowest;
        console.log('RecursiveColorRange1',colorRange)
        return colorRange;
    }


}
class ConfusionMatrix extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            largestData: 10,
            smallestData:0,
            colorStatusRange : [0,0,0,15,0,5],
            middleData: 9,
            matrixSingleArray: [],
            longestColName: 1,
            colorBarHeight: ''
        }
        this.calculateColorRange = this.calculateColorRange.bind(this);
        this.getMetricLabel = this.getMetricLabel.bind(this);
        this.getLongestColName = this.getLongestColName.bind(this);
    }


    componentDidMount() {
        if(this.props.matrixData !== undefined && this.props.matrixData !== '' && this.props.matrixData !== null)
        {
            this.calculateColorRange(this.props.matrixData);
            this.getLongestColName(this.props.predictLabel);
            if(document.getElementById('matrix-container') !== undefined && document.getElementById('matrix-container') !== null){
                let colorBarHeight= document.getElementById('matrix-container').offsetHeight;
                if(colorBarHeight !== undefined && colorBarHeight !== null && colorBarHeight !== ""){
                    this.setState({
                        colorBarHeight: colorBarHeight
                    })
                }
            }


        }

    }
    componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.matrixData !== undefined && nextProps.matrixData !== '' && nextProps.matrixData !== null) {
            this.calculateColorRange(nextProps.matrixData);
            this.getLongestColName(this.props.predictLabel);
            if(document.getElementById('matrix-container') !== undefined && document.getElementById('matrix-container') !== null){
                let colorBarHeight= document.getElementById('matrix-container').offsetHeight;
                if(colorBarHeight !== undefined && colorBarHeight !== null && colorBarHeight !== ""){
                    this.setState({
                        colorBarHeight: colorBarHeight
                    })
                }
            }

        }
    }

    calculateColorRange(matrixData){
        let colorRange ;
        if(matrixData !== undefined && matrixData !== null && matrixData !== ""){
            if(matrixData.length > 0){
                let largest = matrixData[0][0];
                let smallest = matrixData[0][0];
                for(let x = 0 ;x < matrixData.length; x++){
                    for (let y= 0; y< matrixData[x].length; y++){
                        if(matrixData[x][y] > largest){
                            largest = matrixData[x][y];
                        }
                        if(matrixData[x][y] < smallest){
                            smallest = matrixData[x][y]
                        }
                    }
                }
                if(matrixData.length % 2 === 0 ){
                    colorRange = new Array(matrixData.length+1)
                }else{
                    colorRange = new Array(matrixData.length);
                }
                let colorRangeBar = recursiveColorRange(largest,smallest, colorRange, colorRange.length)
                this.setState({
                    colorStatusRange: colorRangeBar,
                    largestData: largest,
                    smallestData: smallest,
                    middleData: colorRangeBar[(largest+smallest)/2]
                })
            }
        }

    }

    getMetricLabel(matrixData){
        let matrixLabel = [];
        if(matrixData !== undefined && matrixData !== null && matrixData !== ""){
            if(matrixData.length > 0 ){
                for(let i=0;i<matrixData.length; i++){
                    matrixLabel = matrixLabel.concat(i);
                }
                this.setState({
                    predictLabel: matrixLabel,
                    actualLabel: matrixLabel
                })
            }
        }

    }
    getLongestColName (labelArray){
        let longest = 0;
        if(labelArray !== undefined && labelArray !== null && labelArray !== "") {
            if (labelArray.length > 0) {
                for(let label=0;label < labelArray.length; label++){
                    if(labelArray[label].length > longest){
                        longest = labelArray[label].length;
                    }
                }
                longest = longest * 8 + 50;
                this.setState({
                    longestColName: longest
                })
            }
        }

    }

    getLabelWidth = (width) =>{
        if(width > 200){
            return ("200px")
        }
        else return (width+"px")
    }
    render() {
        let {matrixData, predictLabel , actualLabel,matrixCenterWidth} = this.props;
        return(
            matrixData !== undefined && matrixData !== null && matrixData !== "" ?
                matrixData.length !== 0?
                    predictLabel.length > 12 || matrixData.length > 12 || actualLabel.length > 12 ?

                        <div className="confusion-matrix-overall-container multi-column-matrix">
                            <div className="confusion-matrix-predict-label">
                                Actual Label
                            </div>
                            <div className="confusion-matrix-center-container" style={{width: `calc(100% - ${matrixCenterWidth})`}}>
                                <div className="confusion-matrix-table-container">
                                    <div className="confusion-matrix-table-horizontal-container multi-column-matrix">
                                        <div className="confusion-matrix-vertical-data-container" >
                                            {
                                                matrixData.map((matrixRowInfo,matrixRowIndex)=>
                                                    <div className="confusion-matrix-horizontal-data-container">
                                                        <div className="multi-matrix-actual-label"
                                                             title={
                                                                 actualLabel !== undefined && actualLabel !== null && actualLabel !== "" ?
                                                                     actualLabel[matrixRowIndex]
                                                                     :
                                                                     matrixRowIndex
                                                             }
                                                             style={{width:this.getLabelWidth(this.state.longestColName, "actaul labe")}}>
                                                            {
                                                                actualLabel !== undefined && actualLabel !== null && actualLabel !== "" ?
                                                                    actualLabel[matrixRowIndex]
                                                                    :
                                                                    matrixRowIndex
                                                            }
                                                        </div>
                                                        <div className="multi-matrix-actual-value-container">
                                                            {
                                                                matrixRowInfo.map((matrixColInfo)=>
                                                                    <div className="multi-matrix-info-container"
                                                                         title={matrixColInfo}
                                                                         style={{backgroundColor: `rgba(252, 127, 58,${(matrixColInfo/this.state.largestData)})`,color: matrixColInfo/this.state.largestData >= 0.5 ? '#fff': '#000' }}>
                                                                        {matrixColInfo}
                                                                    </div>
                                                                )
                                                            }
                                                        </div>

                                                    </div>
                                                )
                                            }
                                            <div className="confusion-matrix-horizontal-predict-container">
                                                <div className="confusion-matrix-horizontal-predict-container-hider" style={{width: this.getLabelWidth(this.state.longestColName, "hider")}}/>
                                                {
                                                    predictLabel !== "" && predictLabel !== undefined && predictLabel !== null?
                                                        matrixData.map((data,dataIndex)=>
                                                            <div className="multi-matrix-predict-label" title={predictLabel[dataIndex]}>
                                                                {predictLabel[dataIndex]}
                                                            </div>
                                                        )
                                                        :
                                                        matrixData.map((_, labelIndex)=>
                                                            <div className="multi-matrix-predict-label" title={labelIndex+1}>
                                                                {labelIndex+1}
                                                            </div>
                                                        )
                                                }
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="multi-class-matrix-label">
                                    Predict Label
                                </div>
                            </div>
                            <div className="multi-col-matrix-color-bar-container">
                                <div className="multi-col-matrix-color-bar" style={
                                    {
                                        background: `linear-gradient(0deg, rgba(252, 127, 58,${(this.state.colorStatusRange[this.state.colorStatusRange.length-1]/this.state.largestData)}) 0%,  rgba(252, 127, 58,${(this.state.colorStatusRange[0]/this.state.largestData)}) 50%,rgba(252, 127, 58) 100%)`,
                                    }
                                }/>

                            </div>
                            <div className="multi-col-matrix-color-range">
                                {
                                    this.state.colorStatusRange.map((colorRange,colorRangeID)=>(
                                        colorRangeID === this.state.colorStatusRange.length-1 ?
                                            <label style={{marginBottom:'0'}}>{colorRange}</label>
                                            :
                                            <label>{colorRange}</label>
                                    ))
                                }
                            </div>
                        </div>
                        :
                        <div className='confusion-matrix-overall-container' >
                            <div className="confusion-matrix-label-container" style={{height:`${this.state.colorBarHeight}px`}}>
                                <div className="confusion-matrix-predict-label less-column">
                                    Actual Label
                                </div>
                            </div>

                            <div className='confusion-matrix-vertical-container' style={{minWidth: matrixData.length === 2 ? "400px": "600px"}}>
                                <div className='confusion-matrix-show-data-container' >
                                    <div className='confusion-matrix-y-axis-container'  id="matrix-container" >
                                        {
                                            matrixData !== "" ?
                                                matrixData.map((row,rowIndex)=>
                                                    <div className='confusion-matrix-x-axis-container'>
                                                        <div className="matrix-label-container-box" title={actualLabel[rowIndex]} style={{minWidth: `${this.state.longestColName - 30 }px`}}>
                                                            {actualLabel[rowIndex]}
                                                        </div>
                                                        {
                                                            row.map((column)=>
                                                                <div className='matrix-container-box' title={column} style={{backgroundColor: column/this.state.largestData === 0 ? "white" : `rgba(252, 127, 58,${(column/this.state.largestData)})`,color: column/this.state.largestData >= 0.5 ? '#fff': '#000' , width : `calc(100% - ${this.state.longestColName - 30} px )`}}>
                                                                    {column}
                                                                </div>
                                                            )
                                                        }
                                                    </div>

                                                )
                                                :
                                                null
                                        }
                                    </div>
                                </div>
                                <div className='confusion-matrix-actual-label-container'>
                                    <div className="matrix-label-container-box" style={{minWidth: `${this.state.longestColName-30}px`}}/>
                                    {
                                        predictLabel !== undefined && predictLabel !== "" ?
                                            matrixData.map((_, labelIndex)=>
                                                <div  className={predictLabel.length >= 5 ? 'matrix-predict-rotate-label': 'matrix-container-box predict-label'} title={predictLabel[labelIndex]}>
                                                    {
                                                        predictLabel[labelIndex]
                                                    }
                                                </div>
                                            )
                                            :
                                            null
                                    }
                                </div>
                                <div className='confusion-matrix-actual-label'>
                                    Predicted Label
                                </div>
                            </div>
                            <div className='confusion-matrix-color-range-container'>
                                <div style={{display: "flex"}}>
                                    <div className='confusion-matrix-show-color-bar'
                                         style={
                                             {
                                                  height:`${this.state.colorBarHeight}px`
                                             }
                                         }
                                    >
                                        <div className="confusion-matrix-color-bar"
                                             style={
                                                 {
                                                     background: `linear-gradient(0deg, rgba(252, 127, 58,${(this.state.colorStatusRange[this.state.colorStatusRange.length-1]/this.state.largestData)}) 0%,  rgba(252, 127, 58,${(this.state.colorStatusRange[0]/this.state.largestData)}) 50%, rgba(252, 127, 58) 100%)`,
                                                 }
                                             }
                                        />
                                    </div>
                                    <div className='confusion-matrix-show-color-range' style={{height: `${this.state.colorBarHeight+10}px`}}>
                                        {
                                            this.state.colorStatusRange.map((colorRange,colorRangeID)=>(
                                                colorRangeID === this.state.colorStatusRange.length-1 ?
                                                    <label style={{marginBottom:'0'}}>{colorRange}&nbsp;(Lowest)</label>
                                                    :
                                                    colorRangeID === 0 ?
                                                        <label>{colorRange}&nbsp;(Highest)</label>
                                                        :
                                                        <label>{colorRange}</label>
                                            ))
                                        }
                                    </div>
                                </div>

                            </div>

                        </div>
                    :
                    <div className='confusion-matrix-empty-container'>
                        <div className='confusion-matrix-empty-image'>
                            <img alt={''} src={empty_confusion_matrix} className='confusion-matrix-image'/>
                        </div>
                        <div className='empty-confusion-matrix-text'>
                            You have no data to generate a confusion matrix. You should first generate a suitable model.
                        </div>
                    </div>
                :
                <div className='confusion-matrix-empty-container'>
                    <div className='confusion-matrix-empty-image'>
                        <img alt={''} src={empty_confusion_matrix} className='confusion-matrix-image'/>
                    </div>
                    <div className='empty-confusion-matrix-text'>
                        You have no data to generate a confusion matrix. You should first generate a suitable model.
                    </div>
                </div>

        )
    }
}
ConfusionMatrix.defaultProps={
    matrixData : [],
    actualLabel: [],
    matrixHeight:'60vh'
}
ConfusionMatrix.propTypes={
    matrixData: PropTypes.array.isRequired,
    predictLabel: PropTypes.array,
    actualLabel: PropTypes.array,
    matrixHeight: PropTypes.string,
    matrixWidth: PropTypes.string
}
export default ConfusionMatrix;